import React, {Fragment, memo} from 'react';
import {useSelector} from 'react-redux';

/**
 * VisibilityWrapper
 *
 * @param {{
 *   additionalCondition?: boolean,
 *   visibleSelector: (state: any) => boolean,
 *   children: React.ReactNode,
 * }} props
 * @returns {React.ReactElement | null} element
 */
function VisibilityWrapper({
	additionalCondition = true,
	children,
	visibleSelector,
}) {
	const visible = useSelector(visibleSelector);

	// TODO: handle `additionalCondition` in "upper code"
	// ```
	// <VisibilityWrapper selector={...}>
	//     {condition && (
	//         ...
	//     )}
	// </VisibilityWrapper>
	// ```
	// pro: makes code more "readable" (the condition is right there, not some prop)
	// con: adds one indentation level
	if (visible && additionalCondition) {
		// we're using `Fragment` here to convert `ReactNode` to a `ReactElement`
		return (
			<Fragment>
				{children}
			</Fragment>
		);
	} else {
		return null;
	}
}

export default memo(VisibilityWrapper);
