import React, {memo} from 'react';

function AttributionEntries({attributions, additional}) {
	return (
		<div className="ms3-attribution">
			<ul className="ms3-attribution__entries">
				{attributions && (
					Object.entries(attributions).map(([key, attr], i) => (
						typeof attr === 'string' ?
							<li
								className="ms3-attribution__entry"
								key={key}
								dangerouslySetInnerHTML={{__html: attr}}
							/> :
							<li
								className="ms3-attribution__entry"
								key={key}
							>
								{attr}
							</li>
					))
				)}
				{additional}
			</ul>
		</div>
	);
}

export default memo(AttributionEntries);
