import {connect} from 'react-redux';
import {createSelector, createStructuredSelector} from 'reselect';

import {createListFeatureSelector, featureSourceIdSelector} from '@mapsight/core/lib/list/selectors';
import {getAllFeatures, getFeatureSourceError, getFeatureSourceStatus} from '@mapsight/core/lib/feature-sources/selectors';

import {FEATURE_LIST, FEATURE_SOURCES, USER_GEOLOCATION} from '../../config/constants/controllers';
import sortFeaturesByDistance from '../../helpers/sort-features-by-distance';
import {
	lastListScrollPositionSelector,
	listQuerySelector,
	listSortingSelector,
	listPageSelector,
	listUiOptionsSelector,
	placesSelector,
	viewSelector,
	tagSwitcherShowSelector,
	layerSwitcherShowExternalSelector,
} from '../../store/selectors';

import FeatureList from './feature-list';
import {filterFeatures} from './filtering';

const selector = createStructuredSelector({
	listUiOptions: listUiOptionsSelector,
	tagSwitcherShow: tagSwitcherShowSelector,
	layerSwitcherShowExternal: layerSwitcherShowExternalSelector,
	view: viewSelector,
	scrollPosition: lastListScrollPositionSelector,
	featureSourceId: createSelector(state => state[FEATURE_LIST], featureSourceIdSelector),
	featureSource: createListFeatureSelector(FEATURE_LIST, FEATURE_SOURCES),
	page: listPageSelector,
	filterOptions: createStructuredSelector({
		query: listQuerySelector,
		sorting: listSortingSelector,
		places: createSelector(
			[
				state => state[USER_GEOLOCATION],
				placesSelector,
			],
			(userGeolocation, places) => ({
				...places,
				geolocation: {
					x: userGeolocation.longitude,
					y: userGeolocation.latitude,
				},
			})
		),
	}),
});

function paginateFeatures(features, page, itemsPerPage) {
	const offsetStart = page * itemsPerPage;
	const offsetEnd = offsetStart + itemsPerPage;

	return features.slice(offsetStart, offsetEnd);
}

const mergeProps = ({listUiOptions, featureSource, filterOptions, page, ...stateProps}, dispatchProps, ownProps) => {
	const features = getAllFeatures(featureSource);

	// 1. filter
	const filteredFeatures = filterFeatures(filterOptions.query, features);

	// get (total) feature count after filtering, but _before_ paging
	const featureCount = filteredFeatures.length;

	// 2. sort
	const sortedFeatures = sortFeaturesByDistance(filteredFeatures, filterOptions.places, filterOptions.sorting);

	// 3. paginate
	const paginatedFeatures = listUiOptions.paginationControl !== false ?
		paginateFeatures(sortedFeatures, page, listUiOptions.itemsPerPage) :
		sortedFeatures;

	return ({
		...dispatchProps,
		...ownProps,
		...stateProps,
		page: page,
		featureCount: featureCount,
		listUiOptions: listUiOptions,
		status: getFeatureSourceStatus(featureSource),
		error: getFeatureSourceError(featureSource),
		features: features,
		filteredFeatures: paginatedFeatures,
	});
};

export default connect(selector, null, mergeProps)(FeatureList);
