import get from '@neonaut/lib-js/es/object/getPath';

/**
 * @typedef {Object} TimeFilterState
 * @property {Object} [options]
 * @property {String} [options.fromDate] from datetime in ISO 8601 (Y-m-d\TH:i:sP)
 * @property {String} [options.toDate] to datetime in ISO 8601 (Y-m-d\TH:i:sP)
 * @property {boolean} [options.allowEmpty=false] if set to true the filter will filter even if neither from nor to date are set.
 */

// TODO: document/collect magic property names
/**
 * @typedef {Object} FeatureWithWhen
 * @property {Object} [when]
 * @property {String} start start datetime in ISO 8601 (Y-m-d\TH:i:sP)
 * @property {String} end end datetime in ISO 8601 (Y-m-d\TH:i:sP)
 */

/**
 * Feature filter to filter by when property in a time range.
 * Features are expected to have a when object property with
 * start and end datetimes.
 *
 * @param {Array.<FeatureWithWhen>} features features
 * @param {TimeFilterState} filterState filter state
 * @returns {Array.<FeatureWithWhen>} filtered features
 */
export default function timeFilter(features, filterState) {
	const filterOptions = filterState && filterState.options;
	if (!filterOptions) {
		return features;
	}

	const {fromDate, toDate, allowEmpty = false} = filterOptions;
	if (!allowEmpty && !fromDate && !toDate) {
		return features;
	}


	// TODO: document/collect magic property names (here: when.start, when.end)
	/* NOTE(PG): parenthesis make it more readable here */
	/* eslint-disable no-extra-parens */
	const predicate = toDate ?
		(
			feature => (
				get(feature, ['when', 'start']) <= toDate &&
				get(feature, ['when', 'end']) >= (fromDate || Date.now())
			)
		) : (
			feature => get(feature, ['when', 'end']) >= fromDate
		);

	return features.filter(predicate);
}
