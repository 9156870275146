export function groupBy(items, groupByF) {
	const itemsByGroupKey = new Map();
	for (const item of items) {
		const groupKey = groupByF(item);
		const itemsForGroup = itemsByGroupKey.get(groupKey);
		if (itemsForGroup !== undefined) {
			itemsForGroup.push(item);
		} else {
			itemsByGroupKey.set(groupKey, [item]);
		}
	}
	return itemsByGroupKey;
}
