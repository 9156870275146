import {createStructuredSelector} from 'reselect';
import {connect} from 'react-redux';

import {visibleLayerAttributionsSelector} from '@mapsight/core/lib/map/selectors';

import {MAP} from '../../config/constants/controllers';

import AttributionEntries from './attribution-entries';

export default connect(createStructuredSelector({
	attributions: state => visibleLayerAttributionsSelector(state[MAP]),
}))(AttributionEntries);
