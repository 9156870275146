import Feature from 'ol/feature';
import Point from 'ol/geom/point';
import LineString from 'ol/geom/linestring';

import getCentroid from '@mapsight/lib-ol/feature/getCentroid';
import spreadPointClusterInRadius from '@mapsight/lib-ol/points/spreadPointClusterInRadius';

export default function createCreateClusterIntoSpreadRadiusFeature(resolution, radius) {
	return function clusterIntoSpreadRadiusFeature(clusterCenter, clusterFeatures) {
		const featureCentroids = clusterFeatures.map(feature => getCentroid(feature));
		const spreadPoints = spreadPointClusterInRadius(clusterCenter, resolution, radius, featureCentroids);
		const result = [];

		Object.keys(spreadPoints).forEach(function createSpreadFeaturesForPoint(pointI) {
			const spreadPoint = spreadPoints[pointI];
			const feature = clusterFeatures[pointI];
			const basePoint = featureCentroids[pointI];
			const translateX = spreadPoint[0] - basePoint[0];
			const translateY = spreadPoint[1] - basePoint[1];

			const id = feature.getId();
			const state = feature.get('state');
			const previousState = feature.get('previousState');

			const spreadFeature = feature.clone();
			spreadFeature.set('spread', true, true);
			spreadFeature.getGeometry().translate(translateX, translateY);
			spreadFeature.setId(feature.getId());
			result.push(spreadFeature);

			// Line from spread (translated) feature to base position
			const lineFeature = new Feature({
				id: 'cluster-spread-line||' + id,
				spreadFeatureLine: true,
				geometry: new LineString([basePoint, spreadPoint]),
				selectable: false,
				relatedFeature: spreadFeature,
				relatedFeatureId: id,
				relatedFeatureState: state,
				relatedFeaturePreviousState: previousState,
			});
			result.push(lineFeature);

			// Dot at base position
			const dotFeature = new Feature({
				id: 'cluster-spread-dot||' + id,
				spreadFeatureDot: true,
				geometry: new Point(basePoint),
				selectable: false,
				relatedFeature: spreadFeature,
				relatedFeatureId: id,
				relatedFeatureState: state,
				relatedFeaturePreviousState: previousState,
			});
			result.push(dotFeature);
		});

		return result;
	};
}
