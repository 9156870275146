import {useEffect} from 'react';

import useUpdateMapSizeCallback from './useUpdateMapSizeCallback';

export default function useUpdateMapSizeOnTransitionEnd(targetRef, dispatch, previousPositionRef = undefined, reCenter = true) {
	const updateMapSize = useUpdateMapSizeCallback(dispatch, previousPositionRef, reCenter);

	/** @var {Element} target */
	const target = targetRef.current;

	useEffect(function () {
		function handleTransitionEnd(e) {
			if (e.target === target) {
				updateMapSize();
			}
		}

		if (target) {
			target.addEventListener('transitionend', handleTransitionEnd);
		}

		return function () {
			if (target) {
				target.removeEventListener('transitionend', handleTransitionEnd);
			}
		};
	}, [target, updateMapSize]);
}
