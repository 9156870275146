import equals from '../coordinates/equals';

export default function getLineStringSegmentVerticesWithRotation(lineStringGeometry, verticesFilter = 'all', intermediateFilter = 'all') {
	const verticesWithRotation = [];
	const first = lineStringGeometry.getFirstCoordinate();
	const last = lineStringGeometry.getLastCoordinate();

	lineStringGeometry.forEachSegment(function processSegment(start, end) {
		const dx = end[0] - start[0];
		const dy = end[1] - start[1];
		const rotation = -Math.round(Math.atan2(dy, dx) * 100) / 100;

		if (
			verticesFilter === 'first' ? equals(start, first) : (
				(verticesFilter === 'start' || verticesFilter === 'all') &&
				!(intermediateFilter === 'intermediate' && equals(start, first))
			)
		) {
			verticesWithRotation.push([[...start], rotation]);
		}

		if (
			verticesFilter === 'last' ? equals(end, last) : (
				(verticesFilter === 'end' || verticesFilter === 'all') &&
				!(intermediateFilter === 'intermediate' && equals(end, last))
			)
		) {
			verticesWithRotation.push([[...end], rotation]);
		}
	});

	return verticesWithRotation;
}
