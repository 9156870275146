"use strict";

exports.__esModule = true;
exports.AbortObserving = void 0;
exports.getAndObserveState = getAndObserveState;
exports.observeState = observeState;
exports.observeStateOnce = observeStateOnce;

function strictEqualCompare(a, b) {
  return a === b;
} //const jsonCompare = (a, b)  => JSON.stringify(a) !== JSON.stringify(b);


var AbortObserving = {}; // TODO: Use Symbol()

exports.AbortObserving = AbortObserving;

function internalObserveState(store, selector, listener, compare, initialValue) {
  compare = compare || strictEqualCompare;
  var currentValue = initialValue;
  var unsubscribe = store.subscribe(function onStateChange() {
    var state = store.getState();
    var newValue = selector(state);

    if (!compare(currentValue, newValue)) {
      var oldValue = currentValue;
      currentValue = newValue;
      var returnValue = listener(newValue, oldValue, state);

      if (returnValue === AbortObserving) {
        unsubscribe();
      }
    }
  });
  return unsubscribe;
}
/**
 * TODO
 *
 * @param {*}        store      TODO
 * @param {Function} selector   TODO
 * @param {Function} listener   TODO
 * @param {Function} [compare]  function used to compare, like _.isEqual, the default tests for strict equality using ===
 * @returns {Function} unsubscribe function
 */


function observeState(store, selector, listener, compare) {
  var initialValue = selector(store.getState());
  return internalObserveState(store, selector, listener, compare, initialValue);
}
/**
 * TODO
 *
 * @param {*}        store      TODO
 * @param {Function} selector   TODO
 * @param {Function} listener   TODO
 * @param {Function} [compare]  function used to compare, like _.isEqual, the default tests for strict equality using ===
 * @returns {Function} unsubscribe function
 */


function observeStateOnce(store, selector, listener, compare) {
  compare = compare || strictEqualCompare;
  var unsubscribe = observeState(store, selector, function handleChange() {
    listener.apply(void 0, arguments);
    unsubscribe();
  }, compare);
  return unsubscribe;
}
/**
 * TODO
 *
 * @param {*}        store      TODO
 * @param {Function} selector   TODO
 * @param {Function} listener   TODO
 * @param {Function} [compare]  function used to compare, like _.isEqual, the default tests for strict equality using ===
 * @returns {Function} unsubscribe function
 */


function getAndObserveState(store, selector, listener, compare) {
  compare = compare || strictEqualCompare;
  var initialState = store.getState();
  var initialValue = selector(initialState);
  var initialReturnValue = listener(initialValue, null, initialState);

  if (initialReturnValue === AbortObserving) {
    return function () {};
  }

  return internalObserveState(store, selector, listener, compare, initialValue);
}