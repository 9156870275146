import Modify from 'ol/interaction/modify';
import Collection from 'ol/collection';
import olEvents from 'ol/events';
import olSourceVectorEventType from 'ol/source/vectoreventtype';
import olInteractionModifyEventType from 'ol/interaction/modifyeventtype';

export default class ModifyInteraction extends Modify {
	constructor(options) {
		const featureCollection = new Collection();

		super({
			...options,

			// The ol constructor requires a feature source or features collection to be passed to the constructor
			// we want to be able to change the source later on so we use a collection as a "buffer", an keep it synchronized.
			features: featureCollection,
			source: undefined,
		});

		this._source = null;
		this._featureCollection = featureCollection;
		olEvents.listen(this, olInteractionModifyEventType.MODIFYEND, this.handleModifyEnd, this);
	}

	getSource() {
		return this._source;
	}

	setSource(source) {
		const oldSource = this._source;
		this._source = source;

		if (source !== oldSource) {
			if (oldSource) {
				olEvents.unlisten(oldSource, olSourceVectorEventType.ADDFEATURE, this.handleSourceAdd, this);
				olEvents.unlisten(oldSource, olSourceVectorEventType.CLEAR, this.removeAllFeatures, this);
				olEvents.unlisten(oldSource, olSourceVectorEventType.REMOVEFEATURE, this.handleSourceRemove, this);
			}
			this.removeAllFeatures();

			if (source) {
				source.getFeatures().forEach(feature => this.addFeature(feature));
				olEvents.listen(source, olSourceVectorEventType.ADDFEATURE, this.handleSourceAdd, this);
				olEvents.listen(source, olSourceVectorEventType.CLEAR, this.removeAllFeatures, this);
				olEvents.listen(source, olSourceVectorEventType.REMOVEFEATURE, this.handleSourceRemove, this);
			}
		}
	}

	/**
	 * @param {ol.source.Vector.Event} event Event.
	 * @private
	 */
	handleSourceAdd({feature}) {
		this.addFeature(feature);
	}

	/**
	 * @param {ol.source.Vector.Event} event Event.
	 * @private
	 */
	handleSourceRemove({feature}) {
		this.removeFeature(feature);
	}

	addFeature(feature) {
		if (feature) {
			this._featureCollection.push(feature);
		}
	}

	removeFeature(feature) {
		if (feature) {
			this._featureCollection.remove(feature);
		}
	}

	removeAllFeatures() {
		this._featureCollection.forEach(feature => this.removeFeature(feature));
	}

	getStyle() {
		return this.overlay_.getStyle();
	}

	setStyle(styleFunction) {
		this.overlay_.setStyle(styleFunction);
	}

	handleModifyEnd({features}) {
		if (this._source && features) {
			this._source.updateFeatures(features.getArray());
		}
	}
}
