import React from 'react';

import globalEventHub, {EVENT_PARTIAL_CONTENT_CHANGED} from '../../helpers/global-event-hub';
import getFeatureProperty from '../../helpers/get-feature-property';
import FeatureDetailsContent from '../feature-details-content';

import FeatureListIcon from './icon';

const preventDefaultClick = e => {
	// Continue as normal for external links and cmd clicks etc
	if (e.which === 2 || e.metaKey) {
		e.stopPropagation();
	} else {
		e.preventDefault();
	}
};

function determineShowDetails({isSelected, isMobile, forceShowDetails}) {
	return isSelected && (isMobile || forceShowDetails); // Warum hat forceShowDetails keinen Vorrang, also: `forceShowDetails || (isSelected && isMobile)` ? -- forceShowDetails ist wahrscheinlich ein allgemeiner Parameter für das Verhalten der Liste, der das Verhalten bei isSelected bestimmt. daher hat isSelected Vorrang
}

function smoothScrollListItemToViewTop(element, offset) {
	if (!element) {
		return;
	}

	const elementOffset = element.getBoundingClientRect().top;
	const scrollDelta = elementOffset - offset;

	window.scrollBy({
		left: 0,
		top: scrollDelta,
		behavior: 'smooth',
	});
}

export default class FeatureListItem extends React.Component {
	constructor(props) {
		super(props);
		this._showDetails = determineShowDetails(props);
		this._lastIsPreselected = undefined;
		this._lastScrollOnPreselection = undefined;
		/** @type {HTMLElement | null} */
		this._ref = null;
		this.setRef = ref => {
			this._ref = ref;
		};
	}

	componentDidMount() {
		this.scrollEffect();
	}

	UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
		this._lastShowDetails = this._showDetails;
		this._showDetails = determineShowDetails(nextProps);
		this._lastIsPreselected = this.props.isPreselected;
		this._lastScrollOnPreselection = nextProps.scrollOnPreselection;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		globalEventHub.emit(EVENT_PARTIAL_CONTENT_CHANGED);
		this.scrollEffect();
	}

	scrollEffect() {
		if (typeof window === 'undefined' || this._ref === null) {
			return;
		}

		const hasToggledDetailsOn = this._showDetails && this._showDetails !== this._lastShowDetails;
		if (this.props.scrollOnSelection && hasToggledDetailsOn) {
			if (this._scrollTimer) {
				window.clearInterval(this._scrollTimer);
			}

			this._scrollTimer = window.setTimeout(
				() => smoothScrollListItemToViewTop(this._ref, this.props.topOffset),
				250,
			);
		} else if (
			// scrolling on preselection is currently enabled & it changed from a disabled state
			// the else is necesarry to not fire on select+preselect as we only want to focus on preselect without select
			// preselect without select is never done by map events but only by project code or by the withKeyboard(Comp)
			this.props.scrollOnPreselection === true
			&& this.props.isPreselected === true
			&& (
				this._lastScrollOnPreselection !== true
				|| this._lastIsPreselected !== true
			)
		) {
			if (this._scrollTimer) {
				window.clearInterval(this._scrollTimer);
			}

			this._scrollTimer = window.setTimeout(
				() => {
					if (this._ref) {
						this._ref.scrollIntoView();
						// scrollOnPreselection wird niemals von der Karte ausgelöst, daher ist focus() hier ok
						// damit es funktioniert, muss das item focus-able sein, s.u. tabIndex bei wrapperProps
						this._ref.focus();
					}
				},
				250,
			);
		}
	}

	render() {
		if (this.props.hidden) {
			return null;
		}

		const {
			as: T = 'tr',
			detailsHtml,
			detailsHasError,
			feature,
			isHighlighted,
			isPreselected,
			isSelected,
			onFeatureHighlight,
			onFeatureSelection,
			onFeatureUnHighlight,
			onFeatureUnSelection,
			showFeatureListInfo,
			selectOnClick = true,
			deselectOnClick = true,
			highlightOnMouse = true,
		} = this.props;
		const U = T === 'tr' ? 'td' : 'span';

		const wrapperProps = {
			className: 'ms3-list__item ' +
				(isSelected ? ' ms3-list__item--selected' : '') +
				(isPreselected ? ' ms3-list__item--preselected' : '') +
				(isHighlighted ? ' ms3-list__item--highlight' : '') +
				(this._showDetails ? ' ms3-list__item--has-details' : '') +
				(showFeatureListInfo ? ' ms3-list__item--has-info' : '') +
				(this._ref && this._ref.className.includes('focus-visible') ? ' focus-visible' : ''),
			ref: this.setRef,
			// tabIndex has to be -1 if we want to focus it on preselect or it has role button (the latter is a WCAG criterion)
			tabIndex: (this.props.scrollOnPreselection || this.props.selectOnClick || this.props.deselectOnClick) ? -1 : 0,
		};

		// if render type is not a native element but assumably a react component,
		// pass some extra props to be used by said component
		if (typeof T !== 'string') {
			wrapperProps.feature = feature;
		}

		if (highlightOnMouse) {
			wrapperProps.onMouseEnter = isSelected ? null : onFeatureHighlight;
			wrapperProps.onMouseLeave = isHighlighted ? onFeatureUnHighlight : null;
		}

		if (selectOnClick === true) {
			wrapperProps.className += ' ms3-list__item--selectable';
			wrapperProps.role = 'button';
			wrapperProps.onClick = onFeatureSelection;
		}

		if (isSelected && deselectOnClick === true) {
			wrapperProps.role = 'button';
			wrapperProps.onClick = onFeatureUnSelection;
		}

		// TODO: document/collect magic property names
		// FIXME: __overrideListHtmlProp: Quick fix to get VMZ NDS running. We should either make the list item _Component_ overwritable or add
		//  some functionality in @mapsight/core to modify the feature source data client side with and transform function
		const overrideListHtmlProperty = getFeatureProperty(feature, '__overrideListHtmlProp', 'overrideListHtml');
		const overrideListHtml = getFeatureProperty(feature, overrideListHtmlProperty);

		// NOTE: If the feature overrides the html content, we return early here!
		if (overrideListHtml) {
			return (
				<T
					{...wrapperProps}
					dangerouslySetInnerHTML={{__html: overrideListHtml}}
				/>
			);
		}

		const iconProps = {
			as: U,
			mapsightIconId: getFeatureProperty(feature, 'mapsightIconId'), // TODO: document/collect magic property names
		};

		if (selectOnClick === 'iconOnly' || selectOnClick === 'mainAndIcon') {
			iconProps.role = 'button';
			iconProps.onClick = onFeatureSelection;
			iconProps.selectable = true;
		}

		if (highlightOnMouse === 'iconOnly' || highlightOnMouse === 'mainAndIcon') {
			iconProps.onMouseEnter = isSelected ? null : onFeatureHighlight;
			iconProps.onMouseLeave = isHighlighted ? onFeatureUnHighlight : null;
		}

		const icon = <FeatureListIcon {...iconProps} />;

		let info = null;
		if (showFeatureListInfo) {
			const listInformation = getFeatureProperty(feature, 'listInformation'); // TODO: document/collect magic property names
			info = (
				<U
					className="ms3-list__info"
					dangerouslySetInnerHTML={{__html: listInformation}}
				/>
			);
		}

		let details = null;
		if (this._showDetails) {
			details = (
				<U className="ms3-list__details">
					<FeatureDetailsContent
						html={detailsHtml}
						hasError={detailsHasError}
						feature={feature}
					/>
				</U>
			);
		}

		const mainProps = {
			className: 'ms3-list__main',
		};

		if (selectOnClick === 'mainOnly' || selectOnClick === 'mainAndIcon') {
			mainProps.role = 'button';
			mainProps.onClick = onFeatureSelection;
			mainProps.className += ' ms3-list__main--selectable';
		}

		if (highlightOnMouse === 'mainOnly' || highlightOnMouse === 'mainAndIcon') {
			mainProps.onMouseEnter = isSelected ? null : onFeatureHighlight;
			mainProps.onMouseLeave = isHighlighted ? onFeatureUnHighlight : null;
		}

		const permanentLink = getFeatureProperty(feature, 'permanentLink'); // TODO: document/collect magic property names
		const listName = getFeatureProperty(feature, 'listName'); // TODO: document/collect magic property names
		const name = getFeatureProperty(feature, 'name'); // TODO: document/collect magic property names
		const mainContent = listName || name;
		const main = (
			<U {...mainProps}>
				{permanentLink ?
					<a
						className="ms3-list__link"
						href={permanentLink}
						onClick={selectOnClick === true || selectOnClick === 'mainOnly' || selectOnClick === 'mainAndIcon' ? preventDefaultClick : undefined}
					>
						{mainContent}
					</a> :
					mainContent
				}
			</U>
		);

		return (
			<T {...wrapperProps}>
				{icon}{main}{info}{details}
			</T>
		);
	}
}
