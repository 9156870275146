/**
 * Gets distance between points
 *
 * @param {Array<number, number>} a point a
 * @param {Array<number, number>} b point b
 * @return {number} distance
 */
function getDistance(a, b) {
	const [aX, aY] = a;
	const [bX, bY] = b;
	return Math.abs(aX - bX) + Math.abs(aY - bY);
}

/**
 * Finds the closest point to given basePoint [x, y] in map. Returns the key.
 *
 * @param {Array<number, number>} basePoint base point
 * @param {Object<*, Array<number, number>>} pointsMap map of
 * @returns {*} key of the closest point in given pointsMap
 */
export default function findClosestPoint(basePoint, pointsMap) {
	return Object.keys(pointsMap)
		.map(key => [key, getDistance(basePoint, pointsMap[key])])
		.reduce((a, b) => (a !== undefined && a[1] > b[1] ? a : b), undefined)[0];
}
