import React, {useState, useContext, useEffect, memo} from 'react';
import {createSelector} from 'reselect';
import {useSelector} from 'react-redux';

import {mapSizeSelector as getMapSize} from '@mapsight/core/lib/map/selectors';
import {findFeatureInFeatureSourcesById} from '@mapsight/core/lib/feature-sources/selectors';
import {createFeatureSelectionSelector, getFilteredFeatures} from '@mapsight/core/lib/feature-selections/selectors';

import getFeatureProperty from '../../helpers/get-feature-property';
import {FEATURE_SELECTIONS, FEATURE_SOURCES, MAP} from '../../config/constants/controllers';
import {FEATURE_SELECTION_HIGHLIGHT} from '../../config/feature/selections';
import {ComponentsContext} from '../contexts';

const featureSelector = createSelector(
	(state) => state[FEATURE_SOURCES],
	createFeatureSelectionSelector(FEATURE_SELECTIONS, FEATURE_SELECTION_HIGHLIGHT),
	(featureSources, selection) => {
		const features = getFilteredFeatures(selection);
		const featureId = features && features[0];
		if (featureId) {
			return findFeatureInFeatureSourcesById(featureSources, featureId);
		}

		return null;
	},
);

function mapSizeSelector(state) {
	return getMapSize(state[MAP]);
}

/**
 * @typedef {null | {left: number, right: number, top: number, bottom: number}} Position
 */

/**
 * @param {any} feature
 * @param {any} mapId
 * @returns {[Position, boolean]}
 */
function useGetPositionWithTransition(feature, mapId) {
	const [withTransition, setWithTransition] = useState(true);

	const mapSize = useSelector(mapSizeSelector);

	/**
	 * @type {[
	*   Position,
	*   React.Dispatch<React.SetStateAction<Position>>
	* ]}
	*/
	const [position, setPosition] = useState(null);

	useEffect(
		() => {
			setWithTransition(false);
		},
		[mapSize]
	);

	useEffect(
		() => {
			const mapEl = document.getElementById(mapId);

			if (!mapEl || !feature) {
				return undefined;
			}

			/**
			 * @param {MouseEvent} e
			 */
			function onMouseMove(e) {
				const x = e.clientX;
				const y = e.clientY;
				const {left, right, top, bottom} = mapEl.getBoundingClientRect();
				setPosition({
					left: x - left,
					right: right - x,
					top: y - top,
					bottom: bottom - y,
				});
				setWithTransition(true);
			}

			function onMouseOut() {
				setPosition(null);
				setWithTransition(true);
			}

			mapEl.addEventListener('mousemove', onMouseMove);
			mapEl.addEventListener('mouseout', onMouseOut);

			return () => {
				setPosition(null);
				setWithTransition(true);

				mapEl.removeEventListener('mousemove', onMouseMove);
				mapEl.removeEventListener('mouseout', onMouseOut);
			};
		},
		[feature, mapId, setPosition]
	);

	return [position, withTransition];
}

function Tooltip({mapId}) {
	const components = useContext(ComponentsContext);

	const feature = useSelector(featureSelector);

	// TODO: what's the purpose of this? remove it?
	const [lastFeature, setLastFeature] = useState(undefined);

	useEffect(
		() => {
			if (!feature) {
				setLastFeature(feature);
			}
		},
		[feature, setLastFeature]
	);

	const [position, withTransition] = useGetPositionWithTransition(feature, mapId);

	const classes = `ms3-tooltip ms3-tooltip--${feature ? 'active' : 'inactive'}`;
	const classesInner = `ms3-tooltip__inner ms3-tooltip__inner--${feature ? 'active' : 'inactive'}`;

	const styles = {};

	if (position) {
		styles.transform = `translate(${-position.right}px, ${position.top}px)`;
	} else {
		styles.display = 'none';
	}

	if (!withTransition) {
		styles.transition = 'none';
	}

	const localFeature = feature || lastFeature;

	// TODO: __overrideListHtmlProp: Quick fix to get VMZ NDS running. We should either make the
	// list item _Component_ overwritable or add some functionality in @mapsight/core to modify
	// the feature source data client side with and transform function.
	const overrideTooltipProperty = getFeatureProperty(
		localFeature,
		'__overrideTooltipProp',
		'tooltip',
	);

	const html = getFeatureProperty(localFeature, overrideTooltipProperty);
	const text = html ? null : getFeatureProperty(localFeature, 'name'); // TODO: document/collect magic property names

	const content = (() => {
		if (html) {
			return (
				<div className={classesInner} dangerouslySetInnerHTML={{__html: html}} />
			);
		} else if (text) {
			return (
				<div className={classesInner}>{text}</div>
			);
		} else {
			return null;
		}
	})();

	return (
		<div className={classes} style={styles}>
			{(() => {
				if (components.TooltipContent) {
					return (
						<components.TooltipContent
							feature={localFeature}
							html={html}
							text={text}
						>
							{content}
						</components.TooltipContent>
					);
				} else {
					return content;
				}
			})()}
		</div>
	);
}

export default memo(Tooltip);
