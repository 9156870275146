import getExtent from '../feature/getExtent';

import fitToExtent from './fitToExtent';

export const DEFAULT_OPTIONS = {
	duration: 300, // TODO: MAGIC NUMBER!
	padding: [60, 100, 60, 100], // TODO: MAGIC NUMBER!
	keepZoom: false, // TODO: Document keepZoom option!
	maxZoom: 17, // TODO: MAGIC NUMBER!
	skipIfInView: true, // TODO: Document skipIfInView option!
};

// TODO: Document keepZoom option!
// TODO: Document skipIfInView option!
export default function fitToFeature(map, feature, options = DEFAULT_OPTIONS) {
	if (!feature) {
		return;
	}

	fitToExtent(map, getExtent(feature), options);
}
