const OL_PRIVATE_MEMBER_PREFIX = '__nnms3__';
const OL_PRIVATE_MEMBER_CONTROLLER = OL_PRIVATE_MEMBER_PREFIX + 'controller';
const OL_PRIVATE_MEMBER_LAYER_GROUP = OL_PRIVATE_MEMBER_PREFIX + 'layerGroup';
const OL_PRIVATE_MEMBER_LAYER_ID = OL_PRIVATE_MEMBER_PREFIX + 'layerId';

export function getGroupForLayer(layer) {
	return layer && layer[OL_PRIVATE_MEMBER_LAYER_GROUP];
}

export function getIdForLayer(layer) {
	return layer && layer[OL_PRIVATE_MEMBER_LAYER_ID];
}

export function getControllerForLayer(layer) {
	return layer && layer[OL_PRIVATE_MEMBER_CONTROLLER];
}

export function tagLayer(layer, controller, id, group = null) {
	// TODO: Check if we should use a WeakSet or property descriptors to hide the tags even more (eg. non-enumerable)
	layer[OL_PRIVATE_MEMBER_CONTROLLER] = controller;
	layer[OL_PRIVATE_MEMBER_LAYER_ID] = id;
	layer[OL_PRIVATE_MEMBER_LAYER_GROUP] = group;
}
