import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {isEmbeddedMapSelector, viewSelector} from '../../store/selectors';

import FeatureDetailsContent from './feature-details-content';


export default connect(createStructuredSelector({
	view: viewSelector,
	isEmbeddedMap: isEmbeddedMapSelector
}))(FeatureDetailsContent);
