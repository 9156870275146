import React, {useContext, memo} from 'react';

import {useSelector} from 'react-redux';

import {VIEW_FULLSCREEN, VIEW_MAP_ONLY} from '../config/constants/app';

import {
	isViewMobile,
	layerSwitcherConfigExternalSelector,
	layerSwitcherShowExternalSelector,
	listVisible,
	tagSwitcherShowSelector,
	timeFilterVisible,
	viewSelector,
	listUiOptionIntegratedSelector,
} from '../store/selectors';

import VisibilityWrapper from './helping/visibility-wrapper';
import LayerSwitcher from './layer-switcher';
import TagSwitcher from './tag-switcher';
import FeatureList from './feature-list';
import TimeFilter from './time-filter';

import {ComponentsContext} from './contexts';

function AdditionalContainer() {
	const comps = useContext(ComponentsContext);
	const view = useSelector(viewSelector);
	const integratedList = useSelector(listUiOptionIntegratedSelector);
	const isMobile = isViewMobile(view);

	let content = (
		<VisibilityWrapper
			visibleSelector={listVisible}
			additionalCondition={view !== VIEW_FULLSCREEN && (isMobile || !integratedList)}
		>
			<div className="ms3-standalone-main-panel-container">
				{comps.MainPanelStart && (
					<comps.MainPanelStart
						panelPosition="standalone"
						view={view}
						contentType="list"
					/>
				)}

				<FeatureList />

				{comps.MainPanelEnd && (
					<comps.MainPanelEnd
						panelPosition="standalone"
						view={view}
						contentType="list"
					/>
				)}
			</div>
		</VisibilityWrapper>
	);

	if (comps.AdditionalContainerContent) {
		content = (
			<comps.AdditionalContainerContent>
				{content}
			</comps.AdditionalContainerContent>
		);
	}


	// m3-additional-container kept for downward compatibilty
	return (
		<div className="m3-additional-container ms3-additional-container">
			<VisibilityWrapper
				visibleSelector={layerSwitcherShowExternalSelector}
				additionalCondition={view !== VIEW_FULLSCREEN && view !== VIEW_MAP_ONLY && (isMobile || !integratedList)}
			>
				<div className="ms3-left-marginal">
					<LayerSwitcher
						configSelector={layerSwitcherConfigExternalSelector}
					/>
				</div>
			</VisibilityWrapper>

			<VisibilityWrapper
				visibleSelector={tagSwitcherShowSelector}
				additionalCondition={view !== VIEW_FULLSCREEN && view !== VIEW_MAP_ONLY && (isMobile || !integratedList)}
			>
				<div className="ms3-left-marginal ms3-tagSwitcher">
					<TagSwitcher />
				</div>
			</VisibilityWrapper>

			{content}

			<div className="ms3-marginal">
				<VisibilityWrapper visibleSelector={timeFilterVisible}>
					<div id="ms3-filter">
						<TimeFilter />
					</div>
				</VisibilityWrapper>
			</div>
		</div>
	);
}

export default memo(AdditionalContainer);
