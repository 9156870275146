/**
 * @typedef {{isParam: boolean, name: *}} PatternSegment
 */

/**
 * Parses a pattern segment.
 *
 * @param {string} patternSegment segment string
 * @returns {PatternSegment} parsed semgent
 */
function parsePatternSegment(patternSegment) {
	if (patternSegment.startsWith(':')) {
		return {
			isParam: true,
			name: patternSegment.slice(1),
		};
	} else {
		return {
			isParam: false,
			name: patternSegment,
		};
	}
}

// TODO: lru/limit cache?
const parsePathPatternCache = {};

/**
 * Parses a path pattern
 *
 * @param {string} pattern path pattern
 * @returns {PatternSegment[]} parsed pattern
 */
function parsePathPattern(pattern) {
	if (!parsePathPatternCache[pattern]) {
		const patternSegments = pattern.split('/');
		parsePathPatternCache[pattern] = patternSegments.map(parsePatternSegment);
	}
	return parsePathPatternCache[pattern];
}

/**
 * This function matches the given path array to a path pattern. The pattern consists of / delimited names and/or
 * named parameters defined as `:name` where name may be any string which will be used in the returned object to
 * access the parameter value.
 *
 * If the path matches the pattern, the return value will be an object containing any present
 * named parameter values that may be in the pattern, otherwise it will return false if any path segment does not
 * match the specified pattern.
 *
 * @param {string[]} pathArr path array
 * @param {string} pattern pattern to match
 * @returns {object<string,string>|boolean} object containing matched named parameter values or false if the path did not patch the pattern
 */
export default function matchPath(pathArr, pattern) {
	if (!pathArr || !Array.isArray(pathArr)) {
		return false;
	}

	const patternSegments = parsePathPattern(pattern);
	if (patternSegments.length !== pathArr.length) {
		return false;
	}

	const params = {};
	for (let i = 0; i < patternSegments.length; i++) {
		const pathValue = pathArr[i];
		const patternSegment = patternSegments[i];
		if (patternSegment.isParam) {
			params[patternSegment.name] = pathValue;
		} else if (pathValue !== patternSegment.name) {
			return false;
		}
	}

	return params;
}
