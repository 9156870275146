import React from 'react';

import Pagination from './pagination';

function FeatureListFooter(props) {
	const {
		as: T = 'div',
		listUiOptions,
		page,
		featureCount,
	} = props;

	return (
		<T className="ms3-list-footer">
			{listUiOptions.paginationControl ?
				<Pagination page={page} count={Math.ceil(featureCount / listUiOptions.itemsPerPage)} /> : null}
		</T>
	);
}

export default FeatureListFooter;
