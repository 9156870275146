export {TIME_FILTER, TAG_FILTER} from '../constants/controllers';

/**
 * Create an empty feature source that can be filled using actions
 * and cannot not load features itself.
 *
 * @return {object} config for a plain feature source
 */
export function plain() {
	return {};
}

/**
 * Create a feature source that loads data from a remote url.
 *
 * @param {String} url url to fetch data from
 * @return {object} config for a xhr json feature source
 */
export function xhrJson(url) {
	return {
		type: 'xhr-json',
		url: url,
	};
}

/**
 * Create a feature source that fetches data from a remote url.
 *
 * Additionally it will fetch the data every {timer} milliseconds.
 *
 * @param {string} url url to fetch data from
 * @param {number} timer time in milliseconds to fetch new data
 * @return {object} config for a xhr json feature source that will refresh regularly
 */
export function xhrJsonRefreshing(url, timer) {
	return {
		type: 'xhr-json',
		url: url,
		doRefresh: true,
		timer: timer,
	};
}

/**
 * add a filter to the definition of a feature source.
 *
 * may be used iteratively.
 *
 * @param {object} featureSource     definition to extend by filterName
 * @param {string} filterName        name of filter to add to the filter collection.
 *                                   The feature filter will be applied when using the appropriate feature source selectors.
 * @returns {object}                 extended definition of feature source
 */
export function withFilter(featureSource, filterName) {
	return {
		...featureSource,
		filters: [
			...(featureSource.filters || []),
			filterName
		],
	};
}
