import {ASYNC_ACTION_FLAG, CONTROLLED_ACTION_FLAG} from '../lib/base/actions';

export default function getSanitizedActionType(action) {
	let sanitizedActionType;

	if (action.type) {
		sanitizedActionType = action.type;
	} else if (typeof action === 'function') {
		sanitizedActionType = action.name ? `thunk<${action.name}>` : 'thunk';
	} else {
		sanitizedActionType = 'unknown action type [' + typeof action + '] ❗';
	}

	sanitizedActionType = sanitizedActionType.replace(/MAPSIGHT_/g, '🗺️ ');

	if (action.meta && action.meta[ASYNC_ACTION_FLAG]) {
		sanitizedActionType += ' ⏱️';
	}

	if (action.meta && action.meta[CONTROLLED_ACTION_FLAG]) {
		sanitizedActionType += ' 🔖️';
	}

	if (action.meta && action.meta.batch && action.payload) {
		return '[ ' + action.payload.map(getSanitizedActionType).join(', ') + ' ]';
	}

	if (action.meta && action.meta.path) {
		sanitizedActionType += ' | ' + action.meta.path.join('.');
	}

	return sanitizedActionType;
}
