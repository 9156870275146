import {deselectAll, selectExclusively} from '@mapsight/core/lib/feature-selections/actions';
import {createFeatureSelectionSelector} from '@mapsight/core/lib/feature-selections/selectors';

import {observeStateOnce} from '@neonaut/lib-redux/es/observe-state';

import getQueryStringParameter from '@neonaut/lib-js/es/string/get-query-string-parameter';
import removeQueryStringParameter from '@neonaut/lib-js/es/string/remove-query-string-parameter';

import * as c from '../../config/constants/controllers';
import {FEATURE_SELECTION_SELECT} from '../../config/feature/selections';
import {VIEW_MAP_ONLY} from '../../config/constants/app';
import {setView} from '../../store/actions';
import {isViewMobile, viewSelector} from '../../store/selectors';

const defaultFeatureSelectionsController = c.FEATURE_SELECTIONS;
const defaultFeatureSelection = FEATURE_SELECTION_SELECT;
const defaultGetParameter = 'feature';
const defaultAutoRemoveParameters = [defaultGetParameter, 'cHash'];

function selectFeature(store, featureSelectionsController, featureSelection, featureId, setMapOnlyViewInMobile) {
	store.dispatch(selectExclusively(featureSelectionsController, featureSelection, featureId));

	if (setMapOnlyViewInMobile && isViewMobile(viewSelector(store.getState()))) {
		store.dispatch(setView(VIEW_MAP_ONLY));
	}
}
/**
 * This plugin will select the feature identified by the defined get parameter and optionally remove parameters from the
 * window.location using the history API.
 *
 * @param {Object} [options] options
 * @param {String} [options.featureSelectionsController] name of the feature selections controller, defaults to mapsight ui default
 * @param {String} [options.featureSelection="select"] name of the feature selection to track
 * @param {String} [options.getParameter="feature"] name get parameter identifying the feature
 * @param {boolean} [options.setMapOnlyViewInMobile=true] will set MapOnly view on mobile if a feature has been linked
 * @param {String[]|boolean} [options.autoRemoveParameters] list of get parameters to remove once the feature
 *                                            has been selected, defaults to ["feature", "cHash"]. Pass false to disable.
 * @param {String[]|boolean} [options.clearMissingParamters] if set to true or an array clear if getParameter and the mentioned
 *                                            parameters are missing. don't clear if getParameter is missing, but one of the
 *                                            mentioned parameters is there. we keep this to be safe in race condition
 *                                            where ?feature is set but ?preselect is not where we want to keep the implicit
 *                                            preselect of the feature parameter
 *
 * @return {MapsightUiPlugin} plugin
 */
export default function createPlugin(options = {}) {
	const {
		featureSelectionsController = defaultFeatureSelectionsController,
		featureSelection = defaultFeatureSelection,
		getParameter = defaultGetParameter,
		autoRemoveParameters = defaultAutoRemoveParameters,
		setMapOnlyViewInMobile = true,
		clearMissingParamters: clearMissingParameters = null, // TODO: fix typo in upcoming (major) release
	} = options;
	const selectorSelectedFeatures = createFeatureSelectionSelector(featureSelectionsController, featureSelection);

	if (process.env.NN_JS_LOG_LEVEL === 'verbose') {
		console.log('feature-deep-link ', options);
	}

	let isHandled = false;
	return {
		afterRender: function handleFeatureDeepLink(context) {
			if (isHandled) {
				return;
			}

			isHandled = true;

			const {store} = context;
			// We always do this action even if we should already have done that
			// by re-hydrating the state as it may not have had the dynamic get parameter passed along
			const featureId = getQueryStringParameter(window.location.search, getParameter);
			if (featureId) {
				selectFeature(store, featureSelectionsController, featureSelection, featureId, setMapOnlyViewInMobile);

				if (autoRemoveParameters && autoRemoveParameters.length) {
					// if we can, remove initial feature id from url
					// when the selection changes (e.g. panel is closed/different feature is selected)
					observeStateOnce(store, selectorSelectedFeatures, () => {
						let newUrl = window.location.href;
						autoRemoveParameters.forEach(key => {
							newUrl = removeQueryStringParameter(newUrl, key);
						});
						window.history.pushState(null, window.document.title, newUrl);
					});
				}
			} else {
				if (clearMissingParameters) {
					let found = false;
					while (!found && clearMissingParameters.shift && clearMissingParameters[0]) {
						found = getQueryStringParameter(window.location.search, clearMissingParameters.shift());
					}
					if (!found) {
						store.dispatch(deselectAll(featureSelectionsController, featureSelection));
					}
				}
				// handle select via state at relaod
				if(window.history.state?.featureId) {
					selectFeature(store, featureSelectionsController, featureSelection, window.history.state?.featureId, setMapOnlyViewInMobile);
				}
			}
			var _wr = function(f) {
				var orig = history[f];
				return function() {
					console.log('handleFeatureDeepLink', {f, state: arguments[0]});
					var rv = orig.apply(this, arguments);
					if(arguments[0]?.featureId) {
						selectFeature(store, featureSelectionsController, featureSelection, arguments[0]?.featureId, setMapOnlyViewInMobile);
					} else if (arguments[0]?.featureId === null) { // test for null to inhibit action if featureId is simply omitted
						store.dispatch(deselectAll(featureSelectionsController, featureSelection));
					}
					return rv;
				};
			};
			history.pushState = _wr('pushState'), history.replaceState = _wr('replaceState');
		},
	};
}
