import UrlTile from 'ol/source/urltile';

import base from './_base';

export default {
	type: 'source',
	name: 'UrlTile',
	Constructor: UrlTile,
	optionMap: {
		...base.optionMap,
		url: 'setUrl',
		urls: 'setUrls',
		tileLoadFunction: 'setTileLoadFunction',
		tileUrlFunction: 'setTileUrlFunction',
	},
	initialOptionMap: {
		...base.initialOptionMap,
		url: 'url',
		urls: 'urls',
		tileLoadFunction: 'tileLoadFunction',
		tileUrlFunction: 'tileUrlFunction',
	},
};
