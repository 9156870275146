import MouseWheelZoom from 'ol/interaction/mousewheelzoom';

import base from './_base';

export default {
	type: 'interaction',
	name: 'MouseWheelZoom',
	Constructor: MouseWheelZoom,
	optionMap: {
		...base.optionMap,
		useAnchor: 'setMouseAnchor',
		mouseAnchor: 'setMouseAnchor', // alias
	},
	initialOptionMap: {
		...base.initialOptionMap,
		duration: 'duration',
		timeout: 'timeout',
		constrainResolution: 'constrainResolution',
		useAnchor: 'useAnchor',
		mouseAnchor: 'useAnchor', // alias
	},
};
