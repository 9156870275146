import {useCallback, useEffect, useState} from 'react';

/**
 * Mounts the mountable object to the element
 *
 * @param {object|undefined} mountable mountable object
 * @param {function(Element): void} mountable.mount mount function, receives element as parameter
 * @param {function(Element): void} mountable.unmount unmount function, receives element as parameter
 *
 * @returns {Function} callback to set target
 */
export default function useMountable(mountable) {
	const [target, setTarget] = useState();

	useEffect(function () {
		if (!mountable) {
			console.error('mount failed, no mountable');
			return undefined;
		}

		if (target) {
			mountable.mount(target);
		} else {
			console.error('mount failed, no target');
		}
		return function () {
			if (target) {
				mountable.unmount(target);
			}
		};
	}, [target, mountable]);

	return useCallback(function (newTarget) {
		setTarget(newTarget);
	}, [setTarget]);
}
