import {createSelector} from 'reselect';
import getPath from '@neonaut/lib-js/es/object/getPath';

import {
	createTagsWithCountFromFeatureSourceSelector,
	mapFeaturesToFeatureSource
} from '@mapsight/core/lib/feature-sources/selectors';
import {visibleLayersWithMiniLegendsSelector} from '@mapsight/core/lib/map/selectors';

import {DETAILS_CONTENT_STATE_KEY, VIEW_FULLSCREEN, VIEW_MAP_ONLY, VIEW_MOBILE} from '../config/constants/app';
import {FEATURE_LIST, FEATURE_SELECTIONS, MAP, TAG_FILTER, TIME_FILTER} from '../config/constants/controllers';
import {FEATURE_SELECTION_SELECT} from "../config/feature/selections";

import {
	FETCH_JSON_STATUS_ERROR,
	FETCH_JSON_STATUS_LOADING,
	FETCH_JSON_STATUS_SUCCESS,
	FETCH_TEXT_STATUS_ERROR,
	FETCH_TEXT_STATUS_SUCCESS
} from './actions';

export const SEARCH_STATUS_INACTIVE = 'inactive';
export const SEARCH_STATUS_EMPTY = 'empty';
export const SEARCH_STATUS_FOUND = 'found';
export const SEARCH_STATUS_LOADING = 'loading';
export const SEARCH_STATUS_ERROR = 'error';


/**
 * @returns {import("../config/constants/app").View}
 */
export const viewSelector = state => state.app.view;

export const isViewFullscreen = view => view === VIEW_FULLSCREEN;

/**
 * returns `true` if view is "mobile" or "mapOnly"
 *
 * @param {import("../config/constants/app").View} view
 * @returns {boolean}
 */
export const isViewMobile = view => view === VIEW_MOBILE || view === VIEW_MAP_ONLY;

/**
 * @param {import("../config/constants/app").View} view
 * @returns {boolean}
 */
export const isViewMapOnly = view => view === VIEW_MAP_ONLY;

export const isViewMobileOrMapOnlySelector = createSelector(viewSelector, isViewMobile);
export const isViewMapOnlySelector = createSelector(viewSelector, isViewMapOnly);
export const isFullscreenSelector = createSelector(viewSelector, isViewFullscreen);

export const mapVisible = state => state[MAP].visible;
export const listVisible = state => state[FEATURE_LIST].visible;
export const timeFilterVisible = state => state[TIME_FILTER].visible;

export const mapAndListVisible = createSelector([mapVisible, listVisible], (map, list) => map && list);

export const viewBreakpointsSelector = state => state.app.viewBreakpoints;

export const titleSelector = state => state.app.title;
export const isMapOutOfViewportSelector = state => state.app.mapIsOutOfViewport;

/**
 * @param {any} state
 * @returns {null | Record<string, any>}
 */
export function regionsSelector(state) {
	if (
		state
		&& state.app
		&& state.app.regions
		&& typeof state.app.regions === 'object'
	) {
		return state.app.regions;
	} else {
		return null;
	}
}

export const placesSelector = state => state.app.places;

export const lastListScrollPositionSelector = state => state.app.lastListScrollPosition;
export const listSortingSelector = state => state.app.listSorting;
export const listQuerySelector = state => state.app.listQuery;
export const listPageSelector = state => (state.app.listPage || 0);

export const searchQuerySelector = state => state.app.searchQuery;
export const searchResultSelector = state => getPath(state, ['app', 'searchResult']);
export const getSearchResultStatus = searchResult => searchResult && searchResult.status;
export const getSearchResultFeatures = searchResult => getPath(searchResult, ['data', 'features'], []);
export const searchResultFeaturesSelector = createSelector(searchResultSelector, getSearchResultFeatures);

export const haveSearchInMapSelector = state => state.app.searchInMap;
export const isEmbeddedMapSelector = state => state.app.embeddedMap;

export const listUiOptionsSelector = createSelector(
	(state) => state && state.app && state.app.list,
	/**
	 * @returns {object}
	 */
	(list) => typeof list === 'object' && list !== null ? list : {},
);
export const listUiOptionDetailsSelector = state => state.app.list.detailsInList;
export const listUiOptionIntegratedSelector = state => state.app.list.integratedList;
export const listUiOptionSelectedOnlySelector = state => state.app.list.showSelectedOnly;

export const listUiOptionSelectionBehaviorSelector = createSelector(
	createSelector(
		listUiOptionsSelector,
		(list) => {
			if (typeof list.selectionBehavior === 'object' && list.selectionBehavior !== null) {
				return list.selectionBehavior;
			} else {
				return undefined;
			}
		},
	),
	/**
	 * @returns {Record<
	 *   import("../config/constants/app").View,
	 *   "expandInList" | "scrollToMap" | "showInMapOnlyView"
	 * >}
	 */
	(sb) => sb !== undefined ? sb : {},
);

export const listUiScrollToItemOnPreselectSelector = createSelector(
	listUiOptionsSelector,
	(list) => list.scrollToItemOnPreselect === true,
);

export const listUiOptionSelectionSelectionSelector = state => (state.app.list.selectionBehaviorSelection || FEATURE_SELECTION_SELECT);
export const listUiOptionsShowVaryingInfoOnlySelector = state => state.app.list.showVaryingInfoOnly;

export const layerSwitcherShowInternalSelector = state => state.app.layerSwitcher.show.internal;
export const layerSwitcherShowExternalSelector = state => state.app.layerSwitcher.show.external;
export const layerSwitcherConfigInternalSelector = state => state.app.layerSwitcher.internal;
export const layerSwitcherConfigExternalSelector = state => state.app.layerSwitcher.external;

export const pageTitleShowSelector = state => state.app.pageTitle.show;

export const tagSwitcherShowSelector = state => state.app.tagSwitcher.show;
export const tagSwitcherToggleableGroups = state => state.app.tagSwitcher.toggleableGroups;
export const tagSwitcherSortTags = state => state.app.tagSwitcher.sortTags;
export const tagSwitcherFeatureSourceIdSelector = state => state.app.tagSwitcher.featureSourceId;
export const tagSwitcherFeatureSourcesControllerNameSelector = state => state.app.tagSwitcher.featureSourcesControllerName;
export const tagSwitcherTagsSelector = state => {
	const controllerName = tagSwitcherFeatureSourcesControllerNameSelector(state);
	const featureSourceId = tagSwitcherFeatureSourceIdSelector(state);

	return createTagsWithCountFromFeatureSourceSelector(controllerName, featureSourceId)(state);
};

export const viewToggleShowSelector = state => state.app.viewToggle.show;
export const viewToggleOptionsSelector = state => state.app.viewToggle;

export const searchStatusSelector = createSelector(
	searchResultSelector,
	searchQuerySelector,
	createSelector(
		getSearchResultStatus,
		getSearchResultFeatures,
		(_, query) => !!query,
		(status, features, hasQuery) => ({
			[FETCH_JSON_STATUS_ERROR]: SEARCH_STATUS_ERROR,
			[FETCH_JSON_STATUS_LOADING]: SEARCH_STATUS_LOADING,
			[FETCH_JSON_STATUS_SUCCESS]: hasQuery ? (features.length ? SEARCH_STATUS_FOUND : SEARCH_STATUS_EMPTY) : SEARCH_STATUS_INACTIVE,
		}[status] || SEARCH_STATUS_INACTIVE)
	)
);
export const userPreferenceListVisibleSelector = state => state.app.userPreferenceListVisible;

export const searchResultSelectionFeaturesSelector = state => state.app.searchResultSelectionFeatures;
export const searchResultSelectionFeatureSourceSelector = createSelector(searchResultSelectionFeaturesSelector, mapFeaturesToFeatureSource);

export const featureDetailsSelector = state => getPath(state, ['app', DETAILS_CONTENT_STATE_KEY]);
export const featureDetailsUrlSelector = state => getPath(state, ['app', DETAILS_CONTENT_STATE_KEY, 'url']);


export const featureDetailsHasErrorSelector = createSelector(featureDetailsSelector, detailsContent =>
	detailsContent && detailsContent.status === FETCH_TEXT_STATUS_ERROR
);

export const featureDetailsHtmlSelector = createSelector(featureDetailsSelector, detailsContent => (
	detailsContent && detailsContent.status === FETCH_TEXT_STATUS_SUCCESS ? detailsContent.data : null
));

export const createTagVisibleSelector = (featureSourceId, tagGroup, tag) =>
	state => !!getPath(state, [TAG_FILTER, 'visibleTags', featureSourceId, tagGroup, tag]);

export const createTagGroupVisibleSelector = (featureSourceId, tagGroup) =>
	state => !!getPath(state, [TAG_FILTER, 'visibleTagGroups', featureSourceId, tagGroup]);

export function isOverlayModalVisibleSelector(state) {
	return getPath(state, ['app', 'isOverlayModalVisible']) === true;
}

/**
 * @param {any} state
 * @returns {null | string}
 */
export function selectedRegionIdSelector(state) {
	if (
		state
		&& state.app
		&& state.app.selectedRegion
		&& typeof state.app.selectedRegion === 'string'
	) {
		return state.app.selectedRegion;
	} else {
		return null;
	}
}

export const selectedRegionSelector = createSelector(
	selectedRegionIdSelector,
	regionsSelector,

	/**
	 * @returns {null | Record<string, any>}
	 */
	(id, regions) => {
		if (
			regions
			&& regions[id]
			&& typeof regions[id] === 'object'
		) {
			return regions[id];
		} else {
			return null;
		}
	},
);


export const miniLegendLayerIdSelector = createSelector(
	state => visibleLayersWithMiniLegendsSelector(state[MAP]),
	state => state.app.miniLegendLayer,

	(layers, layerIdFromStore) => (
			(!layers || !Object.keys(layers).length) ?
				null :
				(!layerIdFromStore || !Object.keys(layers).includes(layerIdFromStore)) ?
					Object.keys(layers)[0] :
					layerIdFromStore
		)
);


export function createSelectionIndexSelector(featureIds, targetSelection) {
	return createSelector(
		state => state[FEATURE_SELECTIONS][targetSelection]?.features,
		(selection) => {
			if (featureIds?.length && selection?.length) {
				return featureIds.indexOf(selection[0]);
			}
			return -1;
		}
	);
}
