//import forEach from 'lodash/forEach';
import {BaseController} from '../base/controller';
import {baseReducer} from '../base/reducer';

export class FeatureSelectionsController extends BaseController {
	reduce(state = {}, action) {
		state = baseReducer(state, action);

		//// enforce limits
		//const nextState = {};
		//let changed = false;
		//forEach(state, (featureSelection, id) => {
		//	if (featureSelection.max && featureSelection.features && featureSelection.features.length > featureSelection.max) {
		//		changed = true;
		//		featureSelection = {
		//			...featureSelection,
		//			filteredFeatures: featureSelection.features.slice(0, featureSelection.max),
		//		};
		//	}
		//
		//	nextState[id] = featureSelection;
		//});
		//
		//if (changed) {
		//	return nextState;
		//}

		return state;
	}
}
