import React from 'react';

import {translate} from '../../helpers/i18n';

export default function MapOverlayLogo() {
	return (
		<span
			className="ms3-logo"
		>
			<span className="visuallyhidden">
				{translate('ui.map-overlay.logo.copyright')}
			</span>
		</span>
	);
}
