import TileImage from 'ol/source/tileimage';

import base from './urltile';

export default {
	type: 'source',
	name: 'TileImage',
	Constructor: TileImage,
	optionMap: {
		...base.optionMap,
		renderReprojectionEdges: 'setRenderReprojectionEdges',
		//tileGridForProjection: ..., see setTileGridForProjection TODO could be represented by an key-value map
	},
	initialOptionMap: {
		...base.initialOptionMap,
		cacheSize: 'cacheSize',
		crossOrigin: 'crossOrigin',
		logo: 'logo',
		opaque: 'opaque',
		projection: 'projection',
		reprojectionErrorThreshold: 'reprojectionErrorThreshold',
		state: 'state',
		tileClass: 'tileClass',
		tileGrid: 'tileGrid',
		tilePixelRatio: 'tilePixelRatio',
		wrapX: 'wrapX',
		transition: 'transition',
	},
};
