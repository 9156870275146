import {hasGeolocationSupport} from '../helpers';

export const ERROR_NO_GEOLOCATION_SUPPORT = 'ERROR_NO_GEOLOCATION_SUPPORT';
export const ERROR_PERMISSION_DENIED = 'ERROR_PERMISSION_DENIED'; // ~ PositionError.PERMISSION_DENIED
export const ERROR_UNAVAILABLE = 'ERROR_UNAVAILABLE'; // ~ PositionError.POSITION_UNAVAILABLE
export const ERROR_TIMEOUT = 'ERROR_TIMEOUT'; // ~ PositionError.TIMEOUT
export const ERROR_NOT_ACCURATE = 'ERROR_NOT_ACCURATE';
export const ERROR_UNKNOWN = 'ERROR_UNKNOWN';

/** @typedef {ERROR_NO_GEOLOCATION_SUPPORT | ERROR_PERMISSION_DENIED | ERROR_UNAVAILABLE | ERROR_TIMEOUT | ERROR_NOT_ACCURATE | ERROR_UNKNOWN} UserGeolocationError */

export const REQUEST_GEOLOCATION_SUCCESS = 'REQUEST_GEOLOCATION_SUCCESS';

export function getGeolocationSuccess(latitude, longitude, accuracy) {
	return {
		type: REQUEST_GEOLOCATION_SUCCESS,
		latitude: latitude,
		longitude: longitude,
		accuracy: accuracy,
	};
}

export const REQUEST_GEOLOCATION_FAILURE = 'REQUEST_GEOLOCATION_FAILURE';

export function getGeolocationFailure(error) {
	return {
		type: REQUEST_GEOLOCATION_FAILURE,
		error: error,
	};
}

export const REQUEST_GEOLOCATION = 'REQUEST_GEOLOCATION';

/**
 * @param {PositionError} positionError position error from Geolocation API
 * @returns {UserGeolocationError} mapsight user geolocation error state
 */
function mapGeolocationPositionErrorToError(positionError) {
	switch (positionError.code) {
		case positionError.PERMISSION_DENIED:
			return ERROR_PERMISSION_DENIED;
		case positionError.POSITION_UNAVAILABLE:
			return ERROR_UNAVAILABLE;
		case positionError.TIMEOUT:
			return ERROR_TIMEOUT;
		default:
			return ERROR_UNKNOWN;
	}
}

export function getGeolocation(options) {
	if (!hasGeolocationSupport) {
		return {
			type: REQUEST_GEOLOCATION_FAILURE,
			error: ERROR_NO_GEOLOCATION_SUPPORT,
		};
	}

	return dispatch => {
		dispatch({type: REQUEST_GEOLOCATION});

		function handleGeoPosition(position) {
			dispatch(getGeolocationSuccess(position.coords.latitude, position.coords.longitude, position.coords.accuracy));
		}

		function handleGeoError(positionError) {
			dispatch(getGeolocationFailure(mapGeolocationPositionErrorToError(positionError)));
		}

		window.navigator.geolocation.getCurrentPosition(handleGeoPosition, handleGeoError, options);
	};
}
