import HighchartsReact from 'highcharts-react-official';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import config from '../config';
import {localTimeTimestampFromDateTimeString, mapChartEntry} from '../helpers';
import Highcharts from '../highcharts';

import ChartLegend from './ChartLegend';

Highcharts.setOptions({
	lang: {
		months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
		shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
		weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
		numericSymbols: null, //[' tsd.', 'M', 'G', 'T', 'P', 'E'],
		printButtonTitle: 'Diagramm ausdrucken',
		resetZoomTitle: 'Zoom zurücksetzen',
		resetZoom: 'Zoom zurücksetzen',
		loading: 'Lädt...',
		exportButtonTitle: 'Diagramm sichern',
		downloadSVG: 'Als SVG sichern',
		downloadPNG: 'Als PNG sichern',
		downloadPDF: 'Als PDF sichern',
		downloadJPEG: 'Als JPEG sichern',
		decimalPoint: ',',
		thousandsSep: '.',
	},
});

const problemSeriesHeight = 20;

/** @type {Highcharts.Options} */
const DEFAULT_STATION_OPTIONS = {
	series: [],

	colors: [
		'#7cb5ec', '#434348', '#90ed7d', '#f7a35c',
		'#8085e9', '#f15c80', '#e4d354', '#2b908f',
		'#f45b5b', '#91e8e1'
	],

	credits: {
		enabled: false,
		href: 'https://neonaut.de/',
		text: 'Neonaut GmbH',
	},

	tooltip: {
		shared: true,
		xDateFormat: '%A, %d.%m.%Y',
		borderWidth: 1,
		borderRadius: 3,
		backgroundColor: 'rgba(250, 250, 250, 0.9)',
	},

	title: {
		text: undefined,
	},
	subtitle: {
		text: undefined,
	},

	xAxis: {
		type: 'datetime',
		dateTimeLabelFormats: {
			day: '%e. %b',
			month: '%b \'%y',
		},
		margin: 0,
		offset: problemSeriesHeight,
	},

	yAxis: [
		{
			type: 'linear',
			min: 0,
			title: {
				text: undefined,
			},
			margin: 0,
			alignTicks: false,
		},
		{
			type: 'linear',
			title: {
				text: undefined,
			},
			opposite: true,
			min: 0,
			max: 1,
			top: problemSeriesHeight,
			labels: {
				enabled: false
			},
			gridLineWidth: 0,
			margin: 0,
			alignTicks: false,
		}
	],

	chart: {
		style: {
			fontFamily: 'HKGrotesk,HelveticaNeue-Light,Helvetica Neue Light,Helvetica Neue,Helvetica,Helvetica Now,HelveticaNow,FreeSans,Nimbus Sans,Nimbus Sans L,Liberation Sans,Arial,Lucida Grande,sans-serif',
			fontSize: '13px',
		},
		type: 'column',
		height: 480,
		backgroundColor: 'transparent',
	},

	plotOptions: {
		column: {
			pointPadding: 0.1,
			groupPadding: 0.1,
			style: 'padding: 0.1em',
		},
		area: {
			stacking: 'normal',
		},
	},

	legend: {
		enabled: false,
		layout: 'horizontal',
		alignColumns: true,
		itemDistance: 20,
		borderWidth: 0,
		padding: 10,
		margin: 2,
		navigation: {
			enabled: true,
			animation: false,
			activeColor: '#444444',
			inactiveColor: '#cccccc'
		},
		itemStyle: {
			fontWeight: 'normal',
			fontSize: '13px',
		},
		backgroundColor: 'transparent',
		itemHiddenStyle: {
			color: '#333333',
		},
	},
};

const problemY = 0;

const colors = config.HIGHCHARTS_OPTIONS.colors;

function getColorForIndex(index) {
	return colors[index % colors.length];
}

function getXDateFormat(resolution) {
	if (resolution === 'hourly') {
		return '%A, %d.%m.%Y, %H Uhr';
	}
	if (resolution === 'weekly') {
		return 'Woche vom %d.%m.%Y';
	}
	if (resolution === 'monthly') {
		return '%B %Y';
	}
	if (resolution === 'yearly') {
		return '%Y';
	}

	return '%A, %d.%m.%Y';
}

function formatProblemTooltip(problems, resolution, stations) {
	if (resolution === 'daily') {
		return problems
			.map((problem) => stations.find(({id}) => id === problem.station_id))
			.filter(Boolean)
			.map(({title}) => title)
			.join('<br>');
	}

	const problemsGroupedByStation = Object.values(problems.reduce((grouped, problem) => {
		grouped[problem.station_id] = grouped[problem.station_id] || {
			problems: [],
			id: problem.stationd_id,
			title: stations.find(({id}) => id === problem.station_id)?.title
		};
		grouped[problem.station_id].problems.push(problem);
		return grouped;
	}, {}));

	const hasManyStations = problemsGroupedByStation.length > 1;
	const hasManyProblems = hasManyStations || problemsGroupedByStation[0]?.problems.length > 1;
	const problemStr = hasManyProblems ? 'Ausfälle' : 'Ausfall';

	return problemStr + ' ' + problemsGroupedByStation
		.map(groupedProblems => {
			const days = groupedProblems.problems.length;
			return `${groupedProblems.title} (${days} ${days > 1 ? ' Tage' : ' Tag'})`;
		}).join('<br>');
}

const MS_PER_DAY = 24 * 60 * 60 * 1000;
const DAYS_PER_WEEK = 7;

function getWeek(date) {
	const dateFirstDay = new Date(date.getFullYear(), 0, 1);
	const msIntoYear = date - dateFirstDay;
	const daysIntoYear = Math.floor(msIntoYear / MS_PER_DAY);
	const firstDayOfWeek = dateFirstDay.getDay() + 1;
	return Math.ceil((daysIntoYear + firstDayOfWeek) / DAYS_PER_WEEK);
}

function getTimestampForWeek(week, year) {
	const dateFirstDay = new Date(year, 0, 1);
	const firstDayOfWeek = dateFirstDay.getDay() + 1;
	const daysIntoYear = (week - 1) * DAYS_PER_WEEK - firstDayOfWeek;
	return dateFirstDay.getTime() + daysIntoYear * MS_PER_DAY;
}

function reduceProblemsForResolution(problems, visibleStationsList, resolution) {
	const visibleProblems = problems && visibleStationsList ?
		problems.filter(problem => visibleStationsList.indexOf(problem.station_id) > -1) :
		[];

	switch (resolution) {
		case 'weekly':
			return Object.values(visibleProblems.reduce((clusters, problem) => {
				const week = getWeek(new Date(problem.start_datetime));
				const dateStr = problem.start_datetime.substr(0, 7) + week;
				clusters[dateStr] = clusters[dateStr] || {
					x: getTimestampForWeek(week, parseInt(dateStr.substr(0, 4), 10)),
					y: problemY,
					problems: [],
				};
				clusters[dateStr].problems.push(problem);
				return clusters;
			}, {}));

		case 'monthly':
			return Object.values(visibleProblems.reduce((clusters, problem) => {
				const dateStr = problem.start_datetime.substr(0, 7);
				clusters[dateStr] = clusters[dateStr] || {
					x: localTimeTimestampFromDateTimeString(dateStr + '-01 00:00:00'),
					y: problemY,
					problems: [],
				};
				clusters[dateStr].problems.push(problem);
				return clusters;
			}, {}));

		case 'yearly':
			return Object.values(visibleProblems.reduce((clusters, problem) => {
				const dateStr = problem.start_datetime.substr(0, 4);
				clusters[dateStr] = clusters[dateStr] || {
					x: localTimeTimestampFromDateTimeString(dateStr + '-01-01 00:00:00'),
					y: problemY,
					problems: [],
				};
				clusters[dateStr].problems.push(problem);
				return clusters;
			}, {}));

		case 'daily':
		default:
			return Object.values(visibleProblems.reduce((clusters, problem) => {
				const dateStr = problem.start_datetime.substr(0, 10);
				clusters[dateStr] = clusters[dateStr] || {
					x: localTimeTimestampFromDateTimeString(dateStr + ' 00:00:00'),
					y: problemY,
					problems: [],
				};
				clusters[dateStr].problems.push(problem);
				return clusters;
			}, {}));
	}
}

function getDefaultStations(stations) {
	return stations?.length ? stations.slice(0, config.DEFAULT_NUMBER_OF_STATIONS).map(({id}) => id) : [];
}

const Chart = function Chart({
	showStationsList,
	data,
	problems = [],
	stations,
	type,
	resolution,
	height = null,
	showLegend = true,
}) {
	const [userSelectedStations, setUserSelectedStations] = useState(showStationsList || null);
	useEffect(() => {
		if (!showStationsList && userSelectedStations === null && stations?.length) {
			setUserSelectedStations(getDefaultStations(stations));
		}
	}, [stations, showStationsList, userSelectedStations]);

	const visibleStations = (!showLegend ? showStationsList : userSelectedStations) || getDefaultStations(stations);

	const options = useMemo(() => ({
		...DEFAULT_STATION_OPTIONS,
		chart: {
			...DEFAULT_STATION_OPTIONS.chart,
			type: type,
			height: height !== null ? height : DEFAULT_STATION_OPTIONS.chart.height,
		},
		tooltip: {
			...DEFAULT_STATION_OPTIONS.tooltip,
			xDateFormat: getXDateFormat(resolution),
		},
	}), [type, resolution, height]);

	const [chart, setChart] = useState(/** @type {import('highcharts').Chart | null} */(null));
	const chartRef = useRef(/** @type {import('highcharts').Chart | null} */(null));

	const dataSeries = useMemo(() => {
			if (!stations) {
				return [];
			}

			return stations.map(({id, title, groupBy}, index) => {
				const initiallyVisible = visibleStations.indexOf(id) > -1;
				return {
					id,
					type,
					index,
					data: data?.[id]?.values.slice(0).map(mapChartEntry) || [],
					visible: initiallyVisible,
					name: title,
					legendIndex: index,
					color: getColorForIndex(index),
					yAxis: 0,
					custom: {
						groupBy,
					},
					events: {
						legendItemClick() {
							if (this.visible) {
								setUserSelectedStations(ids => ids.filter(x => id !== x));
							} else {
								setUserSelectedStations(ids => [...ids, id]);
							}
						},
					},
				};
			}).filter(Boolean);
		},
		// NOTE: We purposely don't include `visibleStations` in the dependencies here
		// because we don't want to redraw the chart as the visibility is controlled by the chart
		// and `visibleStations` is synced with the chart. We only use it in this useMemo to
		// determine the initial visibility of the stations when re-rendering the chart.
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[data, stations, type, showStationsList]
	);

	const drawDataSeries = useCallback(({redraw = true} = {}) => {
		const curChart = chartRef.current;
		if (!curChart) {
			return;
		}

		dataSeries.forEach(series => {
			const existingSeries = curChart.get(series.id);
			if (existingSeries) {
				existingSeries.update(series, false);
				// highcharts@7 does not support custom data, thus we need to apply it manually
				existingSeries.custom = series.custom;
			} else {
				const seriesObj = curChart.addSeries(series, false);
				// highcharts@7 does not support custom data
				seriesObj.custom = series.custom;
			}
		});

		if (redraw) {
			curChart.redraw();
		}
	}, [dataSeries]);

	const problemSeries = useMemo(() => ({
		id: 'problems',
		type: 'scatter',
		visible: true,
		showInLegend: false,
		name: 'Ausfälle',
		index: 9999,
		zIndex: 9999999,
		legendIndex: 9999,
		color: 'red',
		marker: {
			symbol: 'circle',
			radius: 4,
			fillColor: 'red',
		},
		states: {
			inactive: {
				opacity: 1,
			},
		},
		tooltip: {
			enabled: true,
			headerFormat: '<b>Ausfälle, {point.key}</b><br>',
			xDateFormat: getXDateFormat(resolution),
			pointFormatter: function () {
				return formatProblemTooltip(this.problems, resolution, stations);
			},
		},
		yAxis: 1,
		data: reduceProblemsForResolution(problems, visibleStations, resolution),
	}), [stations, problems, visibleStations, resolution]);

	const drawProblemSeries = useCallback(({redraw = true} = {}) => {
		const curChart = chartRef.current;
		if (!curChart) {
			return;
		}

		const existingSeries = curChart.get(problemSeries.id);
		if (existingSeries) {
			existingSeries.update(problemSeries, redraw);
		} else {
			curChart.addSeries(problemSeries, redraw);
		}
	}, [problemSeries]);

	useEffect(
		() => {
			const curChart = chartRef.current;
			if (curChart) {
				drawDataSeries({redraw: false});
				drawProblemSeries({redraw: false});
				curChart.redraw();
			}
		},
		[drawDataSeries, drawProblemSeries]
	);

	const handleChartCreated = useCallback(/** @param {import('highcharts').Chart} newChart */newChart => {
		setChart(newChart);
		chartRef.current = newChart;
		drawDataSeries({redraw: false});
		drawProblemSeries({redraw: false});
		newChart.redraw();
	}, [drawDataSeries, drawProblemSeries]);

	return (
		<div className="vmznds-chart">
			<HighchartsReact
				highcharts={Highcharts}
				options={options}
				callback={handleChartCreated}
			/>

			{showLegend && chart !== null && (
				<ChartLegend chart={chart} />
			)}
		</div>
	);
};

export default Chart;
