import unique from 'lodash/uniq';

export default function reduceByKeys(keys, state) {
	if (keys && state) {
		return unique(keys).reduce(function reduceByKey(newState, key) {
			return state[key] !== undefined ? {...newState, [key]: state[key]} : newState;
		}, {});
	}

	return state;
}
