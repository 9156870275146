import TileWMSSource from 'ol/source/tilewms';

import {OPTION_SET} from '../../index';
import base from './tileimage';

export default {
	type: 'source',
	name: 'TileWMS',
	Constructor: TileWMSSource,
	optionMap: {
		...base.optionMap,
		cacheSize: OPTION_SET,
		crossOrigin: OPTION_SET,
		gutter: OPTION_SET,
		hidpi: OPTION_SET,
		logo: OPTION_SET,
		tileClass: OPTION_SET,
		tileGrid: OPTION_SET,
		reprojectionErrorThreshold: OPTION_SET,
		serverType: OPTION_SET,
		wrapX: OPTION_SET,
		transition: OPTION_SET,
	},
	initialOptionMap: {
		...base.initialOptionMap,
		params: 'params',
	},
};
