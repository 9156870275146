// TODO: Either use external libs or merge into ../../helpers/…
export function mapFeaturesToStations(features) {
	const stations = [];

	let index = 0;
	features.forEach(feature => {
		const featureIndex = index;
		index++;
		const props = feature.properties;
		const title = props.title ? props.title.replace('Fahrradzähler', '').trim() : '';

		stations.push({
			id: props.externalId,
			title: title,
			index: featureIndex,
			groupBy: props.group,
		});
	});

	return stations;
}

export function localTimeTimestampFromDateTimeString(dateTimeString) {
	const [dateString, timeString] = dateTimeString.split(' ');
	const [year, month, day] = dateString.split('-').map(Number);
	const [hour, minute, second] = timeString.split(':').map(Number);
	return Date.UTC(year, month - 1, day, hour, minute, second);
}

export function dateToYmd(date) {
	const year = date.getFullYear();

	let month = '' + (date.getMonth() + 1);
	if (month.length < 2) {
		month = '0' + month;
	}

	let day = '' + date.getDate();
	if (day.length < 2) {
		day = '0' + day;
	}

	return [year, month, day].join('-');
}

export function formatPrettyNumber(num) {
	const str = num.toString();
	return str
		.replace(new RegExp('(^\\d{' + (str.length % 3 || -1) + '})(?=\\d{3})'), '$1.')
		.replace(/(\d{3})(?=\d)/g, '$1.');
}

export const mapChartEntry = entry => [
	localTimeTimestampFromDateTimeString(entry.datetime),
	parseInt(entry.value, 10),
];


export const getFirstDayOfMonth = (
	month = (new Date()).getMonth() + 1,
	year = (new Date()).getFullYear()
) => `${year.toString().padStart(4, '0')}-${month.toString().padStart(2, '0')}-01`;

export const getLastDayOfMonth = (
	month = (new Date()).getMonth() + 1,
	year = (new Date()).getFullYear()
) => {
	const date = new Date(year, (month - 1) + 1, 0, 12, 0, 0, 0);
	return `${year.toString().padStart(4, '0')}-${month.toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
};

export const getFirstDayOfYear = (year = (new Date()).getFullYear()) => `${year.toString().padStart(4, '0')}-01-01`;
export const getLastDayOfYear = (year = (new Date()).getFullYear()) => `${year.toString().padStart(4, '0')}-12-31`;
export const getFirstDayOfLastYear = () => getFirstDayOfYear((new Date()).getFullYear() - 1);
export const getLastDayOfLastYear = () => getLastDayOfYear((new Date()).getFullYear() - 1);
