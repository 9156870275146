import PinchZoom from 'ol/interaction/pinchzoom';

import base from './_base';

export default {
	type: 'interaction',
	name: 'PinchZoom',
	Constructor: PinchZoom,
	optionMap: {
		...base.optionMap,
	},
	initialOptionMap: {
		...base.initialOptionMap,
	},
};
