import React, {Fragment, useRef, memo} from 'react';

import FeatureSelectionInfo from '../feature-selection-info';
import {FEATURE_SELECTION_SELECT} from '../../config/feature/selections';
import FeatureList from '../feature-list';

import MainPanelContent from './content';
import ListToggleButton from './list-toggle-button';
import MainPanelContainer from './container';

export const MS3_UI_MAIN_PANEL_POSITION_LEFT = 'left';
export const MS3_UI_MAIN_PANEL_POSITION_BELOW = 'below';

export const MS3_UI_MAIN_PANEL_CONTENT_TYPE_LIST = 'list';
export const MS3_UI_MAIN_PANEL_CONTENT_TYPE_SELECTION_INFO = 'selectionInfo';


function MainPanel({
	feature,
	canUserCollapseList,
	toggleList,
	userPreferenceListVisible,
	contentType,
	collapsed,
	panelPosition,
	panelWrapper,
}) {
	// we keep using the old content stored in contentRef.current if we do not have any new content
	// to allow for a smooth transition when collapsing the container
	const contentRef = useRef();
	const showInfoRef = useRef();

	if (contentType) {
		showInfoRef.current = contentType === MS3_UI_MAIN_PANEL_CONTENT_TYPE_SELECTION_INFO;
		contentRef.current = (
			<Fragment>
				<FeatureList />

				{showInfoRef.current && (
					<FeatureSelectionInfo
						feature={feature}
						selectionId={FEATURE_SELECTION_SELECT}
					/>
				)}
			</Fragment>
		);
	}

	const container = (
		<MainPanelContainer
			collapsed={collapsed}
			panelPosition={panelPosition}
		>
			{canUserCollapseList && (
				<ListToggleButton active={userPreferenceListVisible} onClick={toggleList} />
			)}

			<MainPanelContent
				panelPosition={panelPosition}
				contentType={contentType}
				collapsed={collapsed}
				hideList={showInfoRef ? showInfoRef.current : false}
			>
				{contentRef ? contentRef.current : null}
			</MainPanelContent>
		</MainPanelContainer>
	);

	// panelWrapper is kept for compatibility with previous code from main-container.jsx
	if(panelWrapper === true) {
		return (
			<div className={`ms3-panel-wrapper ${collapsed ? 'ms3-panel-wrapper--empty' : ''} [ ms3-flex ms3-flex--column ]`}>
				{container}
			</div>
		);
	} else {
		return container;
	}
}

export default memo(MainPanel);
