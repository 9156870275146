import FeatureSelectionConnector from './FeatureSelectionConnector';

export default class FeatureSelectionStatesManager {
	constructor({onChange}) {
		this._store = null;
		this._featureSelectionsControllerName = null;
		this._changeHandler = onChange;
		this._connector = null;
	}

	get(featureId) {
		return this._connector && this._connector.get(featureId);
	}

	getPrevious(featureId) {
		return this._connector && this._connector.getPrevious(featureId);
	}

	getAllFeaturesWithState(state) {
		return this._connector && this._connector.getAllFeaturesWithState(state);
	}

	deselect(state, featureId) {
		if (this._connector) {
			this._connector.deselect(state, featureId);
		}
	}

	filterFeaturesByActiveSelections(features, selections) {
		return features.filter(feature => selections.indexOf(this.get(feature.getId())) > -1);
	}

	filterChangedFeatures(features) {
		return features.filter(feature => this._isChanged(feature.getId()));
	}

	setFeatureSelectionsControllerName(featureSelectionsControllerName) {
		this._featureSelectionsControllerName = featureSelectionsControllerName;
		this._updateConnector();
	}

	bindToStore(store) {
		this._store = store;
		this._updateConnector();
	}

	_isChanged(featureId) {
		return this.get(featureId) !== this.getPrevious(featureId);
	}

	_updateConnector() {
		const featureSelectionsController = this._featureSelectionsControllerName && this._store &&
			this._store.getController(this._featureSelectionsControllerName);

		if (featureSelectionsController) {
			if (this._changeHandler && this._connector) {
				this._connector.unsubscribe(this._changeHandler);
			}

			this._connector = FeatureSelectionConnector.getInstance(featureSelectionsController);

			if (this._changeHandler) {
				this._connector.subscribe(this._changeHandler);
			}
		} else if (this._connector) {
			if (this._changeHandler) {
				this._connector.unsubscribe(this._changeHandler);
			}

			this._connector = null;
		}
	}
}
