import React, {memo, useCallback} from 'react';
import {createSelector} from 'reselect';
import {useSelector, useDispatch} from 'react-redux';

import {
	reduceLayersToLegends,
	visibleLayersWithLegendsSelector
} from '@mapsight/core/lib/map/selectors';

import {translate} from '../../helpers/i18n';
import {setOverlayModalVisible} from '../../store/actions';
import {MAP} from '../../config/constants/controllers';

import Attribution from './attribution';

const legendsSelector = createSelector(
	state => visibleLayersWithLegendsSelector(state[MAP]),
	// we need to run this as well, cause it's filtering stuff
	reduceLayersToLegends,
);

function InfoOverlayRight() {
	const dispatch = useDispatch();

	const legends = useSelector(legendsSelector);

	const expand = useCallback(
		() => {
			dispatch(setOverlayModalVisible(true));
		},
		[dispatch],
	);

	return (
		<div
			className="ms3-info-overlay__desktop-content ms3-info-overlay__area ms3-info-overlay__area--right"
		>
			<Attribution
				additional={Object.keys(legends).length !== 0 && (
					<button
						type="button"
						onClick={expand}
					>
						{translate('ui.map-overlay.info.legend')}
					</button>
				)}
			/>
		</div>
	);
}

export default memo(InfoOverlayRight);
