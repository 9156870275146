import {createStructuredSelector} from 'reselect';
import {connect} from 'react-redux';

import {layerSwitcherConfigInternalSelector} from '../../store/selectors';
import {FEATURE_LIST} from '../../config/constants/controllers';

import LayerSwitcherContainer from './LayerSwticherContainer';

/**
 * @param {Object} props properties
 * @param {Function()} props.onClose callback when closed
 * @param {Function(Object): Object} [props.configSelector] config selector, default = layerSwitcherConfigInternalSelector
 */

const TRUE_SET_FEATURE_SOURCE_PATH = [FEATURE_LIST, 'featureSource'];

export default connect(
	createStructuredSelector({
		layerIdsSelector: (state, {configSelector = layerSwitcherConfigInternalSelector}) => configSelector(state).layerIdsSelector,
		grouped: (state, {configSelector = layerSwitcherConfigInternalSelector}) => configSelector(state).grouped,
		setFeatureSourceIdPath: function setFeatureSourceIdPath(
			state,
			{configSelector = layerSwitcherConfigInternalSelector}
		) {
			const config = configSelector(state);
			if (config.setFeatureSourceId === true) {
				return TRUE_SET_FEATURE_SOURCE_PATH;
			} else {
				return config.setFeatureSourceId;
			}
		},
	}),
	null,
	(stateProps, dispatchProps, {configSelector, ...ownProps}) => ({...ownProps, ...stateProps})
)(LayerSwitcherContainer);
