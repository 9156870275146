import merge from 'lodash/merge';

export default class DependencyManager {
	constructor(dependencies = {}) {
		this._dependencies = dependencies;
	}

	inject(dependencies) {
		merge(this._dependencies, dependencies);
	}

	getDependency(groupOrName = null, name = null) {
		if (arguments.length > 1) {
			if (groupOrName === null) {
				return this._dependencies[name];
			}

			return this._dependencies[groupOrName] && this._dependencies[groupOrName][name];
		}

		return this._dependencies[groupOrName];
	}

	getDependencies(group) {
		return this._dependencies[group];
	}

	static makeInstance(dependency, ...args) {
		if (!dependency) {
			DependencyManager._handleError(dependency, 'Missing dependency, it probably needs to be injected into the controller!');
			return null;
		}

		DependencyManager.emitEvent(dependency, 'beforeCreation', ...args);

		if (!dependency.Constructor) {
			DependencyManager._handleError(dependency, `Missing dependency Constructor for "${dependency.type}.${dependency.name}", it probably needs to be injected into the controller!`);
			return null;
		}

		try {
			const instance = new dependency.Constructor(...args);
			DependencyManager.emitEvent(dependency, 'afterCreation', instance, ...args);
			return instance;
		} catch (exception) {
			DependencyManager._handleError(dependency, `Failed to construct object "${dependency.type}.${dependency.name}".`, exception, {
				Constructor: dependency.Constructor,
				args: args
			});
			return null;
		}
	}

	makeInstance(group, name, ...args) {
		return DependencyManager.makeInstance(this.getDependency(group, name), ...args);
	}

	static _handleError(dependency, ...errorMessages) {
		console.error(...errorMessages);
		DependencyManager.emitEvent(dependency, 'creationFailed', ...errorMessages);
	}

	static emitEvent(dependency, eventName, ...args) {
		if (dependency && dependency.events && dependency.events[eventName]) {
			dependency.events[eventName](...args);
		}
	}

	emitEvent(group, name, eventName, ...args) {
		return DependencyManager.emitEvent(this.getDependency(group, name), eventName, ...args);
	}

	static checkObjectType(dependency, obj) {
		return !!(obj && dependency && obj instanceof dependency.Constructor);
	}

	checkObjectType(group, name, obj) {
		return DependencyManager.checkObjectType(this.getDependency(group, name), obj);
	}
}
