import React, {memo} from 'react';

import {translate} from '../../helpers/i18n';

function FeatureListContent(props) {
	const {status, children, showFeatureListInfo, as: T, itemAs: U, featureSourceId, ...attributes} = props;
	const statusClass = status ? ' ms3-featurelist--status-' + status : '';
	const classes = `[ ms3-list ms3-list--features ${statusClass} ] ${attributes.className || ''}`;

	if (T === 'table') {
		let content = children;
		if (!content) {
			content = (
				<tr>
					<td colSpan="2">
						<p className="ms3-list__empty">
							{featureSourceId && translate('ui.feature-details.content.noEntries')}
							{!featureSourceId && translate('ui.feature-details.content.noListSelected')}
						</p>
					</td>
				</tr>
			);
		}

		return (
			<T className={classes} {...attributes}>
				<thead>
					<tr>
						<th className="center icon" aria-hidden={true} />
						<th className="left title">
							{translate('ui.feature-details.content.title')}
						</th>
						{showFeatureListInfo && (
							<th className="left desc">
								{translate('ui.feature-details.content.description')}
							</th>
						)}
					</tr>
				</thead>
				<tbody>{content}</tbody>
			</T>
		);
	}

	let content = children;
	if (!content) {
		content = (
			<U className="ms3-list__empty">
				{featureSourceId ? translate('ui.feature-details.content.noEntries') : translate('ui.feature-details.content.noListSelected')}
			</U>
		);
	}

	return (
		<T className={classes} {...attributes}>
			{content}
		</T>
	);
}

export default memo(FeatureListContent);
