import getPath from '@neonaut/lib-js/es/object/getPath';

import getFeatureProperty from '../helpers/get-feature-property';

/**
 * @typedef {object} TagFilterCacheEntryForFeatureSource
 * @property {array} features input array of features
 * @property {object<string, string[]>} visibleTags input plain object of visible tags grouped by tag groups
 * @property {array} filtered filtered output array of features
 */

/**
 * @typedef {function} tagFilterFunction
 * @property {array} features features input array of features
 * @property {object} filterState filter state from redux store
 * @property {string} featureSourceId id of feature source
 */

/**
 * Creates a cached tag filter function
 * @return {tagFilterFunction} tag filter function
 */
export function createTagFilterFunction() {
	/**
	 * @type {object<string, TagFilterCacheEntryForFeatureSource>} cache
	 */
	const cache = {
		// structure:
		//featureSourceId: {
		//	// inputs:
		//	features: [...],
		//	visibleTags: {tagGroup: [tag, ...], ...},
		//	// output:
		//	filtered: [...],
		//},
	};

	return function tagFilterFunction(features, filterState, featureSourceId) {
		// early return if parameters are missing
		if (!featureSourceId || !filterState || !features) {
			return features;
		}

		// show all features, if no tag is selected
		const visibleTags = getPath(filterState, ['visibleTags', featureSourceId]);
		const visibleTagGroups = getPath(filterState, ['visibleTagGroups', featureSourceId]);
		if (!visibleTags && !visibleTagGroups) {
			return features;
		}

		const cacheInvalid = !cache[featureSourceId] ||
			cache[featureSourceId].features !== features ||
			cache[featureSourceId].visibleTags !== visibleTags ||
			cache[featureSourceId].visibleTagGroups !== visibleTagGroups;

		if (cacheInvalid) {
			const flattenedTags = transformTagMapsToArrays(visibleTags, visibleTagGroups);

			cache[featureSourceId] = {
				// inputs:
				features: features,
				visibleTags: visibleTags,
				visibleTagGroups: visibleTagGroups,
				// output:
				filtered: features.filter(feature =>
					flattenedTags.every(({group, tags}) => filterFeature(feature, group, tags))
				),
			};
		}

		return cache[featureSourceId].filtered;
	};
}

/**
 * @deprecated Do not use. This uses a global cache. Use export createTagFilterFunction instead to create local function per mapsight instance!
 */
const deprecatedTagFilterFunction = createTagFilterFunction();
export default deprecatedTagFilterFunction;

function transformTagMapsToArrays(visibleTags = null, visibleTagGroups = null) {
	const result = {};
	if (visibleTags) {
		Object.keys(visibleTags).forEach(group => {
			const tags = getVisibleTagsForGroup(visibleTags, group);
			if (tags.length) {
				result[group] = tags;
			}
		});
	}
	if (visibleTagGroups) {
		Object.keys(visibleTagGroups).forEach(group => {
			const isActive = !!visibleTagGroups[group];

			if (isActive) {
				result[group] = result[group] || true;
			}
		});
	}

	return Object.keys(result).map(group => {
		const tags = result[group];
		return {group: group, tags: tags};
	});
}

function getVisibleTagsForGroup(visibleTags, tagGroup) {
	return Object.keys(visibleTags[tagGroup]).filter(tag => visibleTags[tagGroup][tag] === true);
}

function filterFeature(feature, group, visibleTags) {
	const featureData = getFeatureProperty(feature, 'tagGroups', {})[group]; // TODO: document/collect magic property names
	if (!featureData) {
		return false;
	}

	const {andJunction, tags} = featureData;
	if (visibleTags === true) {
		return !!tags.length;
	}

	return visibleTags[andJunction === true ? 'every' : 'some'](tag => tags.indexOf(tag) > -1);
}
