/* eslint-disable */
// helpers for program
import get from '@neonaut/lib-js/es/object/getPath';
import merge from '@neonaut/lib-js/es/object/deep-extend';
import replace from '@neonaut/lib-js/es/string/replaceRegex';

import createCachedStyleFunction from '@mapsight/lib-ol/style/createCachedStyleFunction';

import __vectorStyle_fill from 'ol/style/fill';
import __vectorStyle_stroke from 'ol/style/stroke';
import __vectorStyle_text from 'ol/style/text';
import __vectorStyle_image from 'ol/style/image';
import __vectorStyle_icon from 'ol/style/icon';
import __vectorStyle_circle from 'ol/style/circle';
import __vectorStyle_style from 'ol/style/style';

const createHash = a => JSON.stringify(a);

export default createCachedStyleFunction({
	constructorsMap: {fill: __vectorStyle_fill,
		stroke: __vectorStyle_stroke,
		text: __vectorStyle_text,
		image: __vectorStyle_image,
		icon: __vectorStyle_icon,
		circle: __vectorStyle_circle,
		style: __vectorStyle_style},
	allowedStyles: ['traffic','userGeolocation','features','graphmastersRouteStopsDraw','navigationRoute','graphmastersTraffic','graphmastersRoute'],
	allowedProps: ['state','trafficSituation','los','accuracy','mapsightIconId','isTemporaryRestriction','type','title','occupancyTrendString','mass_restriction','height_restriction','anomalyText','textDirectionX','textDirectionY','speedRatio'],
	declarationHashFunction: (env, props, hashPrefix, geometryType, style) => {
		let hash = hashPrefix + '|' + geometryType + '|';
		const addHash = a => { hash += ',' + a; };

		addHash(1);
		switch (style) {
			case 'traffic':
				addHash(2);
				if (env.zoom < 15) {
					addHash(3);
				}
				if (env.zoom < 12) {
					addHash(4);
				}
				if (env.zoom < 9) {
					addHash(5);
				}
				if (props.state == 'highlight' || props.state == 'select') {
					addHash(6);
				}
				if (props.trafficSituation == null || props.trafficSituation == 'Unbekannt' || props.trafficSituation == 'UNKNOWN_ERROR' || props.los == null || props.los == 0) {
					addHash(7);
				}
				if (props.trafficSituation == null && env.zoom < 15 || props.trafficSituation == 'Unbekannt' && env.zoom < 15 || props.trafficSituation == 'UNKNOWN_ERROR' && env.zoom < 15 || props.los == null && env.zoom < 15 || props.los == 0 && env.zoom < 15) {
					addHash(8);
				}
				if (props.trafficSituation == null && env.zoom < 9 || props.trafficSituation == 'Unbekannt' && env.zoom < 9 || props.trafficSituation == 'UNKNOWN_ERROR' && env.zoom < 9 || props.los == null && env.zoom < 9 || props.los == 0 && env.zoom < 9) {
					addHash(9);
				}
				if (props.trafficSituation == null && props.state == 'highlight' || props.trafficSituation == null && props.state == 'select' || props.trafficSituation == 'Unbekannt' && props.state == 'highlight' || props.trafficSituation == 'Unbekannt' && props.state == 'select' || props.trafficSituation == 'UNKNOWN_ERROR' && props.state == 'highlight' || props.trafficSituation == 'UNKNOWN_ERROR' && props.state == 'select' || props.los == null && props.state == 'highlight' || props.los == null && props.state == 'select' || props.los == 0 && props.state == 'highlight' || props.los == 0 && props.state == 'select') {
					addHash(10);
				}
				if (props.trafficSituation == 'Frei' || props.los == 1) {
					addHash(11);
				}
				if (props.trafficSituation == 'Frei' && env.zoom < 9 || props.los == 1 && env.zoom < 9) {
					addHash(12);
				}
				if (props.trafficSituation == 'Frei' && props.state == 'highlight' || props.trafficSituation == 'Frei' && props.state == 'select' || props.los == 1 && props.state == 'highlight' || props.los == 1 && props.state == 'select') {
					addHash(13);
				}
				if (props.trafficSituation == 'Zaehfliessend' || props.los == 2) {
					addHash(14);
				}
				if (props.trafficSituation == 'Zaehfliessend' && env.zoom < 17 || props.los == 2 && env.zoom < 17) {
					addHash(15);
				}
				if (props.trafficSituation == 'Zaehfliessend' && env.zoom < 9 || props.los == 2 && env.zoom < 9) {
					addHash(16);
				}
				if (props.trafficSituation == 'Zaehfliessend' && props.state == 'highlight' || props.trafficSituation == 'Zaehfliessend' && props.state == 'select' || props.los == 2 && props.state == 'highlight' || props.los == 2 && props.state == 'select') {
					addHash(17);
				}
				if (props.trafficSituation == 'Stau' || props.los == 3 || props.los == 4) {
					addHash(18);
				}
				if (props.trafficSituation == 'Stau' && env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' || props.los == 3 && env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' || props.los == 4 && env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select') {
					addHash(19);
				}
				if (props.trafficSituation == 'Stau' && env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' || props.los == 3 && env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' || props.los == 4 && env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select') {
					addHash(20);
				}
				if (props.trafficSituation == 'Stau' && env.zoom < 9 || props.los == 3 && env.zoom < 9 || props.los == 4 && env.zoom < 9) {
					addHash(21);
				}
				if (props.trafficSituation == 'Stau' && props.state == 'highlight' || props.trafficSituation == 'Stau' && props.state == 'select' || props.los == 3 && props.state == 'highlight' || props.los == 3 && props.state == 'select' || props.los == 4 && props.state == 'highlight' || props.los == 4 && props.state == 'select') {
					addHash(22);
				}
				break;
			case 'userGeolocation':
				addHash(23);
				addHash('@' + createHash(props.accuracy));
				break;
			case 'features':
				addHash(24);
				if (props.mapsightIconId == 'behindertenparken' || env.mapsightIconId == 'behindertenparken' || props.mapsightIconId == 'tiefgarage' || env.mapsightIconId == 'tiefgarage' || props.mapsightIconId == 'parkhaus' || env.mapsightIconId == 'parkhaus' || props.mapsightIconId == 'motorradstellplatz' || env.mapsightIconId == 'motorradstellplatz' || props.mapsightIconId == 'busparkplatz' || env.mapsightIconId == 'busparkplatz') {
					addHash(25);
				}
				if (props.mapsightIconId == 'dot' || env.mapsightIconId == 'dot') {
					addHash(26);
				}
				if (props.mapsightIconId == 'parkhaus' || env.mapsightIconId == 'parkhaus') {
					addHash(27);
				}
				if (props.mapsightIconId == 'friedhof' || env.mapsightIconId == 'friedhof') {
					addHash(28);
				}
				if (props.mapsightIconId == 'haltestelle' || env.mapsightIconId == 'haltestelle') {
					addHash(29);
				}
				if (props.mapsightIconId == 'hoehenbeschraenkung' || env.mapsightIconId == 'hoehenbeschraenkung') {
					addHash(30);
				}
				if (props.mapsightIconId == 'forschung' || env.mapsightIconId == 'forschung') {
					addHash(31);
				}
				if (props.mapsightIconId == 'flughafen' || env.mapsightIconId == 'flughafen') {
					addHash(32);
				}
				if (props.mapsightIconId == 'hotel' || env.mapsightIconId == 'hotel') {
					addHash(33);
				}
				if (props.mapsightIconId == 'i' || env.mapsightIconId == 'i') {
					addHash(34);
				}
				if (props.mapsightIconId == 'gegenverkehr' || env.mapsightIconId == 'gegenverkehr') {
					addHash(35);
				}
				if (props.mapsightIconId == 'info' || env.mapsightIconId == 'info') {
					addHash(36);
				}
				if (props.mapsightIconId == 'j' || env.mapsightIconId == 'j') {
					addHash(37);
				}
				if (props.mapsightIconId == 'flag-start' || env.mapsightIconId == 'flag-start') {
					addHash(38);
				}
				if (props.mapsightIconId == 'k' || env.mapsightIconId == 'k') {
					addHash(39);
				}
				if (props.mapsightIconId == 'gewerbe' || env.mapsightIconId == 'gewerbe') {
					addHash(40);
				}
				if (props.mapsightIconId == 'kita' || env.mapsightIconId == 'kita') {
					addHash(41);
				}
				if (props.mapsightIconId == 'krankenhaus_klinik' || env.mapsightIconId == 'krankenhaus_klinik') {
					addHash(42);
				}
				if (props.mapsightIconId == 'l' || env.mapsightIconId == 'l') {
					addHash(43);
				}
				if (props.mapsightIconId == 'h' || env.mapsightIconId == 'h') {
					addHash(44);
				}
				if (props.mapsightIconId == 'lkwsperrung' || env.mapsightIconId == 'lkwsperrung') {
					addHash(45);
				}
				if (props.mapsightIconId == 'flag-finish' || env.mapsightIconId == 'flag-finish') {
					addHash(46);
				}
				if (props.mapsightIconId == 'flag' || env.mapsightIconId == 'flag') {
					addHash(47);
				}
				if (props.mapsightIconId == 'm' || env.mapsightIconId == 'm') {
					addHash(48);
				}
				if (props.mapsightIconId == 'fahrbahnverengung' || env.mapsightIconId == 'fahrbahnverengung') {
					addHash(49);
				}
				if (props.mapsightIconId == 'marker' || env.mapsightIconId == 'marker') {
					addHash(50);
				}
				if (props.mapsightIconId == 'meldung' || env.mapsightIconId == 'meldung') {
					addHash(51);
				}
				if (props.mapsightIconId == 'faehre' || env.mapsightIconId == 'faehre') {
					addHash(52);
				}
				if (props.mapsightIconId == 'f' || env.mapsightIconId == 'f') {
					addHash(53);
				}
				if (props.mapsightIconId == 'motorradstellplatz' || env.mapsightIconId == 'motorradstellplatz') {
					addHash(54);
				}
				if (props.mapsightIconId == 'museum' || env.mapsightIconId == 'museum') {
					addHash(55);
				}
				if (props.mapsightIconId == 'n' || env.mapsightIconId == 'n') {
					addHash(56);
				}
				if (props.mapsightIconId == 'einbahnstrasse' || env.mapsightIconId == 'einbahnstrasse') {
					addHash(57);
				}
				if (props.mapsightIconId == 'o' || env.mapsightIconId == 'o') {
					addHash(58);
				}
				if (props.mapsightIconId == 'e' || env.mapsightIconId == 'e') {
					addHash(59);
				}
				if (props.mapsightIconId == 'ort' || env.mapsightIconId == 'ort') {
					addHash(60);
				}
				if (props.mapsightIconId == 'db' || env.mapsightIconId == 'db') {
					addHash(61);
				}
				if (props.mapsightIconId == 'd' || env.mapsightIconId == 'd') {
					addHash(62);
				}
				if (props.mapsightIconId == 'p' || env.mapsightIconId == 'p') {
					addHash(63);
				}
				if (props.mapsightIconId == 'cam' || env.mapsightIconId == 'cam') {
					addHash(64);
				}
				if (props.mapsightIconId == 'parkanlage' || env.mapsightIconId == 'parkanlage') {
					addHash(65);
				}
				if (props.mapsightIconId == 'parkflaeche' || env.mapsightIconId == 'parkflaeche') {
					addHash(66);
				}
				if (props.mapsightIconId == 'c' || env.mapsightIconId == 'c') {
					addHash(67);
				}
				if (props.mapsightIconId == 'busparkplatz' || env.mapsightIconId == 'busparkplatz') {
					addHash(68);
				}
				if (props.mapsightIconId == 'g' || env.mapsightIconId == 'g') {
					addHash(69);
				}
				if (props.mapsightIconId == 'pundr' || env.mapsightIconId == 'pundr') {
					addHash(70);
				}
				if (props.mapsightIconId == 'bundr' || env.mapsightIconId == 'bundr') {
					addHash(71);
				}
				if (props.mapsightIconId == 'breitenbeschraenkung' || env.mapsightIconId == 'breitenbeschraenkung') {
					addHash(72);
				}
				if (props.mapsightIconId == 'q' || env.mapsightIconId == 'q') {
					addHash(73);
				}
				if (props.mapsightIconId == 'bilbiothek_archiv' || env.mapsightIconId == 'bilbiothek_archiv') {
					addHash(74);
				}
				if (props.mapsightIconId == 'r' || env.mapsightIconId == 'r') {
					addHash(75);
				}
				if (props.mapsightIconId == 'bike' || env.mapsightIconId == 'bike') {
					addHash(76);
				}
				if (props.mapsightIconId == 'religion' || env.mapsightIconId == 'religion') {
					addHash(77);
				}
				if (props.mapsightIconId == 's' || env.mapsightIconId == 's') {
					addHash(78);
				}
				if (props.mapsightIconId == 'behindertenparken' || env.mapsightIconId == 'behindertenparken') {
					addHash(79);
				}
				if (props.mapsightIconId == 'schule' || env.mapsightIconId == 'schule') {
					addHash(80);
				}
				if (props.mapsightIconId == 'sehenswuerdigkeit' || env.mapsightIconId == 'sehenswuerdigkeit') {
					addHash(81);
				}
				if (props.mapsightIconId == 'sport' || env.mapsightIconId == 'sport') {
					addHash(82);
				}
				if (props.mapsightIconId == 'sportanlage' || env.mapsightIconId == 'sportanlage') {
					addHash(83);
				}
				if (props.mapsightIconId == 'stadtteil' || env.mapsightIconId == 'stadtteil') {
					addHash(84);
				}
				if (props.mapsightIconId == 'stau' || env.mapsightIconId == 'stau') {
					addHash(85);
				}
				if (props.mapsightIconId == 'baustelle' || env.mapsightIconId == 'baustelle') {
					addHash(86);
				}
				if (props.mapsightIconId == 'b' || env.mapsightIconId == 'b') {
					addHash(87);
				}
				if (props.mapsightIconId == 't' || env.mapsightIconId == 't') {
					addHash(88);
				}
				if (props.mapsightIconId == 'ampel' || env.mapsightIconId == 'ampel') {
					addHash(89);
				}
				if (props.mapsightIconId == 'taxi' || env.mapsightIconId == 'taxi') {
					addHash(90);
				}
				if (props.mapsightIconId == 'theater' || env.mapsightIconId == 'theater') {
					addHash(91);
				}
				if (props.mapsightIconId == 'tiefgarage' || env.mapsightIconId == 'tiefgarage') {
					addHash(92);
				}
				if (props.mapsightIconId == 'tonnage' || env.mapsightIconId == 'tonnage') {
					addHash(93);
				}
				if (props.mapsightIconId == 'abfall_recycling' || env.mapsightIconId == 'abfall_recycling') {
					addHash(94);
				}
				if (props.mapsightIconId == 'a' || env.mapsightIconId == 'a') {
					addHash(95);
				}
				if (props.mapsightIconId == 'u' || env.mapsightIconId == 'u') {
					addHash(96);
				}
				if (props.mapsightIconId == 9 || env.mapsightIconId == 9) {
					addHash(97);
				}
				if (props.mapsightIconId == 'umleitung' || env.mapsightIconId == 'umleitung') {
					addHash(98);
				}
				if (props.mapsightIconId == 8 || env.mapsightIconId == 8) {
					addHash(99);
				}
				if (props.mapsightIconId == 7 || env.mapsightIconId == 7) {
					addHash(100);
				}
				if (props.mapsightIconId == 'v' || env.mapsightIconId == 'v') {
					addHash(101);
				}
				if (props.mapsightIconId == 6 || env.mapsightIconId == 6) {
					addHash(102);
				}
				if (props.mapsightIconId == 'vollsperrung' || env.mapsightIconId == 'vollsperrung') {
					addHash(103);
				}
				if (props.mapsightIconId == 5 || env.mapsightIconId == 5) {
					addHash(104);
				}
				if (props.mapsightIconId == 4 || env.mapsightIconId == 4) {
					addHash(105);
				}
				if (props.mapsightIconId == 'w' || env.mapsightIconId == 'w') {
					addHash(106);
				}
				if (props.mapsightIconId == 3 || env.mapsightIconId == 3) {
					addHash(107);
				}
				if (props.mapsightIconId == 'x' || env.mapsightIconId == 'x') {
					addHash(108);
				}
				if (props.mapsightIconId == 2 || env.mapsightIconId == 2) {
					addHash(109);
				}
				if (props.mapsightIconId == 'y' || env.mapsightIconId == 'y') {
					addHash(110);
				}
				if (props.mapsightIconId == 1 || env.mapsightIconId == 1) {
					addHash(111);
				}
				if (props.mapsightIconId == 'z' || env.mapsightIconId == 'z') {
					addHash(112);
				}
				if (props.mapsightIconId == 0 || env.mapsightIconId == 0) {
					addHash(113);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'dot') {
					addHash(114);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'parkhaus') {
					addHash(115);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'friedhof') {
					addHash(116);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'haltestelle') {
					addHash(117);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'hoehenbeschraenkung') {
					addHash(118);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'forschung') {
					addHash(119);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'flughafen') {
					addHash(120);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'hotel') {
					addHash(121);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'i') {
					addHash(122);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'gegenverkehr') {
					addHash(123);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'info') {
					addHash(124);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'j') {
					addHash(125);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'flag-start') {
					addHash(126);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'k') {
					addHash(127);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'gewerbe') {
					addHash(128);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'kita') {
					addHash(129);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'krankenhaus_klinik') {
					addHash(130);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'l') {
					addHash(131);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'h') {
					addHash(132);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'lkwsperrung') {
					addHash(133);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'flag-finish') {
					addHash(134);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'flag') {
					addHash(135);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'm') {
					addHash(136);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'fahrbahnverengung') {
					addHash(137);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'marker') {
					addHash(138);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'meldung') {
					addHash(139);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'faehre') {
					addHash(140);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'f') {
					addHash(141);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'motorradstellplatz') {
					addHash(142);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'museum') {
					addHash(143);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'n') {
					addHash(144);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'einbahnstrasse') {
					addHash(145);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'o') {
					addHash(146);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'e') {
					addHash(147);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'ort') {
					addHash(148);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'db') {
					addHash(149);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'd') {
					addHash(150);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'p') {
					addHash(151);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'cam') {
					addHash(152);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'parkanlage') {
					addHash(153);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'parkflaeche') {
					addHash(154);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'c') {
					addHash(155);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'busparkplatz') {
					addHash(156);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'g') {
					addHash(157);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'pundr') {
					addHash(158);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'bundr') {
					addHash(159);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'breitenbeschraenkung') {
					addHash(160);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'q') {
					addHash(161);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'bilbiothek_archiv') {
					addHash(162);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'r') {
					addHash(163);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'bike') {
					addHash(164);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'religion') {
					addHash(165);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 's') {
					addHash(166);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'behindertenparken') {
					addHash(167);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'schule') {
					addHash(168);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'sehenswuerdigkeit') {
					addHash(169);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'sport') {
					addHash(170);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'sportanlage') {
					addHash(171);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'stadtteil') {
					addHash(172);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'stau') {
					addHash(173);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'baustelle') {
					addHash(174);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'b') {
					addHash(175);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 't') {
					addHash(176);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'ampel') {
					addHash(177);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'taxi') {
					addHash(178);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'theater') {
					addHash(179);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'tiefgarage') {
					addHash(180);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'tonnage') {
					addHash(181);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'abfall_recycling') {
					addHash(182);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'a') {
					addHash(183);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'u') {
					addHash(184);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 9) {
					addHash(185);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'umleitung') {
					addHash(186);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 8) {
					addHash(187);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 7) {
					addHash(188);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'v') {
					addHash(189);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 6) {
					addHash(190);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'vollsperrung') {
					addHash(191);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 5) {
					addHash(192);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 4) {
					addHash(193);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'w') {
					addHash(194);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 3) {
					addHash(195);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'x') {
					addHash(196);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 2) {
					addHash(197);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'y') {
					addHash(198);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 1) {
					addHash(199);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'z') {
					addHash(200);
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 0) {
					addHash(201);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect') {
					addHash(202);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'behindertenparken' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'behindertenparken' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'tiefgarage' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'tiefgarage' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'parkhaus' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'parkhaus' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'motorradstellplatz' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'motorradstellplatz' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'busparkplatz' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'busparkplatz') {
					addHash(203);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'breitenbeschraenkung' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'breitenbeschraenkung') {
					addHash(204);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'hoehenbeschraenkung' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'hoehenbeschraenkung') {
					addHash(205);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'umleitung' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'umleitung') {
					addHash(206);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'tonnage' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'tonnage') {
					addHash(207);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'ampel' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'ampel') {
					addHash(208);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'stau' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'stau') {
					addHash(209);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'baustelle' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'baustelle') {
					addHash(210);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'pundr' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'pundr') {
					addHash(211);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'vollsperrung' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'vollsperrung') {
					addHash(212);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'parkflaeche' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'parkflaeche') {
					addHash(213);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'cam' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'cam') {
					addHash(214);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'ort' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'ort') {
					addHash(215);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'einbahnstrasse' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'einbahnstrasse') {
					addHash(216);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'meldung' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'meldung') {
					addHash(217);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'fahrbahnverengung' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'fahrbahnverengung') {
					addHash(218);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'lkwsperrung' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'lkwsperrung') {
					addHash(219);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'gegenverkehr' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'gegenverkehr') {
					addHash(220);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'flag-finish' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'flag-finish') {
					addHash(221);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'flag-start' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'flag-start') {
					addHash(222);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'flag' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'flag') {
					addHash(223);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'breitenbeschraenkung') {
					addHash(224);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'hoehenbeschraenkung') {
					addHash(225);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'umleitung') {
					addHash(226);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'tonnage') {
					addHash(227);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'ampel') {
					addHash(228);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'stau') {
					addHash(229);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'baustelle') {
					addHash(230);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'pundr') {
					addHash(231);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'vollsperrung') {
					addHash(232);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'parkflaeche') {
					addHash(233);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'cam') {
					addHash(234);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'ort') {
					addHash(235);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'einbahnstrasse') {
					addHash(236);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'meldung') {
					addHash(237);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'fahrbahnverengung') {
					addHash(238);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'lkwsperrung') {
					addHash(239);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'gegenverkehr') {
					addHash(240);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'flag-finish') {
					addHash(241);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'flag-start') {
					addHash(242);
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'flag') {
					addHash(243);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect') {
					addHash(244);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'behindertenparken' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'behindertenparken' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'tiefgarage' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'tiefgarage' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'parkhaus' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'parkhaus' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'motorradstellplatz' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'motorradstellplatz' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'busparkplatz' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'busparkplatz') {
					addHash(245);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 8 || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 8) {
					addHash(246);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'u' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'u') {
					addHash(247);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 9 || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 9) {
					addHash(248);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 1 || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 1) {
					addHash(249);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'a' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'a') {
					addHash(250);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'tonnage' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'tonnage') {
					addHash(251);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 't' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 't') {
					addHash(252);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'ampel' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'ampel') {
					addHash(253);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'x' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'x') {
					addHash(254);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 2 || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 2) {
					addHash(255);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'b' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'b') {
					addHash(256);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'stau' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'stau') {
					addHash(257);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'baustelle' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'baustelle') {
					addHash(258);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'w' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'w') {
					addHash(259);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 's' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 's') {
					addHash(260);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'r' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'r') {
					addHash(261);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'q' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'q') {
					addHash(262);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 3 || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 3) {
					addHash(263);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'breitenbeschraenkung' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'breitenbeschraenkung') {
					addHash(264);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'z' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'z') {
					addHash(265);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'pundr' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'pundr') {
					addHash(266);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 4 || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 4) {
					addHash(267);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'parkflaeche' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'parkflaeche') {
					addHash(268);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'c' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'c') {
					addHash(269);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'p' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'p') {
					addHash(270);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'cam' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'cam') {
					addHash(271);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'vollsperrung' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'vollsperrung') {
					addHash(272);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 5 || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 5) {
					addHash(273);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'd' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'd') {
					addHash(274);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'ort' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'ort') {
					addHash(275);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'o' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'o') {
					addHash(276);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'e' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'e') {
					addHash(277);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'n' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'n') {
					addHash(278);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'einbahnstrasse' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'einbahnstrasse') {
					addHash(279);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'v' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'v') {
					addHash(280);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 6 || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 6) {
					addHash(281);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'f' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'f') {
					addHash(282);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'meldung' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'meldung') {
					addHash(283);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'm' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'm') {
					addHash(284);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'fahrbahnverengung' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'fahrbahnverengung') {
					addHash(285);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'y' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'y') {
					addHash(286);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 7 || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 7) {
					addHash(287);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'lkwsperrung' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'lkwsperrung') {
					addHash(288);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'l' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'l') {
					addHash(289);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'k' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'k') {
					addHash(290);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'j' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'j') {
					addHash(291);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'i' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'i') {
					addHash(292);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 0 || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 0) {
					addHash(293);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'hoehenbeschraenkung' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'hoehenbeschraenkung') {
					addHash(294);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'h' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'h') {
					addHash(295);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'umleitung' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'umleitung') {
					addHash(296);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'g' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'g') {
					addHash(297);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'gegenverkehr' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'gegenverkehr') {
					addHash(298);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'flag-start' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'flag-start') {
					addHash(299);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'flag' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'flag') {
					addHash(300);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'flag-finish' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'flag-finish') {
					addHash(301);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 8) {
					addHash(302);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'u') {
					addHash(303);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 9) {
					addHash(304);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 1) {
					addHash(305);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'a') {
					addHash(306);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'tonnage') {
					addHash(307);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 't') {
					addHash(308);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'ampel') {
					addHash(309);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'x') {
					addHash(310);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 2) {
					addHash(311);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'b') {
					addHash(312);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'stau') {
					addHash(313);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'baustelle') {
					addHash(314);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'w') {
					addHash(315);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 's') {
					addHash(316);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'r') {
					addHash(317);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'q') {
					addHash(318);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 3) {
					addHash(319);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'breitenbeschraenkung') {
					addHash(320);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'z') {
					addHash(321);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'pundr') {
					addHash(322);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 4) {
					addHash(323);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'parkflaeche') {
					addHash(324);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'c') {
					addHash(325);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'p') {
					addHash(326);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'cam') {
					addHash(327);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'vollsperrung') {
					addHash(328);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 5) {
					addHash(329);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'd') {
					addHash(330);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'ort') {
					addHash(331);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'o') {
					addHash(332);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'e') {
					addHash(333);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'n') {
					addHash(334);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'einbahnstrasse') {
					addHash(335);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'v') {
					addHash(336);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 6) {
					addHash(337);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'f') {
					addHash(338);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'meldung') {
					addHash(339);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'm') {
					addHash(340);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'fahrbahnverengung') {
					addHash(341);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'y') {
					addHash(342);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 7) {
					addHash(343);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'lkwsperrung') {
					addHash(344);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'l') {
					addHash(345);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'k') {
					addHash(346);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'j') {
					addHash(347);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'i') {
					addHash(348);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 0) {
					addHash(349);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'hoehenbeschraenkung') {
					addHash(350);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'h') {
					addHash(351);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'umleitung') {
					addHash(352);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'g') {
					addHash(353);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'gegenverkehr') {
					addHash(354);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'flag-start') {
					addHash(355);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'flag') {
					addHash(356);
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'flag-finish') {
					addHash(357);
				}
				if (props.state == 'highlight' || props.state == 'select') {
					addHash(358);
				}
				if (props.state == 'select') {
					addHash(359);
				}
				if (props.mapsightIconId == 'stau') {
					addHash(360);
				}
				if (props.mapsightIconId == 'umleitung') {
					addHash(361);
				}
				if (props.isTemporaryRestriction) {
					addHash(362);
				}
				if (props.type == 'trunk' || props.type == 'trunk_link') {
					addHash(363);
				}
				if (props.type == 'primary' || props.type == 'primary_link') {
					addHash(364);
				}
				if (geometryType == 'Polygon' || geometryType == 'MultiLineString') {
					addHash(365);
				}
				if (geometryType == 'MultiPolygon') {
					addHash(366);
				}
				if (props.state == 'highlight' || props.state == 'select') {
					addHash(367);
				}
				if (props.state == 'highlight') {
					addHash(368);
				}
				if (props.state == 'highlight' && geometryType == 'Polygon' || props.state == 'highlight' && geometryType == 'MultiLineString' || props.state == 'highlight' && geometryType == 'MultiPolygon') {
					addHash(369);
				}
				if (props.state == 'select') {
					addHash(370);
				}
				if (props.state == 'select' && geometryType == 'Polygon' || props.state == 'select' && geometryType == 'MultiLineString' || props.state == 'select' && geometryType == 'MultiPolygon') {
					addHash(371);
				}
				if (props.type == 'parking' && env.zoom >= 15) {
					addHash(372);
					addHash('@' + createHash(props.title));
				}
				if (props.type == 'parking' && props.occupancyTrendString && env.zoom >= 13 || props.type == 'parking' && props.occupancyTrendString && props.state == 'highlight' || props.type == 'parking' && props.occupancyTrendString && props.state == 'select') {
					addHash(373);
					addHash('@' + createHash(props.occupancyTrendString));
				}
				if (props.type == 'parking' && props.occupancyTrendString && env.zoom >= 13 && props.state == 'highlight' || props.type == 'parking' && props.occupancyTrendString && env.zoom >= 13 && props.state == 'select' || props.type == 'parking' && props.occupancyTrendString && props.state == 'highlight' || props.type == 'parking' && props.occupancyTrendString && props.state == 'highlight' && props.state == 'select' || props.type == 'parking' && props.occupancyTrendString && props.state == 'select' && props.state == 'highlight' || props.type == 'parking' && props.occupancyTrendString && props.state == 'select') {
					addHash(374);
				}
				if (props.type == 'parking' && props.occupancyTrendString && env.zoom >= 15) {
					addHash(375);
				}
				if (props.type == 'default' && props.mass_restriction && env.zoom >= 12 || props.type == 'default' && props.height_restriction && env.zoom >= 12) {
					addHash(376);
				}
				if (props.type == 'default' && props.mass_restriction && env.zoom >= 13 || props.type == 'default' && props.height_restriction && env.zoom >= 13) {
					addHash(377);
				}
				if (props.type == 'default' && props.mass_restriction && props.state == 'highlight' || props.type == 'default' && props.mass_restriction && props.state == 'select' || props.type == 'default' && props.height_restriction && props.state == 'highlight' || props.type == 'default' && props.height_restriction && props.state == 'select') {
					addHash(378);
				}
				if (props.type == 'default' && props.mass_restriction && env.zoom >= 12) {
					addHash(379);
					addHash('@' + createHash(props.mass_restriction));
				}
				if (props.type == 'default' && props.height_restriction && env.zoom >= 12) {
					addHash(380);
					addHash('@' + createHash(props.height_restriction));
				}
				if (props.type == 'wheel-counter') {
					addHash(381);
				}
				if (props.type == 'schul') {
					addHash(382);
				}
				if (props.state == 'highlight' || props.state == 'select' || props.state == 'preselect') {
					addHash(383);
				}
				if (props.state == 'select') {
					addHash(384);
				}
				if (props.state == 'preselect') {
					addHash(385);
				}
				if (props.type == 'anomaly') {
					addHash(386);
					addHash('@' + createHash(props.anomalyText));
					addHash('@' + createHash(props.textDirectionX));
					addHash('@' + createHash(props.textDirectionY));
				}
				if (props.type == 'anomaly' && env.zoom >= 12) {
					addHash(387);
					addHash('@' + createHash(props.textDirectionX));
					addHash('@' + createHash(props.textDirectionY));
				}
				if (props.type == 'anomaly' && env.zoom >= 16) {
					addHash(388);
					addHash('@' + createHash(props.textDirectionX));
					addHash('@' + createHash(props.textDirectionY));
				}
				if (props.type == 'anomaly' && props.state == 'preselect') {
					addHash(389);
				}
				if (props.type == 'pundr' && props.occupancyTrendString && env.zoom >= 11 || props.type == 'parking' && props.occupancyTrendString && env.zoom >= 11) {
					addHash(390);
					addHash('@' + createHash(props.occupancyTrendString));
				}
				if (props.type == 'pundr' && props.occupancyTrendString && env.zoom >= 12 || props.type == 'parking' && props.occupancyTrendString && env.zoom >= 12) {
					addHash(391);
					addHash('@' + createHash(props.occupancyTrendString));
				}
				if (props.type == 'pundr' && props.occupancyTrendString && env.zoom >= 13 || props.type == 'pundr' && props.occupancyTrendString && props.state == 'highlight' || props.type == 'pundr' && props.occupancyTrendString && props.state == 'select' || props.type == 'parking' && props.occupancyTrendString && env.zoom >= 13 || props.type == 'parking' && props.occupancyTrendString && props.state == 'highlight' || props.type == 'parking' && props.occupancyTrendString && props.state == 'select') {
					addHash(392);
					addHash('@' + createHash(props.occupancyTrendString));
				}
				if (props.type == 'pundr' && props.occupancyTrendString && env.zoom >= 13 && props.state == 'highlight' || props.type == 'pundr' && props.occupancyTrendString && env.zoom >= 13 && props.state == 'select' || props.type == 'pundr' && props.occupancyTrendString && props.state == 'highlight' || props.type == 'pundr' && props.occupancyTrendString && props.state == 'highlight' && props.state == 'select' || props.type == 'pundr' && props.occupancyTrendString && props.state == 'select' && props.state == 'highlight' || props.type == 'pundr' && props.occupancyTrendString && props.state == 'select' || props.type == 'parking' && props.occupancyTrendString && env.zoom >= 13 && props.state == 'highlight' || props.type == 'parking' && props.occupancyTrendString && env.zoom >= 13 && props.state == 'select' || props.type == 'parking' && props.occupancyTrendString && props.state == 'highlight' || props.type == 'parking' && props.occupancyTrendString && props.state == 'highlight' && props.state == 'select' || props.type == 'parking' && props.occupancyTrendString && props.state == 'select' && props.state == 'highlight' || props.type == 'parking' && props.occupancyTrendString && props.state == 'select') {
					addHash(393);
				}
				if (env.colorScheme == 'high-contrast') {
					addHash(394);
				}
				if (env.colorScheme == 'high-contrast' && geometryType == 'Polygon') {
					addHash(395);
				}
				if (env.colorScheme == 'high-contrast' && props.type == 'anomaly') {
					addHash(396);
				}
				if (env.colorScheme == 'high-contrast' && props.state == 'highlight' || env.colorScheme == 'high-contrast' && props.state == 'select') {
					addHash(397);
				}
				if (env.colorScheme == 'high-contrast' && props.state == 'highlight' && env.zoom >= 14 || env.colorScheme == 'high-contrast' && props.state == 'select' && env.zoom >= 14) {
					addHash(398);
				}
				if (env.colorScheme == 'high-contrast' && props.state == 'highlight' && env.zoom >= 20 || env.colorScheme == 'high-contrast' && props.state == 'select' && env.zoom >= 20) {
					addHash(399);
				}
				if (env.colorScheme == 'high-contrast' && props.state == 'preselect') {
					addHash(400);
				}
				if (env.colorScheme == 'foobar') {
					addHash(401);
				}
				if (env.colorScheme == 'foobar' && geometryType == 'LineString') {
					addHash(402);
				}
				if (env.colorScheme == 'foobar' && geometryType == 'Polygon') {
					addHash(403);
				}
				if (env.colorScheme == 'foobar' && props.type == 'anomaly') {
					addHash(404);
				}
				break;
			case 'graphmastersRouteStopsDraw':
				addHash(405);
				break;
			case 'navigationRoute':
				addHash(406);
				if (env.zoom >= 15) {
					addHash(407);
				}
				if (env.zoom >= 15) {
					addHash(408);
				}
				if (props.state == 'highlight' || props.state == 'select') {
					addHash(409);
				}
				break;
			case 'graphmastersTraffic':
				addHash(410);
				if (geometryType == 'LineString' && props.speedRatio) {
					addHash(411);
				}
				if (geometryType == 'LineString' && props.speedRatio && env.zoom >= 13) {
					addHash(412);
				}
				if (geometryType == 'LineString' && props.speedRatio && env.zoom >= 15) {
					addHash(413);
				}
				if (geometryType == 'LineString' && props.speedRatio && env.zoom >= 20) {
					addHash(414);
				}
				if (geometryType == 'LineString' && props.speedRatio && props.speedRatio < 0.25) {
					addHash(415);
				}
				if (geometryType == 'LineString' && props.speedRatio && props.speedRatio < 0.25 && env.zoom >= 20) {
					addHash(416);
				}
				if (geometryType == 'LineString' && props.speedRatio && props.speedRatio >= 0.25) {
					addHash(417);
				}
				if (geometryType == 'LineString' && props.speedRatio && props.speedRatio >= 0.67) {
					addHash(418);
				}
				if (env.colorScheme == 'high-contrast' && geometryType == 'LineString' && props.speedRatio && props.speedRatio <= 0.25) {
					addHash(419);
				}
				if (env.colorScheme == 'high-contrast' && geometryType == 'LineString' && props.speedRatio && props.speedRatio <= 0.25 && env.zoom >= 14) {
					addHash(420);
				}
				if (env.colorScheme == 'high-contrast' && geometryType == 'LineString' && props.speedRatio && props.speedRatio <= 0.25 && env.zoom >= 20) {
					addHash(421);
				}
				if (env.colorScheme == 'high-contrast' && geometryType == 'LineString' && props.speedRatio && props.speedRatio >= 0.25) {
					addHash(422);
				}
				if (env.colorScheme == 'high-contrast' && geometryType == 'LineString' && props.speedRatio && props.speedRatio >= 0.67) {
					addHash(423);
				}
				break;
			case 'graphmastersRoute':
				addHash(424);
				if (env.colorScheme == 'high-contrast') {
					addHash(425);
				}
				if (env.colorScheme == 'high-contrast') {
					addHash(426);
				}
				if (env.colorScheme == 'high-contrast' && env.zoom >= 15) {
					addHash(427);
				}
				if (env.colorScheme == 'high-contrast' && env.zoom >= 15) {
					addHash(428);
				}
				if (env.colorScheme == 'high-contrast' && props.state == 'highlight' || env.colorScheme == 'high-contrast' && props.state == 'select') {
					addHash(429);
				}
				break;
		}

		return hash;
	},
	declarationFunction: (env, props, geometryType, style) => {
		const declaration = {};
		const declare = a => merge(declaration, a);

		declare({ default: { zIndex: { value: 100 },
			fill: { color: { value: 'rgba(255, 0, 0, 0.46)' } },
			stroke: { color: { value: '#505050' } },
			text: { font: { value: 'bold' },
				fill: { color: { value: '#0c6490' } },
				stroke: { color: { value: '#fff' },
					width: { value: 3 } } } } });
		switch (style) {
			case 'traffic':
				declare({ default: { image: { type: { value: 'none' } },
					stroke: { width: { value: 4 },
						color: { value: 'rgba(255, 156, 4, 0.7)' } } } });
				if (env.zoom < 15) {
					declare({ default: { stroke: { width: { value: 3 } } } });
				}
				if (env.zoom < 12) {
					declare({ default: { stroke: { width: { value: 2 } } } });
				}
				if (env.zoom < 9) {
					declare({ default: { stroke: { color: { value: 'rgba(255, 156, 4, 0.5)' } } } });
				}
				if (props.state == 'highlight' || props.state == 'select') {
					declare({ default: { stroke: { color: { value: '#ff9c04' } } } });
				}
				if (props.trafficSituation == null || props.trafficSituation == 'Unbekannt' || props.trafficSituation == 'UNKNOWN_ERROR' || props.los == null || props.los == 0) {
					declare({ default: { stroke: { color: { value: 'rgba(0, 0, 0, 0.7)' } } } });
				}
				if (props.trafficSituation == null && env.zoom < 15 || props.trafficSituation == 'Unbekannt' && env.zoom < 15 || props.trafficSituation == 'UNKNOWN_ERROR' && env.zoom < 15 || props.los == null && env.zoom < 15 || props.los == 0 && env.zoom < 15) {
					declare({ default: { stroke: { color: { value: 'rgba(0, 0, 0, 0.5)' } } } });
				}
				if (props.trafficSituation == null && env.zoom < 9 || props.trafficSituation == 'Unbekannt' && env.zoom < 9 || props.trafficSituation == 'UNKNOWN_ERROR' && env.zoom < 9 || props.los == null && env.zoom < 9 || props.los == 0 && env.zoom < 9) {
					declare({ default: { stroke: { color: { value: 'rgba(0, 0, 0, 0.3)' } } } });
				}
				if (props.trafficSituation == null && props.state == 'highlight' || props.trafficSituation == null && props.state == 'select' || props.trafficSituation == 'Unbekannt' && props.state == 'highlight' || props.trafficSituation == 'Unbekannt' && props.state == 'select' || props.trafficSituation == 'UNKNOWN_ERROR' && props.state == 'highlight' || props.trafficSituation == 'UNKNOWN_ERROR' && props.state == 'select' || props.los == null && props.state == 'highlight' || props.los == null && props.state == 'select' || props.los == 0 && props.state == 'highlight' || props.los == 0 && props.state == 'select') {
					declare({ default: { stroke: { color: { value: 'black' } } } });
				}
				if (props.trafficSituation == 'Frei' || props.los == 1) {
					declare({ default: { stroke: { color: { value: 'rgba(0, 179, 26, 0.7)' } } } });
				}
				if (props.trafficSituation == 'Frei' && env.zoom < 9 || props.los == 1 && env.zoom < 9) {
					declare({ default: { stroke: { color: { value: 'rgba(0, 179, 26, 0.2)' } } } });
				}
				if (props.trafficSituation == 'Frei' && props.state == 'highlight' || props.trafficSituation == 'Frei' && props.state == 'select' || props.los == 1 && props.state == 'highlight' || props.los == 1 && props.state == 'select') {
					declare({ default: { stroke: { color: { value: '#00b31a' } } } });
				}
				if (props.trafficSituation == 'Zaehfliessend' || props.los == 2) {
					declare({ default: { stroke: { color: { value: 'rgba(255, 156, 0, 0.8)' } } } });
				}
				if (props.trafficSituation == 'Zaehfliessend' && env.zoom < 17 || props.los == 2 && env.zoom < 17) {
					declare({ default: { stroke: { color: { value: 'rgba(255, 156, 0, 0.75)' } } } });
				}
				if (props.trafficSituation == 'Zaehfliessend' && env.zoom < 9 || props.los == 2 && env.zoom < 9) {
					declare({ default: { stroke: { color: { value: 'rgba(255, 156, 0, 0.5)' } } } });
				}
				if (props.trafficSituation == 'Zaehfliessend' && props.state == 'highlight' || props.trafficSituation == 'Zaehfliessend' && props.state == 'select' || props.los == 2 && props.state == 'highlight' || props.los == 2 && props.state == 'select') {
					declare({ default: { stroke: { color: { value: '#ff9c00' } } } });
				}
				if (props.trafficSituation == 'Stau' || props.los == 3 || props.los == 4) {
					declare({ default: { image: { type: { value: 'icon' } },
						icon: { scale: { value: 0.5 },
							src: { value: '/assets/img/mapsight-traffic-style-icon-sprite-2x.png?v=2020-08-18-00-00' },
							snapToPixel: { value: 'true' },
							offsetY: { value: 9864 },
							sizeX: { value: 88 },
							sizeY: { value: 88 } },
						stroke: { color: { value: 'rgba(255, 0, 0, 0.8)' } },
						zIndex: { value: 107 } } });
				}
				if (props.trafficSituation == 'Stau' && env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' || props.los == 3 && env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' || props.los == 4 && env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select') {
					declare({ default: { icon: { offsetY: { value: 260 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (props.trafficSituation == 'Stau' && env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' || props.los == 3 && env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' || props.los == 4 && env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select') {
					declare({ default: { icon: { offsetY: { value: 1588 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (props.trafficSituation == 'Stau' && env.zoom < 9 || props.los == 3 && env.zoom < 9 || props.los == 4 && env.zoom < 9) {
					declare({ default: { stroke: { color: { value: 'rgba(255, 0, 0, 0.6)' } } } });
				}
				if (props.trafficSituation == 'Stau' && props.state == 'highlight' || props.trafficSituation == 'Stau' && props.state == 'select' || props.los == 3 && props.state == 'highlight' || props.los == 3 && props.state == 'select' || props.los == 4 && props.state == 'highlight' || props.los == 4 && props.state == 'select') {
					declare({ default: { stroke: { color: { value: '#ff0066' } } } });
				}
				break;
			case 'userGeolocation':
				declare({ accuracy: { image: { type: { value: 'circle' } },
					circle: { radius: { value: '' + eval('' + props.accuracy + ' / ' + env.resolution + '') + '' },
						fill: { color: { value: 'rgba(51, 153, 204, 0.61)' } } } },
				point: { image: { type: { value: 'circle' } },
					circle: { radius: { value: 6 },
						fill: { color: { value: '#3399cc' } } } } });
				break;
			case 'features':
				declare({ default: { stroke: { width: { value: 4 } },
					image: { type: { value: 'icon' } },
					icon: { scale: { value: 0.5 },
						src: { value: '/assets/img/mapsight-traffic-style-icon-sprite-2x.png?v=2020-08-18-00-00' },
						snapToPixel: { value: 'true' },
						offsetY: { value: 7664 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } },
					zIndex: { value: 102 } } });
				if (props.mapsightIconId == 'behindertenparken' || env.mapsightIconId == 'behindertenparken' || props.mapsightIconId == 'tiefgarage' || env.mapsightIconId == 'tiefgarage' || props.mapsightIconId == 'parkhaus' || env.mapsightIconId == 'parkhaus' || props.mapsightIconId == 'motorradstellplatz' || env.mapsightIconId == 'motorradstellplatz' || props.mapsightIconId == 'busparkplatz' || env.mapsightIconId == 'busparkplatz') {
					declare({ default: { icon: { offsetY: { value: 8192 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'dot' || env.mapsightIconId == 'dot') {
					declare({ default: { icon: { offsetY: { value: 2804 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (props.mapsightIconId == 'parkhaus' || env.mapsightIconId == 'parkhaus') {
					declare({ default: { icon: { offsetY: { value: 4760 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'friedhof' || env.mapsightIconId == 'friedhof') {
					declare({ default: { icon: { offsetY: { value: 4848 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'haltestelle' || env.mapsightIconId == 'haltestelle') {
					declare({ default: { icon: { offsetY: { value: 4936 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'hoehenbeschraenkung' || env.mapsightIconId == 'hoehenbeschraenkung') {
					declare({ default: { icon: { offsetY: { value: 5024 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'forschung' || env.mapsightIconId == 'forschung') {
					declare({ default: { icon: { offsetY: { value: 5112 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'flughafen' || env.mapsightIconId == 'flughafen') {
					declare({ default: { icon: { offsetY: { value: 5200 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'hotel' || env.mapsightIconId == 'hotel') {
					declare({ default: { icon: { offsetY: { value: 5288 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'i' || env.mapsightIconId == 'i') {
					declare({ default: { icon: { offsetY: { value: 5376 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'gegenverkehr' || env.mapsightIconId == 'gegenverkehr') {
					declare({ default: { icon: { offsetY: { value: 5464 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'info' || env.mapsightIconId == 'info') {
					declare({ default: { icon: { offsetY: { value: 5552 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'j' || env.mapsightIconId == 'j') {
					declare({ default: { icon: { offsetY: { value: 5640 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'flag-start' || env.mapsightIconId == 'flag-start') {
					declare({ default: { icon: { offsetY: { value: 5728 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'k' || env.mapsightIconId == 'k') {
					declare({ default: { icon: { offsetY: { value: 5816 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'gewerbe' || env.mapsightIconId == 'gewerbe') {
					declare({ default: { icon: { offsetY: { value: 5904 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'kita' || env.mapsightIconId == 'kita') {
					declare({ default: { icon: { offsetY: { value: 5992 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'krankenhaus_klinik' || env.mapsightIconId == 'krankenhaus_klinik') {
					declare({ default: { icon: { offsetY: { value: 6080 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'l' || env.mapsightIconId == 'l') {
					declare({ default: { icon: { offsetY: { value: 6168 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'h' || env.mapsightIconId == 'h') {
					declare({ default: { icon: { offsetY: { value: 6256 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'lkwsperrung' || env.mapsightIconId == 'lkwsperrung') {
					declare({ default: { icon: { offsetY: { value: 6344 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'flag-finish' || env.mapsightIconId == 'flag-finish') {
					declare({ default: { icon: { offsetY: { value: 6432 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'flag' || env.mapsightIconId == 'flag') {
					declare({ default: { icon: { offsetY: { value: 6520 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'm' || env.mapsightIconId == 'm') {
					declare({ default: { icon: { offsetY: { value: 6608 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'fahrbahnverengung' || env.mapsightIconId == 'fahrbahnverengung') {
					declare({ default: { icon: { offsetY: { value: 6696 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'marker' || env.mapsightIconId == 'marker') {
					declare({ default: { icon: { offsetY: { value: 6784 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'meldung' || env.mapsightIconId == 'meldung') {
					declare({ default: { icon: { offsetY: { value: 6872 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'faehre' || env.mapsightIconId == 'faehre') {
					declare({ default: { icon: { offsetY: { value: 6960 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'f' || env.mapsightIconId == 'f') {
					declare({ default: { icon: { offsetY: { value: 7048 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'motorradstellplatz' || env.mapsightIconId == 'motorradstellplatz') {
					declare({ default: { icon: { offsetY: { value: 7136 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'museum' || env.mapsightIconId == 'museum') {
					declare({ default: { icon: { offsetY: { value: 7224 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'n' || env.mapsightIconId == 'n') {
					declare({ default: { icon: { offsetY: { value: 7312 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'einbahnstrasse' || env.mapsightIconId == 'einbahnstrasse') {
					declare({ default: { icon: { offsetY: { value: 7400 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'o' || env.mapsightIconId == 'o') {
					declare({ default: { icon: { offsetY: { value: 7488 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'e' || env.mapsightIconId == 'e') {
					declare({ default: { icon: { offsetY: { value: 7576 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'ort' || env.mapsightIconId == 'ort') {
					declare({ default: { icon: { offsetY: { value: 7664 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'db' || env.mapsightIconId == 'db') {
					declare({ default: { icon: { offsetY: { value: 7752 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'd' || env.mapsightIconId == 'd') {
					declare({ default: { icon: { offsetY: { value: 7840 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'p' || env.mapsightIconId == 'p') {
					declare({ default: { icon: { offsetY: { value: 7928 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'cam' || env.mapsightIconId == 'cam') {
					declare({ default: { icon: { offsetY: { value: 8016 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'parkanlage' || env.mapsightIconId == 'parkanlage') {
					declare({ default: { icon: { offsetY: { value: 8104 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'parkflaeche' || env.mapsightIconId == 'parkflaeche') {
					declare({ default: { icon: { offsetY: { value: 8192 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'c' || env.mapsightIconId == 'c') {
					declare({ default: { icon: { offsetY: { value: 8280 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'busparkplatz' || env.mapsightIconId == 'busparkplatz') {
					declare({ default: { icon: { offsetY: { value: 8368 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'g' || env.mapsightIconId == 'g') {
					declare({ default: { icon: { offsetY: { value: 8456 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'pundr' || env.mapsightIconId == 'pundr') {
					declare({ default: { icon: { offsetY: { value: 8544 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'bundr' || env.mapsightIconId == 'bundr') {
					declare({ default: { icon: { offsetY: { value: 8632 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'breitenbeschraenkung' || env.mapsightIconId == 'breitenbeschraenkung') {
					declare({ default: { icon: { offsetY: { value: 8720 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'q' || env.mapsightIconId == 'q') {
					declare({ default: { icon: { offsetY: { value: 8808 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'bilbiothek_archiv' || env.mapsightIconId == 'bilbiothek_archiv') {
					declare({ default: { icon: { offsetY: { value: 8896 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'r' || env.mapsightIconId == 'r') {
					declare({ default: { icon: { offsetY: { value: 8984 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'bike' || env.mapsightIconId == 'bike') {
					declare({ default: { icon: { offsetY: { value: 9072 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'religion' || env.mapsightIconId == 'religion') {
					declare({ default: { icon: { offsetY: { value: 9160 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 's' || env.mapsightIconId == 's') {
					declare({ default: { icon: { offsetY: { value: 9248 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'behindertenparken' || env.mapsightIconId == 'behindertenparken') {
					declare({ default: { icon: { offsetY: { value: 9336 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'schule' || env.mapsightIconId == 'schule') {
					declare({ default: { icon: { offsetY: { value: 9424 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'sehenswuerdigkeit' || env.mapsightIconId == 'sehenswuerdigkeit') {
					declare({ default: { icon: { offsetY: { value: 9512 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'sport' || env.mapsightIconId == 'sport') {
					declare({ default: { icon: { offsetY: { value: 9600 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'sportanlage' || env.mapsightIconId == 'sportanlage') {
					declare({ default: { icon: { offsetY: { value: 9688 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'stadtteil' || env.mapsightIconId == 'stadtteil') {
					declare({ default: { icon: { offsetY: { value: 9776 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'stau' || env.mapsightIconId == 'stau') {
					declare({ default: { icon: { offsetY: { value: 9864 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'baustelle' || env.mapsightIconId == 'baustelle') {
					declare({ default: { icon: { offsetY: { value: 9952 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'b' || env.mapsightIconId == 'b') {
					declare({ default: { icon: { offsetY: { value: 10040 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 't' || env.mapsightIconId == 't') {
					declare({ default: { icon: { offsetY: { value: 10128 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'ampel' || env.mapsightIconId == 'ampel') {
					declare({ default: { icon: { offsetY: { value: 10216 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'taxi' || env.mapsightIconId == 'taxi') {
					declare({ default: { icon: { offsetY: { value: 10304 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'theater' || env.mapsightIconId == 'theater') {
					declare({ default: { icon: { offsetY: { value: 10392 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'tiefgarage' || env.mapsightIconId == 'tiefgarage') {
					declare({ default: { icon: { offsetY: { value: 10480 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'tonnage' || env.mapsightIconId == 'tonnage') {
					declare({ default: { icon: { offsetY: { value: 10568 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'abfall_recycling' || env.mapsightIconId == 'abfall_recycling') {
					declare({ default: { icon: { offsetY: { value: 10656 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'a' || env.mapsightIconId == 'a') {
					declare({ default: { icon: { offsetY: { value: 10744 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'u' || env.mapsightIconId == 'u') {
					declare({ default: { icon: { offsetY: { value: 10832 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 9 || env.mapsightIconId == 9) {
					declare({ default: { icon: { offsetY: { value: 10920 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'umleitung' || env.mapsightIconId == 'umleitung') {
					declare({ default: { icon: { offsetY: { value: 11008 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 8 || env.mapsightIconId == 8) {
					declare({ default: { icon: { offsetY: { value: 11096 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 7 || env.mapsightIconId == 7) {
					declare({ default: { icon: { offsetY: { value: 11184 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'v' || env.mapsightIconId == 'v') {
					declare({ default: { icon: { offsetY: { value: 11272 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 6 || env.mapsightIconId == 6) {
					declare({ default: { icon: { offsetY: { value: 11360 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'vollsperrung' || env.mapsightIconId == 'vollsperrung') {
					declare({ default: { icon: { offsetY: { value: 11448 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 5 || env.mapsightIconId == 5) {
					declare({ default: { icon: { offsetY: { value: 11536 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 4 || env.mapsightIconId == 4) {
					declare({ default: { icon: { offsetY: { value: 11624 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'w' || env.mapsightIconId == 'w') {
					declare({ default: { icon: { offsetY: { value: 11712 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 3 || env.mapsightIconId == 3) {
					declare({ default: { icon: { offsetY: { value: 11800 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'x' || env.mapsightIconId == 'x') {
					declare({ default: { icon: { offsetY: { value: 11888 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 2 || env.mapsightIconId == 2) {
					declare({ default: { icon: { offsetY: { value: 11976 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'y' || env.mapsightIconId == 'y') {
					declare({ default: { icon: { offsetY: { value: 12064 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 1 || env.mapsightIconId == 1) {
					declare({ default: { icon: { offsetY: { value: 12152 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 'z' || env.mapsightIconId == 'z') {
					declare({ default: { icon: { offsetY: { value: 12240 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (props.mapsightIconId == 0 || env.mapsightIconId == 0) {
					declare({ default: { icon: { offsetY: { value: 12328 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'dot') {
					declare({ default: { icon: { offsetY: { value: 2804 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'parkhaus') {
					declare({ default: { icon: { offsetY: { value: 4760 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'friedhof') {
					declare({ default: { icon: { offsetY: { value: 4848 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'haltestelle') {
					declare({ default: { icon: { offsetY: { value: 4936 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'hoehenbeschraenkung') {
					declare({ default: { icon: { offsetY: { value: 5024 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'forschung') {
					declare({ default: { icon: { offsetY: { value: 5112 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'flughafen') {
					declare({ default: { icon: { offsetY: { value: 5200 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'hotel') {
					declare({ default: { icon: { offsetY: { value: 5288 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'i') {
					declare({ default: { icon: { offsetY: { value: 5376 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'gegenverkehr') {
					declare({ default: { icon: { offsetY: { value: 5464 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'info') {
					declare({ default: { icon: { offsetY: { value: 5552 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'j') {
					declare({ default: { icon: { offsetY: { value: 5640 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'flag-start') {
					declare({ default: { icon: { offsetY: { value: 5728 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'k') {
					declare({ default: { icon: { offsetY: { value: 5816 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'gewerbe') {
					declare({ default: { icon: { offsetY: { value: 5904 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'kita') {
					declare({ default: { icon: { offsetY: { value: 5992 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'krankenhaus_klinik') {
					declare({ default: { icon: { offsetY: { value: 6080 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'l') {
					declare({ default: { icon: { offsetY: { value: 6168 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'h') {
					declare({ default: { icon: { offsetY: { value: 6256 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'lkwsperrung') {
					declare({ default: { icon: { offsetY: { value: 6344 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'flag-finish') {
					declare({ default: { icon: { offsetY: { value: 6432 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'flag') {
					declare({ default: { icon: { offsetY: { value: 6520 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'm') {
					declare({ default: { icon: { offsetY: { value: 6608 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'fahrbahnverengung') {
					declare({ default: { icon: { offsetY: { value: 6696 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'marker') {
					declare({ default: { icon: { offsetY: { value: 6784 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'meldung') {
					declare({ default: { icon: { offsetY: { value: 6872 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'faehre') {
					declare({ default: { icon: { offsetY: { value: 6960 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'f') {
					declare({ default: { icon: { offsetY: { value: 7048 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'motorradstellplatz') {
					declare({ default: { icon: { offsetY: { value: 7136 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'museum') {
					declare({ default: { icon: { offsetY: { value: 7224 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'n') {
					declare({ default: { icon: { offsetY: { value: 7312 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'einbahnstrasse') {
					declare({ default: { icon: { offsetY: { value: 7400 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'o') {
					declare({ default: { icon: { offsetY: { value: 7488 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'e') {
					declare({ default: { icon: { offsetY: { value: 7576 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'ort') {
					declare({ default: { icon: { offsetY: { value: 7664 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'db') {
					declare({ default: { icon: { offsetY: { value: 7752 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'd') {
					declare({ default: { icon: { offsetY: { value: 7840 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'p') {
					declare({ default: { icon: { offsetY: { value: 7928 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'cam') {
					declare({ default: { icon: { offsetY: { value: 8016 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'parkanlage') {
					declare({ default: { icon: { offsetY: { value: 8104 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'parkflaeche') {
					declare({ default: { icon: { offsetY: { value: 8192 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'c') {
					declare({ default: { icon: { offsetY: { value: 8280 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'busparkplatz') {
					declare({ default: { icon: { offsetY: { value: 8368 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'g') {
					declare({ default: { icon: { offsetY: { value: 8456 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'pundr') {
					declare({ default: { icon: { offsetY: { value: 8544 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'bundr') {
					declare({ default: { icon: { offsetY: { value: 8632 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'breitenbeschraenkung') {
					declare({ default: { icon: { offsetY: { value: 8720 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'q') {
					declare({ default: { icon: { offsetY: { value: 8808 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'bilbiothek_archiv') {
					declare({ default: { icon: { offsetY: { value: 8896 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'r') {
					declare({ default: { icon: { offsetY: { value: 8984 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'bike') {
					declare({ default: { icon: { offsetY: { value: 9072 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'religion') {
					declare({ default: { icon: { offsetY: { value: 9160 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 's') {
					declare({ default: { icon: { offsetY: { value: 9248 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'behindertenparken') {
					declare({ default: { icon: { offsetY: { value: 9336 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'schule') {
					declare({ default: { icon: { offsetY: { value: 9424 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'sehenswuerdigkeit') {
					declare({ default: { icon: { offsetY: { value: 9512 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'sport') {
					declare({ default: { icon: { offsetY: { value: 9600 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'sportanlage') {
					declare({ default: { icon: { offsetY: { value: 9688 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'stadtteil') {
					declare({ default: { icon: { offsetY: { value: 9776 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'stau') {
					declare({ default: { icon: { offsetY: { value: 9864 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'baustelle') {
					declare({ default: { icon: { offsetY: { value: 9952 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'b') {
					declare({ default: { icon: { offsetY: { value: 10040 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 't') {
					declare({ default: { icon: { offsetY: { value: 10128 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'ampel') {
					declare({ default: { icon: { offsetY: { value: 10216 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'taxi') {
					declare({ default: { icon: { offsetY: { value: 10304 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'theater') {
					declare({ default: { icon: { offsetY: { value: 10392 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'tiefgarage') {
					declare({ default: { icon: { offsetY: { value: 10480 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'tonnage') {
					declare({ default: { icon: { offsetY: { value: 10568 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'abfall_recycling') {
					declare({ default: { icon: { offsetY: { value: 10656 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'a') {
					declare({ default: { icon: { offsetY: { value: 10744 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'u') {
					declare({ default: { icon: { offsetY: { value: 10832 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 9) {
					declare({ default: { icon: { offsetY: { value: 10920 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'umleitung') {
					declare({ default: { icon: { offsetY: { value: 11008 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 8) {
					declare({ default: { icon: { offsetY: { value: 11096 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 7) {
					declare({ default: { icon: { offsetY: { value: 11184 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'v') {
					declare({ default: { icon: { offsetY: { value: 11272 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 6) {
					declare({ default: { icon: { offsetY: { value: 11360 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'vollsperrung') {
					declare({ default: { icon: { offsetY: { value: 11448 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 5) {
					declare({ default: { icon: { offsetY: { value: 11536 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 4) {
					declare({ default: { icon: { offsetY: { value: 11624 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'w') {
					declare({ default: { icon: { offsetY: { value: 11712 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 3) {
					declare({ default: { icon: { offsetY: { value: 11800 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'x') {
					declare({ default: { icon: { offsetY: { value: 11888 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 2) {
					declare({ default: { icon: { offsetY: { value: 11976 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'y') {
					declare({ default: { icon: { offsetY: { value: 12064 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 1) {
					declare({ default: { icon: { offsetY: { value: 12152 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 'z') {
					declare({ default: { icon: { offsetY: { value: 12240 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.mapsightIconUse == 'force' && env.mapsightIconId == 0) {
					declare({ default: { icon: { offsetY: { value: 12328 },
						sizeX: { value: 88 },
						sizeY: { value: 88 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect') {
					declare({ default: { icon: { offsetY: { value: 572 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'behindertenparken' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'behindertenparken' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'tiefgarage' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'tiefgarage' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'parkhaus' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'parkhaus' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'motorradstellplatz' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'motorradstellplatz' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'busparkplatz' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'busparkplatz') {
					declare({ default: { icon: { offsetY: { value: 468 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'breitenbeschraenkung' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'breitenbeschraenkung') {
					declare({ default: { icon: { sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'hoehenbeschraenkung' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'hoehenbeschraenkung') {
					declare({ default: { icon: { offsetY: { value: 52 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'umleitung' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'umleitung') {
					declare({ default: { icon: { offsetY: { value: 104 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'tonnage' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'tonnage') {
					declare({ default: { icon: { offsetY: { value: 156 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'ampel' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'ampel') {
					declare({ default: { icon: { offsetY: { value: 208 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'stau' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'stau') {
					declare({ default: { icon: { offsetY: { value: 260 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'baustelle' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'baustelle') {
					declare({ default: { icon: { offsetY: { value: 312 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'pundr' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'pundr') {
					declare({ default: { icon: { offsetY: { value: 364 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'vollsperrung' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'vollsperrung') {
					declare({ default: { icon: { offsetY: { value: 416 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'parkflaeche' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'parkflaeche') {
					declare({ default: { icon: { offsetY: { value: 468 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'cam' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'cam') {
					declare({ default: { icon: { offsetY: { value: 520 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'ort' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'ort') {
					declare({ default: { icon: { offsetY: { value: 572 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'einbahnstrasse' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'einbahnstrasse') {
					declare({ default: { icon: { offsetY: { value: 624 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'meldung' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'meldung') {
					declare({ default: { icon: { offsetY: { value: 676 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'fahrbahnverengung' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'fahrbahnverengung') {
					declare({ default: { icon: { offsetY: { value: 728 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'lkwsperrung' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'lkwsperrung') {
					declare({ default: { icon: { offsetY: { value: 780 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'gegenverkehr' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'gegenverkehr') {
					declare({ default: { icon: { offsetY: { value: 832 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'flag-finish' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'flag-finish') {
					declare({ default: { icon: { offsetY: { value: 3764 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'flag-start' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'flag-start') {
					declare({ default: { icon: { offsetY: { value: 4020 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'flag' || env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'flag') {
					declare({ default: { icon: { offsetY: { value: 4084 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'breitenbeschraenkung') {
					declare({ default: { icon: { sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'hoehenbeschraenkung') {
					declare({ default: { icon: { offsetY: { value: 52 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'umleitung') {
					declare({ default: { icon: { offsetY: { value: 104 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'tonnage') {
					declare({ default: { icon: { offsetY: { value: 156 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'ampel') {
					declare({ default: { icon: { offsetY: { value: 208 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'stau') {
					declare({ default: { icon: { offsetY: { value: 260 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'baustelle') {
					declare({ default: { icon: { offsetY: { value: 312 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'pundr') {
					declare({ default: { icon: { offsetY: { value: 364 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'vollsperrung') {
					declare({ default: { icon: { offsetY: { value: 416 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'parkflaeche') {
					declare({ default: { icon: { offsetY: { value: 468 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'cam') {
					declare({ default: { icon: { offsetY: { value: 520 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'ort') {
					declare({ default: { icon: { offsetY: { value: 572 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'einbahnstrasse') {
					declare({ default: { icon: { offsetY: { value: 624 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'meldung') {
					declare({ default: { icon: { offsetY: { value: 676 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'fahrbahnverengung') {
					declare({ default: { icon: { offsetY: { value: 728 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'lkwsperrung') {
					declare({ default: { icon: { offsetY: { value: 780 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'gegenverkehr') {
					declare({ default: { icon: { offsetY: { value: 832 },
						sizeX: { value: 52 },
						sizeY: { value: 52 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'flag-finish') {
					declare({ default: { icon: { offsetY: { value: 3764 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'flag-start') {
					declare({ default: { icon: { offsetY: { value: 4020 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 1 && env.zoom <= 10 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'flag') {
					declare({ default: { icon: { offsetY: { value: 4084 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect') {
					declare({ default: { icon: { offsetY: { value: 2740 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'behindertenparken' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'behindertenparken' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'tiefgarage' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'tiefgarage' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'parkhaus' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'parkhaus' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'motorradstellplatz' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'motorradstellplatz' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'busparkplatz' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'busparkplatz') {
					declare({ default: { icon: { offsetY: { value: 2292 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 8 || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 8) {
					declare({ default: { icon: { offsetY: { value: 884 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'u' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'u') {
					declare({ default: { icon: { offsetY: { value: 948 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 9 || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 9) {
					declare({ default: { icon: { offsetY: { value: 1012 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 1 || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 1) {
					declare({ default: { icon: { offsetY: { value: 1076 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'a' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'a') {
					declare({ default: { icon: { offsetY: { value: 1140 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'tonnage' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'tonnage') {
					declare({ default: { icon: { offsetY: { value: 1204 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 't' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 't') {
					declare({ default: { icon: { offsetY: { value: 1268 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'ampel' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'ampel') {
					declare({ default: { icon: { offsetY: { value: 1332 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'x' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'x') {
					declare({ default: { icon: { offsetY: { value: 1396 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 2 || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 2) {
					declare({ default: { icon: { offsetY: { value: 1460 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'b' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'b') {
					declare({ default: { icon: { offsetY: { value: 1524 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'stau' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'stau') {
					declare({ default: { icon: { offsetY: { value: 1588 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'baustelle' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'baustelle') {
					declare({ default: { icon: { offsetY: { value: 1652 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'w' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'w') {
					declare({ default: { icon: { offsetY: { value: 1716 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 's' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 's') {
					declare({ default: { icon: { offsetY: { value: 1780 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'r' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'r') {
					declare({ default: { icon: { offsetY: { value: 1844 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'q' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'q') {
					declare({ default: { icon: { offsetY: { value: 1908 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 3 || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 3) {
					declare({ default: { icon: { offsetY: { value: 1972 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'breitenbeschraenkung' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'breitenbeschraenkung') {
					declare({ default: { icon: { offsetY: { value: 2036 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'z' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'z') {
					declare({ default: { icon: { offsetY: { value: 2100 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'pundr' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'pundr') {
					declare({ default: { icon: { offsetY: { value: 2164 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 4 || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 4) {
					declare({ default: { icon: { offsetY: { value: 2228 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'parkflaeche' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'parkflaeche') {
					declare({ default: { icon: { offsetY: { value: 2292 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'c' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'c') {
					declare({ default: { icon: { offsetY: { value: 2356 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'p' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'p') {
					declare({ default: { icon: { offsetY: { value: 2420 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'cam' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'cam') {
					declare({ default: { icon: { offsetY: { value: 2484 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'vollsperrung' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'vollsperrung') {
					declare({ default: { icon: { offsetY: { value: 2548 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 5 || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 5) {
					declare({ default: { icon: { offsetY: { value: 2612 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'd' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'd') {
					declare({ default: { icon: { offsetY: { value: 2676 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'ort' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'ort') {
					declare({ default: { icon: { offsetY: { value: 2740 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'o' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'o') {
					declare({ default: { icon: { offsetY: { value: 2868 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'e' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'e') {
					declare({ default: { icon: { offsetY: { value: 2932 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'n' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'n') {
					declare({ default: { icon: { offsetY: { value: 2996 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'einbahnstrasse' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'einbahnstrasse') {
					declare({ default: { icon: { offsetY: { value: 3060 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'v' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'v') {
					declare({ default: { icon: { offsetY: { value: 3124 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 6 || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 6) {
					declare({ default: { icon: { offsetY: { value: 3188 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'f' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'f') {
					declare({ default: { icon: { offsetY: { value: 3252 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'meldung' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'meldung') {
					declare({ default: { icon: { offsetY: { value: 3316 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'm' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'm') {
					declare({ default: { icon: { offsetY: { value: 3380 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'fahrbahnverengung' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'fahrbahnverengung') {
					declare({ default: { icon: { offsetY: { value: 3444 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'y' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'y') {
					declare({ default: { icon: { offsetY: { value: 3508 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 7 || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 7) {
					declare({ default: { icon: { offsetY: { value: 3572 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'lkwsperrung' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'lkwsperrung') {
					declare({ default: { icon: { offsetY: { value: 3636 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'l' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'l') {
					declare({ default: { icon: { offsetY: { value: 3700 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'k' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'k') {
					declare({ default: { icon: { offsetY: { value: 3828 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'j' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'j') {
					declare({ default: { icon: { offsetY: { value: 3892 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'i' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'i') {
					declare({ default: { icon: { offsetY: { value: 3956 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 0 || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 0) {
					declare({ default: { icon: { offsetY: { value: 4148 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'hoehenbeschraenkung' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'hoehenbeschraenkung') {
					declare({ default: { icon: { offsetY: { value: 4212 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'h' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'h') {
					declare({ default: { icon: { offsetY: { value: 4276 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'umleitung' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'umleitung') {
					declare({ default: { icon: { offsetY: { value: 4340 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'g' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'g') {
					declare({ default: { icon: { offsetY: { value: 4404 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'gegenverkehr' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'gegenverkehr') {
					declare({ default: { icon: { offsetY: { value: 4468 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'flag-start' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'flag-start') {
					declare({ default: { icon: { offsetY: { value: 4532 },
						sizeX: { value: 76 },
						sizeY: { value: 76 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'flag' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'flag') {
					declare({ default: { icon: { offsetY: { value: 4608 },
						sizeX: { value: 76 },
						sizeY: { value: 76 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && props.mapsightIconId == 'flag-finish' || env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconId == 'flag-finish') {
					declare({ default: { icon: { offsetY: { value: 4684 },
						sizeX: { value: 76 },
						sizeY: { value: 76 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 8) {
					declare({ default: { icon: { offsetY: { value: 884 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'u') {
					declare({ default: { icon: { offsetY: { value: 948 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 9) {
					declare({ default: { icon: { offsetY: { value: 1012 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 1) {
					declare({ default: { icon: { offsetY: { value: 1076 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'a') {
					declare({ default: { icon: { offsetY: { value: 1140 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'tonnage') {
					declare({ default: { icon: { offsetY: { value: 1204 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 't') {
					declare({ default: { icon: { offsetY: { value: 1268 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'ampel') {
					declare({ default: { icon: { offsetY: { value: 1332 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'x') {
					declare({ default: { icon: { offsetY: { value: 1396 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 2) {
					declare({ default: { icon: { offsetY: { value: 1460 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'b') {
					declare({ default: { icon: { offsetY: { value: 1524 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'stau') {
					declare({ default: { icon: { offsetY: { value: 1588 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'baustelle') {
					declare({ default: { icon: { offsetY: { value: 1652 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'w') {
					declare({ default: { icon: { offsetY: { value: 1716 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 's') {
					declare({ default: { icon: { offsetY: { value: 1780 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'r') {
					declare({ default: { icon: { offsetY: { value: 1844 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'q') {
					declare({ default: { icon: { offsetY: { value: 1908 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 3) {
					declare({ default: { icon: { offsetY: { value: 1972 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'breitenbeschraenkung') {
					declare({ default: { icon: { offsetY: { value: 2036 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'z') {
					declare({ default: { icon: { offsetY: { value: 2100 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'pundr') {
					declare({ default: { icon: { offsetY: { value: 2164 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 4) {
					declare({ default: { icon: { offsetY: { value: 2228 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'parkflaeche') {
					declare({ default: { icon: { offsetY: { value: 2292 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'c') {
					declare({ default: { icon: { offsetY: { value: 2356 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'p') {
					declare({ default: { icon: { offsetY: { value: 2420 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'cam') {
					declare({ default: { icon: { offsetY: { value: 2484 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'vollsperrung') {
					declare({ default: { icon: { offsetY: { value: 2548 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 5) {
					declare({ default: { icon: { offsetY: { value: 2612 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'd') {
					declare({ default: { icon: { offsetY: { value: 2676 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'ort') {
					declare({ default: { icon: { offsetY: { value: 2740 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'o') {
					declare({ default: { icon: { offsetY: { value: 2868 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'e') {
					declare({ default: { icon: { offsetY: { value: 2932 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'n') {
					declare({ default: { icon: { offsetY: { value: 2996 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'einbahnstrasse') {
					declare({ default: { icon: { offsetY: { value: 3060 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'v') {
					declare({ default: { icon: { offsetY: { value: 3124 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 6) {
					declare({ default: { icon: { offsetY: { value: 3188 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'f') {
					declare({ default: { icon: { offsetY: { value: 3252 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'meldung') {
					declare({ default: { icon: { offsetY: { value: 3316 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'm') {
					declare({ default: { icon: { offsetY: { value: 3380 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'fahrbahnverengung') {
					declare({ default: { icon: { offsetY: { value: 3444 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'y') {
					declare({ default: { icon: { offsetY: { value: 3508 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 7) {
					declare({ default: { icon: { offsetY: { value: 3572 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'lkwsperrung') {
					declare({ default: { icon: { offsetY: { value: 3636 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'l') {
					declare({ default: { icon: { offsetY: { value: 3700 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'k') {
					declare({ default: { icon: { offsetY: { value: 3828 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'j') {
					declare({ default: { icon: { offsetY: { value: 3892 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'i') {
					declare({ default: { icon: { offsetY: { value: 3956 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 0) {
					declare({ default: { icon: { offsetY: { value: 4148 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'hoehenbeschraenkung') {
					declare({ default: { icon: { offsetY: { value: 4212 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'h') {
					declare({ default: { icon: { offsetY: { value: 4276 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'umleitung') {
					declare({ default: { icon: { offsetY: { value: 4340 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'g') {
					declare({ default: { icon: { offsetY: { value: 4404 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'gegenverkehr') {
					declare({ default: { icon: { offsetY: { value: 4468 },
						sizeX: { value: 64 },
						sizeY: { value: 64 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'flag-start') {
					declare({ default: { icon: { offsetY: { value: 4532 },
						sizeX: { value: 76 },
						sizeY: { value: 76 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'flag') {
					declare({ default: { icon: { offsetY: { value: 4608 },
						sizeX: { value: 76 },
						sizeY: { value: 76 } } } });
				}
				if (env.zoom >= 11 && env.zoom <= 12 && props.state != 'highlight' && props.state != 'select' && props.state != 'preselect' && env.mapsightIconUse == 'force' && env.mapsightIconId == 'flag-finish') {
					declare({ default: { icon: { offsetY: { value: 4684 },
						sizeX: { value: 76 },
						sizeY: { value: 76 } } } });
				}
				if (props.state == 'highlight' || props.state == 'select') {
					declare({ selectionBackground: { image: { type: { value: 'circle' } },
						circle: { radius: { value: 26 },
							fill: { color: { value: 'rgba(128, 255, 255, 0.4)' } } } } });
				}
				if (props.state == 'select') {
					declare({ selectionBackground: { circle: { fill: { color: { value: 'rgba(87, 255, 255, 0.8)' } } } } });
				}
				if (props.mapsightIconId == 'stau') {
					declare({ default: { stroke: { color: { value: '#f00' } } } });
				}
				if (props.mapsightIconId == 'umleitung') {
					declare({ default: { stroke: { color: { value: '#2aadea' } } } });
				}
				if (props.isTemporaryRestriction) {
					declare({ default: { stroke: { lineDash: { value: '5, 15' } } } });
				}
				if (props.type == 'trunk' || props.type == 'trunk_link') {
					declare({ default: { stroke: { width: { value: 4 } } } });
				}
				if (props.type == 'primary' || props.type == 'primary_link') {
					declare({ default: { stroke: { width: { value: 3 } } } });
				}
				if (geometryType == 'Polygon' || geometryType == 'MultiLineString') {
					declare({ default: { zIndex: { value: 101 } } });
				}
				if (geometryType == 'MultiPolygon') {
					declare({ default: { zIndex: { value: 100 } } });
				}
				if (props.state == 'highlight' || props.state == 'select') {
					declare({ default: { stroke: { color: { value: '#3afffe' } },
						cursor: { value: 'pointer' } } });
				}
				if (props.state == 'highlight') {
					declare({ default: { zIndex: { value: 105 } } });
				}
				if (props.state == 'highlight' && geometryType == 'Polygon' || props.state == 'highlight' && geometryType == 'MultiLineString' || props.state == 'highlight' && geometryType == 'MultiPolygon') {
					declare({ default: { zIndex: { value: 100 } } });
				}
				if (props.state == 'select') {
					declare({ default: { zIndex: { value: 305 } } });
				}
				if (props.state == 'select' && geometryType == 'Polygon' || props.state == 'select' && geometryType == 'MultiLineString' || props.state == 'select' && geometryType == 'MultiPolygon') {
					declare({ default: { zIndex: { value: 100 } } });
				}
				if (props.type == 'parking' && env.zoom >= 15) {
					declare({ default: { text: { font: { value: 'bold 12px sans-serif' },
						offsetX: { value: 17 },
						offsetY: { value: 1 },
						textAlign: { value: 'left' },
						fill: { color: { value: '#03437e' } },
						stroke: { color: { value: '#fff' },
							width: { value: 1 } },
						text: { value: '' + props.title + '' } } } });
				}
				if (props.type == 'parking' && props.occupancyTrendString && env.zoom >= 13 || props.type == 'parking' && props.occupancyTrendString && props.state == 'highlight' || props.type == 'parking' && props.occupancyTrendString && props.state == 'select') {
					declare({ background: { image: { type: { value: 'icon' } },
						icon: { anchorX: { value: '-14px' },
							anchorY: { value: '12px' },
							src: { value: '/assets/img/parking-dynamic/' + props.occupancyTrendString + '.png' } } } });
				}
				if (props.type == 'parking' && props.occupancyTrendString && env.zoom >= 13 && props.state == 'highlight' || props.type == 'parking' && props.occupancyTrendString && env.zoom >= 13 && props.state == 'select' || props.type == 'parking' && props.occupancyTrendString && props.state == 'highlight' || props.type == 'parking' && props.occupancyTrendString && props.state == 'highlight' && props.state == 'select' || props.type == 'parking' && props.occupancyTrendString && props.state == 'select' && props.state == 'highlight' || props.type == 'parking' && props.occupancyTrendString && props.state == 'select') {
					declare({ background: { zIndex: { value: 105 } } });
				}
				if (props.type == 'parking' && props.occupancyTrendString && env.zoom >= 15) {
					declare({ default: { text: { offsetX: { value: 29 } } } });
				}
				if (props.type == 'default' && props.mass_restriction && env.zoom >= 12 || props.type == 'default' && props.height_restriction && env.zoom >= 12) {
					declare({ default: { text: { font: { value: 'bold 11px sans-serif' },
						offsetX: { value: 0 },
						offsetY: { value: 13 },
						textAlign: { value: 'center' },
						fill: { color: { value: '#03437e' } },
						stroke: { color: { value: '#fff' },
							width: { value: 1 } } } } });
				}
				if (props.type == 'default' && props.mass_restriction && env.zoom >= 13 || props.type == 'default' && props.height_restriction && env.zoom >= 13) {
					declare({ default: { text: { offsetY: { value: 19 },
						font: { value: 'bold 13px sans-serif' } } } });
				}
				if (props.type == 'default' && props.mass_restriction && props.state == 'highlight' || props.type == 'default' && props.mass_restriction && props.state == 'select' || props.type == 'default' && props.height_restriction && props.state == 'highlight' || props.type == 'default' && props.height_restriction && props.state == 'select') {
					declare({ default: { text: { fill: { color: { value: '#003163' } },
						stroke: { width: { value: 2 } },
						offsetY: { value: 22 } } } });
				}
				if (props.type == 'default' && props.mass_restriction && env.zoom >= 12) {
					declare({ default: { text: { text: { value: '' + props.mass_restriction + '' } } } });
				}
				if (props.type == 'default' && props.height_restriction && env.zoom >= 12) {
					declare({ default: { text: { text: { value: '' + props.height_restriction + 'm' } } } });
				}
				if (props.type == 'wheel-counter') {
					declare({ selectionBackground: { image: { type: { value: 'circle' } },
						circle: { radius: { value: 13 },
							fill: { color: { value: 'rgba(35, 175, 35, 0.75)' } },
							stroke: { width: { value: 2 },
								color: { value: '#ffffff' } } } } });
				}
				if (props.type == 'schul') {
					declare({ default: { stroke: { color: { value: '#C67B00' },
						width: { value: 2 } },
					fill: { color: { value: 'rgba(147, 91, 0, 0.3)' } } } });
				}
				if (props.state == 'highlight' || props.state == 'select' || props.state == 'preselect') {
					declare({ selectionBackground: { image: { type: { value: 'circle' } },
						circle: { radius: { value: 26 },
							fill: { color: { value: 'rgba(242, 242, 242, 0.75)' } },
							stroke: { width: { value: 2 },
								color: { value: '#ffffff' } } } } });
				}
				if (props.state == 'select') {
					declare({ selectionBackground: { circle: { fill: { color: { value: 'rgba(87, 255, 255, 0.75)' } } } } });
				}
				if (props.state == 'preselect') {
					declare({ selectionBackground: { circle: { fill: { color: { value: 'rgba(223, 235, 245, 0.75)' } } } } });
				}
				if (props.type == 'anomaly') {
					declare({ default: { image: { type: { value: 'none' } },
						text: { text: { value: '' + props.anomalyText + '' },
							textAlign: { value: 'center' },
							fill: { color: { value: '#BE1E1E' } },
							offsetX: { value: '' + eval('' + props.textDirectionX + ' * 11') + '' },
							offsetY: { value: '' + eval('' + props.textDirectionY + ' * 11') + '' },
							font: { value: 'bold 10px sans-serif' },
							stroke: { width: { value: 2 },
								color: { value: '#fff' } } } } });
				}
				if (props.type == 'anomaly' && env.zoom >= 12) {
					declare({ default: { text: { font: { value: 'bold 12px sans-serif' },
						offsetX: { value: '' + eval('' + props.textDirectionX + ' * 12') + '' },
						offsetY: { value: '' + eval('' + props.textDirectionY + ' * 12') + '' } } } });
				}
				if (props.type == 'anomaly' && env.zoom >= 16) {
					declare({ default: { text: { font: { value: 'bold 14px sans-serif' },
						offsetX: { value: '' + eval('' + props.textDirectionX + ' * 13') + '' },
						offsetY: { value: '' + eval('' + props.textDirectionY + ' * 13') + '' } } } });
				}
				if (props.type == 'anomaly' && props.state == 'preselect') {
					declare({ selectionBackground: { display: { value: 'none' } } });
				}
				if (props.type == 'pundr' && props.occupancyTrendString && env.zoom >= 11 || props.type == 'parking' && props.occupancyTrendString && env.zoom >= 11) {
					declare({ background: { image: { type: { value: 'icon' } },
						icon: { anchorX: { value: '-13px' },
							anchorY: { value: '10px' },
							src: { value: '/assets/img/parking-dynamic/' + props.occupancyTrendString + '.png' },
							scale: { value: 0.8 } } } });
				}
				if (props.type == 'pundr' && props.occupancyTrendString && env.zoom >= 12 || props.type == 'parking' && props.occupancyTrendString && env.zoom >= 12) {
					declare({ background: { image: { type: { value: 'icon' } },
						icon: { anchorX: { value: '-14px' },
							anchorY: { value: '11px' },
							src: { value: '/assets/img/parking-dynamic/' + props.occupancyTrendString + '.png' },
							scale: { value: 0.85 } } } });
				}
				if (props.type == 'pundr' && props.occupancyTrendString && env.zoom >= 13 || props.type == 'pundr' && props.occupancyTrendString && props.state == 'highlight' || props.type == 'pundr' && props.occupancyTrendString && props.state == 'select' || props.type == 'parking' && props.occupancyTrendString && env.zoom >= 13 || props.type == 'parking' && props.occupancyTrendString && props.state == 'highlight' || props.type == 'parking' && props.occupancyTrendString && props.state == 'select') {
					declare({ background: { image: { type: { value: 'icon' } },
						icon: { anchorX: { value: '-15px' },
							anchorY: { value: '12px' },
							src: { value: '/assets/img/parking-dynamic/' + props.occupancyTrendString + '.png' },
							scale: { value: 1 } } } });
				}
				if (props.type == 'pundr' && props.occupancyTrendString && env.zoom >= 13 && props.state == 'highlight' || props.type == 'pundr' && props.occupancyTrendString && env.zoom >= 13 && props.state == 'select' || props.type == 'pundr' && props.occupancyTrendString && props.state == 'highlight' || props.type == 'pundr' && props.occupancyTrendString && props.state == 'highlight' && props.state == 'select' || props.type == 'pundr' && props.occupancyTrendString && props.state == 'select' && props.state == 'highlight' || props.type == 'pundr' && props.occupancyTrendString && props.state == 'select' || props.type == 'parking' && props.occupancyTrendString && env.zoom >= 13 && props.state == 'highlight' || props.type == 'parking' && props.occupancyTrendString && env.zoom >= 13 && props.state == 'select' || props.type == 'parking' && props.occupancyTrendString && props.state == 'highlight' || props.type == 'parking' && props.occupancyTrendString && props.state == 'highlight' && props.state == 'select' || props.type == 'parking' && props.occupancyTrendString && props.state == 'select' && props.state == 'highlight' || props.type == 'parking' && props.occupancyTrendString && props.state == 'select') {
					declare({ background: { zIndex: { value: 105 } } });
				}
				if (env.colorScheme == 'high-contrast') {
					declare({ default: { stroke: { color: { value: 'black' } } } });
				}
				if (env.colorScheme == 'high-contrast' && geometryType == 'Polygon') {
					declare({ default: { stroke: { lineDash: { value: 'none' } } } });
				}
				if (env.colorScheme == 'high-contrast' && props.type == 'anomaly') {
					declare({ default: { text: { fill: { color: { value: 'black' } } } } });
				}
				if (env.colorScheme == 'high-contrast' && props.state == 'highlight' || env.colorScheme == 'high-contrast' && props.state == 'select') {
					declare({ default: { stroke: { lineDash: { value: '' + eval('1 / ' + env.zoom + '') + ', ' + eval('' + env.zoom + ' / 1.5') + '' },
						width: { value: 5 } } } });
				}
				if (env.colorScheme == 'high-contrast' && props.state == 'highlight' && env.zoom >= 14 || env.colorScheme == 'high-contrast' && props.state == 'select' && env.zoom >= 14) {
					declare({ default: { stroke: { width: { value: 9 } } } });
				}
				if (env.colorScheme == 'high-contrast' && props.state == 'highlight' && env.zoom >= 20 || env.colorScheme == 'high-contrast' && props.state == 'select' && env.zoom >= 20) {
					declare({ default: { stroke: { width: { value: 21 } } } });
				}
				if (env.colorScheme == 'high-contrast' && props.state == 'preselect') {
					declare({ default: { stroke: { lineDash: { value: '' + eval('1 / ' + env.zoom + '') + ', ' + eval('' + env.zoom + ' / 1.5') + '' } } } });
				}
				if (env.colorScheme == 'foobar') {
					declare({ default: { icon: { src: { value: '/assets/img/high-contrast/mapsight-traffic-style-icon-sprite-2x.png?v=2021-10-19-22-00' } },
						stroke: { color: { value: 'purple' } } } });
				}
				if (env.colorScheme == 'foobar' && geometryType == 'LineString') {
					declare({ default: { stroke: { lineDash: { value: '3, 10' } } } });
				}
				if (env.colorScheme == 'foobar' && geometryType == 'Polygon') {
					declare({ default: { stroke: { lineDash: { value: 'none' } } } });
				}
				if (env.colorScheme == 'foobar' && props.type == 'anomaly') {
					declare({ default: { text: { fill: { color: { value: 'purple' } } } } });
				}
				break;
			case 'graphmastersRouteStopsDraw':
				declare({ default: { image: { type: { value: 'circle' } },
					circle: { radius: { value: 4 },
						fill: { color: { value: 'white' } },
						stroke: { width: { value: 2 },
							color: { value: '#000000' } } } } });
				break;
			case 'navigationRoute':
				declare({ default: { stroke: { width: { value: 4 },
					color: { value: '#047496' },
					lineJoin: { value: 'round' },
					lineCap: { value: 'round' } } },
				'stroke-bg': { stroke: { width: { value: 8 },
					color: { value: '#00e4f1' },
					lineJoin: { value: 'round' },
					lineCap: { value: 'round' } } } });
				if (env.zoom >= 15) {
					declare({ default: { stroke: { width: { value: 6 } } } });
				}
				if (env.zoom >= 15) {
					declare({ 'bg-stroke': { stroke: { width: { value: 10 },
						color: { value: '#00e4f1' } } } });
				}
				if (props.state == 'highlight' || props.state == 'select') {
					declare({ default: { stroke: { color: { value: '#009ccf' } } } });
				}
				break;
			case 'graphmastersTraffic':
				declare({ default: { stroke: { width: { value: 0 },
					color: { value: 'rgba(0, 0, 0, 0)' } } } });
				if (geometryType == 'LineString' && props.speedRatio) {
					declare({ default: { stroke: { width: { value: 2 },
						color: { value: 'rgba(255, 31, 0, 0.73)' },
						lineJoin: { value: 'round' },
						lineCap: { value: 'round' } } } });
				}
				if (geometryType == 'LineString' && props.speedRatio && env.zoom >= 13) {
					declare({ default: { stroke: { width: { value: 2 } } } });
				}
				if (geometryType == 'LineString' && props.speedRatio && env.zoom >= 15) {
					declare({ default: { stroke: { width: { value: 3 } } } });
				}
				if (geometryType == 'LineString' && props.speedRatio && env.zoom >= 20) {
					declare({ default: { stroke: { width: { value: 15 } } } });
				}
				if (geometryType == 'LineString' && props.speedRatio && props.speedRatio < 0.25) {
					declare({ default: { stroke: { color: { value: 'rgba(255, 31, 0, 0.73)' },
						width: { value: 6 } } } });
				}
				if (geometryType == 'LineString' && props.speedRatio && props.speedRatio < 0.25 && env.zoom >= 20) {
					declare({ default: { stroke: { width: { value: 19 } } } });
				}
				if (geometryType == 'LineString' && props.speedRatio && props.speedRatio >= 0.25) {
					declare({ default: { stroke: { color: { value: 'rgba(255, 132, 0, 0.74)' } } } });
				}
				if (geometryType == 'LineString' && props.speedRatio && props.speedRatio >= 0.67) {
					declare({ default: { stroke: { color: { value: 'rgba(0, 255, 12, 0.73)' } } } });
				}
				if (env.colorScheme == 'high-contrast' && geometryType == 'LineString' && props.speedRatio && props.speedRatio <= 0.25) {
					declare({ default: { stroke: { color: { value: 'black' },
						width: { value: 4 } } } });
				}
				if (env.colorScheme == 'high-contrast' && geometryType == 'LineString' && props.speedRatio && props.speedRatio <= 0.25 && env.zoom >= 14) {
					declare({ default: { stroke: { width: { value: 6 } } } });
				}
				if (env.colorScheme == 'high-contrast' && geometryType == 'LineString' && props.speedRatio && props.speedRatio <= 0.25 && env.zoom >= 20) {
					declare({ default: { stroke: { width: { value: 19 } } } });
				}
				if (env.colorScheme == 'high-contrast' && geometryType == 'LineString' && props.speedRatio && props.speedRatio >= 0.25) {
					declare({ default: { stroke: { color: { value: 'black' } } } });
				}
				if (env.colorScheme == 'high-contrast' && geometryType == 'LineString' && props.speedRatio && props.speedRatio >= 0.67) {
					declare({ default: { stroke: { value: 'none' } } });
				}
				break;
			case 'graphmastersRoute':
				declare({  });
				if (env.colorScheme == 'high-contrast') {
					declare({ default: { stroke: { width: { value: 6 },
						color: { value: 'black' } } } });
				}
				if (env.colorScheme == 'high-contrast') {
					declare({ 'stroke-bg': { stroke: { width: { value: 10 },
						color: { value: 'white' } } } });
				}
				if (env.colorScheme == 'high-contrast' && env.zoom >= 15) {
					declare({ default: { stroke: { width: { value: 8 } } } });
				}
				if (env.colorScheme == 'high-contrast' && env.zoom >= 15) {
					declare({ 'bg-stroke': { stroke: { width: { value: 12 },
						color: { value: 'white' } } } });
				}
				if (env.colorScheme == 'high-contrast' && props.state == 'highlight' || env.colorScheme == 'high-contrast' && props.state == 'select') {
					declare({ default: { stroke: { lineDash: { value: '' + eval('1 / ' + env.zoom + '') + ', ' + eval('' + env.zoom + ' / 1.5') + '' },
						width: { value: 6 } } } });
				}
				break;
		}

		return declaration;
	},
});
