import {BaseController} from '../base/controller';

import {addFilterFunction} from './selectors';

export class FilterController extends BaseController {
	constructor(controllerName, filterFunction) {
		super(controllerName);
		this.setFilterFunction(filterFunction);
	}

	setFilterFunction(filterFunction) {
		addFilterFunction(this.getName(), filterFunction);
	}
}
