import React, {memo} from 'react';
import {Provider as ReduxProvider} from 'react-redux';

import {mapAndListVisible} from '../store/selectors';

import VisibilityWrapper from './helping/visibility-wrapper';
import AppWrapper from './wrapper';
import {ComponentsContext} from './contexts';
import ViewToggleButton from './view-toggle-button';
import AdditionalContainer from './additional-container';
import MainContainer from './main-container';

export const MAP_ID = 'js-ms3-map-id';

// layout/design concept: https://jsfiddle.net/4Ljorau2/4/

/**
 * @param {{
 *   store: import("redux").Store<any>,
 *   selectedFeature: any,
 *   components: import("./contexts").Components,
 * }} props
 */
function App({
	store,
	selectedFeature,
	components,
}) {
	return (
		<ReduxProvider store={store}>
			<ComponentsContext.Provider value={components}>
				<AppWrapper>
					<MainContainer selectedFeature={selectedFeature} />
					<AdditionalContainer />

					<VisibilityWrapper visibleSelector={mapAndListVisible}>
						<ViewToggleButton
							baseClass="ms3-viewport-button"
							additionalClasses="ms3-viewport-button--bottom-right ms3-viewport-button--mobile-view-toggle"
							viewModifierClassPrefix="ms3-viewport-button--mobile-view-toggle--"
						/>
					</VisibilityWrapper>
				</AppWrapper>
			</ComponentsContext.Provider>
		</ReduxProvider>
	);
}

export default memo(App);
