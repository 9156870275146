import React from "react";
import { makeReplaceableComponent } from "../../helpers/components";

function FeatureListGroupName({ groupName, as: T, className }) {
	return <T className={className}>{groupName}</T>;
}

export default makeReplaceableComponent(
	"FeatureListGroupName",
	FeatureListGroupName
);
