function strictEqualCompare(a, b) {
	return a === b;
}

//const jsonCompare = (a, b)  => JSON.stringify(a) !== JSON.stringify(b);

export const AbortObserving = {}; // TODO: Use Symbol()

function internalObserveState(store, selector, listener, compare, initialValue) {
	compare = compare || strictEqualCompare;

	let currentValue = initialValue;
	const unsubscribe = store.subscribe(function onStateChange() {
		const state = store.getState();
		const newValue = selector(state);

		if (!compare(currentValue, newValue)) {
			const oldValue = currentValue;
			currentValue = newValue;

			const returnValue = listener(newValue, oldValue, state);
			if (returnValue === AbortObserving) {
				unsubscribe();
			}
		}
	});

	return unsubscribe;
}

/**
 * TODO
 *
 * @param {*}        store      TODO
 * @param {Function} selector   TODO
 * @param {Function} listener   TODO
 * @param {Function} [compare]  function used to compare, like _.isEqual, the default tests for strict equality using ===
 * @returns {Function} unsubscribe function
 */

export function observeState(store, selector, listener, compare) {
	const initialValue = selector(store.getState());
	return internalObserveState(store, selector, listener, compare, initialValue);
}

/**
 * TODO
 *
 * @param {*}        store      TODO
 * @param {Function} selector   TODO
 * @param {Function} listener   TODO
 * @param {Function} [compare]  function used to compare, like _.isEqual, the default tests for strict equality using ===
 * @returns {Function} unsubscribe function
 */
export function observeStateOnce(store, selector, listener, compare) {
	compare = compare || strictEqualCompare;

	const unsubscribe = observeState(store, selector, function handleChange(...args) {
		listener(...args);
		unsubscribe();
	}, compare);

	return unsubscribe;
}


/**
 * TODO
 *
 * @param {*}        store      TODO
 * @param {Function} selector   TODO
 * @param {Function} listener   TODO
 * @param {Function} [compare]  function used to compare, like _.isEqual, the default tests for strict equality using ===
 * @returns {Function} unsubscribe function
 */
export function getAndObserveState(store, selector, listener, compare) {
	compare = compare || strictEqualCompare;

	const initialState = store.getState();
	const initialValue = selector(initialState);

	const initialReturnValue = listener(initialValue, null, initialState);
	if (initialReturnValue === AbortObserving) {
		return () => {
		};
	}

	return internalObserveState(store, selector, listener, compare, initialValue);
}

