import React, {memo} from 'react';

import {translate} from '../../helpers/i18n';

const ListToggleButton = memo(function ListToggleButton({active, onClick}) {
	return (
		<button
			className={`ms3-button [ ms3-list-toggle-button ${active ? 'ms3-list-toggle-button--active' : ''} ]`}
			role="switch"
			aria-checked={active}
			onClick={onClick}
		>
			<span className="visuallyhidden">
				{translate(active ? 'ui.main-panel.list-toggle.close' : 'ui.main-panel.list-toggle.open')}
			</span>
		</button>
	);
});

export default ListToggleButton;
