import forEach from 'lodash/forEach';
import {di, updateProxyObject} from '@mapsight/ol-proxy';

import proxyPassOpenLayersEventsToMapController from './proxyPassOpenLayersEventsToMapController';

export default function withControls(mapController, map) {
	const controls = {};

	mapController.getAndObserveUncontrolled(state => state.controls, function onControlChange(newDefinitions = {}, oldDefinitions = {}) {
		forEach(newDefinitions, (newDefinition, id) => updateProxyObject({
			di: di,
			group: 'control',
			oldObject: controls[id],
			oldDefinition: oldDefinitions && oldDefinitions[id],
			newDefinition: newDefinition,
			remover: () => {
				map.removeControl(controls[id]);
				delete controls[id];
			},
			adder: (object) => {
				controls[id] = object;
				map.addControl(object);
				proxyPassOpenLayersEventsToMapController(mapController, object, newDefinition.type, id, 'control');
			},
			parentObject: mapController,
		}));
	});
}
