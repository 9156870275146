/**
 * Modifies the openlayers feature with the given properties.
 * Will trigger openlayers observe events when appropriate. Changes to geometry will trigger an change:geometry event otherwise
 * only one change event will be triggered if any of the props changed. Equality is checked strictly (===).
 * Does NOT remove old properties not defined in new props.
 *
 * TODO: Move to @mapsight/lib-ol?
 *
 * @param {ol.Feature} baseFeature base feature to modify
 * @param {object} newProps properties to set
 * @returns {boolean} true if changed, false otherwise
 */
function modifyFeature(baseFeature, newProps) {
	const oldProps = baseFeature.getProperties();

	// TODO: Should we remove old properties not existing in new properties?
	let featureChanged = false;
	Object.keys(newProps).forEach(function updateFeatureProperty(key) {
		const newValue = newProps[key];
		if (oldProps[key] !== newValue) {
			featureChanged = true;
			const silent = key !== 'geometry';
			baseFeature.set(key, newValue, silent);
		}
	});

	if (featureChanged) {
		baseFeature.changed();
	}
	return featureChanged;
}

/**
 * Update the source with the given features, removing old or unidentifiable features, updating existing features if id matches
 * and adding new features. Will trigger openlayers observe events when appropriate.
 *
 * TODO: Move to @mapsight/lib-ol?
 *
 * @param {ol.source.Vector} source source to update
 * @param {Array<ol.Feature>} nextFeatures next features
 * @returns {{removed: boolean, added: boolean, changed: boolean}} flags that indicate the what changes have occurred
 */
export function updateFeaturesInSource(source, nextFeatures) {
	const features = source.getFeatures();

	let hasChanged = false;
	let hasAdded = false;
	let hasRemoved = false;
	const ids = new Set();
	features.forEach(function handlePreviousFeature(feature) {
		if (!feature) {
			return;
		}

		const id = feature.getId();
		if (id) {
			ids.add(id);
		} else {
			// remove features without id
			source.removeFeature(feature);
			hasChanged = true;
			hasRemoved = true;
		}
	});

	nextFeatures.forEach(function updateFeatureInSource(nextFeature) {
		const newId = nextFeature.getId();
		if (ids.has(newId)) {
			ids.delete(newId);

			const prevFeature = source.getFeatureById(newId);
			const hasFeatureChanged = modifyFeature(prevFeature, nextFeature.getProperties());
			if (hasFeatureChanged) {
				hasChanged = true;
			}
		} else {
			hasChanged = true;
			hasAdded = true;
			source.addFeatureInternal(nextFeature);
		}
	});

	if (ids.size) {
		hasChanged = true;
		hasRemoved = true;

		for (const id of ids) {
			source.removeFeature(source.getFeatureById(id));
		}
	}

	if (hasChanged) {
		source.changed();
	}

	return {changed: hasChanged, added: hasAdded, removed: hasRemoved};
}
