import Map from 'ol/map';

import {OPTION_SET} from '../index';

export default {
	name: 'Map',
	Constructor: Map,
	optionMap: {
		pixelRatio: OPTION_SET,
		loadTilesWhileAnimating: OPTION_SET,
		loadTilesWhileInteracting: OPTION_SET,
		moveTolerance: OPTION_SET,
		renderer: OPTION_SET,
		size: 'setSize',
		logo: 'setLogo',
	},
};
