import {setDocumentLanguage} from '../../helpers/i18n';

/**
 * This plugin will apply a lang from the embedOptions to i18n
 *
 * @param {Object} [options] options
 * @return {MapsightUiPlugin} plugin
 */
export default function createLangPlugin(options = {}) {
	return {
		beforeRender: function pluginLangBeforeRender(context) {
			const {lang} = context.createOptions;
			setDocumentLanguage(lang);
		},
	};
}
