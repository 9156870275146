import React, {memo, useState, useCallback} from 'react';
import ReactModal from 'react-modal/lib/components/Modal';

import {useSelector} from 'react-redux';

import LayerSwitcher from '../layer-switcher';
import {isViewMobileOrMapOnlySelector} from '../../store/selectors';
import {translate} from '../../helpers/i18n';

const LayerSwitcherContent = memo(function LayerSwitcherContent({isExpanded, closeSearch}) {
	const isMobileOrMapOnly = useSelector(isViewMobileOrMapOnlySelector);

	if (isMobileOrMapOnly) {
		return (
			<ReactModal
				isOpen={isExpanded}
				// contentLabel="onRequestClose Example"
				// aria-label="" on .ms3-modal
				onRequestClose={closeSearch}
				className="ms3-modal"
				overlayClassName="ms3-app-overlay"
				shouldCloseOnOverlayClick={true}
			>
				<div className="ms3-modal__inner">
					<LayerSwitcher onClose={closeSearch} />
				</div>
			</ReactModal>
		);
	}

	if (isExpanded) {
		return (
			<LayerSwitcher onClose={closeSearch} />
		);
	}

	return (
		<div className="ms3-layer-switcher-placeholder" />
	);
});

function LayerSwitcherOverlay() {
	const [isExpanded, setIsExpanded] = useState(false);

	const toggleIsExpanded = useCallback(
		() => {
			setIsExpanded((prevVal) => !prevVal);
		},
		[setIsExpanded],
	);

	const closeSearch = useCallback(
		() => {
			setIsExpanded(false);
		},
		[setIsExpanded],
	);

	return (
		<div
			className={`ms3-layer-switcher-overlay ${isExpanded ? 'ms3-layer-switcher-overlay--expanded' : ''}`}
		>
			<button
				type="button"
				className="ms3-map-overlay__button ms3-map-overlay__button--with-icon ms3-map-overlay__button--layers"
				onClick={toggleIsExpanded}
				aria-expanded={isExpanded}
			>
				<span className="ms3-map-overlay__button__label">
					<span aria-hidden="true">
						{translate('ui.map-overlay.layer-switcher.layers')}
					</span>

					<span className="visuallyhidden">
						{isExpanded && translate('ui.map-overlay.layer-switcher.closeLayers')}
						{!isExpanded && translate('ui.map-overlay.layer-switcher.openLayers')}
					</span>
				</span>
			</button>

			<LayerSwitcherContent
				isExpanded={isExpanded}
				closeSearch={closeSearch}
			/>
		</div>
	);
}

export default memo(LayerSwitcherOverlay);
