import React, {useCallback, useRef, memo, useContext} from 'react';
import {useSelector, useStore, useDispatch} from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';
import {viewportAnchorSelector} from '@mapsight/core/lib/map/selectors';

import {setMapIsOutOfViewport} from '../store/actions';
import {MAP} from '../config/constants/controllers'; // TODO: Use context for this?
import useUpdateMapSizeOnTransitionEnd from '../hooks/useUpdateMapSizeOnTransitionEnd';
import useUpdateMapSizeOnViewChange from '../hooks/useUpdateMapSizeOnViewChange';
import useMountable from '../hooks/useMountable';
import useUpdateMapSizeOnRender from '../hooks/useUpdateMapSizeOnRender';
import {viewSelector} from '../store/selectors';

import {ComponentsContext} from './contexts';
import {MAP_ID} from './app'; // TODO: Use context for this?
import Map from './map';
import MapSyncedInterlay from './map-synced-interlay';
import MapOverlay from './map-overlay';

function mapViewportAnchorSelector(state) {
	return viewportAnchorSelector(state[MAP]);
}

function MapWrapper() {
	const comps = useContext(ComponentsContext);
	const store = useStore();
	const dispatch = useDispatch();
	const anchor = useSelector(mapViewportAnchorSelector);
	const view = useSelector(viewSelector);

	// WTF is `getController` o0
	const mapController = store.getController(MAP);

	// FIXME: shouldn't this be a `(bool) => void` instead of `() => (bool) => void`?
	const handleVisibilityChange = useCallback(
		() => isVisible => dispatch(setMapIsOutOfViewport(!isVisible)),
		[dispatch]
	);

	const mapWrapperRef = useRef();
	const onMount = useMountable(mapController);
	useUpdateMapSizeOnViewChange(view, store);
	useUpdateMapSizeOnTransitionEnd(mapWrapperRef, dispatch);
	useUpdateMapSizeOnRender(dispatch);

	const className = `ms3-map-wrapper ms3-map-wrapper--anchored-${anchor} [ ms3-flex ms3-flex--column ]`;

	let content = (
		<VisibilitySensor
			onChange={handleVisibilityChange}
			scrollCheck={true}
			resizeCheck={true}
			partialVisibility={true}
		>
			<div ref={mapWrapperRef} className={className}>
				<Map id={MAP_ID} onMount={onMount} />
				<MapSyncedInterlay view={view} />
				<MapOverlay view={view} />
			</div>
		</VisibilitySensor>
	);

	if (comps.MapWrapper) {
		content = (
			<comps.MapWrapper>
				{content}
			</comps.MapWrapper>
		);
	}

	return content;

}

export default memo(MapWrapper);
