import React, {useContext, memo, Fragment} from 'react';
import {useSelector} from 'react-redux';

import {isViewMobileOrMapOnlySelector} from '@mapsight/ui/src/js/store/selectors';


import {ComponentsContext} from "../contexts";

import ViewToggleButton from '../view-toggle-button';

import SearchOverlay from './search-overlay';
import RegionSelector from './region-selector';
import LayerSwitcherOverlay from './layer-switcher-overlay';
import InfoOverlayLeft from './info-overlay-left';
import InfoOverlayRight from './info-overlay-right';
import InfoOverlayModal from './info-overlay-modal';


// der obere ViewToggleButton ist für non-responsive in fullscreen und non-fullscreen
// der untere ViewToggleButton ist für responsive in fullscreen
// für responsive in non-fullscreen gibt es noch einen dritten Button in app.jsx außerhalb der Karte
function MapOverlay() {
	const {
		MapOverlayStart,
		MapOverlayEnd,
		MapOverlayTopLeft,
		MapOverlayTopRight,
		MapOverlayBottomLeft,
		MapOverlayBottomRight,
	} = useContext(ComponentsContext);
	const isMobile = useSelector(isViewMobileOrMapOnlySelector);

	let leftTop = (<SearchOverlay />);
	// RegionSelector removed for VMZNDS
	// (
	// 	<Fragment>
	// 		<SearchOverlay />
	// 		<RegionSelector />
	// 	</Fragment>
	// );
	if (MapOverlayTopLeft) {
		leftTop = (<MapOverlayTopLeft>{leftTop}</MapOverlayTopLeft>);
	}

	let leftBottom = (<InfoOverlayLeft />);
	if (MapOverlayBottomLeft) {
		leftBottom = (<MapOverlayBottomLeft>{leftBottom}</MapOverlayBottomLeft>);
	}


	let rightTop = (<LayerSwitcherOverlay />);
	// removed for vmznds
	// 	(
	// 	<Fragment>
	// 		<LayerSwitcherOverlay />
	//
	// 		<ViewToggleButton
	// 			baseClass="ms3-map-overlay__button"
	// 			additionalClasses="ms3-map-overlay__button--with-icon ms3-map-overlay__button--desktop-view-toggle"
	// 			viewModifierClassPrefix="ms3-map-overlay__button--desktop-view-toggle--"
	// 		/>
	// 	</Fragment>
	// );
	if (MapOverlayTopRight) {
		rightTop = (<MapOverlayTopRight>{rightTop}</MapOverlayTopRight>);
	}

	let rightBottom = (<InfoOverlayRight/>);
	// 	(
	// 	<Fragment>
	// 		<ViewToggleButton
	// 			baseClass="ms3-map-overlay__button"
	// 			additionalClasses="ms3-map-overlay__button--with-icon ms3-map-overlay__button--mobile-view-toggle"
	// 			viewModifierClassPrefix="ms3-map-overlay__button--mobile-view-toggle--"
	// 		/>
	// 		<InfoOverlayRight />
	// 	</Fragment>
	// );
	if (MapOverlayBottomRight) {
		rightBottom = (<MapOverlayBottomRight>{rightBottom}</MapOverlayBottomRight>);
	}

	return (
		<div className="ms3-map-overlay">
			{/* top left */}
			<div className="ms3-map-overlay__area ms3-map-overlay__area--top-left">
				{leftTop}
			</div>

			{/* top center */}
			{MapOverlayStart && (
				<MapOverlayStart />
			)}

			{/* top right */}
			<div className="ms3-map-overlay__area ms3-map-overlay__area--top-right">
				{rightTop}
			</div>

			{/* bottom right on desktop before bottom left as requested by bitv report 4.9.1.3.2 Bedeutungsvolle Reihenfolge */}
			{!isMobile && (
				<div className="ms3-map-overlay__area ms3-map-overlay__area--bottom-right">
					{rightBottom}
				</div>
			)}

			{/* bottom left */}
			<div className="ms3-map-overlay__area ms3-map-overlay__area--bottom-left">
				{leftBottom}
			</div>

			{/* bottom center */}
			{MapOverlayEnd && (
				<MapOverlayEnd />
			)}

			{/* bottom right on mobile */}
			{isMobile && (
				<div className="ms3-map-overlay__area ms3-map-overlay__area--bottom-right">
					{rightBottom}
				</div>
			)}

			<InfoOverlayModal />
		</div>
	);
}

export default memo(MapOverlay);
