import VectorTileLayer from 'ol/layer/vectortile';

import base from './vector';

export default {
	type: 'layer',
	name: 'VectorTile',
	Constructor: VectorTileLayer,
	optionMap: {
		...base.optionMap,
	},
	initialOptionMap: {
		...base.initialOptionMap,
	},
};
