import {createSelector} from 'reselect';

import unique from 'lodash/uniq';
import identity from 'lodash/identity';

import reduceByKeys from '@neonaut/lib-redux/es/reducers/reduce-by-keys';

/**
 * select all layers from state
 *
 * @param {object} state state
 * @returns {object} layers state
 */
export const layersSelector = state => state.layers;

/**
 * select specific layer from state
 *
 * @param {object} state state
 * @param {string} id layer id
 * @returns {object} layer state
 * @deprecated Use makeLayerSelector instead
 */
export const layerSelector = createSelector(
	[
		layersSelector,
		(state, id) => id,
	],
	(layers, id) => layers && layers[id]
);

export const makeLayerSelector = id => state => state.layers[id];
export const makeLayerTitleSelector = id => state => state.layers[id].metaData && state.layers[id].metaData.title;
export const makeLayerLockedInLayerSwitcherSelector = id => state => state.layers[id].metaData && state.layers[id].metaData.lockedInLayerSwitcher;
export const makeLayerSelectionsSelector = id => state => state.layers[id].options && state.layers[id].options.selections;
export const makeLayerSelectionSelector = (id, interactionName) => state => state.layers[id].options && state.layers[id].options.selections && state.layers[id].options.selections[interactionName];

export const layerIdsSelector = createSelector(
	state => (state.layers ? Object.keys(state.layers) : []),
	_ => _ // cache result of above to deliver reference equality if array has the same contents, which will help connect if the selector is called as part of an structured selector
);


export const layerIdsIntegratedSwitcherSelector = createSelector(
	state => (state.layers ?
			Object.keys(state.layers).filter(id => state.layers[id].metaData && state.layers[id].metaData.visibleInLayerSwitcher === true) :
			[]
	),
	_ => _ // cache result of above to deliver reference equality if array has the same contents
);
export const layerIdsExternalSwitcherSelector = createSelector(
	state => (state.layers ?
			Object.keys(state.layers).filter(id => state.layers[id].metaData && state.layers[id].metaData.visibleInExternalLayerSwitcher === true) :
			[]
	),
	_ => _ // cache result of above to deliver reference equality if array has the same contents
);
export const layerIdsBaseSwitcherSelector = createSelector(
	state => (state.layers ?
			Object.keys(state.layers).filter(id => state.layers[id].metaData && state.layers[id].metaData.isBaseLayer === true) :
			[]
	),
	_ => _ // cache result of above to deliver reference equality if array has the same contents
);
export const layerIdsNonBaseSwitcherSelector = createSelector(
	state => (state.layers ?
			Object.keys(state.layers).filter(id => state.layers[id].metaData && state.layers[id].metaData.isBaseLayer !== true) :
			[]
	),
	_ => _ // cache result of above to deliver reference equality if array has the same contents
);

export const makeGroupsFromLayerIdsSelector = idSelector => createSelector(
	state => Object.keys(
		idSelector(state).reduce(
			(collectedGroups, layerId) => {
				if (state.layers[layerId].metaData && state.layers[layerId].metaData.group) {
					collectedGroups[state.layers[layerId].metaData.group] = true;
				} else {
					collectedGroups.__NULL__ = true;
				}
				return collectedGroups;
			},
			{}
		)
	),
	_ => _ // cache result of above to deliver reference equality if array has the same contents
);


// createSelector only caches the very last one result, so we have to create a new selector per ID, see ""But there is a problem!", https://github.com/reduxjs/reselect#selectorstodoselectorsjs
export const makeLayerVisibleSelector = id => createSelector(
	state => state.layers[id],
	layer => (layer && layer.options ? layer.options.visible : false)
);

export const makeFeatureSourceIdFromLayerIdSelector = layerId => createSelector(
	state => state.layers[layerId],
	layer => (layer && layer.options && layer.options.source && layer.options.source.options  ?
		layer.options.source.options.featureSourceId : undefined)
);

export const makeFeatureSourceControllerNameFromLayerIdSelector = layerId => createSelector(
	state => state.layers[layerId],
	layer => (layer && layer.options && layer.options.source && layer.options.source.options ?
		layer.options.source.options.featureSourcesControllerName : undefined)
);

/**
 * create selector for featureState corresponding to the featureSource used by a layer
 *
 * @param {string} layerId id of the layer for which the selector will be created
 * @returns {Function} selector with parameters state (mapsight selected already) and globalFeatureState (featureSources _not_ selected)
 */
export const makeFeatureSourceFromLayerIdSelector = layerId => createSelector(
	(_, globalFeatureState) => globalFeatureState,
	makeFeatureSourceControllerNameFromLayerIdSelector(layerId),
	makeFeatureSourceIdFromLayerIdSelector(layerId),

	(globalFeatureState, featureControllerName, featureSourceId) => (featureControllerName && featureSourceId && globalFeatureState[featureControllerName] ?
		globalFeatureState[featureControllerName][featureSourceId] :
		null)
);

export const mapStyleEnvSelector = state => state.styleEnv;

export const mapSizeSelector = state => state.size;
export const viewportAnchorSelector = state => state.viewportAnchor;

// die tatsächlich von OpenLayer gerenderten Layer. wenn ein Layer mit option.visible grundsätzlich aktiv ist, aber an der angezeigten Stelle nichts beiträgt, ist er hier nicht verzeichnet.
export const visibleLayersSelector = state => state.visibleLayers;

export const reduceLayersToAttributions = layers => (layers ? Object.keys(layers).reduce((acc, layerId) => {
	const attribution = layers[layerId].metaData && layers[layerId].metaData.attribution;

	return attribution ? {...acc, [layerId]: attribution} : acc;
}, {}) : {});

export const layerAttributionsSelector = createSelector(layersSelector, reduceLayersToAttributions);

// TODO Dokumentation notwendig
export const getVisibleLayerAttributions = (visibleLayers, layerAttributions) => visibleLayers &&
	unique(visibleLayers.map(layerId => layerAttributions[layerId]).filter(identity));

export const visibleLayerAttributionsSelector = createSelector(visibleLayersSelector, layerAttributionsSelector, getVisibleLayerAttributions);

export const reduceLayersToLegends = (layers = {}) => {
	const legends = {};
	Object.keys(layers).forEach(function reduceLayer(layerId) {
		const legend = layers[layerId].metaData && layers[layerId].metaData.legend;
		if (legend) {
			legends[layerId] = legend;
		}
	});

	return legends;
};

export const reduceLayersToMiniLegends = (layers = {}) => {
	const miniLegends = {};
	Object.keys(layers).forEach(function reduceLayer(layerId) {
		const miniLegend = layers[layerId].metaData && layers[layerId].metaData.miniLegend;
		if (miniLegend) {
			miniLegends[layerId] = miniLegend;
		}
	});
	return miniLegends;
};

export const reduceToLayersWithLegends = (layers = {}) => {
	const layersWithLegends = {};
	Object.keys(layers).forEach(function reduceLayer(layerId) {
		if (layers[layerId].metaData && layers[layerId].metaData.legend) {
			layersWithLegends[layerId] = layers[layerId];
		}
	});
	return layersWithLegends;
};

export const reduceToLayersWithMiniLegends = (layers = {}) => {
	const layersWithMiniLegends = {};
	Object.keys(layers).forEach(function reduceLayer(layerId) {
		if (layers[layerId].metaData && layers[layerId].metaData.miniLegend) {
			layersWithMiniLegends[layerId] = layers[layerId];
		}
	});
	return layersWithMiniLegends;
};

export const layerLegendsSelector = createSelector(layersSelector, reduceLayersToLegends);
export const layerMiniLegendsSelector = createSelector(layersSelector, reduceLayersToMiniLegends);
export const layersWithLegendsSelector = createSelector(layersSelector, reduceToLayersWithLegends);
export const layersWithMiniLegendsSelector = createSelector(layersSelector, reduceToLayersWithMiniLegends);

/**
 * @deprecated instead use @neonaut/lib-redux/reducers/reduce-by-keys
 * @param {string[]} visibleLayers visible layers
 * @param {object} layerLegends layer legends (key-value-objects)
 * @returns {string[]} legends of visible layers
 */
export const getVisibleLayerLegends = (visibleLayers, layerLegends) => visibleLayers &&
	unique(visibleLayers.map(layerId => layerLegends[layerId]).filter(identity));

export const visibleLayerLegendsSelector = createSelector(visibleLayersSelector, layerLegendsSelector, reduceByKeys);
export const visibleLayersWithLegendsSelector = createSelector(visibleLayersSelector, layersWithLegendsSelector, reduceByKeys);
export const visibleLayersWithMiniLegendsSelector = createSelector(visibleLayersSelector, layersWithMiniLegendsSelector, reduceByKeys);

