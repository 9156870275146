import Pointer from 'ol/interaction/pointer';

import base from './_base';

export default {
	type: 'interaction',
	name: 'Pointer',
	Constructor: Pointer,
	optionMap: {
		...base.optionMap,
	},
	initialOptionMap: {
		...base.initialOptionMap,
		handleDownEvent: 'handleDownEvent',
		handleDragEvent: 'handleDragEvent',
		handleEvent: 'handleEvent',
		handleMoveEvent: 'handleMoveEvent',
		handleUpEvent: 'handleUpEvent',
	},
};
