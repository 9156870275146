import React, {memo, useState, useEffect, useCallback} from 'react';

import {useDebounce} from '../../hooks';

import {translate} from '../../helpers/i18n';

// copy and paste from *src/js/components/feature-list/query-input.jsx*
// TODO: dedup

/* NOTICE: for-attribute not required because of nesting */
/* eslint-disable jsx-a11y/label-has-for, react/jsx-no-bind */
function SearchQueryInput({name, query: defaultQuery, onChange}) {
	const [query, setQuery] = useState(defaultQuery);

	// TODO: do the debouncing in the upper comp
	const debouncedQuery = useDebounce(query, 200);

	useEffect(
		() => {
			if (typeof onChange === 'function' && debouncedQuery !== '') {
				onChange(debouncedQuery);
			}
		},
		[onChange, debouncedQuery]
	);

	// needed to "override" debounce if the value is ""
	useEffect(
		() => {
			if (typeof onChange === 'function' && query === '') {
				onChange('');
			}
		},
		[onChange, query]
	);

	const onQueryChange = useCallback(
		/**
		 * @param {React.ChangeEvent<HTMLInputElement>} e
		 */
		(e) => {
			setQuery(e.target.value);
		},
		[setQuery]
	);

	const onQueryReset = useCallback(
		() => {
			setQuery('');
		},
		[setQuery]
	);

	return (
		<fieldset className="ms3-search__input-group">
			<label>
				<span className="visuallyhidden">
					{translate('ui.search.query-input.label')}
				</span>
				<input
					className="ms3-search__input"
					placeholder={translate('ui.search.query-input.placeholder')}
					type="search"
					name={name}
					value={query}
					onChange={onQueryChange}
					autoComplete="off"
				/>
			</label>

			{query !== '' && (
				<button
					className="ms3-search__reset-button"
					type="button"
					onClick={onQueryReset}
				>
					<span className="visuallyhidden">
						{translate('ui.search.query-input.reset')}
					</span>
				</button>
			)}
		</fieldset>
	);
}


export default memo(SearchQueryInput);
