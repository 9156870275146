import {createStructuredSelector} from 'reselect';
import {connect} from 'react-redux';

import {createTagVisibleSelector} from '../../store/selectors';
import {setTagVisible} from '../../store/actions';

import SwitcherEntry from '../switcher/SwitcherEntry';

export default function createTagSwitcherEntry(featureSourceId, tagGroup, tagName) {
	return connect(
		// mapStateToProps:
		createStructuredSelector({
			visibility: createTagVisibleSelector(featureSourceId, tagGroup, tagName),
		}),
		// mapDispatchToProps:
		null,
		// mergeProps:
		({visibility}, {dispatch}, {...ownProps}) => ({
			title: tagName,
			active: visibility,
			toggleActive: () => dispatch(setTagVisible(featureSourceId, tagGroup, tagName, !visibility)),
			...ownProps
		})
	)(SwitcherEntry);
}
