import React, {memo} from 'react';
import {useSelector} from 'react-redux';

import {isViewMobileOrMapOnlySelector} from '../../store/selectors';
import {MAP_ID} from '../app';

import Tooltip from './tooltip';

function MapSyncedInterlay({size}) {
	const isViewMobileOrMapOnly = useSelector(isViewMobileOrMapOnlySelector);

	const [width = '100%', height = '100%'] = size || [];

	//const debug = true;
	//const debugColor = props.pendingUpdateSize ? 'orange' : 'green';

	/*
		{debug ? <div style={{position: 'absolute', top: 60, left: 40, right: 80, bottom: 60, border: '1px solid ' + debugColor}}>
			<div style={{position: 'absolute', top: '50%', left: '50%', margin: '-10px', width: 20, height: 20, border: '1px solid ' + debugColor, borderRadius: 10}}></div>
		</div> : null}

		{debug ? <div style={{position: 'absolute', width: 329, borderRight: '1px dotted ' + debugColor, height: '100%'}}></div> : null}
	 */


	 // FIXME: stop using hardcoded ids
	return (
		<div className="ms3-map-synced-interlay" style={{width: width, height: height}}>
			{!isViewMobileOrMapOnly && (
				<Tooltip mapId={MAP_ID} />
			)}
		</div>
	);
}

export default memo(MapSyncedInterlay);
