import * as c from '../../config/constants/controllers';
import {VIEW_MAP_ONLY, VIEW_MOBILE} from '../../config/constants/app';

import {setView} from '../../store/actions';
import {isEmbeddedMapSelector, viewSelector} from '../../store/selectors';

const defaultMapControllerName = c.MAP;
const defaultToggleBrowserEvent = 'click';

/**
 * This plugin will bind the search result feature source to the mapsight ui state
 *
 * @param {Object} [options] options
 * @param {String} [options.mapControllerName="map"] name of the map controller, defaults to mapsight ui default
 * @param {String} [options.toggleBrowserEvent="click"] event which should trigger a toggle
 * @return {MapsightUiPlugin} plugin
 */
export default function createPlugin(options = {}) {
	const {
		mapControllerName = defaultMapControllerName,
		toggleBrowserEvent = defaultToggleBrowserEvent,
	} = options;

	if (typeof window === 'undefined') {
		console.error('This plugin will only work as intended in the browser!');
	}

	return {
		afterCreate: function viewsCreatePlugin(context) {
			const {store, controllers} = context;

			const mapController = controllers[mapControllerName];
			mapController._map.on(toggleBrowserEvent, function handleToggleInteraction() {
				const state = store.getState();
				if (viewSelector(state) === VIEW_MOBILE && !isEmbeddedMapSelector(state)) {
					store.dispatch(setView(VIEW_MAP_ONLY));
				}
			});
		},
	};
}
