'use strict';

var loadJS = require('./load-js');

/* NOTE: Piwik only works with globals … */
var _window = {};
if(typeof window !== 'undefined') {
	_window = window;
}

_window._paq = _window._paq || [];

module.exports = {};

/**
 * @param {String} piwikURL
 * @param {Number} piwikSiteId
 * @param {Array.<String>} piwikDomains
 * @param {String} [piwikTrackerUrl]
 */
module.exports.init = function (piwikURL, piwikSiteId, piwikDomains, piwikTrackerUrl) {
	piwikTrackerUrl = piwikTrackerUrl || piwikURL + 'piwik.php';
	_window._paq.push(['setDomains', piwikDomains]);
	_window._paq.push(['enableLinkTracking']);
	_window._paq.push(['enableHeartBeatTimer']);
	_window._paq.push(['setTrackerUrl', piwikURL + 'piwik.php']);
	_window._paq.push(['setSiteId', piwikSiteId]);
	_window._paq.push(['trackPageView']);

	if (_window.console) {
		console.log('loading piwik ', _window._paq[0][0], _window._paq[0][1]);
	}

	loadJS(piwikURL + 'piwik.js', function () {
		if (_window.console) {
			console.log('loaded piwik ', piwikURL, piwikSiteId, piwikDomains, piwikTrackerUrl, _window._paq);
		}
	});
};

function pushPiwikCommand(command) {
	if (_window.console) {
		console.log('pushPiwikCommand', command[0], command[1], command[2], command[3], command[4]);
	}
	_window._paq.push(command);
}

module.exports.pushPiwikCommand = pushPiwikCommand;

module.exports.trackPageView = function (url, documentTitle) {
	url = url || _window.location.href;
	documentTitle = documentTitle || _window.document.title;

	pushPiwikCommand(['setDocumentTitle', documentTitle]);
	pushPiwikCommand(['setCustomUrl', url]);
	pushPiwikCommand(['trackPageView']);
};

module.exports.trackPiwikGoal = function (goalId) {
	pushPiwikCommand(['trackGoal', goalId]);
};

module.exports.trackEvent = function (category, action, name, value) {
	name = name || false;
	value = value || false;

	pushPiwikCommand(['trackEvent', category, action, name, value]);
};

module.exports.trackLink = function (url, linkType) {
	linkType = linkType || 'link';

	pushPiwikCommand(['trackLink', url, linkType]);
};

module.exports.trackSiteSearch = function (keyword, category, searchCount) {
	category = category || false;
	searchCount = searchCount || false;

	pushPiwikCommand(['trackSiteSearch', keyword, category, searchCount]);
};
