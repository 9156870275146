import isFiniteExtent from '../extent/isFiniteExtent';

import containsExtentWithPadding from './containsExtentWithPadding';
import {DEFAULT_OPTIONS as SINGLE_FEATURE_DEFAULT_OPTIONS} from './fitToFeature';

export const DEFAULT_OPTIONS = {...SINGLE_FEATURE_DEFAULT_OPTIONS};

// TODO: Document keepZoom option!
// TODO: Document skipIfInView option!
export default function fitToExtent(map, extent, options = DEFAULT_OPTIONS) {
	if (!isFiniteExtent(extent)) {
		return;
	}

	if (options.skipIfInView === false || !containsExtentWithPadding(map, extent, options.padding)) {
		const view = map.getView();
		view.fit(extent, {
			...options,
			maxZoom: options.keepZoom ? view.getZoom() : options.maxZoom,
		});
	}
}
