import {FeatureSelectionsController} from '@mapsight/core/lib/feature-selections/controller';
import {ACTION_SET, baseReducer} from '@mapsight/core/lib/base/reducer';

import {FEATURE_SELECTION_PRESELECT, FEATURE_SELECTION_SELECT} from './selections';

// TODO: Why is this in config directory? maybe move to plugins or add a new "core-controllers" dir?
//  because this is the only directory containing code concerned with selections
// It definitly does not belong to components, plugins or hooks as those directories imply the implementation of a certain api which this code does not meet
// It may be moved to store/, but that won't be that better ...

// sorgt dafür, dass beim selectExclusively der Karte oder Liste auch der preselect gesetzt wird,
// preselect kann aber unabhängig gesetzt werden und das löschen wird bewusst nicht weiter gegeben
export class FeaturePreSelectionsController extends FeatureSelectionsController {
	reduce(state = {}, action) {
		state = baseReducer(state, action);
		if (action.type === ACTION_SET && action.path.length === 2 && action.path[0] === FEATURE_SELECTION_SELECT &&
			(
				(Array.isArray(action.value) && action.value.length > 0) ||
				(action.value && !Array.isArray(action.value))
			)
		) {
			state = baseReducer(state, {...action, path: [FEATURE_SELECTION_PRESELECT, action.path[1]]}); // use baseReducer here
		}
		return state;
	}
}
