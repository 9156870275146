import {di, updateProxyObject} from '@mapsight/ol-proxy';

import DrawInteraction from '../../lib/map/lib/DrawInteraction';

function createOrUpdateSource(drawInteraction, oldDefinition, newDefinition, mapController) {
	updateProxyObject({
		di: di,
		group: 'source',
		oldObject: drawInteraction.getSource(),
		oldDefinition: oldDefinition,
		newDefinition: newDefinition,
		parentObject: drawInteraction,
		remover: () => {
			drawInteraction.setSource(null);
		},
		adder: source => {
			if (source.setMapController) {
				source.setMapController(mapController);
			}

			drawInteraction.setSource(source);
		},
	});
}

export default {
	type: 'interaction',
	name: 'Draw',
	Constructor: DrawInteraction,
	eventMap: {
		drawend: 'DrawEvent',
		drawstart: 'DrawEvent',
	},
	optionMap: {
		//type: (interaction, _, __, newDefinition) => interaction.setType(newDefinition),
		source: (interaction, _, oldDefinition, newDefinition, {parentObject: mapController}) =>
			createOrUpdateSource(interaction, oldDefinition, newDefinition, mapController),
		style: (interaction, _, __, style, {parentObject: mapController}) => {
			const styleFunction = mapController.createStyleFunction(typeof style === 'string' ? {style: style} : style);
			interaction.setStyle(styleFunction);
		},

		measure: 'setMeasure',
		active: 'setActive',
		replacePrevious: 'setReplacePrevious',
		clearOnStart: 'setClearOnStart',

		// TODO: condition
		// TODO: finishCondition
		// TODO: freehandCondition
		// TODO: geometryFunction
	},
	initialOptionMap: {
		type: 'type',
		clickTolerance: 'clickTolerance',
		dragVertexDelay: 'dragVertexDelay',
		freehand: 'freehand',
		//geometryName: 'geometryName', fixed
		maxPoints: 'maxPoints',
		minPoints: 'minPoints',
		snapTolerance: 'snapTolerance',
		stopClick: 'stopClick',
		wrapX: 'wrapX',
	},
};
