import {createSelector} from 'reselect';

function filter({features = [], max}) {
	if (max) {
		features = features.slice(0, max);
	}

	return features;
}

export function getFilteredFeatures(featureSelection) {
	return featureSelection && filter(featureSelection);
}

const featureSelectionSelectors = {};

export function createFeatureSelectionSelector(controllerName, selectionId) {
	const cacheKey = controllerName + '|' + selectionId;

	if (!featureSelectionSelectors[cacheKey]) {
		featureSelectionSelectors[cacheKey] = createSelector(
			state => state[controllerName],
			featureSelections => featureSelections && featureSelections[selectionId]
		);
	}

	return featureSelectionSelectors[cacheKey];
}

const allFeaturesSelectors = {};

export function createAllFeatureIdsSelector(controllerName, selectionId) {
	const cacheKey = controllerName + '|' + selectionId;

	if (!allFeaturesSelectors[cacheKey]) {
		allFeaturesSelectors[cacheKey] = createSelector(
			createFeatureSelectionSelector(controllerName, selectionId),
			featureSelection => (featureSelection && featureSelection.features) || []
		);
	}

	return allFeaturesSelectors[cacheKey];
}
