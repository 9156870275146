import React, {memo, useState, useCallback} from 'react';
import ReactModal from 'react-modal/lib/components/Modal';

import {useSelector} from 'react-redux';

import {haveSearchInMapSelector, isViewMobileOrMapOnlySelector} from '../../store/selectors';

import Search from '../search';
import {translate} from '../../helpers/i18n';

function SearchOverlay() {
	const [isExpanded, setIsExpanded] = useState(false);

	const searchInMap = useSelector(haveSearchInMapSelector);
	const isMobileOrMapOnly = useSelector(isViewMobileOrMapOnlySelector);

	const toggleIsExpanded = useCallback(
		() => {
			setIsExpanded((prevVal) => !prevVal);
		},
		[setIsExpanded],
	);

	const closeSearch = useCallback(
		() => {
			setIsExpanded(false);
		},
		[setIsExpanded],
	);

	if (searchInMap === false) {
		return null;
	}

	let children;
	if (isMobileOrMapOnly) {
		children = (
			<div>
				<button
					type="button"
					className="ms3-map-overlay__button ms3-map-overlay__button--with-icon ms3-map-overlay__button--search"
					onClick={toggleIsExpanded}
				>
						<span className="ms3-map-overlay__button__label">
							<span className="visuallyhidden">
								{isExpanded && translate('ui.map-overlay.search.close')}
								{!isExpanded && translate('ui.map-overlay.search.open')}
							</span>
						</span>
				</button>

				<ReactModal
					isOpen={isExpanded}
					// contentLabel="onRequestClose Example" // aria-label="" on .ms3-modal
					onRequestClose={closeSearch}
					className="ms3-modal ms3-modal--fixed-height"
					overlayClassName="ms3-app-overlay"
					shouldCloseOnOverlayClick={true}
				>
					<div className="ms3-modal__inner">
						<Search onSelect={closeSearch} />

						<button
							className="ms3-dialog-close-button"
							type="button"
							onClick={closeSearch}
						>
								<span className="visuallyhidden">
									{translate('ui.map-overlay.search.close')}
								</span>
						</button>
					</div>
				</ReactModal>
			</div>
		);
	} else {
		children = <Search />;
	}

	return (
		<div className={`ms3-search-overlay ${isExpanded ? 'ms3-search-overlay--expanded' : ''}`}>
			{children}
		</div>
	);
}

export default memo(SearchOverlay);
