export const VIEW_DESKTOP = 'desktop';
export const VIEW_MOBILE = 'mobile';

export const VIEW_FULLSCREEN = 'fullscreen';
export const VIEW_MAP_ONLY = 'mapOnly';

/**
 * @typedef {VIEW_DESKTOP | VIEW_MOBILE | VIEW_FULLSCREEN | VIEW_MAP_ONLY} View
 */

export const DETAILS_CONTENT_STATE_KEY = 'featureItemDetailsContent';

export const ZOOM_IN = 'zoomIn';
export const ZOOM_OUT = 'zoomOut';
