import merge from 'lodash/merge';

import createDefaultPlugins from '../plugins/browser-defaults';
import browserDomRenderer from '../renderer/browser-dom';

import {create} from '../index';

export default function browserEmbed(container, {styleFunction, baseMapsightCoreConfig = {}, preset = {}, embedUiState = {}, embedOptions = {}}) {
	const createOptions = determineCreateOptions(container, embedOptions, embedUiState);

	if (process.env.NN_JS_LOG_LEVEL === 'verbose') {
		console.log('v-- mapsight embed container:', container, 'preset:', preset, 'createOptions', createOptions);
	}

	const initialRendererProps = {mapsightCorePreset: preset.mapsight};
	const {render: internalRender} = create(container, styleFunction, baseMapsightCoreConfig, createOptions);

	function render(rendererProps = {}) {
		return internalRender(rendererProps);
	}

	// initial render
	render(initialRendererProps);

	if (process.env.NN_JS_LOG_LEVEL === 'verbose') {
		console.log('^-- mapsight embed after call to render');
	}

	return render;
}

/**
 * Determines the create options for the given parameters
 * @param {HTMLElement} container the container element to render the app into
 * @param {EmbedOptions} [embedOptions] optional embed options
 * @param {Object} embedUiState initial ui state
 * @return {CreateOptions} create options
 */
function determineCreateOptions(container, embedOptions = {}, embedUiState = {}) {
	const {
		// these options will be processed by the embed code:
		hook, // legacy alias "hook"
		defaultPluginsOptions,
		dehydratedStateAttributeName = 'data-dehydrated-state',
		// the rest will be passed to create():
		...createOptions
	} = embedOptions;

	createOptions.renderer = createOptions.renderer || browserDomRenderer;
	createOptions.plugins = [...createDefaultPlugins(defaultPluginsOptions), ...(createOptions.plugins || [])];

	if (hook) {
		createOptions.plugins.push(['uiEmbedPlugin', {afterCreate: hook}]);
	}

	if (!createOptions.reHydratedState && dehydratedStateAttributeName && container) {
		pullDeHydratedStateFromContainer(createOptions, dehydratedStateAttributeName, container);
	}
	createOptions.uiState = merge({}, createOptions.uiState || {}, embedUiState);

	return createOptions;
}

function pullDeHydratedStateFromContainer(createOptions, dehydratedStateAttributeName, container) {
	if (container.hasAttribute(dehydratedStateAttributeName)) {
		try {
			createOptions.reHydratedState = JSON.parse(container.getAttribute(dehydratedStateAttributeName));
			container.setAttribute(dehydratedStateAttributeName, '');
		} catch (e) {
			console.error('mapsight ui: error reading dehydrated state from container', e);
		}
	} else {
		if (process.env.NN_JS_LOG_LEVEL === 'verbose') {
			console.info('mapsight ui: could not read dehydrated state from container');
		}
	}
}
