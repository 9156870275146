"use strict";

exports.__esModule = true;
exports["default"] = flattenActions;

var _flatten = _interopRequireDefault(require("lodash/flatten"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Flattens (deeply) batched actions into a flat array of actions
 *
 * @param {object} action the root action
 * @returns {Array<object>} flattened array of actions
 */
function flattenActions(action) {
  if (action && action.meta && action.meta.batch === true) {
    return (0, _flatten["default"])(action.payload.map(flattenActions));
  }

  return [action];
}

module.exports = exports.default;