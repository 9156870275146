import {di} from '@mapsight/ol-proxy';

/**
 * Passes emitted events, as defined in the dependency `eventMap` from the openlayers observable object to the
 * map controller, which re-emits it, using a schema of `${group}.${id}.${eventType}` as event names.
 *
 * For example a `drawend` event emitted by the draw interaction with id myDraw1 will be re-emitted by the map
 * controller as `interaction.myDraw1.drawend`. The openlayers event object will be passed as well as any other
 * additional arguments.
 *
 * @param { import('../controller.js').MapController } mapController map controller
 * @param { import('ol/observable') } object the observable openlayers object
 * @param {string} type the ol-proxy dependency name/type (eg. `Draw` or `KML`)
 * @param {string} [id="default"] the identifier of the object in context of the map controller
 * @param {string|null} [group] the ol-proxy dependency group (eg. `interaction`, `layer`)
 */
function proxyPassOpenLayersEventsToMapController(mapController, object, type, id = 'default', group = null) {
	const {eventMap} = di.getDependency(group, type);

	if (eventMap) {
		const prefix = group ? `${group}.${id}` : id;
		object.on(Object.keys(eventMap), function handleProxyPassOpenLayersEventToMapController(event, ...args) {
			mapController.emit(`${prefix}.${event.type}`, event, ...args);
		});
	}
}

export default proxyPassOpenLayersEventsToMapController;
