/* eslint-disable no-eq-null */
import Cache from '@neonaut/lib-js/es/adt/LRUMap';

import deriveGeometriesFromBase from '../geometry/deriveGeometriesFromBase';

import declarationToGeometry from './declarationToGeometry';
import declarationToStyle from './declarationToStyle';

const TYPE_GEOMETRY_COLLECTION = 'GeometryCollection';
const STYLE_ENV_FIELD_NAME = 'style';
const DEFAULT_STYLE = 'default';
const HASH_STRING_DELIMITER = '|';

const DEFAULT_ROOT_STYLE_TYPE = 'style';

const DEFAULT_CACHE_LEVEL_1_SIZE = 100;
const DEFAULT_CACHE_LEVEL_2_SIZE = 100;

const createPropsFilter = allowedProps => (
	allowedProps === false ?
		props => props :
		function filterProps(props) {
			const filteredProps = {};
			allowedProps.filter(key => props[key]).forEach(key => {
				filteredProps[key] = props[key];
			});

			return filteredProps;
		}
);

/**
 * Creates a cached mapsight style function for openlayers
 *
 * @param {object<string, function>} options.constructorsMap map of style constructors
 * @param {function(object, object, string, string, string): string} options.declarationHashFunction style declaration hash function
 * @param {function(object, object, string, string): object} options.declarationFunction style declaration function
 * @param {String[]|boolean} [options.allowedProps=false] list of props allowed, false = all allowed
 * @param {String[]|boolean} [options.allowedStyles=false] list of styles allowed, false = all allowed
 * @param {int} [options.cacheLevel1Size=100] size of first level cache that caches feature geometry styles based on feature and environment state
 * @param {int} [options.cacheLevel2Size=100] size of second level cache that caches style objects based on the rules that apply and the environment state
 *
 * @return {function(env: object={}, feature: GeoJSONFeature|object)} style function
 */
export default function createCachedStyleFunction(options) {
	const {
		constructorsMap,
		declarationHashFunction,
		declarationFunction,
		allowedProps = false,
		allowedStyles = false,
		cacheLevel1Size = DEFAULT_CACHE_LEVEL_1_SIZE,
		cacheLevel2Size = DEFAULT_CACHE_LEVEL_2_SIZE,
	} = options;
	const filterProps = createPropsFilter(allowedProps);

	const cacheLevel1 = new Cache(cacheLevel1Size); // the first level cache caches feature geometry styles based on feature and environment state
	const cacheLevel2 = new Cache(cacheLevel2Size); // the second level cache caches style objects based on the rules that apply and the environment state

	function level1(env, props, envHash, propsHash, geometryType) {
		const cacheHashL1 = envHash + HASH_STRING_DELIMITER + geometryType + HASH_STRING_DELIMITER + propsHash;
		if (cacheLevel1.has(cacheHashL1)) {
			return cacheLevel1.get(cacheHashL1);
		}

		const cacheEntry = level2(env, props, envHash, propsHash, geometryType);
		cacheLevel1.set(cacheHashL1, cacheEntry);
		return cacheEntry;
	}

	function level2(env, props, envHash, propsHash, geometryType) {
		const envStyle = env[STYLE_ENV_FIELD_NAME];
		const styleName = allowedStyles === false ? envStyle : allowedStyles.indexOf(envStyle) > -1 ? envStyle : DEFAULT_STYLE;
		const cacheHashL2 = declarationHashFunction(env, props, envHash, geometryType, styleName);
		if (cacheLevel2.has(cacheHashL2)) {
			return cacheLevel2.get(cacheHashL2);
		}

		const declarations = declarationFunction(env, props, geometryType, styleName);
		const cacheEntry = Object.keys(declarations).map(group => ({
			style: declarationToStyle(constructorsMap, declarations[group], DEFAULT_ROOT_STYLE_TYPE, group + ',' + cacheHashL2, cacheLevel2),
			geometry: declarationToGeometry(declarations[group]),
		}));
		cacheLevel2.set(cacheHashL2, cacheEntry);

		return cacheEntry;
	}

	function styleGeometryOrGeometryCollectionCached(env, props, envHash, propsHash, geometryOrGeometryCollection, i = 0) {
                if(!geometryOrGeometryCollection)
                        return undefined; // hopefully this gets removed by a .flat()

		const geometryType = geometryOrGeometryCollection.getType();

		// recurse through collections
		if (geometryType === TYPE_GEOMETRY_COLLECTION) {
			return geometryOrGeometryCollection.getGeometries()
				.map(geometry => styleGeometryOrGeometryCollectionCached(env, props, envHash, propsHash, geometry, i + 1))
				.flat();
		}

		return level1(env, props, envHash, propsHash, geometryType)
			.filter(({style}) => style != null)
			.map(function bindStyleToBaseGeometry(item) {
				const {style: baseStyle, geometry: geometryDerivation} = item;
				const derivedGeometries = deriveGeometriesFromBase(geometryOrGeometryCollection, geometryDerivation);

				return derivedGeometries.map(function bindDerivedGeometryToStyle(geometryWithMeta) {
					const [geometry, meta] = geometryWithMeta;
					const style = baseStyle.clone();
					style.setGeometry(geometry);

					if (meta && meta.rotation != null && style.getImage()) {
						const imageStyle = style.getImage().clone();
						const baseRotation = imageStyle.getRotation();
						imageStyle.setRotation(baseRotation + meta.rotation);
						style.setImage(imageStyle);
					}
					return style;
				});
			})
			.flat();
	}

	return function cachedStyleFunction(env = {}, feature) {
		// subset of feature properties that are relevant for styling (also used for caching decisions)
		const filteredProps = filterProps(feature.getProperties());

		return styleGeometryOrGeometryCollectionCached(
			env,
			filteredProps,
			JSON.stringify(env),
			JSON.stringify(filteredProps),
			feature.getGeometry()
		);
	};
}
