import isEqual from 'lodash/isEqual';
import DependencyManager from './DependencyManager';

export const OPTION_SET = '__set__';
export const OPTION_COLLECTION = '__collection__';
export const INITIAL_OPTION_PASS = '__pass__';

const isDef = (a, k) => a[k] !== undefined && a[k] !== null;
const isPropertyEqual = (a, b, k) => a && b && a[k] === b[k];

export const di = new DependencyManager();

// Untested idea to simplify injection:
//export function load(group, name) {
//	di.inject({[group]: {[name]: require(`./definitions/${group}/${name}`)}});
//}

/**
 * Updates a proxy openlayers object
 *
 * @param {ol.Object} target target object e.g. map or view
 * @param {object} oldOptions old options
 * @param {object} newOptions new options
 * @param {object} optionMap map
 * @param {object} parentObject parent object
 * @private
 */
export function setOptions(target, oldOptions, newOptions, optionMap, parentObject = null) {
	if (!target || !newOptions || !optionMap) {
		return;
	}

	Object.keys(optionMap)
		.filter(name => isDef(newOptions, name) && !isPropertyEqual(oldOptions, newOptions, name))
		.forEach(name => {
			const updater = optionMap[name];
			const newValue = newOptions[name];

			// ----
			// Uses early returns!
			// v

			// Case: direct set
			if (updater === OPTION_SET) {
				if (target.set) {
					target.set(name, newValue);
				} else {
					console.error(`cannot set option using updater: ${updater} (OPTION_SET)`);
				}
				return;
			}

			const updaterType = typeof optionMap[name];
			// Case: setter
			if (updaterType === 'string') {
				if (target[updater]) {
					target[updater](newValue);
				} else {
					console.error(`cannot set option using updater: ${updater} (${updaterType})`);
				}
				return;
			}

			// Case: callback
			if (updaterType === 'function') {
				// using a callback
				const oldValue = oldOptions && oldOptions[name];
				updater(target, name, oldValue, newValue, {
					oldOptions: oldOptions,
					newOptions: newOptions,
					optionMap: optionMap,
					parentObject: parentObject,
				});
				return;
			}

			const isArray = Array.isArray(updater);

			// Case: collection
			if (isArray && updater[0] === OPTION_COLLECTION) {
				const collection = target[updater[1]].call(target);

				// todo: improve by patching instead of replacing
				collection.clear();
				collection.extend(newValue);
				return;
			}

			// ^
			// ----
			// Default (if no early return)
			// v
			console.error(`missing support for updater: ${updater} (${updaterType})`);
		});

	if (target.changed && typeof target.changed === 'function') {
		target.changed();
	}
}


/**
 * Maps initial options for a proxy openlayers object
 *
 * @param {object} initialOptions initial options
 * @param {object} optionMap map
 * @returns {object} prepared initial options
 * @private
 */
function mapInitialOptions(initialOptions, optionMap) {
	if (!initialOptions || !optionMap) {
		return {};
	}

	const result = {};

	Object.keys(optionMap)
		.filter(name => isDef(initialOptions, name))
		.forEach(name => {
			const preparer = optionMap[name];
			const value = initialOptions[name];

			// ----
			// Uses early returns!
			// v

			// Case: true or pass
			if (preparer === true || preparer === INITIAL_OPTION_PASS) {
				result[name] = value;
				return;
			}

			const preparerType = typeof optionMap[name];
			// Case: string/number
			if (preparerType === 'string' || preparerType === 'number') {
				result[preparer] = value;
				return;
			}

			// Case: callback
			if (preparerType === 'function') {
				Object.assign(result, preparer(value));
				return;
			}

			// ^
			// ----
			// Default (if no early return)
			// v
			console.error(`missing support for preparer: ${preparer} (${preparerType})`);
		});

	return result;
}

/**
 * @param {DependencyManager} di dependency manager
 * @param {String} group dependency group
 * @param {String} [targetName] target option name, defaults to group
 *
 * @returns {function} dependency mapper
 */
export function createDependencyMapper(di, group, targetName = null) {
	targetName = targetName || group;

	return ({type, options}) => {
		const objectDependency = di.getDependency(group, type);

		if (!objectDependency) {
			console.error(`dependency mapper could not get dependency: ${group}/${type}.`);
			return {};
		}

		const objectOptions = mapInitialOptions(options, objectDependency.initialOptionMap);
		return {[targetName]: di.makeInstance(group, type, objectOptions)};
	};
}

function createOrReplaceObject(di, group, name, oldObject, args, remover = null, adder = null, parentObject = null) {
	if (oldObject && remover) {
		remover(oldObject, parentObject);
	}

	// create new object
	const object = di.makeInstance(group, name, ...args);
	if (object && adder) {
		adder(object, parentObject);
	}

	return object;
}

function removeObject(oldObject, remover = null, parentObject = null) {
	if (oldObject && remover) {
		remover(oldObject, parentObject);
	}
}

function hasSomeOptionChanged(optionMap, newOptions, oldOptions) {
	return Object.keys(optionMap).some(key => !isEqual(newOptions[key], oldOptions[key]));
}

function checkOptionsRequireNewCreation(oldOptions, newOptions, initialOptionMap, optionMap) {
	return (
		(!oldOptions && !!newOptions) || (
			oldOptions !== newOptions &&
			Object.keys(initialOptionMap)
				.some(key => !optionMap.hasOwnProperty(key) && !isEqual(newOptions[key], oldOptions[key]))
		)
	);
}

function getLeftDistinctValues(leftObject, rightObject) {
	const leftValues = {};
	Object.keys(leftObject).forEach(key => {
		if (!rightObject.hasOwnProperty(key) || rightObject[key] === INITIAL_OPTION_PASS) {
			leftValues[key] = leftObject[key];
		}
	});

	return leftValues;
}

export function updateProxyObject({di, group, oldObject, oldDefinition, newDefinition, remover, adder, parentObject}) {
	if (!newDefinition) {
		//console.debug('ol-proxy: Removed object ', {group, oldObject, oldDefinition, parentObject});
		removeObject(oldObject, remover, parentObject);
		return;
	}

	const name = newDefinition.type || oldDefinition && oldDefinition.type;

	if (!name) {
		console.error('ol-proxy: Cannot update proxy object. Definition is insufficient. Missing type.');
		return;
	}

	const dependency = di.getDependency(group, name);

	if (!dependency) {
		console.error(`ol-proxy: Cannot update proxy object. Dependency unknown/not injected: ${group}/${name}.`);
		return;
	}

	const {optionMap = {}, initialOptionMap = {}} = dependency;
	const oldOptions = oldDefinition && oldDefinition.options;
	const newOptions = newDefinition && newDefinition.options;

	const optionsRequireNewCreation = checkOptionsRequireNewCreation(oldOptions, newOptions, initialOptionMap, optionMap);
	if (optionsRequireNewCreation || !di.checkObjectType(group, name, oldObject)) {
		const initialOptions = newOptions ? mapInitialOptions(newOptions, initialOptionMap) : {};
		const obj = createOrReplaceObject(di, group, name, oldObject, [initialOptions], remover, adder, parentObject);
		//console.debug('ol-proxy: Created object ', optionsRequireNewCreation ? 'because of changed options that require construction' : 'because the type has changed', {
		//	group,
		//	name,
		//	newDefinition,
		//	oldObject,
		//	oldDefinition,
		//	parentObject
		//});

		if (newOptions) {
			setOptions(obj, {}, getLeftDistinctValues(newOptions, initialOptionMap), optionMap, parentObject);
			//console.debug('ol-proxy: Set options on just created object ', {
			//	group,
			//	name,
			//	newDefinition,
			//	oldObject,
			//	oldDefinition,
			//	parentObject
			//});
		}
	} else if (newOptions && hasSomeOptionChanged(optionMap, newOptions, oldOptions)) {
		setOptions(oldObject, oldOptions, newOptions, optionMap, parentObject);
		//console.debug('ol-proxy: Update options ', {group, name, newDefinition, oldObject, oldDefinition, parentObject});
	}
}
