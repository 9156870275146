export {
	defaultProjections,
	mapViewCenter,
	mapViewExtent,
	mapView,
	map,
	features,
	featureList,
	timeFilter
} from '../src/js/config/index';
