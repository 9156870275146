import {createSelector} from '@reduxjs/toolkit';
import merge from 'lodash/merge';

import {setLayerVisibility} from '@mapsight/core/lib/map/actions';
import {FEATURE_SELECTIONS, MAP} from '@mapsight/ui/src/js/config/constants/controllers';

import DrawInteraction from '@mapsight/core/ol-proxy/definitions/DrawInteraction';
import ModifyInteraction from '@mapsight/core/ol-proxy/definitions/ModifyInteraction';
import VectorTileSource from '@mapsight/core/ol-proxy/definitions/VectorTileSource';

import {VIEW_FULLSCREEN, VIEW_MAP_ONLY, VIEW_MOBILE} from '@mapsight/ui/src/js/config/constants/app';

import {listQuerySelector, viewSelector} from '@mapsight/ui/src/js/store/selectors';
import {hideTagAndTagGroup, setView} from '@mapsight/ui/src/js/store/actions';

import {di as olProxyDi} from '@mapsight/ol-proxy';
import VectorTileLayer from '@mapsight/ol-proxy/definitions/layer/vectortile';
import MvtFormat from '@mapsight/ol-proxy/definitions/format/mvt';

import {getDictionary} from '@mapsight/ui/src/js/helpers/i18n';
import uiEmbed from '@mapsight/ui/embed';
import {siteConfig} from '@mapsight/ui/src/js/config';

// the file may not be generated when js-qa runs, so we ignore this:
// eslint-disable-next-line import/no-unresolved,import/extensions
import styleFunction from 'mapsight-vector-styles/vector-style.esm.js';

import {NavigationController, initialState as navigationInitialState} from '../modules/navigation/store/controller';
import {NAVIGATION} from '../modules/navigation/store/constants';

import renderAwaitRouteAndFeaturesCreatePlugin from './plugins/render-route-to-store-and-await-features';

import situationSortingPlaces from './config/situation-sorting-places.json';
import baseMapsightConfig from './config';

import {
	defaultVMZPagesState,
	FEATURE_LIST_TWO,
	ListTwoController,
	SPECIAL_PAGE_CONTENT,
	SPECIAL_PAGE_NAVIGATION,
	VMZPAGES,
	VMZPagesController,
} from './store/controller';
import {detailsFeatureSelector} from './store/selectors';

import AdditionalContainerContent from './components/additional-container-content';
import AppWrapperStart from './components/app-wrapper-start';
import MapOverlayModal from './components/map-overlay-modal';
import MapOverlayStart from './components/map-overlay-start';
import MapOverlayEnd from './components/map-overlay-end';
import MapOverlayTopRight from './components/map-overlay-top-right';
import MapOverlayBottomRight from './components/map-overlay-bottom-right';
import MapWrapper from './components/map-wrapper';
import FeatureDetailsContent from './components/feature-details-content';
import MainPanelContainer from './components/main-panel-container';
import TooltipContent from './components/tooltip-content';
import FeatureListGroupName from './components/feature-list-group-name';

import {NavposSelectionController} from './store/navpos-selection-controller';



// i18n
const {de, en/*, fr, es */} = getDictionary();
if (de) {
	de['ui.feature-list.sorting.byDistance'] = '';
	de['ui.feature-list.sorting.choose'] = 'Sortieren nach Entfernung von ...';
	de['ui.map-overlay.info.legend'] = 'Tastaturbedienung und vollständige Legende';
	de['ui.map-overlay.info.open'] = 'Tastaturbedienung, Legende und weitere Informationen anzeigen';
	de['ui.main-panel.list-toggle.close'] = 'Karte in Vollbild zeigen';
	de['ui.main-panel.list-toggle.open'] = 'Vollbild schließen';

	de['vmznds.map-overlay.reduce.turnon'] = 'Kartenmodus mit reduzierten Inhalten und hohem Kontrast aktivieren';
	de['vmznds.map-overlay.reduce.turnoff'] = 'Reduzierten Kartenmodus deaktivieren';

	// see Error in ../modules/navigation/types.ts
	de['navigation.network'] = 'Der Server meldet einen Fehler.<br />\n' +
		'Bitte versuchen Sie es später nochmal.<br />';
	de['navigation.unsupported'] = 'Bei dem gewählten Start und Ziel wird die Routenplanung nicht unterstützt.<br />';
	de['navigation.unsupported.bike'] = 'Bei dem gewählten Start und Ziel wird die Routenplanung nicht unterstützt.<br />' +
		'Bitte wählen Sie Start und Ziel innerhalb der Region Hannover<br />';
	// visuallyhidden für Screenreader:
	de['navigation.modality.car'] = 'PKW';
	de['navigation.modality.bike'] = 'Fahrrad';
	de['navigation.modality.public'] = 'Öffentlicher Personen-Nahverkehr';
	// sichtbar bei der Anzeige der Alternativen
	de['navigation.modality.per.car'] = 'per PKW';
	de['navigation.modality.per.bike'] = 'per Fahrrad';
	de['navigation.modality.per.public'] = 'per ÖPNV';

	// siehe qr-link.jsx
	de['navigation.qr.link-message.nunav'] = 'Nutzen Sie unsere Echtzeit-Navigation per NUNAV-App';
	de['navigation.qr.link-message.bikeCitizens'] = 'Nutzen Sie die offizielle Fahrrad-App der Region';
	de['navigation.qr.link-message.efa'] =  'Weitere Verbindungen und Tickets bietet die GVH-App';
	de['navigation.qr.name.nunav'] = 'NUNAV';
	de['navigation.qr.name.bikeCitizens'] = 'BikeCitizens';
	de['navigation.qr.name.efa'] = 'GVH';
}

if (en) {
	en['ui.feature-list.sorting.byDistance'] = '';
	en['ui.feature-list.sorting.choose'] = 'Sort by distance from ...';
	de['ui.map-overlay.info.legend'] = 'keyboard shortcuts and complete legend';
	en['ui.map-overlay.info.open'] = 'show keyboard shortcuts, legend and further information';
	en['ui.main-panel.list-toggle.close'] = 'switch map to full screen';
	en['ui.main-panel.list-toggle.open'] = 'close full screen';
}
// TODE fr/es fehlen eh noch in i18n und auch Teil von en + ausgliedern in eigene .js

function determineInitialClientRenderingView(currentBreakpoints) {
	// NOTE: This will only be used for client side only rendering, otherwise initial view will be determined by server!
	return currentBreakpoints.indexOf('mobile') > -1 ? VIEW_MOBILE : VIEW_FULLSCREEN;
}

siteConfig.topOffsetForView = function topOffsetForView(view) {
	if (view === VIEW_MOBILE) {
		return 50; // AreaSwitcher ist 42 hoch, der Menü-Knopf darüber 50. dh. ein Wert von 50 scrollt 8px oberhalb des Areaswitcher hin
	}
	return 0;
};

// // not available to users by now, but service is already implemented
// // TODO search box has to understand photon answers
// siteConfig.searchUrl = 'https://geo.neonaut.de/photon/';
// siteConfig.searchQueryParameter = 'q';


olProxyDi.inject({
	interaction: {
		Draw: DrawInteraction,
		Modify: ModifyInteraction,
	},
	source: {
		VectorTile: VectorTileSource,
	},
	layer: {
		VectorTile: VectorTileLayer,
	},
	format: {
		MVT: MvtFormat,
	},
});

// supply minimal default that may be overridden in the typo3-Blob but will be optional there
// auch deshalb hier definiert weil die areas geographisch von groß nach klein sortiert sein müssen
const MAGIC_DEFAULT = {
	areas: [
		{
			uri: 'bundesweit',
			name: 'Bundes\xADweit',
			bounds: [777000, 6331000, 1483000, 7222000],
		},
		{
			uri: 'niedersachsen',
			name: 'Nieder\xADsachsen',
			bounds: [804958, 6715004, 1224657, 7111299],
		},
		{
			uri: 'region-hannover',
			name: 'Region Hannover',
			bounds: [1028295, 6825333, 1150973, 6927598],
		},
		{
			uri: 'stadt-hannover',
			name: 'Stadt Hannover',
			bounds: [1073722, 6855183, 1097388, 6878779],
		},
	],
};

// used to define defaults specific to projects
// STATE_DEFAULT.app wird überschrieben von mapsights eigenen defaultUiState, stattdessen UI_STATE_DEFAULT nutzen
const STATE_DEFAULT = {
	featureSources: {},
	list: {
		visible: true,
	},
	map: {
		visible: true,
	},
};
const UI_STATE_DEFAULT = {
	viewDefaultDesktop: VIEW_FULLSCREEN,
	places: situationSortingPlaces,
	listSorting: '',
	embeddedMap: false, // map is embedded which switch some behaviours which would be annoying
	searchInMap: false,  // enable address search in map overlay
	list: {
		visible: true,
		selectionBehavior: {mobile: 'showInMapOnlyView'},
		scrollToItemOnPreselect: true,
		integratedList: true, // if true list will be left of the map on desktops even when not in fullscreen mode
		detailsInList: false, // if true details will always be shown in list even if not on mobile
		showSelectedOnly: false, // don't show list entries, only show the selected one (need some other kind of communication, cyclingControl or Icons on the map)
		deselectOnClick: false, // deselect on click to selected list item
		cyclingControl: false, // show a control to select next or previous list entry
		sortControl: false, // show sort control icon
		filterControl: false, // show filter box
		stickyHeader: false, // disable sticky list header
		renderAs: 'ul',
		renderItemAs: 'li',
		renderGroupAs: 'h2', // TODO set renderGroupAs to useful value
	},
	pageTitle: {
		show: false,
	},
	layerSwitcher: {
		show: {
			internal: true,
			external: false,
		},
		internal: {
			grouped: true,
		},
	},
	tagSwitcher: {
		toggleableGroups: true,
		sortTags: true,
	},
};

/**
 * @param {import("./types").Magic} origMagic
 * @returns {import("./types").Magic}
 */
export function processMagic(origMagic) {
	const magic = Object.assign({}, MAGIC_DEFAULT, origMagic);
	if (magic.topics) {
		magic.topics.forEach((topic) => {
			// apply fallbacks
			if (!topic.pageName) {
				topic.pageName = topic.name;
			}

			if (!topic.pageUri) {
				topic.pageUri = topic.uri;
			}

			// SPECIAL_PAGE_CONTENT is used internally only for magic.additionalContent, so we have to stop topics using it.
			if (topic.type === SPECIAL_PAGE_CONTENT) {
				topic.type = ''; // user has to choose beetween SPECIAL_PAGE_CONTENTLIST or SPECIAL_PAGE_CONTENTSUM;
			}

			if(topic.type === SPECIAL_PAGE_NAVIGATION) {
				topic.deepLinks = true;
			}
		});
	}

	if (magic.additionalContent) {
		magic.additionalContent.forEach((ac) => {
			// apply fallbacks
			if (!ac.pageName) {
				ac.pageName = ac.name;
			}
		});
	}

	return magic;
}

const losVisibleSelector = createSelector(
	state => state[MAP].layers.los,
	_ => _ && _.options && _.options.visible
);

const haveAnomaliesSelector = createSelector(
	state => state[MAP].layers,
	_ => !!_.anomalies
);

/**
 * @param {{magic: import("./types").Magic, uri: string, state?: object}} options
 */
export default function embed({magic, uri: locPathName, state}) {
	const embedOptions = {
		imagesUrl: '/assets/img/',
		plugins: typeof window !== 'undefined' ? [
			['corePreset', null],
		] : [
			// for server side rendering we want to execute routeToStore first and then wait for the lists feature sources as well as feature details to be loaded
			['route', renderAwaitRouteAndFeaturesCreatePlugin({magic, locPathName})],
			['corePreset', null],
		],
		controllers: {
			[FEATURE_SELECTIONS]: new NavposSelectionController(FEATURE_SELECTIONS, magic),
			// add second ListController
			[FEATURE_LIST_TWO]: new ListTwoController(FEATURE_LIST_TWO),
			[VMZPAGES]: new VMZPagesController(VMZPAGES),
			[NAVIGATION]: new NavigationController(NAVIGATION),
		},
		hook({store, controllers}) {
			if (process.env.NN_JS_LOG_LEVEL === 'verbose') {
				console.warn('debug: hook(controllers):', controllers);
			}

			let detailsFeature = undefined;

			let listQuery = undefined;
			let losVisible = undefined;

			store.subscribe(() => {
				const storeState = store.getState();

				// clear tag selection when `listQuery` changes
				const prevListQuery = listQuery;
				listQuery = listQuerySelector(storeState);
				if (listQuery !== prevListQuery) {
					store.dispatch(hideTagAndTagGroup());
				}

				// wenn die liste nach klick auf einem POI angezeigt werden soll:
				// → diese coder muss in eine Komponente in additionalContent (analog zum RouteToStore)
				// → die Komopnente muss per useNav() die aktuelle area festellen
				// → geschaltet wird dann {area}-anomalies entsprechend losVisible, für alle anderen areas zugleich auf false
				const oldLosVisible = losVisible;
				losVisible = losVisibleSelector(storeState);
				if (oldLosVisible !== losVisible && haveAnomaliesSelector(storeState)) {
					store.dispatch(setLayerVisibility(MAP, 'anomalies', losVisible));
				}


				// wenn es eine _gültige_ selection gibt (z.B. über die URL bei topic.deepLink===true)
				// dann darf der view nicht VIEW_MOBILE sein
				detailsFeature = storeState[FEATURE_SELECTIONS] && detailsFeatureSelector(storeState); // this is called very early so it need an addtional test to protect the selector
				if (
					detailsFeature
					&& viewSelector(storeState) === VIEW_MOBILE
				) {
					store.dispatch(setView(VIEW_MAP_ONLY));
				}
			});
		},
		/** @type {import("react").Context<import("@mapsight/ui/src/js/components/contexts.js").Components>} */
		components: {
			AppWrapperStart, // enhält Kopf für VIEW_MOBILE inkl. <Content />
			AdditionalContainerContent, // RouteToStore + Inhalt unterhalb der Karte
			//MainPanelStart,
			MainPanelContainer, // enthält Gestaltung POI bei MAP_ONLY (auf Handy) und für Desktop der seitlichen Boxen
			MapOverlayModal, // nur nötig für reduced mode, fügt entsprechende CSS-Klasse ein
			MapOverlayStart, // enthält kleines Logo bei nur-Karten-Ansichten,  AreaSwitcher und Content für Desktop-Seiten
			MapOverlayEnd, // Legende und Info-Overlay
			MapOverlayTopRight, // vmz-spezifischer ListToggleButton (aka Fullscreen-Button)
			MapOverlayBottomRight, // Zoom und Geo-Location
			MapWrapper,
			FeatureDetailsContent,
			TooltipContent,
			FeatureListGroupName,
		},
		// ...embedOptions,  // removed as not allowed for pages with VMZNDS2020
		defaultPluginsOptions: {
			views: {
				determineInitialView: determineInitialClientRenderingView
			},
			userGeolocation: {
				storeInLocalStorage: true,
			},
			piwikTrackFullscreenToggleEvent: {
				disabled: true
			},
		},
	};

	const mergedState = merge(
		{},
		STATE_DEFAULT,
		baseMapsightConfig(magic, locPathName),
		{
			[VMZPAGES]: defaultVMZPagesState,
			list: {
				featureSource: 'niedersachsen-verkehrslage', // overwritten in RouteToStore
			},
			listTwo: {
				featureSource: 'niedersachsen-baustellen', // overwritten in RouteToStore
			},
			[NAVIGATION]: navigationInitialState,
		}
	);

	return uiEmbed(
		styleFunction,
		mergedState,
		{},
		Object.assign({}, UI_STATE_DEFAULT, state ? state.app : undefined),
		embedOptions,
	);
}
