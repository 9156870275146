import {di, INITIAL_OPTION_PASS, OPTION_COLLECTION, updateProxyObject} from '@mapsight/ol-proxy';
import base from '@mapsight/ol-proxy/definitions/interaction/pointer';

import ModifyInteraction from '../../lib/map/lib/ModifyInteraction';

function createOrUpdateSource(interaction, oldDefinition, newDefinition, mapController) {
	updateProxyObject({
		di: di,
		group: 'source',
		oldObject: interaction.getSource(),
		oldDefinition: oldDefinition,
		newDefinition: newDefinition,
		parentObject: interaction,
		remover: () => {
			interaction.setSource(null);
		},
		adder: source => {
			if (source.setMapController) {
				source.setMapController(mapController);
			}
			if (source.setActive) {
				source.setActive(true);
			}

			interaction.setSource(source);
		},
	});
}

export default {
	type: 'interaction',
	name: 'Modify',
	Constructor: ModifyInteraction,
	optionMap: {
		...base.optionMap,
		features: OPTION_COLLECTION,
		source: (interaction, _, oldDefinition, newDefinition, {parentObject: mapController}) =>
			createOrUpdateSource(interaction, oldDefinition, newDefinition, mapController),
		style: (interaction, _, __, style, {parentObject: mapController}) => {
			const styleFunction = mapController.createStyleFunction(typeof style === 'string' ? {style: style} : style);
			interaction.setStyle(styleFunction);
		},
		active: 'setActive',
	},
	initialOptionMap: {
		...base.initialOptionMap,
		source: INITIAL_OPTION_PASS,
		condition: true, // TODO function
		deleteCondition: true, // TODO function
		insertVertexCondition: true, // TODO function
		pixelTolerance: true,
		wrapX: true,
	},
};
