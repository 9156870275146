import reducers from '@neonaut/lib-redux/es/reducers/immutable-path';

export const ACTION_NOOP = 'MAPSIGHT_NOOP';
export const ACTION_SET = 'MAPSIGHT_SET';
export const ACTION_ADD_TO = 'MAPSIGHT_ADD_TO';
export const ACTION_MERGE = 'MAPSIGHT_MERGE';
export const ACTION_REMOVE_FROM = 'MAPSIGHT_REMOVE_FROM';

const reducerMap = {
	[ACTION_NOOP]: reducers.noop,
	[ACTION_SET]: reducers.set,
	[ACTION_ADD_TO]: reducers.addTo,
	[ACTION_MERGE]: reducers.merge,
	[ACTION_REMOVE_FROM]: reducers.removeFrom,
};

export function baseReducer(state, action) {
	return ((action && action.type && reducerMap[action.type]) || reducerMap[ACTION_NOOP])(state, action);
}
