import {applyMiddleware, compose, createStore as reduxCreateStore} from 'redux';
import {batchDispatchMiddleware} from 'redux-batched-actions';
import forEach from 'lodash/forEach';
import mapValues from 'lodash/mapValues';

import createPrefixedAsyncActionMiddleware from '@neonaut/lib-redux/es/create-prefixed-async-action-middleware';
import enableAsyncDispatch from '@neonaut/lib-redux/es/enable-async-dispatch';
import enableControlledDispatchAndObserve from '@neonaut/lib-redux/es/enable-controlled-dispatch-and-observe';

import createFilteredReducerForPath from '@neonaut/lib-redux/es/create-filtered-reducer-for-path';
import combineReducersExt from '@neonaut/lib-redux/es/combine-reducers-ext';

import {ASYNC_ACTION_FLAG, CONTROLLED_ACTION_FLAG, STATE_PATH_KEY} from './lib/base/actions';
import defaultSanitizeAction from './redux-devtools/defaultSanitizeAction';
import defaultPredicate from './redux-devtools/defaultPredicate';

/**
 * Creates an enhanced redux store, which contains all the state of an mapsight application.
 *
 * @param {object<string, import('./lib/base/controller')>} controllers map of controller instances
 * @param {ReducersMapObject} [appReducers] optional map of key => Reducer to add
 * @param {object} [preLoadedState] optional plain object pre-loaded state to be merged
 * @param {import('redux').GenericStoreEnhancer} [appEnhancer] optional Enhancer to compose
 * @param {object} [options] additional options for the store creation, see below:
 * @param {object} [options.reduxDevToolsOptions] redux dev tools options, @see https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md
 * @returns {Store<*>} created, enhanced, store
 */
export function createMapsightStore(controllers, appReducers = {}, preLoadedState = {}, appEnhancer = null, options = {}) {
	const {reduxDevToolsOptions = {}} = options;

	// Get reducers from controllers bound to the controller
	const coreReducers = mapValues(controllers, (controller, key) => createFilteredReducerForPath(controller.createReducer(), key, STATE_PATH_KEY));

	const reducer = combineReducersExt({
		...coreReducers,
		...appReducers,
	});

	let composeEnhancers = compose;

	// Enable redux dev tools
	if (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
		composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
			trace: true,
			actionSanitizer: defaultSanitizeAction,
			predicate: defaultPredicate,
			...reduxDevToolsOptions,
		});
	}

	const coreEnhancer = createStore => (...args) => {
		const store = createStore(...args);
		enableAsyncDispatch(store, ASYNC_ACTION_FLAG);
		enableControlledDispatchAndObserve(store, CONTROLLED_ACTION_FLAG);
		store.getController = name => controllers[name];

		return store;
	};
	const coreMiddlewareEnhancer = applyMiddleware(createPrefixedAsyncActionMiddleware(ASYNC_ACTION_FLAG), batchDispatchMiddleware);

	const enhancers = [coreEnhancer, appEnhancer, coreMiddlewareEnhancer].filter(enhancer => !!enhancer);
	const enhancer = composeEnhancers(...enhancers);

	const store = reduxCreateStore(reducer, preLoadedState, enhancer);

	// bind controllers to store
	forEach(controllers, controller => controller.bindToStore(store));

	// after all are bound, init each
	forEach(controllers, controller => controller.init());

	return store;
}
