function strictEqualCompare(a, b) {
	return a === b;
}

/**
 * Enhances the store. Allows actions to be flagged as controlled and adds a new function
 * store.observeUncontrolled(selector, onChange, compare) to the store.
 *
 * @param {object} store redux store
 * @param {string|symbol} controlledActionFlag flag that controlled actions expose (action[controlledActionFlag] == true)
 */
export default function enableControlledDispatchAndObserve(store, controlledActionFlag = 'isControlled') {
	let listeners = [];

	function isControlled(action) {
		return action.meta && action.meta[controlledActionFlag];
	}

	function removeListener(listener) {
		listeners = listeners.filter(l => l !== listener);
	}

	function addListener(listener) {
		listeners.push(listener);
	}

	function subscribeUncontrolled(listener) {
		addListener(listener);

		return function removeListenerBound() {
			removeListener(listener);
		};
	}

	function observeUncontrolled(selector, onChange, compare = strictEqualCompare) {
		return subscribeUncontrolled(function handleUncontrolledChange(previousState, state) {
			const previousValue = selector(previousState);
			const newValue = selector(state);

			if (!compare(previousValue, newValue)) {
				onChange(newValue, previousValue, state);
			}
		});
	}

	let wasControlled = false;
	const baseDispatch = store.dispatch;

	function enhancedDispatch(action, ...args) {
		wasControlled = isControlled(action);
		return baseDispatch(action, ...args);
	}

	let previousState = store.getState();

	function listenForUncontrolledActions() {
		const state = store.getState();

		if (!wasControlled) {
			listeners.forEach(function callUncontrolledChangeListener(listener) {
				listener(previousState, state);
			});
		}

		previousState = state;
	}

	store.subscribe(listenForUncontrolledActions);

	Object.assign(store, {
		dispatch: enhancedDispatch,
		subscribeUncontrolled: subscribeUncontrolled,
		observeUncontrolled: observeUncontrolled,
	});
}
