import unique from 'lodash/uniq';
import Layer from 'ol/layer/layer';

export default function getVisibleLayersFromFramestate(frameState) {
	return unique(
		frameState.layerStatesArray
			.filter(layerState => Layer.visibleAtResolution(layerState, frameState.viewState.resolution))
			.map(({layer}) => layer)
	);
}
