import {useEffect} from 'react';
import {updateMapSize} from '@mapsight/core/lib/map/actions';

import {VIEW_MAP_ONLY, VIEW_MOBILE} from '../config/constants/app';
import {MAP} from '../config/constants/controllers';

import usePrevious from './usePrevious';

export default function useUpdateMapSizeOnViewChange(view, store) {
	const prevView = usePrevious(view);

	useEffect(function () {
		const didViewChangeBetweenMobileAndMapOnly = (
			prevView === VIEW_MOBILE && view === VIEW_MAP_ONLY ||
			view === VIEW_MOBILE && prevView === VIEW_MAP_ONLY
		);
		const options = didViewChangeBetweenMobileAndMapOnly ? {
			from: 'below',
			to: 'below',
			reCenter: false,
		} : undefined;

		if (process.env.NN_JS_LOG_LEVEL === 'verbose') {
			console.debug('ms.ui.components.App.effect -> dispatch update map size ', {
				options: options,
				didViewChangeBetweenMobileAndMapOnly: didViewChangeBetweenMobileAndMapOnly,
				view: view,
				prevView: prevView,
			});
		}
		store.dispatch(updateMapSize(MAP, options));
	}, [prevView, view, store]);
}
