import olProj from 'ol/proj';

import updateSizeOnTransitionEnd from '@mapsight/lib-ol/map/updateSizeOnTransitionEnd';
import canvasSizeFixer from '@mapsight/lib-ol/map/canvasSizeFixer';

import {BaseController} from '../base/controller';
import {ProjectionsController} from '../projections/controller';

import withAnchoredViewport from './lib/withAnchoredViewport';
import withAnimations from './lib/withAnimations';
import withClusterFeatureFunction from './lib/withClusterFeatureFunction';
import withControls from './lib/withControls';
import withCursor from './lib/withCursor';
import withFeatureInteractions from './lib/withFeatureInteractions';
import withInteractions from './lib/withInteractions';
import withLayers from './lib/withLayers';
import withLayerOverlays from './lib/withLayerOverlays';
import withMap from './lib/withMap';
import withMounting from './lib/withMounting';
import withSize from './lib/withSize';
import withStyleFunction from './lib/withStyleFunction';
import withVisibleLayers from './lib/withVisibleLayers';

export {DEFAULT_CURSOR} from './lib/withCursor';
export const ANIMATE_ONCE = 'once';

/**
 * @class MapController
 */
export class MapController extends BaseController {
	constructor(controllerName, styleFunction) {
		super(controllerName);
		this._reducers = [];
		this._styleFunction = styleFunction;
	}

	registerReducer(reducer) {
		this._reducers.push(reducer);
	}

	reduce(state = {}, action) {
		this._reducers.forEach(reducer => {
			state = reducer(state, action);
		});
		return super.reduce(state, action);
	}

	init() {
		olProj.setProj4(ProjectionsController.getProj4());

		const map = withMap(this);

		// TODO: Can we actually make this private using new es proposal or using property descriptors?
		//  Does code outside of core need the _map property?
		this._map = map;

		updateSizeOnTransitionEnd(map);
		canvasSizeFixer(map);

		withStyleFunction(this, map, this._styleFunction);
		withMounting(this, map);
		withLayers(this, map);
		withLayerOverlays(this, map);
		withVisibleLayers(this, map);
		withControls(this, map);
		withFeatureInteractions(this, map);
		withInteractions(this, map);
		withClusterFeatureFunction(this);
		withAnimations(this, map);
		withAnchoredViewport(this, map);
		withCursor(this, map);
		withSize(this, map);
	}

	// TODO: use store instead
	getMinResolution() {
		return this._map.getView().getMinResolution();
	}

	// TODO: use store instead
	getMaxResolution() {
		return this._map.getView().getMaxResolution();
	}

	forEachFeatureAtPixel(pixel, callback, options) {
		return this._map.forEachFeatureAtPixel(pixel, callback, options);
	}
}
