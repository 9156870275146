// store[MAP][visible] is only used by @mapsight/ui and not at all by @mapsight/core
// unfortunately this was not added to the store ['app'] of @mapsight/ui so we now have to extend the reducer of core
// this is ok, as there's an api to extend the core redurce

import {SET_MAP_VISIBLE} from './actions';

export default function mapReducer(state, action) {
	if (action.type === SET_MAP_VISIBLE) {
		state = {
			...state,
			visible: action.value
		};
	}
	return state;
}
