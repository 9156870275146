import {async, controlled, set} from '../../base/actions';

export default function withSize(mapController, map) {
	const name = mapController.getName();

	map.on('change:size', ({oldValue}) => {
		const newValue = map.getSize();
		if (newValue && (!oldValue || oldValue[0] !== newValue[0] || oldValue[1] !== newValue[1])) {
			mapController.dispatch(controlled(async(set([name, 'size'], newValue))));
		}
	});
}
