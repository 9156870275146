import React, {useCallback, memo} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {filterListQuery} from '../../store/actions';
import {listQuerySelector} from '../../store/selectors';

import FeaturesQueryInput from './query-input';


function FeatureFilter() {
	const dispatch = useDispatch();
	const query = useSelector(listQuerySelector);

	const onChange = useCallback(
		(newQuery) => {
			dispatch(filterListQuery(newQuery));
		},
		[dispatch],
	);

	return (
		<div className="ms3-list__filter-box">
			<FeaturesQueryInput query={query} onChange={onChange} />
		</div>
	);
}

export default memo(FeatureFilter);
