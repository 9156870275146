import React, {memo} from 'react';
import {useSelector} from 'react-redux';

import {titleSelector} from '../store/selectors';

function TitleBar() {
	const title = useSelector(titleSelector);

	return (
		<div className="ms3-flex ms3-flex--row ms3-flex-no-shrink">
			<div className="ms3-page-title ms3-flex-grow">
				<h2>{(title || '')}</h2>
			</div>
		</div>
	);
}

// TODO: Split into separate files (for container and stateless component)?
export default memo(TitleBar);
