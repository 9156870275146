import GeoJSON from 'ol/format/geojson';

export default {
	type: 'format',
	name: 'GeoJSON',
	Constructor: GeoJSON,
	optionMap: {},
	initialOptionMap: {
		defaultDataProjection: 'defaultDataProjection',
		featureProjection: 'featureProjection',
		geometryName: 'defaultDataProjection',
	}
};
