"use strict";

exports.__esModule = true;
exports.default = _default;

/**
 * get value of single query string parameter
 *
 * @param {string} uri uri to parse
 * @param {string} key key to return value for
 * @returns {string | null} value
 */
function _default(uri, key) {
  const match = new RegExp('(?:^|[?&])' + key + '=(.*?)(?:&|$)', 'i').exec(uri);
  return match ? decodeURI(match[1]) : null;
}

module.exports = exports.default;