import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {removeFrom} from '@mapsight/core/lib/base/actions';

import {FEATURE_SELECTIONS} from '../../config/constants/controllers';
import {FEATURE_SELECTION_SELECT} from '../../config/feature/selections';

import {featureDetailsHasErrorSelector, featureDetailsHtmlSelector} from '../../store/selectors';

import FeatureSelectionInfo from './feature-selection-info';

export default connect(
	createStructuredSelector({
		detailsHasError: featureDetailsHasErrorSelector,
		detailsHtml: featureDetailsHtmlSelector,
	}),
	(dispatch, {feature}) => ({
		removeSelection: () => dispatch(removeFrom([FEATURE_SELECTIONS, FEATURE_SELECTION_SELECT, 'features'], feature.id)),
	})
)(FeatureSelectionInfo);
