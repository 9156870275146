import OlDrawInteraction from 'ol/interaction/draw';
import GeometryType from 'ol/geom/geometrytype';
import Polygon from 'ol/geom/polygon';
import LineString from 'ol/geom/linestring';
import events from 'ol/events';
import sphere from 'ol/sphere';

import ensureFeatureId from '@mapsight/lib-ol/feature/ensureId';

/**
 * Format length output.
 *
 * @param {LineString} line The line.
 * @returns {string} The formatted length.
 */
function formatLength(line) {
	const length = sphere.getLength(line);

	// TODO: i18n / l10n
	return length > 100 ?
		`${Math.round(length / 1000 * 100) / 100} km` :
		`${Math.round(length * 100) / 100} m`;
}

/**
 * Format area output.
 *
 * @param {Polygon} polygon The polygon.
 * @returns {string} Formatted area.
 */
function formatArea(polygon) {
	const area = sphere.getArea(polygon);

	// TODO: i18n / l10n
	return area > 10000 ?
		`${Math.round(area / 1000000 * 100) / 100} km²` :
		`${Math.round(area * 100) / 100} m²`;
}

const defaultOptions = {
	type: GeometryType.POINT,
};

export default class DrawInteraction extends OlDrawInteraction {
	constructor(options) {
		super({...defaultOptions, ...options});

		this._source = null;
		this._replacePrevious = false;
		this._clearOnStart = false;

		this._measure = false;
		this._sketchFeature = null;
		this._sketchPoint = null;
		this._sketchLine = null;

		this.on('drawstart', this.handleSketchStart.bind(this));
		this.on('drawend', this.handleSketchEnd.bind(this));
	}

	abort() {
		// only need to abort if active
		if (this.getActive()) {
			// abort by quickly de- and then reactivating ol interaction
			this.setActive(false);
			this.setActive(true);
		}
	}

	/** @override */
	updateSketchFeatures_(...args) {
		super.updateSketchFeatures_(...args);
		this._sketchPoint = this.sketchPoint_;
		this._sketchLine = this.sketchLine_;
	}

	/** @override */
	createOrUpdateSketchPoint_(...args) {
		super.createOrUpdateSketchPoint_(...args);
		this._sketchPoint = this.sketchPoint_;
	}

	handleSketchStart(startEvent) {
		// set sketch
		this._sketchFeature = startEvent.feature;

		if (this._clearOnStart) {
			this._source.clear();
		}
	}

	handleSketchEnd({feature}) {
		// unset sketch
		this._sketchFeature = null;

		// save feature?
		if (this._source) {
			feature.set('measurementType', undefined);
			ensureFeatureId(feature);

			if (this._replacePrevious) {
				this._source.clear();
			}
			this._source.addFeature(feature);
		}
	}

	initMeasurement() {
		this._measureChangeListener = null;
		this._measureCoordinate = null;

		this.on('drawstart', this.handleMeasurementStart.bind(this));
		this.on('drawend', this.handleMeasurementEnd.bind(this));
	}

	updateMeasurementFeatures(label = '') {
		this._sketchFeature.set('measurementType', 'drawFeature');
		this._sketchFeature.set('measurementLabel', label);

		if (this._sketchPoint) {
			this._sketchPoint.set('measurementType', 'drawPoint');
			this._sketchPoint.set('measurementLabel', label);
		}

		if (this._sketchLine) {
			this._sketchLine.set('measurementType', 'drawLine');
			this._sketchLine.set('measurementLabel', label);
		}
	}

	handleMeasurementStart({coordinate}) {
		this._measureCoordinate = coordinate;

		const sketchGeometry = this._sketchFeature.getGeometry();
		this._measureChangeListener = sketchGeometry.on('change', this.handleMeasurementChange.bind(this));
		this.updateMeasurementFeatures();
	}

	handleMeasurementChange({target: geometry}) {
		let output;
		if (geometry instanceof Polygon) {
			output = formatArea(geometry);
			this._measureCoordinate = geometry.getInteriorPoint().getCoordinates();
		} else if (geometry instanceof LineString) {
			output = formatLength(geometry);
			this._measureCoordinate = geometry.getLastCoordinate();
		}

		this.updateMeasurementFeatures(output);
	}

	handleMeasurementEnd() {
		events.unlistenByKey(this._measureChangeListener);
	}

	/** @override */
	setMap(map) {
		super.setMap(map);

		this.initMeasurement();
	}

	setSource(source) {
		this._source = source;
	}

	getSource() {
		return this._source;
	}

	setMeasure(measure) {
		this._measure = measure;
	}

	getMeasure() {
		return this._measure;
	}

	setReplacePrevious(replacePrevious) {
		this._replacePrevious = replacePrevious;
	}

	getReplacePrevious() {
		return this._replacePrevious;
	}

	setClearOnStart(clearOnStart) {
		this._clearOnStart = clearOnStart;
	}

	getClearOnStart() {
		return this._clearOnStart;
	}

	getStyle() {
		this.overlay_.getStyle();
	}

	setStyle(styleFunction) {
		this.overlay_.setStyle(styleFunction);
	}
}
