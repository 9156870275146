import {di} from '@mapsight/ol-proxy';

import TileLayer from '@mapsight/ol-proxy/definitions/layer/tile';
import VectorLayer from '@mapsight/ol-proxy/definitions/layer/vector';
import VectorOverlayLayer from '@mapsight/ol-proxy/definitions/layer/vector-overlay';

import VectorSource from '@mapsight/ol-proxy/definitions/source/vector';
import OSMSource from '@mapsight/ol-proxy/definitions/source/osm';
import TileWMSSource from '@mapsight/ol-proxy/definitions/source/tilewms';

import GeoJSONFormat from '@mapsight/ol-proxy/definitions/format/geojson';

import SelectInteraction from '@mapsight/ol-proxy/definitions/interaction/select';
import DragPanInteraction from '@mapsight/ol-proxy/definitions/interaction/dragpan';
import DoubleClickZoomInteraction from '@mapsight/ol-proxy/definitions/interaction/doubleclickzoom';
import PinchZoomInteraction from '@mapsight/ol-proxy/definitions/interaction/pinchzoom';
import MouseWheelZoomInteraction from '@mapsight/ol-proxy/definitions/interaction/mousewheelzoom';
import KeyboardPanInteraction from '@mapsight/ol-proxy/definitions/interaction/keyboardpan';
import KeyboardZoomInteraction from '@mapsight/ol-proxy/definitions/interaction/keyboardzoom';

import VectorFeatureSource from '@mapsight/core/ol-proxy/definitions/VectorFeatureSource';

/**
 * This plugin will inject the ol-proxy dependencies required by default.
 *
 * @param {Object} [options] options (not used currently)
 * @return {MapsightUiPlugin} plugin
 */
export default function createPlugin(options = {}) {
	return {
		afterInit: function olProxyInitPlugin(context) {
			di.inject({
				layer: {
					Tile: TileLayer,
					Vector: VectorLayer,
					VectorOverlay: VectorOverlayLayer,
				},
				source: {
					Vector: VectorSource,
					TileWMS: TileWMSSource, // TODO: Make optional to reduce bundle size
					OSM: OSMSource,
					VectorFeatureSource: VectorFeatureSource,
				},
				format: {
					GeoJSON: GeoJSONFormat,
				},
				interaction: {
					Select: SelectInteraction,
					DragPan: DragPanInteraction,
					DoubleClickZoom: DoubleClickZoomInteraction,
					PinchZoom: PinchZoomInteraction,
					MouseWheelZoom: MouseWheelZoomInteraction,
					KeyboardPan: KeyboardPanInteraction,
					KeyboardZoom: KeyboardZoomInteraction,
				},
			});
		},
	};
}

