const filterFunctions = {};

export const addFilterFunction = (filterKey, filterFunction) => {
	filterFunctions[filterKey] = filterFunction;
};

const applyFilter = (features, filterKey, filterState, featureSourceId) => {
	const filterFunction = filterFunctions[filterKey];
	if (filterFunction) {
		return filterFunction(features, filterState, featureSourceId);
	}

	console.info('No filter function defined for ' + filterKey + '. skipping filter');
	return features;
};

// TODO HS: Möglichkeit der Vereinfachung: den filterKey als key im store verwenden und statt filters den kompletten state übergeben.
//  das derzeit nötige herausfischen der FilterStates kann dann zusammen mit dem Parameter filters entfallen
/**
 * Applies filter to features
 *
 * @param {object} filters KV-Objekt mit dem filterKey als key und dem Filter-State als Value
 * @param {object[]} features Array der zu filternden Features
 * @param {string} featureSourceId id der Quelle des Arrays der zu filternden Featuers
 * @returns {object[]} Array der gefilterten Features
 */
export const applyFilters = (filters, features, featureSourceId) => (
	Object.keys(filters).reduce(
		(filteredFeatures, filterKey) => applyFilter(filteredFeatures, filterKey, filters[filterKey], featureSourceId),
		features
	)
);
