import {batchActions} from 'redux-batched-actions';

import {deselectAll, selectExclusively} from '@mapsight/core/lib/feature-selections/actions';
import getDocumentScroll from '@neonaut/lib-js/es/dom/access/get-document-scroll';

import scrollToMapTop from '../../helpers/scroll-to-map-top';
import {siteConfig} from '../../config';
import {setLastListScrollPosition, setView} from '../../store/actions';

import {VIEW_MAP_ONLY} from '../../config/constants/app';
import {FEATURE_SELECTIONS} from '../../config/constants/controllers';
import {FEATURE_SELECTION_HIGHLIGHT} from '../../config/feature/selections';

export default function createSelectFeature(selectedOnly, selectionBehavior, selectionBehaviorSelection, view, dispatch) {
	return function handleFeatureSelection(featureId) {
		const actions = [
			deselectAll(FEATURE_SELECTIONS, FEATURE_SELECTION_HIGHLIGHT),
			selectExclusively(FEATURE_SELECTIONS, selectionBehaviorSelection, featureId),
		];

		// console.log('feature-list-item/index', selectedOnly, selectionBehavior[view], view, getDocumentScroll());
		if (!selectedOnly && selectionBehavior[view] === 'scrollToMap') {
			actions.push(setLastListScrollPosition(getDocumentScroll()));
			scrollToMapTop(siteConfig.topOffsetForView(view));
		} else if (selectionBehavior[view] === 'showInMapOnlyView') {
			actions.push(setLastListScrollPosition(getDocumentScroll())); // hier auch speichern, damit auch hier das close des poi die scroll-position wiederherstellt
			actions.push(setView(VIEW_MAP_ONLY));
		}

		dispatch(batchActions(actions));
	};
}
