/** @typedef {import('redux').Store} Store */

import get from 'lodash/get';
import set from 'lodash/set';

import {observeState} from './observe-state';

/**
 * @typedef {{
 *     updateLocalStorageState: (path: string, value: *) => void,
 *     synchronizePathsToLocalStorage: (store: Store, paths: string[]|string[][]) => void,
 *     setLocalStorageState: (state: object) => void,
 *     setStorageKey: (k: string) => void,
 *     getLocalStorageState: () => object
 * }} ReduxLocalStorageAdapter
 */

/**
 * Creates a storage object to access local storage.
 *
 * @param {string|null} [storageKey=null] key to be used in local storage
 * @returns {ReduxLocalStorageAdapter} storage object
 */
export function createStorage(storageKey = null) {
	/**
	 * @param {string} k storage key
	 * @returns {void}
	 */
	function setStorageKey(k) {
		storageKey = k;
	}

	function ensureStorageKey() {
		if (!storageKey) {
			throw Error('@neonaut/lib-redux: Cannot access local storage without a key. Please set a storage key using the `string: storageKey` property on `createStorage(string?: storageKey)` or using the `setStorageKey(string: storageKey)` method on an existing storage object.');
		}
	}

	/**
	 * @returns {object} state
	 */
	function getLocalStorageState() {
		ensureStorageKey();

		return window.localStorage && JSON.parse(window.localStorage.getItem(storageKey));
	}

	/**
	 * @param {object} state state object
	 * @returns {void}
	 */
	function setLocalStorageState(state) {
		ensureStorageKey();

		if (window.localStorage) {
			window.localStorage.setItem(storageKey, JSON.stringify(state));
		}
	}

	/**
	 * @param {string} path store path
	 * @param {*} value value
	 * @returns {void}
	 */
	function updateLocalStorageState(path, value) {
		ensureStorageKey();

		setLocalStorageState(set(getLocalStorageState() || {}, path, value));
	}

	/**
	 * Observes and synchronizes the the state of the given store with local storage.
	 *
	 * @see lodash/get for path definition
	 *
	 * @param {Store} store the store to observe
	 * @param {string[]|string[][]} paths array of path strings (string[]) or path arrays (string[][])
	 */
	function synchronizePathsToLocalStorage(store, paths) {
		ensureStorageKey();

		if (window.localStorage) {
			paths.forEach(function synchronizePathToLocalStorage(path) {
				observeState(
					store,
					state => get(state, path),
					value => updateLocalStorageState(path, value)
				);
			});
		}
	}

	return {
		setStorageKey: setStorageKey,
		getLocalStorageState: getLocalStorageState,
		setLocalStorageState: setLocalStorageState,
		updateLocalStorageState: updateLocalStorageState,
		synchronizePathsToLocalStorage: synchronizePathsToLocalStorage,
	};
}


// Legacy global default storage, remove with next major version!

/** @deprecated Use `createStorage(string?: storageKey)` instead */
const defaultStorageKey = 'neonaut-redux-default-store-please-set-storage-key';

/** @deprecated Use `createStorage(string?: storageKey)` instead */
const defaultStorage = createStorage(defaultStorageKey);

/** @deprecated Use `createStorage(string?: storageKey)` instead */
export const setStorageKey = defaultStorage.setStorageKey.bind(defaultStorage);

/** @deprecated Use `createStorage(string?: storageKey)` instead */
export const getLocalStorageState = defaultStorage.getLocalStorageState.bind(defaultStorage);

/** @deprecated Use `createStorage(string?: storageKey)` instead */
export const setLocalStorageState = defaultStorage.setLocalStorageState.bind(defaultStorage);

/** @deprecated Use `createStorage(string?: storageKey)` instead */
export const updateLocalStorageState = defaultStorage.updateLocalStorageState.bind(defaultStorage);

/** @deprecated Use `createStorage(string?: storageKey)` instead */
export const synchronizePathsToLocalStorage = defaultStorage.synchronizePathsToLocalStorage.bind(defaultStorage);

/** @deprecated Use `createStorage(string?: storageKey)` instead */
export default defaultStorage;
