/**
 * Create an redux middleware that allow dispatching of functions as actions, when the action
 * exposes the defined actionFlag. If an prefix is provided, the action function will recive a
 * prefixed getState, meaning it will retrieve the sub store with the prefix key (store.getState()[prefix]).
 *
 * Works similar to thunk, but with additional flag and optional sub store getState.
 *
 * @param {string|symbol} [actionFlag] action flag, default: 'isAsync'
 * @param {string} [prefix] prefix state prefix
 * @param {*} [extraArgument] extra argument to be passed to action function
 *
 * @returns {function(*=): function(*): Function} resulting middleware
 */
export default function createPrefixedAsyncActionMiddleware(actionFlag = 'isAsync', prefix = undefined, extraArgument = undefined) {
	return function prefixedAsyncActionMiddleware(api) {
		const getState = prefix ? function getStateWithPrefix() {
			return api.getState()[prefix];
		} : api.getState;

		return function prefixedAsyncActionMiddlewareWithNext(next) {
			return function prefixedAsyncActionMiddlewareWithAction(action) {
				if (typeof action === 'function' && action.meta && action.meta[actionFlag]) {
					return action(api.dispatch, getState, extraArgument);
				}

				return next(action);
			};
		};
	};
}
