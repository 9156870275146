import React, {memo, useEffect, useRef, useMemo, useLayoutEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import setDocumentScroll from '@neonaut/lib-js/es/dom/access/set-document-scroll';

import {async} from '@mapsight/core/lib/base/actions';
import {load} from '@mapsight/core/lib/feature-sources/actions';

import makeSticky from '../../helpers/sticky';
import {isViewFullscreen, isViewMobile, viewSelector} from '../../store/selectors';
import getFeatureProperty from '../../helpers/get-feature-property';

import {siteConfig} from '../../config';
import {VIEW_MOBILE} from '../../config/constants/app';
import {FEATURE_SOURCES} from '../../config/constants/controllers';

import FeatureListHeader from './header';
import FeatureListFooter from './footer';
import FeatureListGroupedContent from './grouped-content';

export const MAKE_STICKY_CLASS = 'js-make-sticky';

function stopEventPropagation(e) {
	e.stopPropagation();
}

function determineShowListInfo(features) {
	if (features.length) {
		const firstInfo = getFeatureProperty(features[0], 'listInformation'); // TODO: document/collect magic property names
		for (let i = 0; i < features.length; i++) {
			const info = getFeatureProperty(features[i], 'listInformation'); // TODO: document/collect magic property names

			if (firstInfo !== info) {
				return true;
			}
		}
	}
	return false;
}

function determineItemType(containerType) {
	if (containerType === 'table') {
		return 'tr';
	}

	if (containerType === 'ul' || containerType === 'ol') {
		return 'li';
	}

	return 'p';
}

/**
 * @param {{
 *   listUiOptions: any,
 *   tagSwitcherShow: any,
 *   layerSwitcherShowExternal: any,
 *   features: any,
 *   filteredFeatures: any,
 *   status: null | string,
 *   featureSourceId: any,
 *   scrollPosition?: number,
 *   additionalClasses?: string,
 * }} props
 */
function FeatureList({
	listUiOptions,
	tagSwitcherShow,
	layerSwitcherShowExternal,
	features,
	filteredFeatures,
	page,
	featureCount,
	status,
	featureSourceId,
	scrollPosition,
	additionalClasses,
	itemAs,
}) {
	const dispatch = useDispatch();
	const view = useSelector(viewSelector);

	const isMobile = isViewMobile(view);
	const {
		detailsInList,
		showVaryingListInfoOnly,
		renderGroupAs,
		renderAs = 'table',
	} = listUiOptions;
	const {renderItemAs = determineItemType(renderAs)} = listUiOptions;

	/** @type {React.RefObject<HTMLDivElement>} */
	const thisRef = useRef(null);

	useEffect(
		() => {
			if (featureSourceId) {
				dispatch(async(load(FEATURE_SOURCES, featureSourceId)));
			}
		},
		[featureSourceId, dispatch],
	);

	useEffect(
		() => {
			if ((isViewMobile(view) || isViewFullscreen(view)) && scrollPosition) {
				setDocumentScroll(scrollPosition);
			}
		},
		[view, scrollPosition],
	);

	// make header sticky
	useLayoutEffect(
		() => {
			if (listUiOptions.stickyHeader && thisRef.current !== null) {
				// TODO: cleanup direct dom changes
				const stickyElement = thisRef.current.querySelector(`.${MAKE_STICKY_CLASS}`);

				if (stickyElement) {
					return makeSticky(
						stickyElement,
						// use `view === VIEW_MOBILE` here because `isViewMobile` is checking view
						// for mobile or mapOnly
						view === VIEW_MOBILE ? window : thisRef.current,
						{
							offset: siteConfig.topOffsetForView(view),
						}
					);
				}
			}

			return undefined;
		},
		// executed after each render
	);

	// Decide whether to show the info column
	// based on if there is any "interesting" aka distinct data available in the features
	// We do this before filtering so it does not change while changing the filter
	const showFeatureListInfo = filteredFeatures.length !== 0
		&& (showVaryingListInfoOnly === false || determineShowListInfo(features));

	// TODO:
	//<StatusIndicator {...{
	//	lastUpdate: this.props.lastUpdate,
	//	status: this.props.status,
	//	key: 'si'
	//}} />

	let classes = 'ms3-list-wrapper';
	if (detailsInList) {
		classes += ' ms3-list-wrapper--with-details';
	}
	if (additionalClasses) {
		classes += ` ${additionalClasses}`;
	}

	const topOffset = siteConfig.topOffsetForView(view)
		+ listUiOptions.additionalScrollOffsetSelect;

	const itemProps = useMemo(
		() => ({
			as: itemAs || renderItemAs,
			showFeatureListInfo: showFeatureListInfo,
			isMobile: isMobile,
			forceShowDetails: detailsInList,
			selectedOnly: listUiOptions.showSelectedOnly,
			selectOnClick: listUiOptions.selectOnClick,
			deselectOnClick: listUiOptions.deselectOnClick,
			highlightOnMouse: listUiOptions.highlightOnMouse,
			topOffset: topOffset,
		}),
		[
			itemAs,
			renderItemAs,
			showFeatureListInfo,
			isMobile,
			detailsInList,
			listUiOptions.showSelectedOnly,
			listUiOptions.selectOnClick,
			listUiOptions.deselectOnClick,
			listUiOptions.highlightOnMouse,
			topOffset,
		]
	);

	return (
		<div
			className={classes}
			onTouchMove={stopEventPropagation}
			ref={thisRef}
		>
			<FeatureListHeader
				listUiOptions={listUiOptions}
				allFeatures={features}
				filteredFeatures={filteredFeatures}
				showTagSwitcher={tagSwitcherShow}
				showExternalLayerSwitcher={layerSwitcherShowExternal}
				page={page}
				featureCount={featureCount}
			/>

			<FeatureListGroupedContent
				filteredFeatures={filteredFeatures}
				itemProps={itemProps}
				groupAs={renderGroupAs}
				as={renderAs}
				itemAs={itemAs || renderItemAs}
				status={status}
				showFeatureListInfo={showFeatureListInfo}
				featureSourceId={featureSourceId}
			/>

			<FeatureListFooter
				listUiOptions={listUiOptions}
				allFeatures={features}
				filteredFeatures={filteredFeatures}
				page={page}
				featureCount={featureCount}
			/>
		</div>
	);
}

export default memo(FeatureList);
