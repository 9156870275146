import Sphere from 'ol/sphere';

import getFeatureProperty from './get-feature-property';

/**
 * @typedef {import('../components/feature-list-sorting/feature-list-sorting').MapsightUiPlacesData} MapsightUiPlacesData
 * @typedef {import('../components/feature-list-sorting/feature-list-sorting').MapsightUiPlace} MapsightUiPlace
 * @typedef {import('../components/feature-list-sorting/feature-list-sorting').MapsightUiPlaceGroup} MapsightUiPlaceGroup
 */

const wgs84Sphere = new Sphere(6378137); // WGS-84 ellipsoid, see https://de.wikipedia.org/wiki/World_Geodetic_System

/**
 * @param {object} feature feature object
 * @returns {Array.<number>} anchor
 */
function defaultFeatureAnchorSelector(feature) {
	if (!feature) {
		return [null, null];
	}

	// TODO: document/collect magic property names
	const bbox = feature.bbox;

	// TODO: document/collect magic property names
	// TODO: deprecate and then remove legacy bbox_center_x/y properties and require standard feature.bbox.
	const x = getFeatureProperty(feature, 'bbox_center_x') || bbox && bbox[0] && bbox[2] && ((bbox[0] + bbox[2]) / 2);
	const y = getFeatureProperty(feature, 'bbox_center_y') || bbox && bbox[1] && bbox[3] && ((bbox[1] + bbox[3]) / 2);

	return [x, y];
}

/**
 * @param {string} sorting sorting state
 * @param {MapsightUiPlacesData} places places
 * @returns {MapsightUiPlace|null} place to sort by or null
 */
function findPlaceForSorting(sorting, places) {
	const sortingKeys = sorting.split(',');

	/** @type {MapsightUiPlacesData|MapsightUiPlace|MapsightUiPlaceGroup} */
	let placePointer = places;

	for (const key of sortingKeys) {
		if (!placePointer[key]) {
			return null;
		}

		placePointer = placePointer[key];

		// NOTE(PG): 'entries' is reserved by JS (Object.entries). should be changed to something else at some point.
		//            We are using hasOwnProperty() as a workaround for now.
		if (placePointer.hasOwnProperty('entries')) {
			placePointer = placePointer.entries;
		}
	}

	if (placePointer.x && placePointer.y) {
		return placePointer;
	}

	return null;
}

/**
 * sorts features by distance
 *
 * @param {object[]} features features
 * @param {MapsightUiPlacesData} places places
 * @param {string} sorting sorting state
 * @param {object} [settings] settings
 * @param {function(object):Array.<number>} [settings.featureAnchorSelector] selector to get anchor from feature object. The returned anchor has to be x,y-coordinates in WGS84!
 * @returns {object[]} sorted features
 */
export default function sortFeaturesByDistance(features, places, sorting, {featureAnchorSelector = defaultFeatureAnchorSelector} = {}) {
	if (!sorting) {
		return features;
	}

	const placePointer = findPlaceForSorting(sorting, places);
	if (placePointer) {
		const placeCoords = [placePointer.x, placePointer.y];
		return features.slice().sort((a, b) =>
			wgs84Sphere.haversineDistance(placeCoords, featureAnchorSelector(a)) -
			wgs84Sphere.haversineDistance(placeCoords, featureAnchorSelector(b))
		);
	}

	return features;
}
