import {createFilteredFeatureSourceSelector} from '../feature-sources/selectors';

export const featureSourceIdSelector = state => state && state.featureSource;

export function createListFeatureSelector(listControllerName, featureSourcesControllerName) {
	let selector = _ => undefined; // default which is needed if there's no list source set at first call to this (then selector is undefined and keeps undefined as featureSourceId and currentFeatureSourceId are undefined too und updateFeatureSelector does not get called
	let currentFeatureSourceId;

	function updateFeatureSelector() {
		selector = createFilteredFeatureSourceSelector(
			featureSourcesControllerName,
			currentFeatureSourceId,
			listControllerName,
		);
	}

	return function listFeatureSelector(state, props) {
		const featureSourceId = featureSourceIdSelector(state[listControllerName]);
		if (featureSourceId !== currentFeatureSourceId) {
			currentFeatureSourceId = featureSourceId;
			updateFeatureSelector();
		}

		return selector(state, props);
	};
}
