import React, {memo, useEffect, useCallback, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createSelector, createStructuredSelector} from 'reselect';
import ReactModal from 'react-modal/lib/components/Modal';

import escapeCssName from '@neonaut/lib-js/es/string/escapeCssName';
import getPath from '@neonaut/lib-js/es/object/getPath';
import {reduceLayersToLegends, visibleLayersWithLegendsSelector} from '@mapsight/core/lib/map/selectors';

import {isOverlayModalVisibleSelector, isViewMobileOrMapOnlySelector} from '../../store/selectors';
import {MAP} from '../../config/constants/controllers';
import {translate} from '../../helpers/i18n';
import {setOverlayModalVisible} from '../../store/actions';

import Attribution from './attribution';
import AttributionEntries from './attribution-entries';
import Logo from './logo';
import {ComponentsContext} from '../contexts';

// die anzuzeigenden Legenden hier selbst berechnen zusammen mit den css-Namen
const legendsSelector = createSelector(
	state => visibleLayersWithLegendsSelector(state[MAP]),
	createStructuredSelector({
		legends: reduceLayersToLegends,
		legendClasses: (layersWithLegend) => {
			if (layersWithLegend) {
				return Object.values(layersWithLegend)
					.map((layer) => {
						const title = getPath(layer, ['metaData', 'title']);
						if (title) {
							return `ms3-modal-legend__${escapeCssName(title)}`;
						}
						return null;
					})
					.filter(Boolean)
					.join(' ');
			}

			return '';
		},
	}),
);

function InfoOverlayModal() {
	const dispatch = useDispatch();
	const {MapOverlayModal} = useContext(ComponentsContext);

	const isExpanded = useSelector(isOverlayModalVisibleSelector);
	const isMobileOrMapOnly = useSelector(isViewMobileOrMapOnlySelector);
	const {legends, legendClasses} = useSelector(legendsSelector);

	// close modals on view-switch to something else than mobile
	useEffect(
		() => {
			if (!isMobileOrMapOnly) {
				dispatch(setOverlayModalVisible(false));
			}
		},
		[dispatch, isMobileOrMapOnly],
	);

	const collapse = useCallback(
		() => {
			dispatch(setOverlayModalVisible(false));
		},
		[dispatch],
	);

	let content = (
		<div className="ms3-modal__inner ms3-modal__inner--with-legend">
			<button
				className="ms3-dialog-close-button"
				type="button"
				onClick={collapse}
			>
					<span className="visuallyhidden">
						{isMobileOrMapOnly && translate('ui.map-overlay.info.closeSources')}
						{!isMobileOrMapOnly && translate('ui.map-overlay.info.closeLegend')}
					</span>
			</button>

			{isMobileOrMapOnly && (
				<Attribution />
			)}

			<AttributionEntries attributions={legends} />
			<Logo />
		</div>
	);

	if(MapOverlayModal)  {
		content = (<MapOverlayModal>{content}</MapOverlayModal>);
	}

	return (
		<ReactModal
			isOpen={isExpanded}
			// contentLabel="onRequestClose Example" // aria-label="" on .ms3-modal
			onRequestClose={collapse}
			className={'ms3-modal ' + legendClasses}
			overlayClassName="ms3-app-overlay"
			shouldCloseOnOverlayClick={true}
		>
			{content}
		</ReactModal>
	);
}

export default memo(InfoOverlayModal);
