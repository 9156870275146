import {connect} from 'react-redux';
import {createSelector, createStructuredSelector} from 'reselect';

import {createFeatureSelectionSelector, getFilteredFeatures} from '@mapsight/core/lib/feature-selections/selectors';
import {deselectAll, selectExclusively} from '@mapsight/core/lib/feature-selections/actions';

import {FEATURE_SELECTIONS} from '../../config/constants/controllers';
import {FEATURE_SELECTION_HIGHLIGHT, FEATURE_SELECTION_PRESELECT, FEATURE_SELECTION_SELECT} from '../../config/feature/selections';

import {
	featureDetailsHasErrorSelector,
	featureDetailsHtmlSelector,
	isViewMobile,
	listUiOptionSelectionBehaviorSelector,
	listUiOptionSelectionSelectionSelector,
	listUiScrollToItemOnPreselectSelector,
	viewSelector
} from '../../store/selectors';

import FeatureListItem from './feature-list-item';

export default connect(
	// stateProps selector
	createSelector(
		[
			// mapsight ui appState
			createStructuredSelector({
				view: viewSelector,
				detailsHasError: featureDetailsHasErrorSelector,
				detailsHtml: featureDetailsHtmlSelector,
				selectionBehavior: listUiOptionSelectionBehaviorSelector,
				selectionBehaviorSelection: listUiOptionSelectionSelectionSelector,
				scrollToItemOnPreselect: listUiScrollToItemOnPreselectSelector,
			}),

			// mapsight core
			createFeatureSelectionSelector(FEATURE_SELECTIONS, FEATURE_SELECTION_SELECT),
			createFeatureSelectionSelector(FEATURE_SELECTIONS, FEATURE_SELECTION_PRESELECT),
			createFeatureSelectionSelector(FEATURE_SELECTIONS, FEATURE_SELECTION_HIGHLIGHT),
			(_, ownProps) => ownProps.feature.id,
			(_, ownProps) => ownProps.selectedOnly,
		],

		(appState, selectSelection, preselectSelection, highlightSelection, featureId, selectedOnly) => {
			const selectSelectionFeatures = getFilteredFeatures(selectSelection);
			const hasSelection = selectSelectionFeatures && selectSelectionFeatures.length > 0;
			const isSelected = hasSelection && selectSelectionFeatures[0] === featureId;

			const preselectSelectionFeatures = getFilteredFeatures(preselectSelection);
			const highlightSelectionFeatures = getFilteredFeatures(highlightSelection);

			return {
				...appState,
				hasSelection: hasSelection,
				selectedOnly: selectedOnly,
				hidden: selectedOnly && hasSelection && !isSelected,
				isSelected: isSelected,
				isHighlighted: highlightSelectionFeatures && highlightSelectionFeatures[0] === featureId,
				isPreselected: !hasSelection && preselectSelectionFeatures && preselectSelectionFeatures[0] === featureId,
			};
		}
	),

	// dispatchProps
	(dispatch, ownProps) => {
		const {feature} = ownProps;
		return {
			dispatch: dispatch,
			onFeatureHighlight: () => dispatch(selectExclusively(FEATURE_SELECTIONS, FEATURE_SELECTION_HIGHLIGHT, feature.id)),
			onFeatureUnHighlight: () => dispatch(deselectAll(FEATURE_SELECTIONS, FEATURE_SELECTION_HIGHLIGHT)),
		};
	},

	(stateProps, {dispatch, ...dispatchPropsRest}, ownProps) => {
		const {
			view,
			selectionBehavior,
			selectionBehaviorSelection,
			selectedOnly,
			hasSelection,
			scrollToItemOnPreselect,
		} = stateProps;
		const {feature, selectFeature} = ownProps;
		const isMobile = isViewMobile(stateProps.view);

		const scrollOnSelection = selectionBehavior[view] === 'expandInList' && !selectedOnly;
		const scrollOnPreselection = !hasSelection && scrollToItemOnPreselect;

		const handleFeatureSelection = selectFeature.bind(null, feature.id);

		const onFeatureUnSelection = () => dispatch(deselectAll(FEATURE_SELECTIONS, selectionBehaviorSelection));

		return {
			...stateProps,
			...dispatchPropsRest,
			...ownProps,
			scrollOnSelection: scrollOnSelection,
			scrollOnPreselection: scrollOnPreselection,
			isMobile: isMobile,
			onFeatureSelection: handleFeatureSelection,
			onFeatureUnSelection: onFeatureUnSelection,
		};
	}
)(FeatureListItem);
