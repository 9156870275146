/**
 * @typedef {CreateOptions} EmbedOptions
 * @property {AfterCreatePluginFunction} [plugin] a function that will be called after the application has been (optional)
 * @property {Object<String, Object>} [defaultPluginsOptions] map of options for the default plugins
 * @property {String} [dehydratedStateAttributeName] the attribute name by which to pull dehydrated json encoded state from
 */

/**
 * @typedef {Function} MapsightUiEmbedRenderFunction
 * @param {String} currentTitle title of the app, defaults to `window.document.title`
 * @param {Object} currentPreset mapsight preset to apply
 */

/**
 * @param {Function} styleFunction the mapsight core vector style function (see @mapsight/core)
 * @param {Object} baseMapsightCoreConfig base mapsight config TODO: document further
 * @param {Object} preset initial mapsight preset TODO: document further
 * @param {Object} embedUiState initial embed state TODO: document further
 * @param {EmbedOptions} embedOptions options on how to embed and create the mapsight app
 * @return {Object} render function in browser or
 */
export default function embed(styleFunction, baseMapsightCoreConfig = {}, preset = {}, embedUiState = {}, embedOptions = {}) {
	return {
		styleFunction: styleFunction,
		baseMapsightCoreConfig: baseMapsightCoreConfig,
		preset: preset,
		embedUiState: embedUiState,
		embedOptions: embedOptions,
	};
}
