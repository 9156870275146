import olExtent from 'ol/extent';

export default function getPaddedViewExtent(map, padding) {
	const [mapWidth, mapHeight] = map.getSize();

	// remove padding from size (pixels)
	const [top, right, bottom, left] = padding;
	const innerMapWith = mapWidth - left - right;
	const innerMapHeight = mapHeight - top - bottom;
	const innerMapSize = [innerMapWith, innerMapHeight];

	// pixels -> coondinate
	const paddedCenterPixel = [
		left + (innerMapWith / 2),
		top + (innerMapHeight / 2),
	];
	const innerCenterCoordinate = map.getCoordinateFromPixel(paddedCenterPixel);

	const view = map.getView();
	const resolution = view.getResolution();
	const rotation = view.getRotation();

	return olExtent.getForViewAndSize(innerCenterCoordinate, resolution, rotation, innerMapSize);
}
