import React, {useEffect, useRef, memo} from 'react';

import {translate} from '../helpers/i18n';


function MapsightMap({id, onMount, ...attributes}) {
	const targetRef = useRef();

	useEffect(
		() => {
			onMount(targetRef.current);
		},

		// equivalent to `[]` (targetRef should never change)
		// FIXME: onMount should be a dep, but it's ok/desired to not list it?
		[targetRef]
	);

	return (
		<div className="ms3-map-target" tabIndex={0} id={id} ref={targetRef} {...attributes}>
			<span className="visuallyhidden">{translate('ui.map.visuallyhidden')}</span>
			<div className="ol-viewport">
				<canvas className="ol-unselectable" />
			</div>
		</div>
	);
}

export default memo(MapsightMap);
