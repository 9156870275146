var _Object$assign = require("core-js-pure/features/object/assign.js");
var _bindInstanceProperty = require("core-js-pure/features/instance/bind.js");
function _extends() {
  var _context;
  return module.exports = _extends = _Object$assign ? _bindInstanceProperty(_context = _Object$assign).call(_context) : function (n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, module.exports.__esModule = true, module.exports["default"] = module.exports, _extends.apply(null, arguments);
}
module.exports = _extends, module.exports.__esModule = true, module.exports["default"] = module.exports;