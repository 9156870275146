import flatten from 'lodash/flatten';

import olExtent from 'ol/extent';
import Feature from 'ol/feature';
import Point from 'ol/geom/point';

import getCentroidForFeatures from '../features/getCentroidForFeatures';

import getCentroid from './getCentroid';
import getUid from './getUid';

function defaultCreateClusterFeature(centroid, features) {
	const clusterFeature = new Feature(new Point(centroid));
	clusterFeature.set('features', features);
	return clusterFeature;
}

const identity = a => a;

const defaultDistance = 20;

export default function cluster(
	features,
	resolution,
	getFeaturesInExtent,
	distance = defaultDistance,
	createClusterFeature = defaultCreateClusterFeature,
	mapFeatureToCoordinate = getCentroid
) {
	if (resolution === undefined) {
		return features;
	}

	const mapDistance = distance * resolution;
	const extent = olExtent.createEmpty();
	/** @type {!Object.<string, boolean>} */
	const isClusteredMap = {};
	const markClustered = feature => {
		isClusteredMap[getUid(feature)] = true;
	};
	const isAlreadyClustered = feature => getUid(feature) in isClusteredMap;
	const isNotAlreadyClustered = feature => !isAlreadyClustered(feature);

	return flatten(features.map(feature => {
		if (isAlreadyClustered(feature)) {
			return false;
		}

		const coordinate = mapFeatureToCoordinate(feature);
		const canCluster = coordinate && coordinate.length >= 2 && coordinate[0] && coordinate[1];

		if (canCluster) {
			// reuse extent to reduce object creation
			/* extent = */olExtent.createOrUpdateFromCoordinate(coordinate, extent);
			/* extent = */olExtent.buffer(extent, mapDistance, extent);

			const neighbors = getFeaturesInExtent(extent).filter(isNotAlreadyClustered);
			if (neighbors.length >= 2) {
				neighbors.forEach(markClustered);
				return createClusterFeature(getCentroidForFeatures(neighbors), neighbors);
			}
		}

		return feature;
	})).filter(identity);
}
