import React, {memo} from 'react';

import {layerSwitcherConfigExternalSelector} from '../../store/selectors';

import FeatureSorter from '../feature-list-sorting';
import FeatureCycling from '../feature-list-cycling';

import TagSwitcher from '../tag-switcher';
import LayerSwitcher from '../layer-switcher';

import FeatureFilter from './filter';
import {MAKE_STICKY_CLASS} from './feature-list';

function FeatureListHeader({
	as: T = 'header',
	listUiOptions,
	showTagSwitcher,
	showExternalLayerSwitcher,
	allFeatures,
	filteredFeatures,
}) {
	return (
		<T className={`ms3-list-header ${listUiOptions.stickyHeader ? MAKE_STICKY_CLASS : ''}`}>
			<div style={{position: 'relative'}}>
				{(() => {
					const {filterControl, sortControl, cyclingControl} = listUiOptions;

					if (filterControl !== false) {
						return (
							<FeatureFilter allFeatures={allFeatures} />
						);
					} else if (sortControl !== false || !cyclingControl) {
						return (
							<div className="ms3-list__filter-box">&nbsp;</div>
						);
					} else {
						return null;
					}
				})()}

				{listUiOptions.sortControl !== false && (
					<FeatureSorter />
				)}

				{listUiOptions.cyclingControl && (
					<FeatureCycling filteredFeatures={filteredFeatures} />
				)}

				{listUiOptions.integratedList && showExternalLayerSwitcher && (
					<LayerSwitcher configSelector={layerSwitcherConfigExternalSelector} />
				)}

				{listUiOptions.integratedList && showTagSwitcher && (
					<TagSwitcher />
				)}
			</div>
		</T>
	);
}

export default memo(FeatureListHeader);
