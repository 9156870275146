import {setMapSizeAfterUpdate, UPDATE_SIZE} from '../actions';
import {async, controlled} from '../../base/actions';

function getAnchorForDirection(direction) {
	switch (direction) {
		case 'left':
			return 'right';
		case 'right':
			return 'left';
		case 'above':
			return 'bottom';
		case 'below':
			return 'top';
		default:
			return null;
	}
}

function movePoint(point, dX = 0, dY = 0, from = null, to = null) {
	let x = point[0];
	let y = point[1];

	if (from) {
		x = (from === 'left' && to !== 'right') ? x + dX : (from === 'right' && to !== 'left') ? x - dX : x;
		y = (from === 'above' && to !== 'below') ? y + dY : (from === 'below' && to !== 'above') ? y - dY : y;
	}

	if (to && from !== to) {
		x = (to === 'left' && from !== 'right') ? x + dX : (to === 'right' && from !== 'left') ? x - dX : x;
		y = (to === 'above' && from !== 'below') ? y + dY : (to === 'below' && from !== 'above') ? y - dY : y;
	}

	return [x, y];
}


export default function withAnimations(mapController, map) {
	function updateMapSize(stateSize, from = null, to = null, reCenter = false) {
		const oldSize = map.getSize();
		const targetElement = map.getTargetElement();

		if (targetElement) {
			const size = [targetElement.offsetWidth, targetElement.offsetHeight];

			// has container size changed compared to canvas? => update canvas size and recenter if requested
			if (!oldSize || size[0] !== oldSize[0] || size[1] !== oldSize[1]) {
				map.setSize(size);

				if (reCenter !== false) {
					reCenterMap(oldSize, size, from, to);
				}
			}

			// has container size changed to last state? => update state
			if (!stateSize || size[0] !== stateSize[0] || size[1] !== stateSize[1]) {
				mapController.dispatch(async(controlled(setMapSizeAfterUpdate(mapController.getName(), size))));
			}
		}
	}

	function reCenterMap(oldSize, size, from, to) {
		const dX = (oldSize[0] - size[0]) / 2;
		const dY = (oldSize[1] - size[1]) / 2;
		const xChanged = Math.abs(dX) > 1;
		const yChanged = Math.abs(dY) > 1;

		// if the center has not actually moved at least one pixel we are done
		if (xChanged || yChanged) {
			const newCenter = map.getCoordinateFromPixel(
				movePoint([oldSize[0] / 2, oldSize[1] / 2], dX, dY, from, to)
			);

			if (newCenter) {
				const view = map.getView();
				view.setCenter([
					// only change those coordinates that changed significantly
					xChanged ? newCenter[0] : view.getCenter()[0],
					yChanged ? newCenter[1] : view.getCenter()[1],
				]);
			}
		}
	}

	mapController.registerReducer(function reduceWithAnchoredViewport(state, action) {
		if (action.type === UPDATE_SIZE) {
			const viewportAnchor = getAnchorForDirection(action.from) || getAnchorForDirection(action.to);
			if (viewportAnchor && viewportAnchor !== state.viewportAnchor) {
				state = {
					...state,
					viewportAnchor: viewportAnchor,
				};
			}
			state = {
				...state,
				updateSizePending: true,
			};

			updateMapSize(state.size, action.from, action.to, action.reCenter);
		}

		return state;
	});
}
