/**
 * @param {string} name Name of block or element
 * @param {Record<string, boolean> | Array<string | boolean | null | undefined> | string | boolean | null | undefined} mods modifiers
 * @returns {string} class string
 */
export default function modClasses(name, mods) {
	// string to array
	if (typeof mods === 'string') {
		mods = [mods];
	}

	// object to array
	if (mods !== null && typeof mods === 'object' && !Array.isArray(mods)) {
		mods = Object.entries(mods)
			.filter(([_, val]) => val === true)
			.map(([key, _]) => key);
	}

	if (!mods || !Array.isArray(mods)) {
		mods = [];
	}

	return [
		name,
		...mods
			.filter((mod) => mod !== false && mod !== null && mod !== undefined)
			.map((mod) => `${name}--${mod}`),
	]
		.join(' ');
}
