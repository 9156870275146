import {useEffect, useRef} from 'react';
import {setViewportAnchor} from '@mapsight/core/lib/map/actions';
import {useDispatch} from 'react-redux';

import {MAP} from '../config/constants/controllers';

import useUpdateMapSizeOnTransitionEnd from './useUpdateMapSizeOnTransitionEnd';

function useMapsightPanel(containerRef, panelPosition, collapsed) {
	const previousPosition = useRef();
	const previousCollapsed = useRef();
	const dispatch = useDispatch();

	useEffect(function () {
		if (previousPosition.current && (previousPosition.current !== panelPosition || previousCollapsed.current !== collapsed)) {
			dispatch(setViewportAnchor(MAP, 'right'));
		}

		previousPosition.current = panelPosition;
		previousCollapsed.current = collapsed;
	}, [collapsed, dispatch, panelPosition]);

	useUpdateMapSizeOnTransitionEnd(containerRef, dispatch, previousPosition);
}

export default useMapsightPanel;
