import React, {memo} from 'react';
import {useSelector} from 'react-redux';

import {
	SEARCH_STATUS_EMPTY,
	SEARCH_STATUS_ERROR,
	SEARCH_STATUS_FOUND,
	SEARCH_STATUS_LOADING,
	searchResultFeaturesSelector,
	searchStatusSelector
} from '../../store/selectors';

import {translate} from '../../helpers/i18n';

import SearchResultEntry from './result-entry';

function SearchResult({onSelect}) {
	const status = useSelector(searchStatusSelector);
	const features = useSelector(searchResultFeaturesSelector);

	return (
		<div className={`ms3-search-result ms3-search-result--${status}`}>
			{status === SEARCH_STATUS_ERROR && (
				<p className="ms3-search-result__error">
					{translate('ui.search.result.error')}
				</p>
			)}

			{status === SEARCH_STATUS_EMPTY && (
				<p className="ms3-search-result__empty-message">
					{translate('ui.search.result.empty')}
				</p>
			)}

			{((status === SEARCH_STATUS_FOUND || status === SEARCH_STATUS_LOADING) && features) && (
				<ul className="ms3-search-result__list">
					{features.slice(0, 10).map((feature, i) => (
						<SearchResultEntry
							key={feature.id || i}
							feature={feature}
							onSelect={onSelect}
						/>
					))}
				</ul>
			)}
		</div>
	);
}

export default memo(SearchResult);
