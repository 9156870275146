import loadingStrategy from 'ol/loadingstrategy';
import GeoJSONFormat from 'ol/format/geojson';
import VectorSource from 'ol/source/vector';

import {getControllerForLayer} from '../tagLayer';

import FeatureSourceConnector from './FeatureSourceConnector';
import FeatureSelectionStatesManager from './FeatureSelectionStatesManager';

import MapAnimationManager from './MapAnimationManager';
import FeatureClusterManager from './FeatureClusterManager';
import {updateFeaturesInSource} from './updateFeaturesInSource';

export const DEFAULT_FEATURE_STATE = 'default';
const FALLBACK_INTERNAL_PROJECTION_CODE = 'EPSG:3857';

const featureShouldOverlay = feature => {
	const state = feature.get('state');
	return state && state !== DEFAULT_FEATURE_STATE;
};

/**
 * @classdesc
 * Extended openlayers vector source that is connected to a mapsight feature source
 *
 * @extends {ol.source.Vector}
 */
export default class VectorFeatureSource extends VectorSource {
	constructor(options = {}) {
		options = {
			canAnimate: true, // TODO: Make dynamic option?
			canCluster: true, // TODO: Make dynamic option?
			useSelectionOverlay: true, // TODO: Make dynamic option?
			...options,
		};

		super({
			strategy: loadingStrategy.all, // TODO: Support other strategies?!
			format: new GeoJSONFormat(), // TODO: Support other formats?!
		});

		this._options = options;

		this.setLoader(this._loader.bind(this));

		// mapsight
		this._internalProjection = FALLBACK_INTERNAL_PROJECTION_CODE;
		this._mapController = null; // @mapsight/core glue
		this._featureSourceConnector = new FeatureSourceConnector({
			format: this.getFormat(),
			internalProjection: FALLBACK_INTERNAL_PROJECTION_CODE,
			externalProjection: null,
			onUpdate: this._handleStateChanges.bind(this),
		});
		this._featureSelectionStatesConnector = new FeatureSelectionStatesManager({
			onChange: this._handleStateChanges.bind(this),
		});
		this._featureSelections = undefined;

		// openlayers
		this._layer = null; // reference to openlayers object
		this._active = false;

		// externalised functionality
		if (this._options.canAnimate) {
			this._mapAnimatorManager = new MapAnimationManager(this._featureSourceConnector, this._featureSelectionStatesConnector); // TODO: make optional
		}
		if (this._options.canCluster) {
			this._featureClusterManager = new FeatureClusterManager(this._featureSourceConnector, this._featureSelectionStatesConnector); // TODO: make optional
		}
	}

	_loader(extentToLoad, resolution, projection) {
		// TODO: What to do with extent and resolution
		// TODO: Check why projection is sometimes missing (workaround: hard coded fallback to EPSG:3857);
		const nextProjection = (projection && projection.getCode()) || this._internalProjection || FALLBACK_INTERNAL_PROJECTION_CODE;
		this._featureSourceConnector.setInternalProjection(nextProjection);
		this._featureSourceConnector.load();
	}

	setActive(active = true) {
		this._active = active;
		this.refresh();
	}

	/** @override */
	loadFeatures(extentToLoad, resolution, projection) {
		this._active = true;
		super.loadFeatures(extentToLoad, resolution, projection);

		if (this._options.canCluster) {
			this._featureClusterManager.setResolution(resolution);
			if (this._featureClusterManager.needsRefresh()) {
				this.refresh();
			}
		}
	}

	/** @override */
	refresh() {
		if (!this._active) {
			return;
		}

		// remove (all) features from overlay
		if (this._options.useSelectionOverlay && this._mapController) {
			this.getFeatures().forEach(feature => this._mapController.removeFeatureFromOverlay(this._layer, feature));
		}

		// get, filter, cluster and update features
		let features = this._featureSourceConnector.getFeatures();
		features = this._filter(features);

		// check if we got new features before clustering
		//   or cluster features could yield false positives
		const hasAdded = this._checkNewFeatures(features);

		features = this._cluster(features);
		updateFeaturesInSource(this, features);

		if (this._mapAnimatorManager && hasAdded) {
			this._mapAnimatorManager.handleNewFeatures();
		}

		// add features that should overlay to overlay
		if (this._options.useSelectionOverlay && this._mapController) {
			this.getFeatures().filter(featureShouldOverlay).forEach(feature => this._mapController.moveFeatureToOverlay(this._layer, feature));
		}

		super.refresh();
	}

	_checkNewFeatures(nextFeatures) {
		let hasAdded = false;
		const oldIds = this._featureIds || new Set();
		const newIds = new Set();

		nextFeatures.forEach(function checkNewFeature(nextFeature) {
			const newId = nextFeature.getId();
			if (newId) {
				newIds.add(newId);

				if (!oldIds.has(newId)) {
					hasAdded = true;
				}
			}
		});

		this._featureIds = newIds;

		return hasAdded;
	}

	_cluster(features) {
		if (this._options.canCluster) {
			const getFilteredFeaturesInExtent = (extent) => {
				const featuresInExtent = this._featureSourceConnector.getFeaturesInExtent(extent);
				return this._filter(featuresInExtent);
			};

			features = this._featureClusterManager.applyClusteringToFeatures(features, getFilteredFeaturesInExtent);
		}

		return features;
	}

	_filter(features) {
		// filter by selections
		if (this._featureSelections && this._featureSelections.length) {
			features = features.filter(feature => {
				const id = feature.getId();
				const selectionState = this._featureSelectionStatesConnector.get(id);
				return this._featureSelections.includes(selectionState);
			});
		}

		return features;
	}

	_handleStateChanges() {
		// update states
		this.refresh();
		this._applyStateToFeatures();
	}

	_applyStateToFeatures() {
		let hasChanged = false;

		const applyStateToFeature = (feature) => {
			const id = feature.getId();
			const selectionState = this._featureSelectionStatesConnector.get(id);
			const previousSelectionState = this._featureSelectionStatesConnector.getPrevious(id);

			feature.set('previousState', previousSelectionState, true);
			feature.set('state', selectionState, true);

			if (selectionState !== previousSelectionState) {
				hasChanged = true;
				feature.changed();
			}
		};
		this.getFeatures().forEach(applyStateToFeature);

		if (this._mapAnimatorManager && hasChanged) {
			this._mapAnimatorManager.handleFeatureSelectionStateChange();
		}

		return hasChanged;
	}

	addFeature(feature, {note = null} = {}) {
		const format = this.getFormat();
		this._featureSourceConnector.addFeature(format.writeFeatureObject(feature, {
			dataProjection: this._externalProjection,
			featureProjection: this._internalProjection,
		}), {note: note});
	}

	addFeatures(features, {note = null} = {}) {
		const format = this.getFormat();
		this._featureSourceConnector.addFeatures(format.writeFeaturesObject(features, {
			dataProjection: this._externalProjection,
			featureProjection: this._internalProjection,
		}), {note: note});
	}

	updateFeature(feature, {quiet = false, note = null} = {}) {
		if (!feature.getId()) {
			console.error('Cannot update feature without id');
			return;
		}

		const format = this.getFormat();
		this._featureSourceConnector.updateFeature(feature.getId(), format.writeFeatureObject(feature, {
			dataProjection: this._externalProjection,
			featureProjection: this._internalProjection,
		}), {quiet: quiet, note: note});
	}

	updateFeatures(features, {quiet = false, note = null} = {}) {
		if (!features || !Array.isArray(features)) {
			console.error('Cannot update features. Expected array, got: ', features);
			return;
		}

		const format = this.getFormat();
		this._featureSourceConnector.updateFeatures(format.writeFeaturesObject(features, {
			dataProjection: this._externalProjection,
			featureProjection: this._internalProjection,
		}).features, {quiet: quiet, note: note});
	}

	updateFeatureGeometry(feature, {quiet = false, note = null} = {}) {
		if (!feature) {
			console.error('Cannot update feature. Missing feature.');
			return;
		}

		const id = feature.getId();
		if (id === null || id === undefined) {
			console.error('Cannot update feature. Missing id');
			return;
		}

		const format = this.getFormat();
		this._featureSourceConnector.updateFeatureGeometry(id, format.writeGeometryObject(feature.getGeometry(), {
			dataProjection: this._externalProjection,
			featureProjection: this._internalProjection,
		}), {quiet: quiet, note: note});
	}

	updateFeatureGeometries(features, options) {
		features.forEach(feature => this.updateFeatureGeometry(feature, options));
	}

	clear() {
		this._featureSourceConnector.clear();
	}

	setProjection(projection) {
		this._externalProjection = projection;
		this._featureSourceConnector.setProjection(projection);
	}

	setFeatureSourceId(id) {
		this._featureSourceConnector.setId(id);
	}

	setFeatureSourcesControllerName(featureSourcesControllerName) {
		this._featureSourceConnector.setControllerName(featureSourcesControllerName);
	}

	setFeatureSelectionsControllerName(featureSelectionsControllerName) {
		this._featureSelectionStatesConnector.setFeatureSelectionsControllerName(featureSelectionsControllerName);
	}

	setFeatureSelections(featureSelections) {
		this._featureSelections = featureSelections;
	}

	setLayer(layer) {
		this._layer = layer;
		const mapController = getControllerForLayer(layer);
		if (mapController) {
			this.setMapController(mapController);
		}
		if (this._featureClusterManager) {
			this._featureClusterManager.setLayer(layer);
		}
		this.setActive(true);
	}

	setDrawInteraction(drawInteraction) {
		this._drawInteraction = drawInteraction;
	}

	setMapController(mapController) {
		this._mapController = mapController;
		this._featureSourceConnector.setStore(mapController.getStore());
		this._featureSourceConnector.setTargetControllerName(mapController.getName());
		this._featureSelectionStatesConnector.bindToStore(mapController.getStore());

		if (this._mapAnimatorManager) {
			this._mapAnimatorManager.setMapController(mapController);
		}
	}

	setKeepFeaturesInViewSelections(selections) {
		if (this._mapAnimatorManager) {
			this._mapAnimatorManager.setOption('keepFeaturesInViewSelections', selections);
		}
	}

	setKeepFeaturesInViewOptions(options) {
		if (this._mapAnimatorManager) {
			this._mapAnimatorManager.setOption('keepFeaturesInViewOptions', options);
		}
	}

	setKeepAllFeaturesInView(value) {
		if (this._mapAnimatorManager) {
			this._mapAnimatorManager.setOption('keepAllFeaturesInView', !!value);
		}
	}

	setFitFeaturesInViewSelections(selections) {
		if (this._mapAnimatorManager) {
			this._mapAnimatorManager.setOption('fitFeaturesInViewSelections', selections);
		}
	}

	setFitFeaturesInViewOptions(options) {
		if (this._mapAnimatorManager) {
			this._mapAnimatorManager.setOption('fitFeaturesInViewOptions', options);
		}
	}

	setFitAllFeaturesInView(value) {
		if (this._mapAnimatorManager) {
			this._mapAnimatorManager.setOption('fitAllFeaturesInView', value);
		}
	}

	setCenterFeaturesInViewSelections(selections) {
		if (this._mapAnimatorManager) {
			this._mapAnimatorManager.setOption('centerFeaturesInViewSelections', selections);
		}
	}

	setCenterFeaturesInViewOptions(options) {
		if (this._mapAnimatorManager) {
			this._mapAnimatorManager.setOption('centerFeaturesInViewOptions', options);
		}
	}

	setCenterAllFeaturesInView(value) {
		if (this._mapAnimatorManager) {
			this._mapAnimatorManager.setOption('centerAllFeaturesInView', value);
		}
	}

	setClusterFeatures(value) {
		if (this._featureClusterManager) {
			this._featureClusterManager.setActive(value);
		}
	}

	setClusterFeaturesOptions(options) {
		if (this._featureClusterManager) {
			this._featureClusterManager.setOptions(options);
		}
	}
}
