import forEach from 'lodash/forEach';

import {di, updateProxyObject} from '@mapsight/ol-proxy';

import {getIdForLayer, tagLayer} from './tagLayer';

export const Z_INDEX_OVERLAY = 2;
export const LAYER_GROUP_DEFAULT = 'default';
export const LAYER_TYPE = 'VectorOverlay';

function getOverlayFeatureCollectionForLayer(overlays, layer) {
	const overlay = overlays[getIdForLayer(layer)];
	return overlay && overlay.getSource().getFeaturesCollection();
}

export default function withLayerOverlays(mapController, map) {
	const overlays = {};

	mapController.moveFeatureToOverlay = function moveFeatureToOverlay(layer, feature) {
		map.skipFeature(feature);

		const overlayFeatureCollection = getOverlayFeatureCollectionForLayer(overlays, layer);
		if (overlayFeatureCollection && overlayFeatureCollection.getArray().indexOf(feature) === -1) {
			overlayFeatureCollection.push(feature);
		}
	};

	mapController.removeFeatureFromOverlay = function removeFeatureFromOverlay(layer, feature) {
		map.unskipFeature(feature);

		const overlayFeatureCollection = getOverlayFeatureCollectionForLayer(overlays, layer);
		if (overlayFeatureCollection) {
			overlayFeatureCollection.remove(feature);
		}
	};

	function updateLayerOverlay(id, newDefinition, oldDefinitions) {
		const oldDefinition = oldDefinitions[id];
		newDefinition = newDefinition && {zIndex: Z_INDEX_OVERLAY, ...newDefinition};

		// update overlay
		// TODO: make overlay optional?
		const featureSelections = newDefinition && newDefinition.options && newDefinition.options.selections;
		updateProxyObject({
			di: di,
			group: 'layer',
			oldObject: overlays[id],
			oldDefinition: {...oldDefinition, type: LAYER_TYPE},
			newDefinition: featureSelections && {...newDefinition, type: LAYER_TYPE},
			remover: function removeOverlayLayer() {
				overlays[id].setMap(null);
				delete overlays[id];
			},
			adder: function addOverlayLayer(overlay) {
				overlay.setMap(map);
				overlays[id] = overlay;
				tagLayer(overlay, mapController, id);
			},
			parentObject: mapController,
		});
	}

	mapController.getAndObserveUncontrolled(
		state => state.layers,
		function updateLayerOverlays(newDefinitions = {}, oldDefinitions = {}) {
			forEach(oldDefinitions, (_, id) => updateLayerOverlay(id, newDefinitions[id], oldDefinitions));
			forEach(newDefinitions, (newDefinition, id) => updateLayerOverlay(id, newDefinition, oldDefinitions));
		}
	);
}
