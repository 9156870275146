import * as c from '../../config/constants/controllers';
import {searchResultSelectionFeatureSourceSelector} from '../../store/selectors';

const defaultFeatureSourceId = 'searchResult';
const defaultFeatureSourcesControllerName = c.FEATURE_SOURCES;

/**
 * This plugin will bind the search result feature source to the mapsight ui state
 *
 * @param {Object} [options] options
 * @param {String} [options.featureSourceId] name of the feature source, default = "searchResult"
 * @param {String} [options.featureSourcesControllerName] name of the feature sources controller, defaults to mapsight ui default
 * @return {MapsightUiPlugin} plugin
 */
export default function createPlugin(options = {}) {
	const {
		featureSourceId = defaultFeatureSourceId,
		featureSourcesControllerName = defaultFeatureSourcesControllerName,
	} = options;

	return {
		afterCreate: function searchPlugin(context) {
			context.controllers[featureSourcesControllerName].bindFeatureSourceToStore(featureSourceId, searchResultSelectionFeatureSourceSelector);
		},
	};
}

