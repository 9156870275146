import * as localStorage from '@neonaut/lib-redux/es/local-storage';
import merge from 'lodash/merge';
import {mergeAll} from '@mapsight/core/lib/base/actions';

/**
 * This plugin will synchronize the some paths of the store with the browser's local storage and restored on initialization.
 * See {CreateOptions.localStorageKey} and {CreateOptions.localStoragePaths} for configuration.
 *
 * @param {Object} [options] options (not used currently)
 * @return {MapsightUiPlugin} plugin
 */
export default function createPlugin(options = {}) {
	if (typeof window === 'undefined') {
		console.error('This plugin will only work as intended in the browser!');
	}

	let localStoragePaths;
	let restoredState;
	let isRestored = false;

	return {
		afterInit: function localStorageInitPlugin(context) {
			const {createOptions, initialState} = context;

			localStoragePaths = createOptions.localStoragePaths;

			// override initial state from local storage
			// FIXME: The local-storage module has global state! This will break with multiple parts trying to use a different
			// storage key on the same page!
			localStorage.setStorageKey(createOptions.localStorageKey); // TODO what is stored in localStorage ... do we have to clear tag states there?

			// FIXME: This should only fetch the paths specified and not ALL!
			restoredState = localStorage.getLocalStorageState();

			// We cannot change the state before we have not at least rendered the dehydrated html at least once
			// to prevent a mixed up DOM when server-side state and client-side determined state mismatch or has changed
			// since page load. If the app is not re-hydrated but initially rendered in the client we can omit the additional render
			// and set the restored state on creation (before render).
			if (!context.isStateReHydrated) {
				isRestored = true;
				merge(initialState, restoredState);
			}
		},
		afterCreate: function localStorageCreatePlugin(context) {
			// sync local storage
			if (localStoragePaths) {
				localStorage.synchronizePathsToLocalStorage(context.store, localStoragePaths);
			}
		},
		afterRender: function localStorageAfterRenderPlugin(context) {
			if (!isRestored) {
				context.store.dispatch(mergeAll(restoredState));
			}
		}
	};
}
