import {applyMiddleware} from 'redux';
import flattenActions from '@neonaut/lib-redux/flatten-actions';  // FIXME: use es once default export is fixed in @neonaut/lib-redux

// TODO: This is a candidate for lib-redux
export default function createActionWatcher() {
	const context = {
		handler: null,
	};

	context.enhancer =  applyMiddleware(store => nextMiddleware => action => {
		const result = nextMiddleware(action);

		if (context.handler) {
			flattenActions(action).map(singleAction => context.handler(singleAction));
		}

		return result;
	});

	return context;
}
