/**
 * convert string to a valid css name by trimming it, converting it to lowercase and
 * replacing everything but numbers and latin characters with a dash. An dash will be prepended
 * if the string starts with an number!
 *
 * @param {string} str string to be converted
 * @returns {string} converted string
 */
export default function escapeCssName(str) {
	if (str === undefined || str === null) {
		return str;
	}

	return String(str)
		.trim()
		.toLowerCase()
		.replace(/[^a-z0-9]/g, '-')
		.replace(/^([0-9])/, '-$1');
}
