import Point from 'ol/geom/point';
import Feature from 'ol/feature';

import defaultClusterFeaturePropertiesFunction from './defaultClusterFeaturePropertiesFunction';

function createClusterFeatureId(coordinates, features) {
	return 'cluster||' + features.map(feature => feature.getId()).join('||');
}

/**
 * creates a createCluster* function that will create single point features for each cluster
 *
 * @param {function(object,object):object} clusterFeaturePropertiesFunction clusterFeaturePropertiesFunction
 * @param {*} options options
 * @param {Map<string,object>} [cache] cache
 * @param {Map<string,object>} [previousCache] previous cache
 * @returns {function(*=, *=): *} createCluster* function
 */
export default function createCreateClusterIntoSingleFeature(clusterFeaturePropertiesFunction, options, cache, previousCache) {
	clusterFeaturePropertiesFunction = clusterFeaturePropertiesFunction || defaultClusterFeaturePropertiesFunction;

	return function clusterIntoSingleFeature(clusterCenter, clusterFeatures) {
		const id = createClusterFeatureId(clusterCenter, clusterFeatures);

		const baseProperties = {
			id: id,
			geometry: new Point(clusterCenter),
			cluster: true,
			clusterSize: clusterFeatures.length,
			clusterFeatures: clusterFeatures,
		};

		const featureProperties = clusterFeaturePropertiesFunction(baseProperties, options);

		// reuse old feature and just update props or create new feature
		let clusterFeature;
		const cachedCluster = previousCache && previousCache.get(id);
		if (cachedCluster) {
			clusterFeature = cachedCluster.clusterFeature;
			clusterFeature.setProperties(featureProperties);
		} else {
			clusterFeature = new Feature(featureProperties);
			clusterFeature.setId(id);
		}

		if (cache) {
			cache.set(id, {clusterFeature: clusterFeature, features: clusterFeatures});
		}

		return clusterFeature;
	};
}
