import {observeState} from '@neonaut/lib-redux/es/observe-state';
import {trackEvent} from '@neonaut/lib-js/es/misc/piwik';

import {isFullscreenSelector} from '../../store/selectors';

const defaultCategory = 'Mapsight';
const defaultActionEnabled = 'FullscreenEnabled';
const defaultActionDisabled = 'FullscreenDisabled';

/**
 * This plugin will track piwik actions when the fullscreen mode is en-/disabled.
 * @see `@neonaut/lib-js/es/misc/piwik`
 *
 * @param {Object} [options] options
 * @param {String} [options.category="Mapsight"] piwik category to track
 * @param {String} [options.actionEnabled="FullscreenEnabled"]  piwik action to track when enabling fullscreen
 * @param {String} [options.actionDisabled="FullscreenDisabled"]  piwik action to track when disabling fullscreen
 * @return {MapsightUiPlugin} plugin
 */
export default function createPlugin(options = {}) {
	const {
		category = defaultCategory,
		actionEnabled = defaultActionEnabled,
		actionDisabled = defaultActionDisabled,
		disabled,
	} = options;

	if(disabled === true) {
		console.log('plugins/browser/piwik-track-fullscreen-toggle-event loaded but disabled');
		return {};
	}

	return {
		afterCreate: function piwikTrackFullscreenToggleEvent(context) {
			const {store} = context;

			// track fullscreen toggles
			observeState(store, isFullscreenSelector, newValue => trackEvent(category, newValue ? actionEnabled : actionDisabled));
		},
	};
}
