import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {getFilteredFeatures} from '@mapsight/core/lib/feature-selections/selectors';
import {findFeatureInFeatureSourcesById} from '@mapsight/core/lib/feature-sources/selectors';

import {FEATURE_LIST, FEATURE_SELECTIONS, FEATURE_SOURCES} from '../../config/constants/controllers';
import {VIEW_DESKTOP, VIEW_FULLSCREEN, VIEW_MOBILE} from '../../config/constants/app';
import {FEATURE_SELECTION_SELECT} from '../../config/feature/selections';

import {
	isViewMobile,
	listUiOptionDetailsSelector,
	listUiOptionIntegratedSelector, listVisible,
	userPreferenceListVisibleSelector,
	viewSelector
} from '../../store/selectors';
import {toggleUserPreferenceListVisible} from '../../store/actions';

import MainPanel, {
	MS3_UI_MAIN_PANEL_CONTENT_TYPE_LIST,
	MS3_UI_MAIN_PANEL_CONTENT_TYPE_SELECTION_INFO,
	MS3_UI_MAIN_PANEL_POSITION_BELOW,
	MS3_UI_MAIN_PANEL_POSITION_LEFT
} from './main-panel';

export default connect(
	createStructuredSelector({
		view: viewSelector,
		featureSelection: state => state[FEATURE_SELECTIONS][FEATURE_SELECTION_SELECT],
		featureSources: state => state[FEATURE_SOURCES],
		showList: listVisible,
		userPreferenceListVisible: userPreferenceListVisibleSelector,
		detailsInList: listUiOptionDetailsSelector,
		integratedList: listUiOptionIntegratedSelector
	}),
	(dispatch) => ({
		dispatch: dispatch,

		// user collapsible list
		toggleList: () => dispatch(toggleUserPreferenceListVisible()),
	}),
	(stateProps, dispatchProps, ownProps) => {
		const {view, showList, userPreferenceListVisible, detailsInList, integratedList, featureSources, featureSelection} = stateProps;

		let feature;
		const features = getFilteredFeatures(featureSelection);
		const featureId = features && features[0];
		if (featureId) {
			feature = findFeatureInFeatureSourcesById(featureSources, featureId);
		}

		// There are 4 render cases:
		//
		// ------------------------------------------------------------------------------------------------------
		//    panelPosition  contentType    collapsed
		// ======================================================================================================
		// A  left/below     null           true        => show nothing
		// ------------------------------------------------------------------------------------------------------
		// B  left           selectionInfo  false       => show feature selection details on the left of the map
		// ------------------------------------------------------------------------------------------------------
		// C  left           list           true/false  => show list on the left of the map
		// ------------------------------------------------------------------------------------------------------
		// D  below          selectionInfo  true/false  => show feature selection details below the map
		// ------------------------------------------------------------------------------------------------------

		let contentType = null;
		if (
			(feature && !(detailsInList && showList) && view !== VIEW_MOBILE) ||
			(view === VIEW_MOBILE && !showList)
		) {
			contentType = MS3_UI_MAIN_PANEL_CONTENT_TYPE_SELECTION_INFO;
		} else if (showList && (view === VIEW_FULLSCREEN || (view === VIEW_DESKTOP && integratedList))) {
			contentType = MS3_UI_MAIN_PANEL_CONTENT_TYPE_LIST;
		}

		const canUserCollapseList = contentType === MS3_UI_MAIN_PANEL_CONTENT_TYPE_LIST && view === VIEW_FULLSCREEN;
		const collapsed = contentType === null || canUserCollapseList && !userPreferenceListVisible;
		const panelPosition = isViewMobile(view) ?
			MS3_UI_MAIN_PANEL_POSITION_BELOW : // case A) or D)
			MS3_UI_MAIN_PANEL_POSITION_LEFT;

		return {
			...dispatchProps,
			...ownProps,

			// content
			view: view,
			feature: feature,
			contentType: contentType,

			// options
			collapsed: collapsed,
			panelPosition: panelPosition,

			// user collapsible list
			canUserCollapseList: canUserCollapseList,
			userPreferenceListVisible: userPreferenceListVisible,
		};
	}
)(MainPanel);
