import {FEATURE_SELECTION_PRESELECT} from '../config/feature/selections';

import createAppTitlePlugin from './browser/app-title';
import createCorePresetPlugin from './common/core-preset';
import createFeatureDeepLinkPlugin from './browser/feature-deep-link';
import createFeatureSelectionDetailsUrlPlugin from './common/feature-selection-details-url';
import createLocalStoragePlugin from './browser/local-storage';
import createOlProxyPlugin from './common/ol-proxy';
import createNoScrollPlugin from './browser/no-scroll';
import createPiwikTrackFeatureSelectionEventPlugin from './browser/piwik-track-feature-selection-event';
import createPiwikTrackFullscreenToggleEventPlugin from './browser/piwik-track-fullscreen-toggle-event';
import createLangPlugin from './common/lang';
//import createQuitFullscreenOnRenderPlugin from './browser/quit-fullscreen-on-render';
import createSearchPlugin from './browser/search';
import createToggleMobileViewsPlugin from './browser/toggle-mobile-views';
import createUserGeolocationPlugin from './browser/user-geolocation';
import createViewsPlugin from './browser/views';

/**
 * Create the default set of plugins for use in the browser.
 *
 * @param {Object<String, Object>} options options map for default plugins
 * @return {MapsightUiPluginDefinition[]} sorted list of plugins
 */
// TODO document: werden diese Plugin in der genannten Reihenfolge ausgeführt?
export default function createDefaultPlugins(options = {}) {
	return [
		['lang', createLangPlugin()],
		['olProxy', createOlProxyPlugin(options.olProxy)],
		['appTitle', createAppTitlePlugin(options.appTitle)],
		['corePreset', createCorePresetPlugin(options.corePreset)],
		['featurePreselectDeepLink', createFeatureDeepLinkPlugin({
			getParameter: 'preselect',
			featureSelection: FEATURE_SELECTION_PRESELECT,
			clearMissingParamters: ['feature'],
			...(options.featurePreselectDeepLink || {}),
		})],
		['featureDeepLink', createFeatureDeepLinkPlugin(options.featureDeepLink)],
		['featureSelectionDetailsUrl', createFeatureSelectionDetailsUrlPlugin(options.featureSelectionDetailsUrl)],
		['localStoragePlugin', createLocalStoragePlugin(options.localStoragePlugin)],
		['noScroll', createNoScrollPlugin(options.noScroll)],
		['piwikTrackFeatureSelectionEvent', createPiwikTrackFeatureSelectionEventPlugin(options.piwikTrackFeatureSelectionEvent)],
		['piwikTrackFullscreenToggleEvent', createPiwikTrackFullscreenToggleEventPlugin(options.piwikTrackFullscreenToggleEvent)],
		['search', createSearchPlugin(options.search)],
		//['quitFullscreenOnRender', createQuitFullscreenOnRenderPlugin(options.quitFullscreenOnRender)],
		['toggleMobileViews', createToggleMobileViewsPlugin(options.toggleMobileViews)],
		['userGeolocation', createUserGeolocationPlugin(options.userGeolocation)],
		['views', createViewsPlugin(options.views)],
	];
}
