import Point from 'ol/geom/point';
import LineString from 'ol/geom/linestring';
import Polygon from 'ol/geom/polygon';

import getLSSegmentVertices from './getLineStringSegmentVerticesWithRotation';
import getPolygonSegmentVertices from './getPolygonRingSegmentVerticesWithRotation';

const deriveLSVertexGeometries = (b, v, i) => getLSSegmentVertices(b, v, i).map(([c, r]) => [new Point(c), {rotation: r}]);
const derivePolygonVertexGeometries = (b, v, i, j) => getPolygonSegmentVertices(b, v, i, j).map(([c, r]) => [new Point(c), {rotation: r}]);

export default function deriveGeometriesFromBase(base, derivation = null) {
	// early return for common case
	if (!derivation) {
		return [
			[base, {}]
		];
	}

	if (base instanceof LineString) {
		switch (derivation) {
			case 'vertex':
			case 'vertices':
				return deriveLSVertexGeometries(base);
			case 'firstVertex':
			case 'firstVertices':
				return deriveLSVertexGeometries(base, 'first');
			case 'lastVertex':
			case 'lastVertices':
				return deriveLSVertexGeometries(base, 'last');
			case 'segmentStart':
			case 'segmentStarts':
				return deriveLSVertexGeometries(base, 'start');
			case 'intermediateSegmentStart':
			case 'intermediateSegmentStarts':
				return deriveLSVertexGeometries(base, 'start', 'intermediate');
			case 'segmentEnd':
			case 'segmentEnds':
				return deriveLSVertexGeometries(base, 'end');
			case 'intermediateSegmentEnd':
			case 'intermediateSegmentEnds':
				return deriveLSVertexGeometries(base, 'end', 'intermediate');
			default:
		}
	} else if (base instanceof Polygon) {
		// TODO: discriminate outer and inner ring
		switch (derivation) {
			case 'vertex':
			case 'vertices':
				return derivePolygonVertexGeometries(base);
			case 'firstVertex':
			case 'firstVertices':
				return derivePolygonVertexGeometries(base, 'first');
			case 'lastVertex':
			case 'lastVertices':
				return derivePolygonVertexGeometries(base, 'last');
			case 'segmentStart':
			case 'segmentStarts':
				return derivePolygonVertexGeometries(base, 'start');
			case 'intermediateSegmentStart':
			case 'intermediateSegmentStarts':
				return derivePolygonVertexGeometries(base, 'start', 'intermediate');
			case 'segmentEnd':
			case 'segmentEnds':
				return derivePolygonVertexGeometries(base, 'end');
			case 'intermediateSegmentEnd':
			case 'intermediateSegmentEnds':
				return derivePolygonVertexGeometries(base, 'end', 'intermediate');
			default:
		}
	}

	console.error('Could not derive ' + derivation + ' from base geometry ' + base);

	return [
		[base, {}]
	];
}
