export const FEATURE_SOURCES = 'featureSources';
export const FEATURE_SELECTIONS = 'featureSelections';

export const FEATURE_LIST = 'list';
export const MAP = 'map';
export const PROJECTIONS = 'projections';

export const USER_GEOLOCATION = 'userGeolocation';

export const TIME_FILTER = 'timeFilter';
export const TAG_FILTER = 'tagFilter';
