import flow from 'lodash/flow';

import {DEFAULT_OPTIONS as FIT_DEFAULT_OPTIONS} from '@mapsight/lib-ol/map/fitToFeatures';
import cluster from '@mapsight/lib-ol/feature/cluster';

import {getControllerForLayer} from '../tagLayer';

import defaultClusterFeaturePropertiesFunction from './defaultClusterFeaturePropertiesFunction';
import createCreateClusterIntoSpreadRadiusFeature from './createCreateClusterIntoSpreadRadiusFeature';
import createCreateClusterIntoSingleFeature from './createCreateClusterIntoSingleFeature';

const SPREAD_CLOSE_TO_MINIMUM_THRESHOLD = 1.5;

export default class FeatureClusterManager {
	constructor(featureSourceConnector, featureSelectionStatesManager) {
		this._featureSourceConnector = featureSourceConnector;
		this._featureSelectionStatesManager = featureSelectionStatesManager;

		this._options = {
			distance: 20, // TODO: Magic number
			spreadDistance: 10, // TODO: Magic number
			spreadRadius: 25, // TODO: Magic number
			spreadFeaturesIfCloseToMinResolution: true, // TODO: keep default?
			fitInViewSelections: ['select'], // TODO: keep default?
			fitInViewOptions: {
				...FIT_DEFAULT_OPTIONS,
				maxZoom: 9999,
				nearest: false,
				skipIfInView: false,
			},
		};

		this._clusterCache = new Map();
		this._resolution = null;
		this._active = false;
		this._needsRefresh = true;

		this._layer = null;
		this._mapController = null;
	}

	setOptions(options) {
		if (options) {
			Object.assign(this._options, options);
		}
	}

	setActive(active) {
		this._active = !!active;
	}

	setResolution(resolution) {
		if (resolution !== this._resolution) {
			this._resolution = resolution;
			this._needsRefresh = true;
		}
	}

	setLayer(layer) {
		this._layer = layer;
		this._mapController = getControllerForLayer(layer);
	}

	needsRefresh() {
		return this._active && this._needsRefresh;
	}

	isActive() {
		return this._active;
	}

	/**
	 * Get clustered features from features for the given resolution and layer.
	 *
	 * @param {*} features features
	 * @param {function(Extent):object[]} getFeaturesInExtent get features in extent
	 * @returns {*} clustered features
	 */
	applyClusteringToFeatures(features, getFeaturesInExtent) {
		this._needsRefresh = false;

		if (!this._active) {
			return features;
		}

		// Check if we should spread because the resolution is close to minimum
		let spread = this._options.spread;
		if (spread !== true && this._options.spreadFeaturesIfCloseToMinResolution) {
			const mapMinResolution = this._mapController.getMinResolution();

			if (this._resolution && mapMinResolution) {
				const minResolution = Math.max(this._layer.getMinResolution(), mapMinResolution);
				spread = this._resolution / minResolution < SPREAD_CLOSE_TO_MINIMUM_THRESHOLD;
			}
		}

		if (spread) {
			return this._getFeaturesSpread(features, getFeaturesInExtent);
		}

		return this._getFeaturesClustered(features, getFeaturesInExtent);
	}

	_cluster(features, getFeaturesInExtent, distance, createClusterFeatureFunction) {
		return cluster(features, this._resolution, getFeaturesInExtent, distance, createClusterFeatureFunction);
	}

	_getFeaturesSpread(features, getFeaturesInExtent) {
		return this._cluster(
			features,
			getFeaturesInExtent,
			this._options.spreadDistance || this._options.distance,
			createCreateClusterIntoSpreadRadiusFeature(this._resolution, this._options.spreadRadius)
		);
	}

	_getFeaturesClustered(unClusteredFeatures, getFeaturesInExtent) {
		const propertiesFunction = flow([
			// Add state properties
			properties => Object.assign(properties, {
				state: this._featureSelectionStatesManager.get(properties.id),
				previousState: this._featureSelectionStatesManager.getPrevious(properties.id),
			}),
			this._mapController.getClusterFeaturePropertiesFunction() || defaultClusterFeaturePropertiesFunction,
		]);

		this._clusterCache.clear();

		// actually cluster
		const clusteredFeatures = this._cluster(
			unClusteredFeatures,
			getFeaturesInExtent,
			this._options.distance,
			createCreateClusterIntoSingleFeature(propertiesFunction, this._options, this._clusterCache, new Map(this._clusterCache))
		);

		// zoom to features on click
		if (this._options.fitInViewSelections) {
			this._clusterCache.forEach(({features}, id) => {
				const state = this._featureSelectionStatesManager.get(id);

				if (state && state !== this._featureSelectionStatesManager.getPrevious(id) && this._options.fitInViewSelections.indexOf(state) > -1) {
					this._featureSelectionStatesManager.deselect(state, id);
					this._mapController.fitMapViewToFeatures(features, this._options.fitInViewOptions);
				}
			});
		}

		return clusteredFeatures;
	}
}
