function getLinearRingSegmentVerticesWithRotation(linearRing, verticesFilter, intermediateFilter) {
	const verticesWithRotation = [];
	const ringCoords = linearRing.getCoordinates();

	ringCoords.forEach((start, i) => {
		const end = ringCoords[i + 1];
		if (!end) {
			return;
		}
		const isFirst = i === 0;
		const isLast = ringCoords[i + 2] === undefined;

		const dx = end[0] - start[0];
		const dy = end[1] - start[1];
		const rotation = -Math.round(Math.atan2(dy, dx) * 100) / 100;

		if (
			verticesFilter === 'first' ? isFirst : (
				(verticesFilter === 'start' || verticesFilter === 'all') &&
				!(intermediateFilter === 'intermediate' && isFirst)
			)
		) {
			verticesWithRotation.push([[...start], rotation]);
		}

		if (
			verticesFilter === 'last' ? isLast : (
				(verticesFilter === 'end' || verticesFilter === 'all') &&
				!(intermediateFilter === 'intermediate' && isLast)
			)
		) {
			verticesWithRotation.push([[...end], rotation]);
		}
	});

	return verticesWithRotation;
}

export default function getPolygonRingSegmentVerticesWithRotation(polygonGeometry, verticesFilter = 'all', intermediateFilter = 'all', ringFilter = 'all') {
	let verticesWithRotation = [];

	if (ringFilter === 'all' || ringFilter === 'outer') {
		const outerRing = polygonGeometry.getLinearRing(0);
		if (outerRing) {
			verticesWithRotation = getLinearRingSegmentVerticesWithRotation(outerRing, verticesFilter, intermediateFilter);
		}
	}
	if (ringFilter === 'all' || ringFilter === 'inner') {
		const innerRing = polygonGeometry.getLinearRing(1);
		if (innerRing) {
			verticesWithRotation = [
				...verticesWithRotation,
				...getLinearRingSegmentVerticesWithRotation(innerRing, verticesFilter, intermediateFilter),
			];
		}
	}

	return verticesWithRotation;
}
