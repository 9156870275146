// TODO: https://www.npmjs.com/package/i18next ?

import de from './de';
import en from './en';
// import es from './es';
// import fr from './fr';


const DEFAULT_LANGUAGE = 'de';
let documentLanguage = typeof document !== 'undefined' && document.documentElement && document.documentElement.lang || DEFAULT_LANGUAGE;

const DICTIONARY = {
	de: de,
	en: en,
	// es: es,
	// fr: fr,
};

// use this to make dictionary expandable/overwritable by clients
export function getDictionary() {
	return DICTIONARY;
}

export function setDocumentLanguage(value) {
	documentLanguage = value;
}

export function getDocumentLanguage() {
	return documentLanguage;
}

/**
 * @param {string} key
 * @param {string} language
 * @returns {string}
 */
export function translate(key, language = documentLanguage) {
	if (
		DICTIONARY[language]
		&& typeof DICTIONARY[language] === 'object'
		&& typeof DICTIONARY[language][key] === 'string'
	) {
		return DICTIONARY[language][key];
	}

	// fallback to default lang
	if (
		DICTIONARY[DEFAULT_LANGUAGE]
		&& typeof DICTIONARY[DEFAULT_LANGUAGE] === 'object'
		&& typeof DICTIONARY[DEFAULT_LANGUAGE][key] === 'string'
	) {
		return DICTIONARY[DEFAULT_LANGUAGE][key];
	}

	// fallback to `key`
	return key;
}
