import {createSelector, createStructuredSelector} from 'reselect';

import find from 'lodash/find';
import get from 'lodash/get';
import union from 'lodash/union';

import {applyFilters} from '../filter/selectors';

export const STATUS_OK = 'ok';
export const STATUS_LOADING = 'loading';
export const STATUS_ERROR = 'error';

export const ERROR = 'error';
export const ERROR_PENDING = 'errorPending';
export const ERROR_COLD_CACHE = 'errorColdCache';

export const getSourceFilters = source => source && source.filters || [];
export const getAllFeatures = source => get(source, ['data', 'features'], []);

export const getSourceData = source => source.data || {};
export const getSourceDataHistory = source => source.dataHistory || {past: [], future: []};

export const canUndo = source => !!(source.dataHistory && source.dataHistory.past && source.dataHistory.past.length > 0);
export const canRedo = source => !!(source.dataHistory && source.dataHistory.future && source.dataHistory.future.length > 0);

export const getControllerOverrideFeatureSourceFilters =
	(ctr) => get(ctr, ['overrideFeatureSourceFilters']);

/**
 * @param {object} feature source state
 *
 * @returns {object[]} features
 */
export const getFilteredFeatures = createSelector(getSourceFilters, getAllFeatures, applyFilters); // TODO: does not work!

export const getFeatureSourceStatus = featureSource => {
	if (featureSource) {
		return featureSource.error ? STATUS_ERROR : featureSource.isLoading ? STATUS_LOADING : STATUS_OK;
	}

	return null;
};

export const findFeatureSourceForFeatureId = (sources, featureId) =>
	find(sources, source => source.ids && source.ids.indexOf(featureId) > -1);

export const findFeatureInFeatureSourcesById = (sources, featureId, {includeFiltered = true} = {}) => {
	if (!featureId) {
		return null;
	}

	const source = findFeatureSourceForFeatureId(sources, featureId);
	if (!source) {
		return null;
	}

	const sourceFeatures = includeFiltered ? getAllFeatures(source) : getFilteredFeatures(source);
	if (!sourceFeatures || !sourceFeatures.length) {
		return null;
	}

	return find(sourceFeatures, feature => feature.id === featureId);
};

export const getRefreshPaused = source => !!source.refreshPaused;

export const getFeatureSourceError = featureSource => (featureSource ? featureSource.error : 'unknown feature source');

function createUnfilteredFeatureSourceSelector(featureSourcesControllerName, featureSourceId) {
	return state => state[featureSourcesControllerName] && state[featureSourcesControllerName][featureSourceId];
}

function createUnfilteredFeaturesSelector(featureSourcesControllerName, featureSourceId) {
	return state => state[featureSourcesControllerName] &&
		state[featureSourcesControllerName][featureSourceId] &&
		state[featureSourcesControllerName][featureSourceId].data &&
		state[featureSourcesControllerName][featureSourceId].data.features || null;
}

export function createFilteredFeatureSourceSelector(
	featureSourcesControllerName,
	featureSourceId,
	targetControllerName,
) {
	const featureSourceSelector = createUnfilteredFeatureSourceSelector(featureSourcesControllerName, featureSourceId);
	let filtersSelector = () => null;

	// internal state holding
	const cache = {};

	return function (state) {
		let hasChanged = false;

		const source = featureSourceSelector(state);
		if (source !== cache.source) {
			cache.source = source;
			hasChanged = true;

			const filterNames = (() => {
				if (targetControllerName && state[targetControllerName]) {
					const ctrOverride =
						getControllerOverrideFeatureSourceFilters(state[targetControllerName]);
					if (ctrOverride) {
						return ctrOverride;
					}
				}
				return getSourceFilters(source);
			})();

			if (filterNames !== cache.filterNames) {
				cache.filterNames = filterNames;

				const filterSelectors = {};
				if (filterNames) {
					filterNames.forEach(filter => {
						filterSelectors[filter] = filterState => filterState[filter];
					});
				}

				filtersSelector = createStructuredSelector(filterSelectors);
			}
		}

		const filters = filtersSelector(state);
		if (filters !== cache.filters) {
			cache.filters = filters;
			hasChanged = true;
		}

		if (hasChanged) {
			cache.state = source && {
				...source,
				data: (filters && source.data && source.data.features) ? {
					type: 'FeatureCollection', // TODO: Check if necessary
					...source.data,
					features: applyFilters(filters, source.data.features, featureSourceId),
				} : source.data,
			};
		}

		return cache.state;
	};
}

/**
 * Returns tag groups for features
 *
 * @param {object[]} features features
 * @returns {object<string,{andJunction:boolean,tags:string[]}>} tag groups
 */
export const tagGroupsFromFeaturesSelector = features => {
	const result = {};
	if (features) {
		features.forEach(entry => {
			if (entry.properties && entry.properties.tagGroups) {
				Object.keys(entry.properties.tagGroups).forEach(group => {
					// hier müssen nicht nur die Objekte gemergt werden, sondern die Inhalte des Array .tags
					result[group] = {
						andJunction: entry.properties.tagGroups[group].andJunction || (result[group] && result[group].andJunction),
						tags: result[group] ?
							union(result[group].tags, entry.properties.tagGroups[group].tags)
							: entry.properties.tagGroups[group].tags
					};
				});
			}
		});
	}

	return result;
};

/**
 * Return a key-value-object of grouped tags with group name as key and tags as value
 *
 * @param {object[]} features features
 * @returns {object<string,string[]>} grouped tags
 * @deprecated Use export getGroupedTagsWithCountFromFeatures instead!
 */
export function groupedTagsFromFeautresSelector(features) {
	const result = {};
	if (features) {
		features.forEach(entry => {
			if (entry.properties && entry.properties.tagGroups) {
				Object.keys(entry.properties.tagGroups).forEach(key => {
					result[key] = union(entry.properties.tagGroups[key].tags, result[key]);
				});
			}
		});
	}
	return result;
}

/**
 * Create an selector to select tags from defined feature source
 *
 * @deprecated Use export createTagsWithCountFromFeatureSourceSelector instead!
 * @param {string} featureSourcesControllerName name of features sources controller
 * @param {string} featureSourceId feature source id
 * @returns {Function} selector
 */
export function createTagsFromFeatureSourceSelector(featureSourcesControllerName, featureSourceId) {
	return createSelector(
		createUnfilteredFeaturesSelector(featureSourcesControllerName, featureSourceId),
		groupedTagsFromFeautresSelector
	);
}

/**
 * Returns grouped tags with count
 *
 * @param {object[]} features features
 * @returns {object<string,{count:number,tags:string[]}>} grouped tags with count
 */
export function getGroupedTagsWithCountFromFeatures(features) {
	const result = {};
	if (features) {
		features.forEach(entry => {
			const featureTagGroups = entry.properties && entry.properties.tagGroups;
			if (!featureTagGroups) {
				return;
			}

			Object.keys(featureTagGroups).forEach(group => {
				const tagsForGroup = featureTagGroups[group].tags;
				result[group] = result[group] || {count: 0, tags: {}};

				if (tagsForGroup && tagsForGroup.length) {
					result[group].count++;
					tagsForGroup.forEach(tag => {
						result[group].tags[tag] = result[group].tags[tag] || 0;
						result[group].tags[tag]++;
					});
				}
			});
		});
	}
	return result;
}

export function createTagsWithCountFromFeatureSourceSelector(featureSourcesControllerName, featureSourceId) {
	return createSelector(
		createUnfilteredFeaturesSelector(featureSourcesControllerName, featureSourceId),
		getGroupedTagsWithCountFromFeatures
	);
}

export const mapFeaturesToFeatureSource = features => ({
	data: {
		type: 'FeatureCollection',
		features: features,
	},
});

/**
 * Checks if the feature source state warrants an update
 *
 * @param {object} state state to check
 * @returns {boolean} true if update is due, false otherwise
 */
export function getIsDue(state) {
	// always okay if we do not refresh, but load if we have not loaded yet
	if (state.doRefresh !== true || state.timer < 1) {
		return !state.lastUpdate;
	}

	// otherwise check timer
	const now = +new Date();
	return now - state.lastUpdate >= state.timer;
}
