import React, {useContext, memo} from 'react';
import {useSelector} from 'react-redux';
import {viewportAnchorSelector} from '@mapsight/core/lib/map/selectors';

import {MAP} from '../config/constants/controllers';

import {viewSelector, isEmbeddedMapSelector, mapVisible} from '../store/selectors';

import {ComponentsContext} from './contexts';

function mapViewportAnchorSelector(state) {
	return viewportAnchorSelector(state[MAP]);
}

function AppWrapper({children, ...attributes}) {
	const {AppWrapperStart, AppWrapperEnd} = useContext(ComponentsContext);

	const view = useSelector(viewSelector);
	const isEmbeddedMap = useSelector(isEmbeddedMapSelector);
	const viewportAnchor = useSelector(mapViewportAnchorSelector);
	const isMapVisible = useSelector(mapVisible);

	const className = `ms3-wrapper ms3-wrapper--${view} ${isEmbeddedMap ? 'ms3-wrapper--embedded' : ''} ms3-wrapper--anchored-${viewportAnchor || 'not'} ${isMapVisible ? '' : 'ms3-wrapper--withoutmap'} ${attributes.className || ''}`;

	return (
		<div className={className} {...attributes}>
			{AppWrapperStart && <AppWrapperStart />}
			{children}
			{AppWrapperEnd && <AppWrapperEnd />}
		</div>
	);
}

export default memo(AppWrapper);
