import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';

import * as c from '../../config/constants/controllers';
import {applyMapsightCorePreset, resetMapsightCore} from '../../store/actions';

function defaultCalculateResetConfig(baseConfiguration) {
	return {
		[c.MAP]: {
			visible: true,
			view: baseConfiguration[c.MAP].view,
			layers: mapValues(baseConfiguration[c.MAP].layers, layer => pick(layer, [
				'options.visible',
				'options.source.options',
				'metaData.visibleInLayerSwitcher',
			])),
		},
	};
}

const defaultRendererPropName = 'mapsightCorePreset';

/**
 * This plugin will apply a preset on render passed as a prop to the renderer and optionally reset the state before that.
 *
 * @param {Object} [options] options
 * @param {String} [options.rendererPropName="mapsightCorePreset"] preset property name passed to the renderer
 * @param {function(Object): Object|boolean} [options.calculateResetConfig] function to calculate what state to reset on preset changes.
 *                       The function will be passed the (initial) base mapsight config and may return either a object describing
 *                       the state to be changed or false if no reset is wanted. Defaults to making the map visible, resetting
 *                       the map view and resetting the map layer visibility, source options and visibility in layer switcher.
 * @return {MapsightUiPlugin} plugin
 */
export default function createPlugin(options = {}) {
	const {
		calculateResetConfig = defaultCalculateResetConfig,
		rendererPropName = defaultRendererPropName,
	} = options;
	let resetConfig;

	return {
		afterCreate: function (context) {
			const {baseMapsightConfig} = context;
			resetConfig = calculateResetConfig(baseMapsightConfig);
		},
		beforeRender: function (context) {
			const {store, hasRendered, isStateReHydrated, rendererProps} = context;

			if (rendererProps[rendererPropName] && hasRendered || !isStateReHydrated) {
				if (resetConfig) {
					store.dispatch(resetMapsightCore(resetConfig));
				}
				store.dispatch(applyMapsightCorePreset(rendererProps[rendererPropName]));
			}
		},
	};
}
