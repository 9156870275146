import KeyboardPan from 'ol/interaction/keyboardpan';

import base from './_base';

export default {
	type: 'interaction',
	name: 'KeyboardPan',
	Constructor: KeyboardPan,
	optionMap: {
		...base.optionMap,
	},
	initialOptionMap: {
		...base.initialOptionMap,
		condition: 'condition',
		duration: 'duration',
		pixelDelta: 'pixelDelta',
	},
};
