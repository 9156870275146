import React, {memo} from 'react';

const CombinedButtons = memo(
	/**
	 * @param {{
	 *   children: React.ReactNode,
	 * }} props
	 */
	function CombinedButtons({children}) {
		return (
			<div className="ms3-map-overlay-combined-buttons">
				{React.Children.map(children, (c) => (
					<div className="ms3-map-overlay-combined-buttons__button">
						{c}
					</div>
				))}
			</div>
		);
	}
);
export default CombinedButtons;
