import {observeState} from '@neonaut/lib-redux/es/observe-state';
import {trackEvent} from '@neonaut/lib-js/es/misc/piwik';

import {createFeatureSelectionSelector} from '@mapsight/core/lib/feature-selections/selectors';
import {getFilteredFeatures} from '@mapsight/core/lib/feature-sources/selectors';

import * as c from '../../config/constants/controllers';
import {FEATURE_SELECTION_SELECT} from '../../config/feature/selections';

const defaultCategory = 'Mapsight';
const defaultAction = 'SelectedFeature';

const defaultFeatureSelectionsController = c.FEATURE_SELECTIONS;
const defaultFeatureSelection = FEATURE_SELECTION_SELECT;

/**
 * This plugin will track piwik actions when the a feature is selected
 * @see `@neonaut/lib-js/es/misc/piwik`
 *
 * @param {Object} [options] options
 * @param {String} [options.featureSelectionsController] name of the feature selections controller, defaults to mapsight ui default
 * @param {String} [options.featureSelection="select"] name of the feature selection to track
 * @param {String} [options.category="Mapsight"] piwik category to track
 * @param {String} [options.action="SelectedFeature"]  piwik action to track
 * @return {MapsightUiPlugin} plugin
 */
export default function createPlugin(options = {}) {
	const {
		featureSelectionsController = defaultFeatureSelectionsController,
		featureSelection = defaultFeatureSelection,
		category = defaultCategory,
		action = defaultAction,
		disabled,
	} = options;
	const {name = featureSelection} = options;

	if(disabled === true) {
		console.log('plugins/browser/piwik-track-feature-selection-event loaded but disabled');
		return {};
	}

	return {
		afterCreate: function piwikTrackFullscreenToggleEvent(context) {
			const {store} = context;

			observeState(store, createFeatureSelectionSelector(featureSelectionsController, featureSelection), selection => {
				const filteredFeatures = getFilteredFeatures(selection);
				const hasFeatures = !!filteredFeatures.length;

				if (hasFeatures) {
					trackEvent(category, action, name, JSON.stringify(filteredFeatures));
				}
			}); // TODO: Check!
		},
	};
}
