import OlVectorSource from 'ol/source/vector';
import Collection from 'ol/collection';

import {di, createDependencyMapper, OPTION_COLLECTION, OPTION_SET} from '../../index';
import base from './_base';

class VectorSource extends OlVectorSource {
	constructor(options) {
		super({
			features: new Collection(), // ensure we have getFeaturesCollection() available to be able to replace features
			...options,
		});
	}
}

export default {
	type: 'source',
	name: 'Vector',
	Constructor: VectorSource,
	optionMap: {
		...base.optionMap,
		logo: OPTION_SET,
		overlaps: OPTION_SET,
		strategy: OPTION_SET,
		useSpatialIndex: OPTION_SET,
		wrapX: OPTION_SET,
		features: [OPTION_COLLECTION, 'getFeaturesCollection'],
	},
	initialOptionMap: {
		...base.initialOptionMap,
		logo: 'logo',
		overlaps: 'overlaps',
		strategy: 'strategy',
		useSpatialIndex: 'useSpatialIndex',
		wrapX: 'wrapX',
		url: 'url',
		loader: 'loader',
		format: createDependencyMapper(di, 'format'),
		// TODO: features
	},
};
