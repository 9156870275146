import find from 'lodash/find';
import flatten from 'lodash/flatten';

import {observeState} from '@neonaut/lib-redux/es/observe-state';

import {DEFAULT_OPTIONS as FIT_DEFAULT_OPTIONS} from '@mapsight/lib-ol/map/fitToFeatures';
import {DEFAULT_OPTIONS as CENTER_DEFAULT_OPTIONS} from '@mapsight/lib-ol/view/centerOnFeature';

import {mapSizeSelector} from '../../selectors';
import {ANIMATE_ONCE} from '../../controller';

export default class MapAnimationManager {
	constructor(featureSourceConnector, featureSelectionStatesManager) {
		this._featureSourceConnector = featureSourceConnector;
		this._featureSelectionStatesManager = featureSelectionStatesManager;

		this._options = {
			// center = when adding new features, center the view to fit features
			centerAllFeaturesInView: false, // true|false|"once"
			centerFeaturesInViewSelections: [],
			centerFeaturesInViewOptions: CENTER_DEFAULT_OPTIONS,

			// fit = when adding new features, fit view to features
			fitAllFeaturesInView: false, // true|false|"once"
			fitFeaturesInViewSelections: ['select'], // TODO: keep default?
			fitFeaturesInViewOptions: FIT_DEFAULT_OPTIONS,

			// keep = on map size change, fit view to features
			keepAllFeaturesInView: false,
			keepFeaturesInViewOptions: {...FIT_DEFAULT_OPTIONS, keepZoom: true},
			keepFeaturesInViewSelections: ['select'], // TODO: keep default?
		};

		this._isFitted = false;
		this._isCentered = false;

		this._mapController = null;
		this._unsubscribeMapSize = null;
	}

	setOptions(options) {
		Object.assign(this._options, options);
	}

	setOption(key, value) {
		this._options[key] = value;
	}

	setMapController(mapController) {
		if (this._unsubscribeMapSize) {
			this._unsubscribeMapSize();
			this._unsubscribeMapSize = null;
		}

		this._mapController = mapController;

		// listen to map size changes of displaying layer
		// TODO: Does not belong here. Belong to the layer/map controller!
		this._unsubscribeMapSize = observeState(this._mapController, mapSizeSelector, this.handleMapSizeChange.bind(this));
	}

	handleMapSizeChange() {
		const {keepAllFeaturesInView} = this._options;
		if (keepAllFeaturesInView) {
			this._mapController.fitMapViewToFeatures(this._featureSourceConnector.getFeatures(), this._options.keepFeaturesInViewOptions);
			return;
		}

		const {keepFeaturesInViewSelections} = this._options;
		if (keepFeaturesInViewSelections) {
			const ids = flatten(
				keepFeaturesInViewSelections.map(selection => this._featureSelectionStatesManager.getAllFeaturesWithState(selection))
			);
			const features = ids.map(featureId => this._getFeatureById(featureId)).filter(a => a);
			if (features.length) {
				this._mapController.fitMapViewToFeatures(features, this._options.keepFeaturesInViewOptions);
			}
		}
	}

	handleFeatureSelectionStateChange() {
		const {fitAllFeaturesInView, centerAllFeaturesInView} = this._options;
		if (fitAllFeaturesInView || centerAllFeaturesInView) {
			return;
		}

		this._handleFitAndCenterSelectedFeaturesInView();
	}

	handleNewFeatures() {
		const {fitAllFeaturesInView, centerAllFeaturesInView} = this._options;
		if (fitAllFeaturesInView) {
			this._handleFitAll();
		} else if (centerAllFeaturesInView) {
			this._handleCenterAll();
		} else {
			this._handleFitAndCenterSelectedFeaturesInView();
		}
	}

	_handleCenterAll() {
		const {centerFeaturesInViewOptions, centerAllFeaturesInView} = this._options;
		if (centerAllFeaturesInView !== ANIMATE_ONCE || !this._isCentered) {
			this._isCentered = true;
			this._mapController.centerViewOnFeatures(this._featureSourceConnector.getFeatures(), centerFeaturesInViewOptions);
		}
	}

	_handleFitAll() {
		const {fitAllFeaturesInView, fitFeaturesInViewOptions} = this._options;
		if (fitAllFeaturesInView !== ANIMATE_ONCE || !this._isFitted) {
			this._isFitted = true;
			this._mapController.fitMapViewToFeatures(this._featureSourceConnector.getFeatures(), fitFeaturesInViewOptions);
		}
	}

	_handleFitAndCenterSelectedFeaturesInView() {
		const {fitFeaturesInViewSelections, centerFeaturesInViewSelections} = this._options;
		if (fitFeaturesInViewSelections || centerFeaturesInViewSelections) {
			const changedFeatures = this._featureSelectionStatesManager.filterChangedFeatures(this._featureSourceConnector.getFeatures());

			if (fitFeaturesInViewSelections) {
				const features = this._featureSelectionStatesManager.filterFeaturesByActiveSelections(changedFeatures, fitFeaturesInViewSelections);
				if (features.length) {
					this._mapController.fitMapViewToFeatures(features, this._options.fitFeaturesInViewOptions);
					return;
				}
			}

			if (centerFeaturesInViewSelections) {
				const features = this._featureSelectionStatesManager.filterFeaturesByActiveSelections(changedFeatures, centerFeaturesInViewSelections);
				if (features.length) {
					this._mapController.centerViewOnFeatures(features, this._options.centerFeaturesInViewOptions);
				}
			}
		}
	}

	_getFeatureById(featureId) {
		return featureId && find(this._featureSourceConnector.getFeatures(), feature => feature.getId() === featureId);
	}
}
