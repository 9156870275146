import proj4 from 'proj4';

import {BaseController} from '../base/controller';

export class ProjectionsController extends BaseController {
	init() {
		this.getAndSubscribeUncontrolled(value => {
			if (value) {
				proj4.defs(value);
			}
		});
	}

	static getProj4() {
		return proj4;
	}
}
