import TileLayer from 'ol/layer/tile';

import {di, updateProxyObject} from '../../index';
import base from './_base';

export default {
	type: 'layer',
	name: 'Tile',
	Constructor: TileLayer,
	optionMap: {
		...base.optionMap,
		source: (layer, _, oldDefinition, newDefinition) => newDefinition && updateProxyObject({
			di: di,
			group: 'source',
			oldObject: layer.getSource(),
			oldDefinition: oldDefinition,
			newDefinition: newDefinition,
			remover: null,
			adder: obj => layer.setSource(obj),
			parentObject: layer,
		}),
		preload: 'setPreload',
		useInterimTilesOnError: 'setUseInterimTilesOnError',
	},
	initialOptionMap: {
		...base.initialOptionMap,
		preload: 'preload',
		useInterimTilesOnError: 'useInterimTilesOnError',
	},
};
