import React, {useRef, useContext, memo, Fragment} from 'react';

import useMapsightPanel from '../../hooks/useMapsightPanel';

import {ComponentsContext} from '../contexts';

import {MS3_UI_MAIN_PANEL_POSITION_BELOW} from './main-panel';

function MainPanelContainer({panelPosition, collapsed, children}) {
	const comps = useContext(ComponentsContext);

	/** @type {React.MutableRefObject<Element>} containerRef */
	const containerRef = useRef();
	useMapsightPanel(containerRef, panelPosition, collapsed);

	const containerFlexClassNames = panelPosition === MS3_UI_MAIN_PANEL_POSITION_BELOW ?
		'[ ms3-flex ms3-flex--row ms3-flex-no-shrink ]' : '';
	const containerOrientationClassName = panelPosition === MS3_UI_MAIN_PANEL_POSITION_BELOW ?
		'ms3-panel-container--horizontal' : 'ms3-panel-container--vertical';
	const containerClasses = `${containerFlexClassNames} [ ms3-panel-container ${containerOrientationClassName} ${collapsed ? 'ms3-panel-container--empty' : ''} ]`;

	let content = (
		<Fragment>
			{comps.MainPanelContainerStart && (
				<comps.MainPanelContainerStart
					panelPosition={panelPosition}
					collapsed={collapsed}
				/>
			)}

			{children}

			{comps.MainPanelContainerEnd && (
				<comps.MainPanelContainerEnd
					panelPosition={panelPosition}
					collapsed={collapsed}
				/>
			)}
		</Fragment>
	);

	if (comps.MainPanelContainer) {
		content = (
			<comps.MainPanelContainer
				panelPosition={panelPosition}
				collapsed={collapsed}
			>
				{content}
			</comps.MainPanelContainer>
		);
	}

	return (
		<div className={containerClasses} ref={containerRef}>
			{content}
		</div>
	);
}

export default memo(MainPanelContainer);
