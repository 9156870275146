import React, {useCallback, memo} from 'react';
import {useDispatch} from 'react-redux';

import {selectExclusively} from '@mapsight/core/lib/feature-selections/actions';

import getFeatureProperty from '../../helpers/get-feature-property';
import {selectSearchResult} from '../../store/actions';
import {FEATURE_SELECTIONS} from '../../config/constants/controllers';
import {FEATURE_SELECTION_SELECT} from '../../config/feature/selections';

function SearchResultEntry({onSelect, feature}) {
	const dispatch = useDispatch();

	const handleClick = useCallback(
		() => {
			dispatch(selectSearchResult(feature));

			if (onSelect) {
				onSelect(feature);
			}

			// TODO: Center on Feature!
			dispatch(selectExclusively(FEATURE_SELECTIONS, FEATURE_SELECTION_SELECT, feature.id));
		},
		[dispatch, feature, onSelect],
	);

	const name = getFeatureProperty(feature, 'name'); // TODO: document/collect magic property names
	const listInformation = getFeatureProperty(feature, 'listInformation'); // TODO: document/collect magic property names

	return (
		<li className="ms3-search-result__list-item">
			<button className="ms3-search-result__entry" type="button" onClick={handleClick}>
				<div className="ms3-search-result__entry__title">{name}</div>
				{listInformation && (
					<div className="ms3-search-result__entry__subline">{listInformation}</div>
				)}
			</button>
		</li>
	);
}

export default memo(SearchResultEntry);
