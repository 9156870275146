import { createElement, useContext } from "react";
import { ComponentsContext } from "../components/contexts";

export function makeReplaceableComponent(componentName, Component) {
	const NewComp = (props) => {
		const CtxComp = useContext(ComponentsContext)[componentName];
		if (CtxComp !== undefined) {
			return createElement(CtxComp, props);
		}
		return createElement(Component, props);
	};
	NewComp.displayName = `MsUiReplaceable${componentName}`;
	return NewComp;
}
