import getPath from '@neonaut/lib-js/es/object/getPath';

/**
 * Gets the property value by key, falling back to the specified fallback if value is not defined.
 *
 * @param {Object} feature feature object
 * @param {String} key property key
 * @param {*} fallback value to return in case the property is not defined
 * @return {*} property value (or fallback value)
 */
export default function getFeatureProperty(feature, key, fallback = null) {
	return getPath(feature, ['properties', key], fallback);
}
