import Select from 'ol/interaction/select';

import base from './_base';

export default {
	type: 'interaction',
	name: 'Select',
	Constructor: Select,
	optionMap: {
		...base.optionMap,
	},
	initialOptionMap: {
		...base.initialOptionMap,
	},
};
