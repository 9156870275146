import React, {memo, useCallback, useContext, useEffect, useRef} from 'react';

import getFeatureProperty from '../../helpers/get-feature-property';
import {translate} from '../../helpers/i18n';

import {ComponentsContext} from '../contexts';

function FeatureDetailsContentInner({feature, url, hasError, html, handleContentChange}) {
	const comps = useContext(ComponentsContext);

	// NOTE(PG): using useCallback in addition to useRef alone so we actually get called when node changes
	const containerRef = useRef();
	const containerRefSetter = useCallback(element => {
		containerRef.current = element;
		if (element && handleContentChange) {
			handleContentChange(element);
		}
	}, [handleContentChange]);

	const desc = getFeatureProperty(feature, 'description');  // TODO: document/collect magic property names

	// NOTE(PG): Using useEffect to detect content changes (here: props), maybe we could move this up into the parent comp?
	useEffect(() => {
		handleContentChange(containerRef.current);
	}, [handleContentChange, html, hasError, desc]);

	let content = null;
	if (url) {
		if (html) {
			// success
			content = (
				<div
					className="ms3-feature-details-content ms3-feature-details-content--html"
					dangerouslySetInnerHTML={{__html: html}}
					ref={containerRefSetter}
				/>
			);
		} else if (hasError) {
			// error
			content = (
				<div
					className="ms3-feature-details-content ms3-feature-details-content--error"
					ref={containerRefSetter}
				>
					{translate('ui.feature-details.content-inner.error')}
					<br />

					<a href={url}>
						{translate('ui.feature-details.content-inner.gotoPage')}
					</a>
				</div>
			);
		} else {
			// loading
			content = (
				<div
					className="ms3-feature-details-content ms3-feature-details-content--loading"
					ref={containerRefSetter}
				>
					{translate('ui.feature-details.content-inner.loading')}
				</div>
			);
		}
	} else {
		if (desc) {
			content = (
				<div
					className="ms3-feature-details-content ms3-feature-details-content--description"
					dangerouslySetInnerHTML={{__html: desc}}
					ref={containerRefSetter}
				/>
			);
		}
	}

	if (comps.FeatureDetailsContent) {
		content = (
			<comps.FeatureDetailsContent
				feature={feature}
				url={url}
				hasError={hasError}
				html={html}
			>
				{content}
			</comps.FeatureDetailsContent>
		);
	}

	return content;
}

export default memo(FeatureDetailsContentInner);
