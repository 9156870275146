import getSanitizedActionType from './sanitizeActionType';

export default function defaultSanitizeAction(action) {
	if (!action.meta || !action.meta.sanitized) {
		action.meta = action.meta || {};
		action.meta.sanitized = true;
		action._type = action.type;
		action.type = getSanitizedActionType(action);
	}

	return action;
}
