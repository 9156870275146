import fitMapViewToFeature from '@mapsight/lib-ol/map/fitToFeature';
import fitMapViewToFeatures from '@mapsight/lib-ol/map/fitToFeatures';
import centerViewOnFeature from '@mapsight/lib-ol/view/centerOnFeature';
import centerViewOnFeatures from '@mapsight/lib-ol/view/centerOnFeatures';

import {ANIMATE} from '../actions';

export default function withAnimations(mapController, map) {
	let pendingAnimation = null;
	map.on('postrender', function postRenderAnimationHandler() {
		if (pendingAnimation && map.getSize() !== undefined) {
			pendingAnimation();
			pendingAnimation = null;
		}
	});

	function enqueueAnimation(animationFunction) {
		pendingAnimation = animationFunction;
		map.render(); // trigger render to ensure the animation is fullfilled timely
	}

	mapController.fit = function (bounds, options) {
		enqueueAnimation(function () {
			map.getView().fit(bounds, options);
		});
	};

	mapController.animate = function (options) {
		enqueueAnimation(function () {
			map.getView().animate(options);
		});
	};

	// TODO: make this an action?
	mapController.fitMapViewToFeatures = function (features, options) {
		enqueueAnimation(function () {
			fitMapViewToFeatures(map, features, options);
		});
	};

	// TODO: make this an action?
	mapController.fitMapViewToFeature = function (feature, options) {
		enqueueAnimation(function () {
			fitMapViewToFeature(map, feature, options);
		});
	};

	// TODO: make this an action?
	mapController.centerViewOnFeatures = function (features, options) {
		enqueueAnimation(function () {
			centerViewOnFeatures(map.getView(), features, options);
		});
	};

	// TODO: make this an action?
	mapController.centerViewOnFeature = function (feature, options) {
		enqueueAnimation(function () {
			centerViewOnFeature(map.getView(), feature, options);
		});
	};

	mapController.registerReducer(function reduceWithAnimations(state, action) {
		if (action.type === ANIMATE) {
			const {center, resolution, bounds, zoom, ...options} = action.options;

			if (bounds) {
				mapController.fit(bounds, options);
			} else {
				const view = map.getView();
				mapController.animate({
					zoom: resolution ? view.getZoomForResolution(resolution) : zoom,
					center: center,
					...options,
				});
			}
		}

		return state;
	});
}
