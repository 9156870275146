import merge from 'lodash/merge';

import {applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';

import {createMapsightStore} from '@mapsight/core';
import {layerIdsExternalSwitcherSelector} from '@mapsight/core/lib/map/selectors';

import {MapController} from '@mapsight/core/lib/map/controller';
import {ListController} from '@mapsight/core/lib/list/controller';
import {FilterController} from '@mapsight/core/lib/filter/controller';
import {UserGeolocationController} from '@mapsight/core/lib/user-geolocation/controller';
import {ProjectionsController} from '@mapsight/core/lib/projections/controller';
import {FeatureSourcesController} from '@mapsight/core/lib/feature-sources/controller';

import {VIEW_MOBILE} from './config/constants/app';
import * as c from './config/constants/controllers';

import timeFilter from './filters/time-filter';
import {createTagFilterFunction} from './filters/tag-filter';

import reducers from './store/reducers';

import {siteConfig} from './config';
import {FeaturePreSelectionsController} from './config/feature/preselect-controller';
import mapReducer from "./store/map-reducer";

// initial state
const defaultResetStateOnReHydration = {
};

/**
 * Default mapsight ui renderer (Does nothing, but log a warning!).
 * @type {MapsightUiRenderer}
 */
function defaultRenderer(container, props, hydrate = false) {
	console.info('create has to be passed a renderer to render the app');
}

// TODO: use type annotations (jsdoc/typescript)
const defaultCreateOptions = {
	baseUrl: undefined,
	imagesUrl: undefined,
	// search
	searchUrl: undefined,
	searchQueryParameter: undefined,
	reHydratedState: undefined,
	uiState: {
		isFullscreen: false,
		listQuery: '',
		listSorting: null,
		listPage: 0, // current page
		searchQuery: '',
		searchResult: {},
		searchResultSelectionFeatures: [],
		title: '',
		userPreferenceListVisible: true,
		mapIsOutOfViewport: false,

		// views
		view: VIEW_MOBILE, // mobile first
		viewBreakpoints: [],

		embeddedMap: false, // map is embedded which switch some behaviours which would be annoying
		searchInMap: true,  // enable address search in map overlay

		pageTitle: {
			show: true, // show page title
		},
		tagSwitcher: {
			show: false, // turn on tag switcher which will filter
			featureSourceId: undefined,
			featureSourcesControllerName: c.FEATURE_SOURCES,
			toggleableGroups: false, // make group names (headers) buttons that toggle on/off groups
			sortTags: false, // sort tags with locale sort, but not the tagGroups. to sort tagGroups add all tagGroups to first feature entry (but with empty tags if the entry doesn't have tags for a tagGroup)
		},
		viewToggle: {
			show: true,
			deselectFeaturesOnToggle: true,
		},
		layerSwitcher: {
			show: {
				internal: true,
				external: false
			},
			internal: {
				layerIdsSelector: undefined, // implied default: layer ids of layers viewed in internal Selector
				grouped: false,
			},
			external: {
				// FIXME: function in redux state?
				layerIdsSelector: layerIdsExternalSwitcherSelector,
				grouped: true,
			},
		},
		list: {
			selectionBehavior: {
				desktop: 'scrollToMap', // always 'scrollToMap' for now
				mobile: 'expandInList', // either 'expandInList', 'scrollToMap', 'showInMapOnlyView'
			},
			additionalScrollOffsetSelect: 20, // additional offset to keep above the selected list items when scrolled to
			detailsInList: false, // if true details will always be shown in list even if not on mobile
			showSelectedOnly: false, // don't show list entries, only show the selected one (need some other kind of communication, cyclingControl or icons on the map)
			highlightOnMouse: true, // highlights list item on mouse enter (and un-highlights on leave)
			selectOnClick: true, // select on click to selected list item
			deselectOnClick: false, // deselect on click to selected list item
			cyclingControl: false, // show a control to select next or previous list entry
			sortControl: true, // show sort control icon
			filterControl: true, // show filter box
			paginationControl: false, // show pagination
			itemsPerPage: 10, // number of items per page
			stickyHeader: true, // make list header sticky?
			showVaryingListInfoOnly: true, // show info column only, if the contents vary (determined before list filter)
		},
		regions: {},
		places: {},
	},

	plugins: [],
	renderer: defaultRenderer,
	components: {
		MainPanelStart: undefined,
		MainPanelEnd: undefined,
		TooltipContent: undefined,
		FeatureSelectionInfoHeader: undefined,
		FeatureDetailsContent: undefined,
	},
	renderBreakpoints: {
		// NOTE: Keep in sync with css!
		mobile: [0, 767],
		tablet: [768, 1014],
		desktop: [1015, -1],
	},
	partialChangeHandler: undefined,

	// add additional reducers
	reducers: {...reducers},
	// These paths will be saved in local storage
	localStorageKey: 'nn-mapsight--v3-ui',
	localStoragePaths: [
		//['app', 'isFullscreen'],
		//['app', 'view'],
		['app', 'userPreferenceListVisible'],
	],
};


/**
 * plugin function that gets called
 * @typedef {Function} PluginFunction
 * @param {MapsightUiContext} context the current mapsight ui context
 * @return {Promise|undefined}
 */

/**
 * plugin function that gets called after the mapsight ui has been initiated
 * @typedef {PluginFunction} AfterInitPluginFunction
 * @return {undefined}
 */

/**
 * plugin function that gets called after the mapsight ui has been created
 * @typedef {PluginFunction} AfterCreatePluginFunction
 * @return {undefined}
 */

/**
 * plugin function that gets called after the mapsight ui has been rendered
 * @typedef {PluginFunction} BeforeRenderPluginFunction
 * @return {Promise|undefined}
 */

/**
 * plugin function that gets called after the mapsight ui has been rendered
 * @typedef {PluginFunction} AfterRenderPluginFunction
 * @return {undefined}
 */

/**
 * @typedef {"afterInit"|"afterCreate"|"beforeRender"|"afterRender"} PluginPhase
 */

/**
 * plugin function that gets called
 * @typedef {Object} MapsightUiPlugin
 * @property {AfterInitPluginFunction} [afterInit] optional plugin to be called after initialization
 * @property {AfterCreatePluginFunction} [afterCreate] optional plugin to be called after creation
 * @property {BeforeRenderPluginFunction} [beforeRender] optional plugin to be called after rendering
 * @property {AfterRenderPluginFunction} [afterRender] optional plugin to be called after rendering
 */

/**
 * @typedef {Array<[String, MapsightUiPlugin]>} MapsightUiPluginDefinition
 */

/**
 * @typedef {Function} MapsightUiRenderer
 * @param {HTMLElement} [container] the container element the instance should be rendered into
 * @param {Object} [props] props to render
 * @param {Boolean} [hydrate] whether to hydrate existing render target, default: false
 */

/**
 * @typedef {Object} MapsightUiRendererProps
 * @property {String} [title] the container element the instance should be rendered into
 * @property {Object} [mapsightCorePreset] preset (see @mapsight/core)
 */

/**
 * @typedef {Function} MapsightUiRenderFunction
 * @param {MapsightUiRendererProps} [rendererProps] props to render
 * @return {*} render ref
 */

/**
 * @typedef {Function} MapsightUiAsyncRenderFunction
 * @param {MapsightUiRendererProps} [rendererProps] props to render
 * @return {Promise<*>} promise of render ref
 */

/**
 * @typedef {Object} CreateOptions
 * @property {MapsightUiRenderer} [renderer] render function that takes the container
 * @property {Function} [storeEnhancer] redux store enhancer
 *
 *  FIXME createOptions dokumentieren, insbesonderere renderProps
 */

/**
 * We keep a context object for each mapsight ui instance holding all the information
 * plugins will be passed this object and may manipulate it's properties
 *
 * @typedef {Object} MapsightUiContext
 *
 * After init:
 * @property {boolean} hasRendered indicates if the instance has been rendered at least once (available after init)
 * @property {HTMLElement} container the container element the instance should be rendered into (available after init)
 * @property {Function} styleFunction the mapsight core vector style function (see @mapsight/core) (available after init)
 * @property {Object} baseMapsightConfig base mapsight config TODO: document further (available after init)
 * @property {CreateOptions} createOptions options on how to create the mapsight app (available after init)
 * @property {Object} [initialState] initial state TODO: document further (available after init)
 * @property {boolean} [isStateReHydrated] indicates if the instance has been re-hydrated (available after init)
 * @property {Object<String, BaseController>} [controllers] map of controllers (available after init)
 *
 * After create:
 * @property {Function} [storeEnhancer] redux store enhancer TODO: document further, use correct types from redux (available after create)
 * @property {Object} [store] redux store TODO: document further, use correct types from redux (available after create)
 * @property {MapsightUiRenderFunction} [render] function to render the app (available after create)
 * @property {MapsightUiAsyncRenderFunction} [renderAsync] function to render the app async (available after create)
 *
 * Before render:
 * @property {boolean} [canPluginsDelayRender] indicates whether the render is delayed by a promise returned by the plugin function (available before render)
 *
 * After render:
 * @property {MapsightUiRendererProps} [rendererProps] props to be rendered (available after render)
 * @property {*} [renderRef] the reference that may have been returned by the renderer (available after render)
 */

/**
 * Creates an mapsight ui instance
 *
 * @param {HTMLElement} _container element the mapsight ui app should be rendered into
 * @param {Function} _styleFunction mapsight style function
 * @param {Object} [_baseMapsightConfig] base mapsight configuration
 * @param {CreateOptions} [_createOptions] ui creation options
 * @returns {{store, render}} mapsight ui instance
 */
export function create(_container, _styleFunction, _baseMapsightConfig = {}, _createOptions = {}) {
	/** @var {MapsightUiContext} context */
	const context = {
		hasRendered: false,
		container: _container,
		styleFunction: _styleFunction,
		baseMapsightConfig: _baseMapsightConfig,
		createOptions: merge({},
			defaultCreateOptions,
			_baseMapsightConfig.app ? {uiState: _baseMapsightConfig.app} : undefined,
			_createOptions
		),
	};

// transfer some create options to global site config to make them available even outside of the redux context
	// TODO: Replace this mechanic with an explicit API, e.g. exposing the site config directly as `@mapsight/ui/site-config` or
	//    make them local to the mapsight ui context to allow for several independent instances with different site configs
	siteConfig.baseUrl = context.createOptions.baseUrl;
	siteConfig.imagesUrl = context.createOptions.imagesUrl || siteConfig.imagesUrl;
	siteConfig.searchUrl = context.createOptions.searchUrl || siteConfig.searchUrl;
	siteConfig.searchQueryParameter = context.createOptions.searchQueryParameter || siteConfig.searchQueryParameter;

// initial state
	context.initialState = merge({}, context.baseMapsightConfig, {app: context.createOptions.uiState});
	delete context.createOptions.uiState;

// override initial state by re-hydration
	context.isStateReHydrated = false;
	if (context.createOptions.reHydratedState !== undefined) {
		context.isStateReHydrated = true;
		merge(context.initialState, context.createOptions.reHydratedState, defaultResetStateOnReHydration);
	}

// setup core controllers
	context.controllers = Object.assign({
			[c.PROJECTIONS]: new ProjectionsController(c.PROJECTIONS),
			[c.MAP]: new MapController(c.MAP, context.styleFunction),
			[c.FEATURE_LIST]: new ListController(c.FEATURE_LIST),
			[c.TIME_FILTER]: new FilterController(c.TIME_FILTER, timeFilter),
			[c.TAG_FILTER]: new FilterController(c.TAG_FILTER, createTagFilterFunction()),
			[c.USER_GEOLOCATION]: new UserGeolocationController(c.USER_GEOLOCATION),
			[c.FEATURE_SOURCES]: new FeatureSourcesController(c.FEATURE_SOURCES),
			[c.FEATURE_SELECTIONS]: new FeaturePreSelectionsController(c.FEATURE_SELECTIONS),
		},
		context.createOptions.controllers
	);
	// @mapsight/ui added data to store[c.MAP] which it has to take care
	context.controllers[c.MAP].registerReducer(mapReducer);

// store enhancer
	const uiStoreEnhancer = applyMiddleware(thunk);
	context.storeEnhancer = context.createOptions.storeEnhancer ?
		compose(uiStoreEnhancer, context.createOptions.storeEnhancer) :
		uiStoreEnhancer;

// plugin: afterInit
	callPlugins(context, 'afterInit');

// store
	context.store = createMapsightStore(context.controllers, context.createOptions.reducers, context.initialState, context.storeEnhancer);


// render
	function internalRender() {
		const hydrate = !context.hasRendered && context.isStateReHydrated;
		const props = {store: context.store, components: context.createOptions.components};
		context.renderRef = context.createOptions.renderer(context.container, props, hydrate);
		context.hasRendered = true;

		callPlugins(context, 'afterRender');

		return context.renderRef;
	}

	/**
	 * @param {MapsightUiRendererProps} [rendererProps]
	 * @return {*}
	 */
	context.render = function mapsightUiRender(rendererProps) {
		context.rendererProps = rendererProps;
		context.canPluginsDelayRender = false;

		callPlugins(context, 'beforeRender');

		return internalRender();
	};

	/**
	 * @param {MapsightUiRendererProps} [rendererProps]
	 * @return {Promise}
	 */
	context.renderAsync = async function mapsightUiRenderAsync(rendererProps) {
		context.rendererProps = rendererProps;
		context.canPluginsDelayRender = true;

		await callAndAwaitPlugins(context, 'beforeRender');

		return internalRender();
	};

// plugin: afterCreate
	callPlugins(context, 'afterCreate');

	return context;
}

/**
 * Overrides plugins with the same name (last to come wins), null unsets the plugin,
 * Flattened order is in order of first occurance of a name.
 *
 * @param {Array<MapsightUiPluginDefinition|null>} plugins (sorted) array of plugins to be flattened, null unsets
 * @returns {MapsightUiPluginDefinition[]} flattened plugins array
 */
function flattenPlugins(plugins) {
	const pluginPosition = {};
	let flattenedPlugins = [];

	plugins.forEach(function filterPlugin(plugin) {
		const [key] = plugin;
		const position = pluginPosition[key];
		if (position) {
			flattenedPlugins.splice(position, 1, plugin);
		} else {
			pluginPosition[key] = flattenedPlugins.length;
			flattenedPlugins.push(plugin);
		}
	});

	flattenedPlugins = flattenedPlugins.filter(([key, plugin]) => !!plugin);

	return flattenedPlugins;
}

/**
 * @param {MapsightUiContext} context context in which the plugin will be called
 * @param {PluginPhase} phase phase of the plugin
 * @return {*[]}
 */
function callPlugins(context, phase) {
	return flattenPlugins(context.createOptions.plugins)
			.map(([, plugin]) => plugin[phase])
			.filter(plugin => !!plugin)
			.map(plugin => callPlugin(context, plugin));
}

/**
 * @param {MapsightUiContext} context context in which the plugin will be called
 * @param {PluginPhase} phase phase of the plugin
 * @return {Promise} promise that all plugins are resolved
 */
function callAndAwaitPlugins(context, phase) {
	return Promise.all(callPlugins(context, phase));
}

/**
 * @param {MapsightUiContext} context context in which the plugin will be called
 * @param {PluginFunction} pluginFunction plugin function to be called
 * @return {Promise|undefined} plugin result
 */
function callPlugin(context, pluginFunction) {
	if (pluginFunction) {
		return pluginFunction(context);
	}

	return undefined;
}
