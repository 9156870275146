import {useCallback} from 'react';
import {async} from '@mapsight/core/lib/base/actions';
import {updateMapSize} from '@mapsight/core/lib/map/actions';

import {MAP} from '../config/constants/controllers';

export default function useUpdateMapSizeCallback(dispatch, previousPositionRef = undefined, reCenter = true) {
	return useCallback(function updateMapSizeCallback() {
		const options = {
			from: (previousPositionRef && previousPositionRef.current) || undefined,
			reCenter: reCenter,
		};
		dispatch(async(updateMapSize(MAP, options)));
	}, [dispatch, previousPositionRef, reCenter]);
}
