import eventEmitter from 'event-emitter';
import {compose} from 'redux';

import {baseReducer} from './reducer';

const PRIVATE_MEMBER_STORE = '__MS3_BASE_CONTROLLER_PRIVATE_MEMBER_STORE';
const PRIVATE_MEMBER_SELECTOR = '__MS3_BASE_CONTROLLER_PRIVATE_MEMBER_SELECTOR';
const PRIVATE_MEMBER_NAME = '__MS3_BASE_CONTROLLER_PRIVATE_MEMBER_NAME';

export class BaseController {
	// TODO: Should we also implement: observe, getAndObserve and getAndSubscribe?

	constructor(controllerName) {
		this[PRIVATE_MEMBER_NAME] = controllerName;
	}

	getName() {
		return this[PRIVATE_MEMBER_NAME];
	}

	getStore() {
		this._guardUnboundStore();

		return this[PRIVATE_MEMBER_STORE];
	}

	getState() {
		this._guardUnboundStore();

		return this[PRIVATE_MEMBER_SELECTOR](this.getStore().getState());
	}

	dispatch(action) {
		this._guardUnboundStore();

		return this.getStore().dispatch(action);
	}

	/**
	 * Observes the controller's state by the given selector,
	 * calling the given handler if the selected state changes (by means of strict equality)
	 * but ONLY if the action that changed the state was not controlled by the controller.
	 *
	 *
	 * @param {Function} selector observe selector
	 * @param {Function} handler observe handler
	 * @returns {Function} unsubscribe function
	 */
	observeUncontrolled(selector, handler) {
		this._guardUnboundStore();

		const boundHandler = handler.bind(this);
		const composedSelector = compose(selector, this[PRIVATE_MEMBER_SELECTOR]);
		return this.getStore().observeUncontrolled(composedSelector, boundHandler);
	}

	/**
	 * Observes the controller's state by the given selector,
	 * calling the given handler if the selected state changes (by means of strict equality)
	 * but ONLY if the action that changed the state was not controlled by the controller.
	 *
	 * Additionally the handler is called with the initial state.
	 *
	 * @param {Function} selector observe selector
	 * @param {Function} handler observe handler
	 * @returns {Function} unsubscribe function
	 */
	getAndObserveUncontrolled(selector, handler) {
		this._guardUnboundStore();

		const boundHandler = handler.bind(this);
		const composedSelector = compose(selector, this[PRIVATE_MEMBER_SELECTOR]);
		const unsubscribe = this.getStore().observeUncontrolled(composedSelector, boundHandler);
		boundHandler(composedSelector(this.getStore().getState()));
		return unsubscribe;
	}

	/**
	 * Observes the controller's state
	 * calling the given handler if the selected state changes (by means of strict equality).
	 *
	 * @param {Function} handler subscribe handler
	 * @returns {Function} unsubscribe function
	 */
	subscribe(handler) {
		this._guardUnboundStore();

		return this.getStore().subscribe(handler.bind(this));
	}

	/**
	 * Observes the controller's state
	 * calling the given handler if the selected state changes (by means of strict equality)
	 * but ONLY if the action that changed the state was not controlled by the controller.
	 *
	 * @param {Function} handler subscribe handler
	 * @returns {Function} unsubscribe function
	 */
	subscribeUncontrolled(handler) {
		this._guardUnboundStore();

		return this.getStore().observeUncontrolled(this[PRIVATE_MEMBER_SELECTOR], handler.bind(this));
	}

	/**
	 * Observes the controller's state
	 * calling the given handler if the selected state changes (by means of strict equality)
	 * but ONLY if the action that changed the state was not controlled by the controller.
	 *
	 * Additionally the handler is called with the initial state.
	 *
	 * @param {Function} handler subscribe handler
	 * @returns {Function} unsubscribe function
	 */
	getAndSubscribeUncontrolled(handler) {
		this._guardUnboundStore();

		const boundHandler = handler.bind(this);
		const unsubscribe = this.getStore().observeUncontrolled(this[PRIVATE_MEMBER_SELECTOR], boundHandler);
		boundHandler(this.getState());
		return unsubscribe;
	}

	/**
	 * This method will be called when the controller is bound by createMapsightStore().
	 *
	 * @param {object} store store to bind to
	 */
	bindToStore(store) {
		if (this[PRIVATE_MEMBER_STORE]) {
			console.error('Controller is already bound to a store. Rebinding is NOT supported!');
			return;
		}

		this[PRIVATE_MEMBER_STORE] = store;
		this[PRIVATE_MEMBER_SELECTOR] = state => state[this.getName()];
	}

	/**
	 * This method will be called once all controllers are bound to the store.
	 * Use this method to implement controller code that requires access to the store or other controllers!
	 */
	init() {
	}

	/**
	 *
	 * @param state	that part of the state in control of the controller
	 * @param action action to perform on state
	 * @param fullStore full store containing data from other controllers
	 * @return {*} modified part of the state in control of the controller after performing action
	 */
	/*eslint no-unused-vars: ["error", { "args": "none" }]*/
	reduce(state = {}, action, fullStore) {
		return baseReducer(state, action);
	}

	createReducer() {
		return this.reduce.bind(this);
	}

	_guardUnboundStore() {
		if (!this[PRIVATE_MEMBER_STORE]) {
			console.error('Cannot access store from controller because the controller is not bound to the store yet!');
		}
	}
}

eventEmitter(BaseController.prototype);
