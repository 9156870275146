import toggleClass from '@neonaut/lib-js/es/dom/access/toggle-class';

function onTouchMoveNoScroll(e) {
	e.preventDefault();
}

/**
 * Test selector, returning boolean for given state
 *
 * @typedef {Function} testSelector
 * @param   {object} state mapsight store's state
 * @returns {boolean} true if noScroll is set, false if scrolling is allowed
 */

/**
 * set no scroll for page depending on mapsight store's state an a parameter
 *
 * @typedef {Function} noScrollHandler
 * @param {object}     state       mapsight store's state
 * @param {boolean}    forceAllow  if true allow scroll even if mapsight would not, ie. for some menu overlay
 * @returns {boolean}              true if noScroll is set, false if scrolling is allowed
 */

/**
 * create a no-scroll-handler
 *
 * @param {testSelector}      selector             redux store selector to test if scroll should be inhibited
 * @param {string}            [noScrollCssClass]   class to set, default = 'ms3--map-no-scroll'
 * @returns {noScrollHandler}                      function to update no-scroll state.
 */
export function createNoScrollHandler(selector, noScrollCssClass = 'ms3--map-no-scroll') {
	return function noScrollHandler(state, forceAllow) {
		const isNoScroll = !forceAllow && selector(state);
		toggleClass(window.document.documentElement, noScrollCssClass, isNoScroll);
		window.document[isNoScroll ? 'addEventListener' : 'removeEventListener']('touchmove', onTouchMoveNoScroll);
		return isNoScroll;
	};
}
