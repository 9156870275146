import React, {useCallback, useEffect, useState} from 'react';
import sortBy from 'lodash/sortBy';

import {groupBy} from '../../../utils/array';

/**
 * @param {{chart: import('highcharts').Chart}} props
 */
export default function ChartLegend({chart}) {
	const [selectedGroup, setSelectedGroup] = useState(
		/**@type {null |string} */ (null),
	);
	const [_redrawCounter, setRedrawCounter] = useState(0);
	const triggerRedraw = useCallback(
		() => setRedrawCounter((prev) => prev + 1),
		[],
	);

	// highcharts does not update the data immutable, thus we can't use useMemo(..., [chart.series])
	const legendItemGroups = (() => {
		const visibleSeries = chart.series.filter(
			(s) => s.options.showInLegend !== false,
		);

		const seriesByGroup = groupBy(visibleSeries, (series) => {
			if (series.custom?.groupBy) {
				return series.custom.groupBy;
			}

			const parts = series.name.split(',');
			return parts[parts.length - 1] ?? 'Unbekannt';
		});
		return sortBy(Array.from(seriesByGroup.entries()), ([group]) => group);
	})();

	useEffect(() => {
		if (selectedGroup === null && legendItemGroups.length > 0) {
			setSelectedGroup(legendItemGroups[0][0]);
		}
	}, [selectedGroup, legendItemGroups]);

	const selectedGroupItems = legendItemGroups.find(
		([groupName]) => groupName === selectedGroup,
	)?.[1];

	return (
		<div className="vmznds-chart__legend">
			<div className="vmznds-chart__legend-groups">
				{legendItemGroups.map(([groupName, series]) => {
					const visibleCount = series.reduce(
						(acc, s) => acc + (s.visible ? 1 : 0),
						0,
					);

					return (
						<div
							key={groupName}
							className={`vmznds-chart__legend-group-entry ${groupName === selectedGroup ? 'vmznds-chart__legend-group-entry--active' : ''} ${visibleCount > 0 ? 'vmznds-chart__legend-group-entry--has-visible' : ''}`}
							onClick={() => setSelectedGroup(groupName)}
						>
							<span className="vmznds-chart__legend-group-entry-name">
								{(() => {
									const [prefix, name] = groupName.split('_', 2);
									return name ?? prefix;
								})()}
							</span>
							<span className="vmznds-chart__legend-group-entry-counts">
								<span>{visibleCount}</span>
								{'/'}
								<span>{series.length}</span>
							</span>
						</div>
					);
				})}
			</div>

			<div className="vmznds-chart__legend-items">
				{selectedGroupItems !== undefined &&
					selectedGroupItems.map((s, sIndex) => (
						<ChartLegendItem
							key={sIndex}
							series={s}
							triggerRedraw={triggerRedraw}
						/>
					))}
			</div>
		</div>
	);
}

/**
 * @param {{series: import('highcharts').Series, triggerRedraw: () => void}} props
 */
function ChartLegendItem({series, triggerRedraw}) {
	const handleClick = useCallback(() => {
		series.setVisible(!series.visible);
		triggerRedraw();
	}, [series, triggerRedraw]);

	return (
		<div className="vmznds-chart__legend-item" onClick={handleClick}>
			<span
				className="vmznds-chart__legend-item-indicator"
				style={{
					backgroundColor: series.visible ? series.color : 'transparent',
				}}
			/>
			{series.name}
		</div>
	);
}
