import {createContext} from 'react';

// TODO: move comp typedefs to a more appropriate place?
/**
 * @typedef {import("react").ComponentType<{
 * }>} AppWrapperStart
 */

/**
 * @typedef {import("react").ComponentType<{
 * }>} AppWrapperEnd
 */

/**
 * @typedef {import("react").ComponentType<{
 *   panelPosition: "standalone" | any,
 *   contentType: "list" | any,
 *   view: import("../config/constants/app").View,
 * }>} MainPanelStart
 */

/**
 * @typedef {import("react").ComponentType<{
 *   panelPosition: "standalone" | any,
 *   contentType: "list" | any,
 *   view: import("../config/constants/app").View,
 * }>} MainPanelEnd
 */

/**
 * @typedef {import("react").ComponentType<{
 *   feature: any,
 *   url?: string,
 *   hasError: boolean,
 *   html?: string,
 * }>} FeatureDetailsContent
 */

/**
 * @typedef {import("react").ComponentType<{
 *   feature: any,
 * }>} FeatureSelectionInfoHeader
 */

 /**
 * @typedef {import("react").ComponentType<{
 *   panelPosition: any,
 *   collapsed: any,
 * }>} MainPanelContainer
 */

/**
 * @typedef {import("react").ComponentType<{
 *   panelPosition: any,
 *   collapsed: any,
 * }>} MainPanelContainerStart
 */

/**
 * @typedef {import("react").ComponentType<{
 *   panelPosition: any,
 *   collapsed: any,
 * }>} MainPanelContainerEnd
 */

/**
 * @typedef {import("react").ComponentType<{
 *   feature: any,
 *   html: string,
 *   text: string,
 * }>} TooltipContent
 */

/**
 * @typedef {import("react").ComponentType<{
 * }>} MapOverlayModal
 */

/**
 * @typedef {import("react").ComponentType<{
 * }>} MapOverlayStart
 */

/**
 * @typedef {import("react").ComponentType<{
 * }>} MapOverlayEnd
 */

/**
 * @typedef {import("react").ComponentType<{
 * }>} MapOverlayTopLeft
 */

/**
 * @typedef {import("react").ComponentType<{
 * }>} MapOverlayTopRight
 */

/**
 * @typedef {import("react").ComponentType<{
 * }>} MapOverlayBottomLeft
 */

/**
 * @typedef {import("react").ComponentType<{
 * }>} MapOverlayBottomRight
 */

/**
 * @typedef {import("react").ComponentType<{
 * }>} MapWrapper
 */

/**
 * @typedef {import("react").ComponentType<{
 * }>} AdditionalContainerContent
 */

/**
 * @typedef {{
 *   AppWrapperStart?: AppWrapperStart,
 *   AppWrapperEnd?: AppWrapperEnd,
 *   MainPanelStart?: MainPanelStart,
 *   MainPanelEnd?: MainPanelEnd,
 *   MainPanelContainer?: MainPanelContainer,
 *   MainPanelContainerStart?: MainPanelContainerStart,
 *   MainPanelContainerEnd?: MainPanelContainerEnd,
 *   MapOverlayModal?: MapOverlayModal,
 *   MapOverlayStart?: MapOverlayStart,
 *   MapOverlayEnd?: MapOverlayEnd,
 *   MapOverlayTopLeft?: MapOverlayTopLeft,
 *   MapOverlayTopRight?: MapOverlayTopRight,
 *   MapOverlayBottomLeft?: MapOverlayBottomLeft,
 *   MapOverlayBottomRight?: MapOverlayBottomRight,
 *   MapWrapper?: MapWrapper,
 *   AdditionalContainerContent?: AdditionalContainerContent,
 *   FeatureDetailsContent?: FeatureDetailsContent,
 *   FeatureSelectionInfoHeader?: FeatureSelectionInfoHeader,
 *   TooltipContent?: TooltipContent,
 * }} Components
 */

/**
 * @type {import("react").Context<Components>}
 */
export const ComponentsContext = createContext({});
ComponentsContext.displayName = 'ComponentsContext';
