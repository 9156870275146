import VectorTileSource from 'ol/source/vectortile';

import {createDependencyMapper, di} from '../../index';
import base from './urltile';

export default {
	type: 'source',
	name: 'VectorTile',
	Constructor: VectorTileSource,
	optionMap: {
		...base.optionMap,
	},
	initialOptionMap: {
		...base.initialOptionMap,
		format: createDependencyMapper(di, 'format'),
		cacheSize: 'cacheSize',
		logo: 'logo',
		overlaps: 'overlaps',
		projection: 'projection',
		state: 'state',
		tileClass: 'tileClass',
		tileGrid: 'tileGrid',
		wrapX: 'wrapX',
		transition: 'transition',
	},
};
