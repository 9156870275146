import React, {memo} from 'react';

import {useSelector} from 'react-redux';

import {isViewMobile, mapVisible, pageTitleShowSelector, viewSelector} from '../store/selectors';

import VisibilityWrapper from './helping/visibility-wrapper';
import TitleBar from './title-bar';
import MainPanel from './main-panel';
import MapWrapper from './map-wrapper';

function MainContainer({selectedFeature}) {
	const view = useSelector(viewSelector);
	const isMobile = isViewMobile(view);

	return (
		<div className="ms3-main-container ms3-flex ms3-flex--column">
			<VisibilityWrapper visibleSelector={pageTitleShowSelector}>
				<TitleBar />
			</VisibilityWrapper>

			<VisibilityWrapper visibleSelector={mapVisible}>
				<div className="ms3-map-row [ ms3-flex ms3-flex-grow ms3-flex--row ]">
					{!isMobile ? (
							<MainPanel view={view} selectedFeature={selectedFeature} panelWrapper={true} />
					) : null}

					<MapWrapper />
				</div>
			</VisibilityWrapper>

			{isMobile && (
				<MainPanel view={view} selectedFeature={selectedFeature} />
			)}
		</div>
	);
}

export default memo(MainContainer);
