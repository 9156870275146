import olArray from 'ol/array';

import getVisibleLayersFromFramestate from '@mapsight/lib-ol/map/getVisibleLayersFromFramestate';

import {async, controlled, set} from '../../base/actions';

import {getIdForLayer} from './tagLayer';
import throttleDispatch from './throttleDispatch';

export default function withVisibleLayers(mapController, map) {
	let visibleLayers = [];
	map.on('postrender', throttleDispatch(function onPostRender({frameState}) {
		if (!frameState) {
			return;
		}

		const newVisibleLayers = getVisibleLayersFromFramestate(frameState).map(getIdForLayer);
		if (!olArray.equals(newVisibleLayers, visibleLayers)) {
			visibleLayers = newVisibleLayers;
			mapController.dispatch(controlled(async(set([mapController.getName(), 'visibleLayers'], visibleLayers))));
		}
	}));
}
