import React, {memo, useContext, useCallback, useRef, useEffect, useState} from 'react';

import getFeatureProperty from '../../helpers/get-feature-property';
import FeatureDetailsContent from '../feature-details-content';
import {ComponentsContext} from '../contexts';
import {translate} from '../../helpers/i18n';

const stopEventPropagation = e => e.stopPropagation();
const STUCK_HEADER_SIZE = 30;

const FeatureSelectionInfoHeader = memo(function FeatureSelectionInfoHeader({feature}) {
	const comps = useContext(ComponentsContext);

	const content = (
		<h3>{getFeatureProperty(feature, 'name')}</h3> // TODO: document/collect magic property names
	);

	if (comps.FeatureSelectionInfoHeader) {
		return (
			<comps.FeatureSelectionInfoHeader feature={feature}>
				{content}
			</comps.FeatureSelectionInfoHeader>
		);
	}
	return content;
});

function FeatureSelectionInfo({detailsHtml, detailsHasError, feature, removeSelection}) {
	const [isHeaderStuck, setIsHeaderStuck] = useState(false);
	const [stickThreshold, setStickThreshold] = useState(0);

	const stickyRef = useRef();
	const stickyScrollAreaRef = useRef();

	const onScroll = useCallback(
		/**
		 * @param {React.UIEvent<HTMLDivElement, UIEvent>} e
		 */
		(e) => {
			setIsHeaderStuck(stickThreshold < e.target.scrollTop);
		},
		[setIsHeaderStuck, stickThreshold]
	);

	const featureId = feature && typeof feature === 'object' && feature.id;

	// reset on feature change
	useEffect(
		() => {
			if (stickyScrollAreaRef.current) {
				stickyScrollAreaRef.current.scrollTop = 0;
			}

			setIsHeaderStuck(false);
		},
		[featureId],
	);

	useEffect(
		() => {
			if (stickyRef.current) {
				const headerSize = stickyRef.current.offsetHeight;
				setStickThreshold(headerSize - STUCK_HEADER_SIZE);
			}
		},
		// executed after each render
	);

	if (!feature) {
		return null;
	}

	const stuckClass = (prefix) => prefix + ' ' + (isHeaderStuck ? prefix + '--stuck' : '');

	return (
		<div
			className={`[ ms3-feature-selection-info ms3-feature-selection-info--select ] [ ${stuckClass('ms3-sticky-header__container')} ]`}
		>
			<button
				className={`ms3-button [ ${stuckClass('ms3-feature-selection-info__close-button')} ]`}
				type="button"
				onClick={removeSelection}
			>
				<span className="visuallyhidden">
					{translate('ui.feature-selection-info.close')}
				</span>
			</button>
			{/* TODO: ARIA */}

			<header
				className={`ms3-feature-selection-info__header [ ms3-sticky-header__header ${isHeaderStuck ? 'ms3-sticky-header__header--stuck' : 'ms3-sticky-header__header--stuck-placeholder'} ]`}
			>
				<FeatureSelectionInfoHeader feature={feature} />
			</header>

			<div
				className={`ms3-feature-selection-info__wrapper [ ${stuckClass('ms3-sticky-header__scroll-area')} ]`}
				onScroll={onScroll}
				onTouchMove={stopEventPropagation}
				ref={stickyScrollAreaRef}
			>
				<header
					className={`ms3-feature-selection-info__header [ ms3-sticky-header__header ${isHeaderStuck ? 'ms3-sticky-header__header--sticky-placeholder' : 'ms3-sticky-header__header--sticky'} ]`}
					ref={stickyRef}
				>
					<FeatureSelectionInfoHeader feature={feature} />
				</header>

				<div className="ms3-feature-selection-info__content">
					<FeatureDetailsContent
						html={detailsHtml}
						hasError={detailsHasError}
						feature={feature}
					/>
				</div>
			</div>
		</div>
	);
}

export default memo(FeatureSelectionInfo);
