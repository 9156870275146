import Cache from '@neonaut/lib-js/es/adt/LRUMap';

const IMAGE_TYPES = ['icon', 'circle'];
const NONE_ABLE_PROPS = ['fill', 'stroke'];

const unitFromAnchorValue = a => (a && typeof a === 'string' && a.indexOf('px') > -1 ? 'pixels' : 'fraction');
const boolishValue = a => a === true || a === 1 || a === '1' || a === 'true';
const numberArrayValue = a => (a && typeof a === 'string' ? a.split(',') : Array.isArray(a) ? a : []).map(b => parseFloat(b.trim()));

const HASH_STRING_DELIMITER = '|';
export const DEFAULT_CACHE_SIZE = 100;

let _cache;

function getDefaultCache() {
	return _cache || (_cache = new Cache(DEFAULT_CACHE_SIZE));
}

function _declarationToStyle(constructorsMap, declaration, type, cache) {
	if (declaration.display && declaration.display.value === 'none') {
		return null;
	}

	const style = {};
	const keys = Object.keys(declaration).filter(key => IMAGE_TYPES.indexOf(key) === -1);  // exclude image types

	keys.forEach(key => {
		const declarationValue = declaration[key];
		const declarationValueValue = declarationValue.value;

		if (
			declarationValueValue === 'unset' ||
			(declarationValueValue === 'none' && NONE_ABLE_PROPS.indexOf(key) > -1)
		) {
			delete style[key];
			return;
		}

		const handleDefault = () => {
			if (declarationValueValue) {
				style[key] = declarationValueValue;
			} else if (constructorsMap[key]) {
				try {
					style[key] = declarationToStyle(constructorsMap, declarationValue, key, undefined, cache);
				} catch (e) {
					console.error('Style error', e);
				}
			}
		};

		/* eslint-disable no-case-declarations, smells/no-complex-switch-case */
		switch (key) {
			case 'image':
				// image is the base for icon, circle and regular shape. image only has image-type: *; any other options
				// will be in either of the above. e.g. icon-src: ...;
				const imageType = declarationValue && declarationValue.type && declarationValue.type.value;
				if (imageType) {
					if (declaration[imageType]) {
						style[key] = declarationToStyle(constructorsMap, declaration[imageType], imageType, undefined, cache);
					}
				} else {
					console.error('Image type unknown', imageType, declaration);
				}
				break;

			case 'geometry':
				// we ignore the geometry for the style. this will be used when binding the style to the geometry
				break;

			// booleans
			case 'snapToPixel':
			case 'rotateWithView':
				style[key] = boolishValue(declarationValueValue);
				break;

			// allow space separated double value (x and y)
			//case 'size':
			//	style.size = declarationValueValue ? declarationValueValue.split(' ').map(part => parseFloat(part)) : style.size;
			//	break;

			case 'sizeX':
				style.size = style.size || [0, 0];
				style.size[0] = declarationValueValue ? parseFloat(declarationValueValue) : 0;
				break;

			case 'sizeY':
				style.size = style.size || [0, 0];
				style.size[1] = declarationValueValue ? parseFloat(declarationValueValue) : 0;
				break;

			// allow space separated double value (x and y)
			//case 'imgSize':
			//	style.imgSize = declarationValueValue ? declarationValueValue.split(' ').map(part => parseFloat(part)) : style.imgSize;
			//	break;

			case 'imgSizeX':
				style.imgSize = style.imgSize || [0, 0];
				style.imgSize[0] = declarationValueValue ? parseFloat(declarationValueValue) : 0;
				break;

			case 'imgSizeY':
				style.imgSize = style.imgSize || [0, 0];
				style.imgSize[1] = declarationValueValue ? parseFloat(declarationValueValue) : 0;
				break;

			case 'anchorX':
				style.anchor = style.anchor || [0, 0];
				style.anchor[0] = declarationValueValue ? parseFloat(declarationValueValue) : 0;
				style.anchorXUnits = unitFromAnchorValue(declarationValueValue);
				break;

			case 'anchorY':
				style.anchor = style.anchor || [0, 0];
				style.anchor[1] = declarationValueValue ? parseFloat(declarationValueValue) : 0;
				style.anchorYUnits = unitFromAnchorValue(declarationValueValue);
				break;

			// can be directly (offsetX in ol.style.Text or offset array in ol.style.Icon)
			case 'offsetX':
				style.offset = style.offset || [0, 0];
				style.offset[0] = declarationValueValue ? parseFloat(declarationValueValue) : 0;
				handleDefault();
				break;

			// can be directly (offsetY in ol.style.Text or offset array in ol.style.Icon)
			case 'offsetY':
				style.offset = style.offset || [0, 0];
				style.offset[1] = declarationValueValue ? parseFloat(declarationValueValue) : 0;
				handleDefault();
				break;

			case 'colorRed':
				style.color = style.color || [0, 0, 0, 1];
				style.color[0] = declarationValueValue ? parseInt(declarationValueValue, 10) : 0;
				break;

			case 'colorGreen':
				style.color = style.color || [0, 0, 0, 1];
				style.color[1] = declarationValueValue ? parseInt(declarationValueValue, 10) : 0;
				break;

			case 'colorBlue':
				style.color = style.color || [0, 0, 0, 1];
				style.color[2] = declarationValueValue ? parseInt(declarationValueValue, 10) : 0;
				break;

			case 'colorAlpha':
				style.color = style.color || [0, 0, 0, 1];
				style.color[3] = declarationValueValue ? parseFloat(declarationValueValue) : 0;
				break;

			case 'lineDash':
				style[key] = numberArrayValue(declarationValueValue);
				break;

			default:
				handleDefault();
		}
	});

	return new constructorsMap[type](style);
}

export default function declarationToStyle(constructorsMap, declaration, type, hash, cache = getDefaultCache()) {
	if (!constructorsMap[type]) {
		console.error('Style unknown', type, declaration);
		return null;
	}

	const cacheHash = type + HASH_STRING_DELIMITER + (hash || JSON.stringify(declaration));
	if (!cache[cacheHash]) {
		cache[cacheHash] = _declarationToStyle(constructorsMap, declaration, type, cache);
	}

	return cache[cacheHash];
}
