import loadJS from '../dom/network/load-js';

/* NOTE: Piwik only works with globals … */
/* eslint-disable @neonaut/no-restricted-global-extend */
if (typeof window !== 'undefined') {
	window._paq = window._paq || [];
}

/**
 * @param {string} piwikURL piwik url
 * @param {number} piwikSiteId piwik site id
 * @param {Array.<string>} piwikDomains domains to track
 * @param {string} [piwikTrackerUrl] piwik tracker url. default: piwikURL + 'piwik.php'
 */
export function init(piwikURL, piwikSiteId, piwikDomains, piwikTrackerUrl) {
	piwikTrackerUrl = piwikTrackerUrl || piwikURL + 'piwik.php';

	window._paq.push(['setDomains', piwikDomains]);
	window._paq.push(['enableLinkTracking']);
	window._paq.push(['enableHeartBeatTimer']);
	window._paq.push(['setTrackerUrl', piwikURL + 'piwik.php']);
	window._paq.push(['setSiteId', piwikSiteId]);
	window._paq.push(['trackPageView']);

	if (window.console) {
		console.log('loading piwik ', window._paq[0][0], window._paq[0][1]);
	}

	loadJS(piwikURL + 'piwik.js', () => {
		if (window.console) {
			console.log('loaded piwik ', piwikURL, piwikSiteId, piwikDomains, piwikTrackerUrl, window._paq);
		}
	});
}

export function pushPiwikCommand(command) {
	if (window.console) {
		console.log('pushPiwikCommand', command[0], command[1], command[2], command[3], command[4]);
	}
	window._paq.push(command);
}

export function trackPageView(url, documentTitle) {
	url = url || window.location.href;
	documentTitle = documentTitle || window.document.title;

	pushPiwikCommand(['setDocumentTitle', documentTitle]);
	pushPiwikCommand(['setCustomUrl', url]);
	pushPiwikCommand(['trackPageView']);
}

export function trackPiwikGoal(goalId) {
	pushPiwikCommand(['trackGoal', goalId]);
}

export function trackEvent(category, action, name, value) {
	name = name || false;
	value = value || false;

	pushPiwikCommand(['trackEvent', category, action, name, value]);
}

export function trackLink(url, linkType) {
	linkType = linkType || 'link';

	pushPiwikCommand(['trackLink', url, linkType]);
}

export function trackSiteSearch(keyword, category = false, searchCount = false) {
	category = category || false;
	searchCount = searchCount || false;

	pushPiwikCommand(['trackSiteSearch', keyword, category, searchCount]);
}
