import {SET_STYLE_ENV} from '../actions';

const noopStyleFunction = () => undefined;

export default function withStyleFunction(mapController, map, initialStyleFunction = undefined) {
	let styleFunctionRef = initialStyleFunction;

	let globalStyleEnv;
	mapController.registerReducer(function reduceWithStyleFunction(state, action) {
		if (action.type === SET_STYLE_ENV) {
			state.styleEnv = action.styleEnv;
			globalStyleEnv = action.styleEnv;
		}

		return state;
	});

	mapController.setStyleFunction = function setStyleFunction(styleFunction) {
		styleFunctionRef = styleFunction;
	};
	mapController.createStyleFunction = function createStyleFunction(instanceStyleEnv = {}, ...args) {
		return function styleFeature(feature) {
			const map = mapController._map;
			const view = map.getView();

			if (!styleFunctionRef) {
				console.error('Cannot style feature, because styleFunction is missing. Set it with mapController.setStyleFunction(fn).');
				return noopStyleFunction;
			}

			return styleFunctionRef({
				...globalStyleEnv,
				...instanceStyleEnv,

				// TODO: Use store state instead of view object?
				zoom: Math.round(view.getZoom()),
				resolution: view.getResolution(),
				rotation: view.getRotation(),
				size: map.getSize(),
			}, feature, ...args);
		};
	};
}
