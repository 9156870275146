import getFeatureProperty from '../../helpers/get-feature-property';

function filterByQueries(queries, feature) {
	// TODO: This list of properties should be configurable!
	// TODO: document/collect magic property names
	const values = ['id', 'source', 'name', 'description', 'roadNumber']
		.map(key => getFeatureProperty(feature, key))
		.filter(val => !!val)
		.map(val => val.toLowerCase());

	return queries.every(query => values.some(value => value.indexOf(query) !== -1));
}

/**
 * @param {null | string} [query]
 * @param {Record<string, any>} features
 */
export function filterFeatures(query, features) {
	const queries = (query || '').trim().toLowerCase().split(/\s+/g);

	if (queries.length === 0 || (queries.length === 1 && queries[0] === '')) {
		return features;
	}

	return features.filter(feature => filterByQueries(queries, feature));
}
