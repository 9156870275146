import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {mapSizeSelector} from '@mapsight/core/lib/map/selectors';

import {MAP} from '../../config/constants/controllers';

import MapSyncedInterlay from './map-synced-interlay';

export default connect(createStructuredSelector({
	size: state => mapSizeSelector(state[MAP]),
	//pendingUpdateSize: state => state[MAP].pendingUpdateSize || false,
}))(MapSyncedInterlay);
