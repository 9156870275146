import range from 'lodash/range';

import findClosestPoint from './findClosestPoint';

const FULL_CIRCLE_ANGLE = 2 * Math.PI; // 360 degrees

/**
 * Calculates points evenly spread on circle around center [x, y] on the given radius.
 *
 * @todo consider exporting / moving into separate file
 *
 * @param {Array<Number, Number>} center center coordinates
 * @param {Number} resolution resolution
 * @param {Number} radius radius
 * @param {Number} number number of points
 * @returns {Array<Array<Number, Number>>} points on circle
 */
function calcPointsOnCircle(center, resolution, radius, number) {
	const [x, y] = center;
	const spreadRadius = resolution * radius;
	const spreadAngle = FULL_CIRCLE_ANGLE / number;

	return range(number).map(j => [
		x + (spreadRadius * Math.cos(j * spreadAngle)),
		y + (spreadRadius * Math.sin(j * spreadAngle)),
	]);
}

/**
 * Spreads points around coords in circle around radius
 *
 * @param {ol.coordinates|Array<number,number>} coords center of the cluster
 * @param {Number} resolution current resolution
 * @param {Number} radius radius to spread in
 * @param {Array<ol.coordinates|Array<Number, Number>>} points points to spread
 * @returns {Object<Number, ol.coordinates|Array<Number, Number>>} spread points
 */
export default function spreadPointClusterInRadius(coords, resolution, radius, points) {
	// Check if too close/too many icons?
	//const sehnenLaenge = 2 * spreadRadius * Math.sin(spreadAngle / 2);
	//console.log({sehnenLaenge: sehnenLaenge});

	// We calculate ideal points on the circle with the given radius
	const targetPoints = calcPointsOnCircle(coords, resolution, radius, points.length);

	// Copy real points to object
	const realPoints = {...points}; // https://stackoverflow.com/questions/4215737/convert-array-to-object

	// Find the closest real point for each point on the circle, save in object keeping the key of the real point
	const spreadPoints = {};
	for (const targetPoint of targetPoints) {
		const key = findClosestPoint(targetPoint, realPoints);
		delete realPoints[key];
		spreadPoints[key] = targetPoint;
	}

	return spreadPoints;
}
