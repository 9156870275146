import React, {Fragment, memo, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import getFeatureProperty from '../../helpers/get-feature-property';

import createSelectFeature from '../feature-list-item/create-select-feature';
import {
	listUiOptionSelectedOnlySelector,
	listUiOptionSelectionBehaviorSelector,
	listUiOptionSelectionSelectionSelector, viewSelector
} from '../../store/selectors';

import FeatureListItem from '../feature-list-item';
import FeatureListContent from './content';
import FeatureListGroupName from './group-name';


const renderItems = (features, itemProps) => features.map(feature => (
	<FeatureListItem {...itemProps} key={feature.id} feature={feature} />
));

/**
 * @param {boolean} enableGrouping grouping enabled
 * @param {object[]} features filtered features
 * @param {object} itemProps item props
 * @returns {{groups: null|MapsightUiListGroup[], itemGroups: FeatureListItem[][]}} itemGroups
 */
function calcAndRenderGroupedFeatureItems(enableGrouping, features, itemProps) {
	if (enableGrouping && features.length) {
		const groups = determineFeatureGroups(features);
		if (groups && groups.length) {
			return {
				groups: groups,
				itemGroups: groups.map(group => renderItems(group.features, itemProps)),
			};
		}
	}

	return {
		groups: null,
		itemGroups: features.length ? [renderItems(features, itemProps)] : [],
	};
}

/**
 * Grouped content component
 *
 * @param {object} props props
 * @returns {React.ReactElement} react element
 */
function FeatureListGroupedContent(props) {
	const {
		groupAs = null,
		filteredFeatures = [],
		itemProps,
		...restProps
	} = props;

	const dispatch = useDispatch();
	const selectionBehavior = useSelector(listUiOptionSelectionBehaviorSelector);
	const selectionBehaviorSelection = useSelector(listUiOptionSelectionSelectionSelector);
	const selectedOnly = useSelector(listUiOptionSelectedOnlySelector);
	const view = useSelector(viewSelector);
	// das braucht hier _kein_ useMemo, bis dato wurde das für jedes einzelne Element ausgeführt
	// wenn es dennoch optimiert werden soll, dann per memoizee in der create-feature-select.js.
	// dann wird das Ergebnis auch gleich für withKeyboard verwedent.
	const selectFeature = createSelectFeature(selectedOnly, selectionBehavior, selectionBehaviorSelection, view, dispatch);

	// we have to separate calculation of items from wrapping them,
	// as the outer tags depend on restProps and we do not want to recreate list items on changes to restProps.
	const {
		/** @var {MapsightUiListGroup[]} groups */
		groups,
		/** @var {FeatureListItem[][]} itemGroups */
		itemGroups,
	} = useMemo(
		() => calcAndRenderGroupedFeatureItems(!!groupAs, filteredFeatures, {selectFeature, ...itemProps}),
		[groupAs, filteredFeatures, itemProps]
	);

	if (!filteredFeatures.length) {
		return (
			<FeatureListContent {...restProps} />
		);
	}

	if (groups) {
		// TODO make a better heuristic or add parameter groupNameAs
		const wrapWithGroupType = groupAs === 'li';
		const GroupWrapperT = wrapWithGroupType ? groupAs : Fragment;
		const GroupNameT = wrapWithGroupType ? Fragment : groupAs;

		return (
			<Fragment>
				{groups.map(function composeGroup(group, index) {
					const groupWrapperClass = wrapWithGroupType ? 'ms3-list__group ms3-list__group--wrapper' : null;
					const groupNameClass = wrapWithGroupType ? null : 'ms3-list__group ms3-list__group--name';

					return (
						<GroupWrapperT key={group.name} className={groupWrapperClass}>
							{group.name && (
								<FeatureListGroupName
									className={groupNameClass}
									as={GroupNameT}
									groupName={group.name}
								/>
							)}
							<FeatureListContent
								data-ms3-group-name={group.name}
								{...restProps}
							>
								{itemGroups[index]}
							</FeatureListContent>
						</GroupWrapperT>
					);
				})}
			</Fragment>
		);
	}

	return (
		<FeatureListContent {...restProps}>
			{itemGroups[0]}
		</FeatureListContent>
	);
}

export default memo(FeatureListGroupedContent);


/**
 * @typedef {object} MapsightUiListGroup
 * @property {string} name
 * @property {object[]} features
 */

/**
 * Determines feature groups
 *
 * @param {object[]} features features
 * @returns {MapsightUiListGroup[]} groups
 */
function determineFeatureGroups(features) {
	const groups = [];

	// default group
	groups[0] = createGroup('');

	features.forEach(feature => {
		const featureGroupName = getFeatureProperty(feature, 'group'); // TODO: document/collect magic property names

		if (!featureGroupName) {
			groups[0].features.push(feature);
		} else {
			// find group and add feature to it
			let group = findGroup(groups, featureGroupName);
			if (!group) {
				group = createGroup(featureGroupName);
				groups.push(group);
			}
			group.features.push(feature);
		}
	});

	// reset if no groups found in data
	// TODO discuss with paul, if we should show an empty header for the group without name and
	//  if to show that even if there's no other group
	if (groups.length === 1) {
		return [];
	}

	// remove default group if there are no features left
	if (!groups[0].features.length) {
		groups.shift();
	}

	return groups;
}

/**
 * @param {string} name group name
 * @returns {MapsightUiListGroup} group
 */
function createGroup(name) {
	return {name: name, features: []};
}

/**
 * Finds group by name
 *
 * @param {MapsightUiListGroup[]} groups groups
 * @param {string} name name
 * @returns {MapsightUiListGroup} group
 */
function findGroup(groups, name) {
	let group = null;
	groups.some(g => {
		if (g.name === name) {
			group = g;
			return true;
		}
		return false;
	});
	return group;
}
