import {observeState} from '@neonaut/lib-redux/es/observe-state';

import {async} from '../../../base/actions';
import {getFilteredFeatures} from '../../../feature-selections/selectors';
import {deselect} from '../../../feature-selections/actions';

const featureSelectionsObserverInstances = new WeakMap();

export default class FeatureSelectionConnector {
	constructor(featureSelectionsController) {
		this._featureSelectionsController = featureSelectionsController;
		this._reverseData = new Map();
		this._data = new Map();
		this._previousData = new Map();

		this._listeners = [];
	}

	deselect(selectionId, featureId) {
		this._featureSelectionsController.dispatch(async(deselect(this._featureSelectionsController.getName(), selectionId, featureId)));
	}

	get(featureId) {
		return this._data.get(featureId);
	}

	getPrevious(featureId) {
		return this._previousData.get(featureId);
	}

	getAllFeaturesWithState(state) {
		return this._reverseData.get(state) || [];
	}

	subscribe(listener) {
		this._listeners.push(listener);

		if (this._listeners.length === 1) {
			this._unsubscribeFeatureSelections = observeState(
				this._featureSelectionsController,
				selections => selections, // select all
				selections => {
					if (!selections) {
						return;
					}

					// update selection maps
					const previous = this._previousData;
					this._previousData = this._data;
					this._data = previous;
					this._data.clear();
					this._reverseData.clear();

					Object.keys(selections).forEach(state =>
						getFilteredFeatures(selections[state]).forEach(featureId => {
							if (this.get(featureId) === undefined) {
								this._data.set(featureId, state);
								this._reverseData.set(
									state,
									(this._reverseData.get(state) || []).concat([featureId]),
								);
							}
						})
					);

					// feature changes
					this._emit();
				}
			);
		}

		return () => this.unsubscribe(listener);
	}

	unsubscribe(listener) {
		this._listeners = this._listeners.filter(l => l !== listener);

		if (this._listeners.length === 0 && this._unsubscribeFeatureSelections) {
			this._unsubscribeFeatureSelections();
			this._unsubscribeFeatureSelections = null;
		}
	}

	_emit() {
		this._listeners.forEach(listener => listener());
	}

	static getInstance(featureSelectionsController) {
		if (featureSelectionsObserverInstances.has(featureSelectionsController)) {
			return featureSelectionsObserverInstances.get(featureSelectionsController);
		}

		const instance = new FeatureSelectionConnector(featureSelectionsController);
		featureSelectionsObserverInstances.set(featureSelectionsController, instance);
		return instance;
	}
}
