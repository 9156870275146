function shallowCloneObject(o) {
	return {...o};
}

/**
 * Creates a new action (object or function) with the same content but new reference.
 *
 * @param {object|Function} action action
 * @param {object} [action.meta] an action may have meta data
 * @returns {object|Function} cloned action
 */
export default function cloneAction(action) {
	// special case for redux-thunk where the action is a function
	if (typeof action === 'function') {
		const newAction = function (...args) {
			return action(...args);
		};

		if (action.meta) {
			newAction.meta = shallowCloneObject(action.meta);
		}

		if (action.name) {
			Object.defineProperty(newAction, 'name', {value: action.name + '__cloned'});
		}

		return newAction;
	}

	// otherwise just make a shallow copy of the plan object action
	return shallowCloneObject(action);
}
