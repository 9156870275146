export default function deepExtend(targetObject = {}, ...sourceObjects) {
	sourceObjects.forEach(overrideObject => {
		if (!overrideObject) {
			return;
		}

		for (const key in overrideObject) {
			if (overrideObject.hasOwnProperty(key)) {
				if (typeof overrideObject[key] === 'object') {
					targetObject[key] = deepExtend(targetObject[key], overrideObject[key]);
				} else {
					targetObject[key] = overrideObject[key];
				}
			}
		}
	});

	return targetObject;
}
