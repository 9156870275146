import Control from 'ol/control/control';

export default function withMounting(mapController, map) {
	const childrenContainer = document.createElement('div');

	map.addControl(new Control({
		element: childrenContainer,
		target: map.getOverlayContainer(),
	}));

	mapController.mount = function mount(target) {
		const oldViewportElements = target.getElementsByClassName('ol-viewport');

		// cleanup target before rendering (remove pre-rendered nodes)
		[...oldViewportElements].forEach(function cleanupOldViewportElement(oldViewportElement) {
			target.removeChild(oldViewportElement);

			const oldOverlayElements = oldViewportElement.getElementsByClassName('ms3-map-overlay');
			if (oldOverlayElements.length) {
				// move pre-rendered overlay to new container to reduce react re-renders
				childrenContainer.appendChild(oldOverlayElements[0]);
			}
		});

		if (typeof window !== 'undefined') {
			map.setTarget(target);
		}
	};
	mapController.unmount = function unmount() {
		map.setTarget(null);
	};
	mapController.getChildrenContainer = function getChildrenContainer() {
		return childrenContainer;
	};
}
