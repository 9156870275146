export const hasGeolocationSupport = (() => {
	if (typeof window === 'undefined' || !('geolocation' in navigator)) {
		return false;
	}

	if (
		location.protocol !== 'https:'
		&& location.hostname !== 'localhost'
		&& location.hostname !== '127.0.0.1'
	) {
		return false;
	}

	return true;
})();
