import VectorSource from 'ol/source/vector';

import {getAndObserveState} from '@neonaut/lib-redux/es/observe-state';

import {createFilteredFeatureSourceSelector} from '../../../feature-sources/selectors';

import {updateFeaturesInSource} from './updateFeaturesInSource';

const listenerStoreMaps = new WeakMap();

class SharedReadonlyVectorFeatureSource extends VectorSource {
	constructor(store, controllerName, id, targetControllerName, format, internalProjection, externalProjection) {
		super({
			format: format,
		});

		this._listeners = [];
		this._store = store;
		this._controllerName = controllerName;
		this._id = id;
		this._targetControllerName = targetControllerName;
		this._format = format;
		this._internalProjection = internalProjection;
		this._externalProjection = externalProjection;
	}

	static subscribe(store, controllerName, id, targetControllerName, format, internalProjection, externalProjection, listener) {
		let map;
		if (listenerStoreMaps.has(store)) {
			map = listenerStoreMaps.get(store);
		} else {
			map = new Map();
			listenerStoreMaps.set(store, map);
		}

		const hash = JSON.stringify([controllerName, id, targetControllerName, externalProjection, internalProjection]);

		let instance;
		if (!map.has(hash)) {
			instance = new SharedReadonlyVectorFeatureSource(store, controllerName, id, targetControllerName, format, internalProjection, externalProjection);
			instance.__hash = hash;
			map.set(hash, instance);
		} else {
			instance = map.get(hash);
		}

		return {instance: instance, unsubscribe: instance.subscribe(listener)};
	}

	isAbandoned() {
		return this._listeners.length < 1;
	}

	subscribe(listener) {
		if (this.isAbandoned()) {
			this._unsubscribeFromStore = this._subscribeToSource();
		}

		this._listeners.push(listener);

		return () => {
			this._listeners = this._listeners.filter(f => f !== listener);
			if (this._unsubscribeFromStore && this.isAbandoned()) {
				this._unsubscribeFromStore();
			}
		};
	}

	_subscribeToSource() {
		const handleFeatureSourceStateChange = (sourceState)  => {
			if (!sourceState) {
				return;
			}

			if (sourceState.error) {
				console.info(`VectorFeatureSourceFeatureSourceConnector error [${this._controllerName}, ${this._id}]`, sourceState.error);
				return;
			}

			if (sourceState.data) {
				// try to read from feature source
				let newFeatures;
				try {
					newFeatures = this._format.readFeatures(sourceState.data, {
						dataProjection: this._format.readProjection(sourceState.data) || this._externalProjection,
						featureProjection: this._internalProjection,
					});

					updateFeaturesInSource(this, newFeatures);

					this._listeners.forEach(listener => listener());
				} catch (e) {
					// TODO: Should we report exceptions with reading the data?
				}
			}
		};
		const selector = createFilteredFeatureSourceSelector(
			this._controllerName,
			this._id,
			this._targetControllerName,
		);

		return getAndObserveState(this._store, selector, handleFeatureSourceStateChange);
	}
}

export default SharedReadonlyVectorFeatureSource;
