import get from 'lodash/get';

import {async, controlled, withPath} from '../base/actions';

import * as xhrJson from './loaders/xhr-json-loader';
import * as local from './loaders/local-state-loader';
import * as noop from './loaders/noop-loader';

import {ERROR_COLD_CACHE, getIsDue} from './selectors';

function getLoader(type) {
	switch (type) {
		case 'local':
			return local;
		case 'xhr-json':
			return xhrJson;
		default:
			return noop;
	}
}

export const FEATURE_SOURCE_DATA = 'MAPSIGHT_FEATURE_SOURCE_DATA';
export const FEATURE_SOURCE_DATA_UNDO = 'MAPSIGHT_FEATURE_SOURCE_DATA_UNDO';
export const FEATURE_SOURCE_DATA_REDO = 'MAPSIGHT_FEATURE_SOURCE_DATA_REDO';
export const FEATURE_SOURCE_DATA_ADD_FEATURE = 'MAPSIGHT_FEATURE_SOURCE_DATA_ADD_FEATURE';
export const FEATURE_SOURCE_DATA_ADD_FEATURES = 'MAPSIGHT_FEATURE_SOURCE_DATA_ADD_FEATURES';
export const FEATURE_SOURCE_DATA_UPDATE_FEATURE = 'MAPSIGHT_FEATURE_SOURCE_DATA_UPDATE_FEATURE';
export const FEATURE_SOURCE_DATA_UPDATE_FEATURE_PROPERTY = 'MAPSIGHT_FEATURE_SOURCE_DATA_UPDATE_FEATURE_PROPERTY';
export const FEATURE_SOURCE_DATA_UPDATE_FEATURE_GEOMETRY = 'MAPSIGHT_FEATURE_SOURCE_DATA_UPDATE_FEATURE_GEOMETRY';
export const FEATURE_SOURCE_DATA_REMOVE_FEATURE = 'MAPSIGHT_FEATURE_SOURCE_DATA_REMOVE_FEATURE';
export const FEATURE_SOURCE_DATA_REMOVE_FEATURES = 'MAPSIGHT_FEATURE_SOURCE_DATA_REMOVE_FEATURES';
export const FEATURE_SOURCE_DATA_UPDATE_FEATURES = 'MAPSIGHT_FEATURE_SOURCE_DATA_UPDATE_FEATURES';
export const FEATURE_SOURCE_DATA_REMOVE_ALL_FEATURES = 'MAPSIGHT_FEATURE_SOURCE_DATA_REMOVE_ALL_FEATURES';
export const FEATURE_SOURCE_ERROR = 'MAPSIGHT_FEATURE_SOURCE_ERROR';

export const LOAD_FEATURE_SOURCE = 'MAPSIGHT_LOAD_FEATURE_SOURCE';
export const LOAD_FEATURE_SOURCE_SUCCESS = 'MAPSIGHT_LOAD_FEATURE_SOURCE_SUCCESS';
export const LOAD_FEATURE_SOURCE_ERROR = 'MAPSIGHT_LOAD_FEATURE_SOURCE_ERROR';
export const PAUSE_FEATURE_SOURCE_REFRESH_UNTIL_NEXT_LOAD = 'MAPSIGHT_PAUSE_FEATURE_SOURCE_REFRESH_UNTIL_NEXT_LOAD';

export const USE_CACHE_ONLY = 'only';
export const USE_CACHE_NO = false;
export const USE_CACHE_YES = true;

export const undo = (controllerName, id) => withPath({
	type: FEATURE_SOURCE_DATA_UNDO,
	id: id,
}, [controllerName]);

export const redo = (controllerName, id) => withPath({
	type: FEATURE_SOURCE_DATA_REDO,
	id: id,
}, [controllerName]);

export const addFeature = (controllerName, sourceId, feature, options) => controlled(withPath({
	type: FEATURE_SOURCE_DATA_ADD_FEATURE,
	id: sourceId,
	feature: feature,
	options: options,
}, [controllerName]));

export const updateFeature = (controllerName, sourceId, featureId, feature, options) => controlled(withPath({
	type: FEATURE_SOURCE_DATA_UPDATE_FEATURE,
	id: sourceId,
	featureId: featureId,
	feature: feature,
	options: options,
}, [controllerName]));


export const updateFeatureProperty = (controllerName, sourceId, featureId, propertyId, value, options) => controlled(withPath({
	type: FEATURE_SOURCE_DATA_UPDATE_FEATURE_PROPERTY,
	id: sourceId,
	featureId: featureId,
	propertyId: propertyId,
	value: value,
	options: options,
}, [controllerName]));


export const updateFeatures = (controllerName, id, features, options) => controlled(withPath({
	type: FEATURE_SOURCE_DATA_UPDATE_FEATURES,
	id: id,
	features: features,
	options: options,
}, [controllerName]));

export const updateFeatureGeometry = (controllerName, id, featureId, geometry, options) => controlled(withPath({
	type: FEATURE_SOURCE_DATA_UPDATE_FEATURE_GEOMETRY,
	id: id,
	featureId: featureId,
	geometry: geometry,
	options: options,
}, [controllerName]));

export const addFeatures = (controllerName, id, features, options) => controlled(withPath({
	type: FEATURE_SOURCE_DATA_ADD_FEATURES,
	id: id,
	features: features,
	options: options,
}, [controllerName]));

export const removeFeature = (controllerName, id, featureId, options) => controlled(withPath({
	type: FEATURE_SOURCE_DATA_REMOVE_FEATURE,
	id: id,
	featureId: featureId,
	options: options,
}, [controllerName]));

export const removeFeatures = (controllerName, id, featureIds, options) => controlled(withPath({
	type: FEATURE_SOURCE_DATA_REMOVE_FEATURES,
	id: id,
	featureIds: featureIds,
	options: options,
}, [controllerName]));

export const removeAllFeatures = (controllerName, id, options) => controlled(withPath({
	type: FEATURE_SOURCE_DATA_REMOVE_ALL_FEATURES,
	id: id,
	options: options,
}, [controllerName]));

export const setData = (controllerName, id, data) => withPath({
	type: FEATURE_SOURCE_DATA,
	id: id,
	data: data,
}, [controllerName]);

export const setError = (controllerName, id, error) => withPath({
	type: FEATURE_SOURCE_ERROR,
	id: id,
	error: error,
}, [controllerName]);

export const pauseRefreshUntilNextLoad = (controllerName, id) => withPath({
	type: PAUSE_FEATURE_SOURCE_REFRESH_UNTIL_NEXT_LOAD,
	id: id,
}, [controllerName]);

export const loadSuccess = (controllerName, id, data) => async(withPath({
	type: LOAD_FEATURE_SOURCE_SUCCESS,
	id: id,
	data: data,
}, [controllerName]));

export const loadFailure = (controllerName, id, error) => async(withPath({
	type: LOAD_FEATURE_SOURCE_ERROR,
	id: id,
	error: error,
}, [controllerName]));

export const load = (controllerName, id, options) => async(function handleLoad(dispatch, getState) {
	const currentState = getState()[controllerName][id] || {};
	const {requestId = 0} = currentState || {};
	const nextRequestId = requestId + 1;

	dispatch(withPath({
		type: LOAD_FEATURE_SOURCE,
		id: id,
		options: options,
		requestId: nextRequestId,
	}, [controllerName]));

	return Promise.all([
		loadWithCache(currentState, getState, id, options).then(
			function handleLoadResolved(data) {
				dispatch(loadSuccess(controllerName, id, data));
			},
			function handleLoadRejected(err) {
				dispatch(loadFailure(controllerName, id, err));
				return Promise.reject(err);
			},
		),
		refreshByTimer(currentState).then(function handleRefreshFulfilled(doRefresh = false) {
			if (doRefresh) {
				const state = get(getState(), [controllerName, id], null);
				if (state && state.doRefresh && !state.refreshPaused && state.requestId === nextRequestId) {
					dispatch(load(controllerName, id, {forceRefresh: state.error || getIsDue(state)}));
				}
			}
		}),
	]).catch(function handleLoadException(error) {
		console.error('Feature source load exception: ', error);
	});
});

export function setDataOrError(controllerName, id, {data, error}) {
	if (error) {
		return setError(controllerName, id, error);
	}

	return setData(controllerName, id, data);
}

function refreshByTimer(state) {
	if (typeof window !== 'undefined' && state.doRefresh && state.timer > 0) {
		return new Promise(resolve => setTimeout(() => resolve(true), state.timer));
	}

	return Promise.resolve(false);
}

function loadWithCache(state, getState, id, options = {}) {
	const {forceRefresh = false, useCache = USE_CACHE_YES} = options;

	const canUseCache = !forceRefresh && state.data;
	if (useCache === USE_CACHE_ONLY && !canUseCache) {
		return Promise.resolve().then(() => Promise.reject(ERROR_COLD_CACHE));
	}

	if (useCache !== USE_CACHE_NO && canUseCache) {
		return Promise.resolve(state.data);
	}

	return getLoader(state.type).load(state, options, id, getState);
}
