export default function scrollToElementTop(selector, offsetTop = 0, immediate = false) {
	if (selector && typeof window !== 'undefined') {
		const element = window.document.querySelector(selector);
		if (element) {
			const top = element.getBoundingClientRect().top - offsetTop;

			window.scrollBy({
				left: 0,
				top: top,
				behavior: !immediate ? 'smooth' : 'auto',
			});
		}
	}
}
