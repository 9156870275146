import DoubleClickZoom from 'ol/interaction/doubleclickzoom';

import base from './_base';

export default {
	type: 'interaction',
	name: 'DoubleClickZoom',
	Constructor: DoubleClickZoom,
	optionMap: {
		...base.optionMap,
	},
	initialOptionMap: {
		...base.initialOptionMap,
		duration: 'duration',
		delta: 'delta',
	},
};
