import {getAndObserveState} from '@neonaut/lib-redux/es/observe-state';
import {createNoScrollHandler} from '@neonaut/lib-redux/es/no-scroll-handler-2';

import {viewSelector} from '../../store/selectors';
import {VIEW_FULLSCREEN, VIEW_MAP_ONLY} from '../../config/constants/app';

export const defaultNoScrollHandler = createNoScrollHandler(function (state) {
	const view = viewSelector(state);
	return view === VIEW_MAP_ONLY || view === VIEW_FULLSCREEN;
});
export const defaultNoScrollObserveSelector = viewSelector;

/**
 * This plugin will observe the state for changes to the view (default) and will call the defined handler.
 * By default this will disable scrolling the document and add a class to the document element
 * when the view is in either "mapOnly" or "fullscreen" view.
 *
 * @param {Object} [options] options
 * @param {function(Object)} [options.noScrollHandler] handler to call (will be passed the current state)
 * @param {function(Object): *} [options.noScrollObserveSelector] selector to observe state with
 * @return {MapsightUiPlugin} plugin
 */
export default function createPlugin(options = {}) {
	const {
		noScrollHandler = defaultNoScrollHandler,
		noScrollObserveSelector = defaultNoScrollObserveSelector,
	} = options;

	if (typeof window === 'undefined') {
		console.info('This plugin might not work as intended, if not run in the browser!');
	}

	return {
		afterCreate: function noScrollPlugin(context) {
			const {store} = context;

			getAndObserveState(store, noScrollObserveSelector, (a, b, state) => noScrollHandler(state, false));
		},
	};
}


//// complex example for no-scroll if on mobile and @mapsight/ui wants it, but site has reasons to keep scrolling
//import setDocumentScroll from '@neonaut/simplejs/dom/set-document-scroll';
//import {noScrollHandler, noScrollObserveSelector} from '@mapsight/ui';
//
//let currentIsMainNavigationOpen = getIsNavigationOpen();
//subscribeNavigationOpenChange(isMainNavigationOpen => {
//	currentIsMainNavigationOpen = isMainNavigationOpen;
//	noScrollHandler(store.getState(), currentIsMainNavigationOpen);
//});
//observeState(store, noScrollObserveSelector, newView => {
//	const isNoScroll = noScrollHandler(store.getState(), currentIsMainNavigationOpen);
//	if (isNoScroll) { // zurückscrollen, bei Wechsel der Ansicht oder dem Schließen der Navigation, falls vorher gescrollt worden ist und es jetzt verboten ist.
//		setDocumentScroll(0);
//	}
//	closeNavigation();
//});
//noScrollHandler(store.getState(), currentIsMainNavigationOpen);
