import KeyboardZoom from 'ol/interaction/keyboardzoom';

import base from './_base';

export default {
	type: 'interaction',
	name: 'KeyboardZoom',
	Constructor: KeyboardZoom,
	optionMap: {
		...base.optionMap,
	},
	initialOptionMap: {
		...base.initialOptionMap,
		condition: 'condition',
		duration: 'duration',
		delta: 'delta',
	},
};
