import React, {useContext, memo} from 'react';
import {useSelector} from 'react-redux';

import {ComponentsContext} from '../contexts';
import {viewSelector} from '../../store/selectors';

function MainPanelContent({panelPosition, contentType, collapsed, children, hideList}) {
	const view = useSelector(viewSelector);
	const {MainPanelStart, MainPanelEnd} = useContext(ComponentsContext);

	return (
		<div
			className={`ms3-panel ms3-panel--docked-${panelPosition} ${collapsed ? 'ms3-panel--empty' : ''} ${hideList ? 'ms3-panel__hide-list' : ''}`}
		>
			{MainPanelStart && (
				<MainPanelStart
					panelPosition={panelPosition}
					view={view}
					contentType={contentType}
				/>
			)}

			{children
				/* TODO: maybe wrap the children if we want to position them on top of each other:
				     <div className={`ms3-panel__content ms3-panel__content--docked-${panelPosition}`}> {children} </div> */}

			{MainPanelEnd && (
				<MainPanelEnd
					panelPosition={panelPosition}
					view={view}
					contentType={contentType}
				/>
			)}
		</div>
	);
}

export default memo(MainPanelContent);
