import baseDefinition from '@mapsight/ol-proxy/definitions/source/vectortile';

import VectorTileSource from '../../lib/map/lib/VectorTileSource';

export default {
	...baseDefinition,
	Constructor: VectorTileSource,
	optionMap: {
		...baseDefinition.optionMap,
		timer: 'setTimer',
		doRefresh: 'setDoRefresh',
	},
};
