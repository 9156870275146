import {di, setOptions, updateProxyObject} from '@mapsight/ol-proxy';
import View from '@mapsight/ol-proxy/definitions/view';
import Map from '@mapsight/ol-proxy/definitions/map';

import {async, controlled} from '@mapsight/core/lib/base/actions';
import throttleDispatch from '@mapsight/core/lib/map/lib/throttleDispatch';
import {setViewCenter, setViewRotation, setViewZoomAndResolution} from '@mapsight/core/lib/map/actions';

// Legacy. We always need Map and View so we inject it by default. This should not be done here.
di.inject({
	__root: {
		Map: Map,
		View: View,
	},
});

export default function withMap(mapController) {
	const olMap = di.getDependency('__root', 'Map');

	const name = mapController.getName();

	/** @var {ol.Map} map */
	// eslint-disable-next-line prefer-const
	let map;

	// view
	let view;
	mapController.getAndObserveUncontrolled(state => state.view, (newOptions, oldOptions) => {
		updateProxyObject({
			di: di,
			group: '__root',
			oldObject: view,
			oldDefinition: {type: 'View', options: oldOptions},
			newDefinition: {type: 'View', options: newOptions},
			remover: () => {
				view = null;
			},
			adder: (nextView) => {
				view = nextView;

				if (map) {
					map.setView(view);
				}

				view.on('change:resolution', throttleDispatch(() => {
					mapController.dispatch(async(() => mapController.dispatch(controlled(setViewZoomAndResolution(name, view.getZoom(), view.getResolution())))));
				}));
				view.on('change:center', throttleDispatch(() => {
					mapController.dispatch(async(() => mapController.dispatch(controlled(setViewCenter(name, view.getCenter())))));
				}));
				view.on('change:rotation', throttleDispatch(() => {
					mapController.dispatch(async(() => mapController.dispatch(controlled(setViewRotation(name, view.getRotation())))));
				}));
			},
		});
	});

	map = new (olMap.Constructor)({
		view: view,
		controls: [], // see ./lib/withControls.js
		interactions: [], // see ./lib/withInteractions.js
	});
	mapController.getAndObserveUncontrolled(state => state.map, (newOptions, oldOptions) => {
		setOptions(map, oldOptions, newOptions, olMap.optionMap);
	});
	return map;
}
