import forEach from 'lodash/forEach';
import LayerGroup from 'ol/layer/group';

import {di, updateProxyObject} from '@mapsight/ol-proxy';

import matchesPath from '@neonaut/lib-redux/es/matchesPath';
import reducers from '@neonaut/lib-redux/es/reducers/immutable-path';

import {ACTION_SET} from '../../base/reducer';

import {FIT_MAP_VIEW_TO_LAYER_FEATURE, FIT_MAP_VIEW_TO_LAYER_SOURCE_EXTENT, SET_STYLE_ENV} from '../actions';

import {getGroupForLayer, tagLayer} from './tagLayer';
import proxyPassOpenLayersEventsToMapController from './proxyPassOpenLayersEventsToMapController';

export const LAYER_GROUP_DEFAULT = 'default';

function getOrCreateLayerGroup(mapController, map, groups, id) {
	if (!groups[id]) {
		const groupLayer = new LayerGroup();
		tagLayer(groupLayer, mapController, id);
		groups[id] = {id: id, groupLayer: groupLayer, layers: {}};
		map.addLayer(groupLayer);
	}

	return groups[id].groupLayer;
}

function reduceSetLayerVisibility(state, value, condition) {
	// go through all base layers and set invisible
	for (const id of Object.keys(state.layers)) {
		if (condition(state.layers[id])) {
			state = reducers.set(state, {value: value, path: ['layers', id, 'options', 'visible']});
		}
	}
	return state;
}

export default function withLayers(mapController, map) {
	const layers = {};
	const groups = {
		//id: {id, groupLayer: <layer>, layers: {<layer>, <layer>, ...}}
	};

	function updateLayer(id, newDefinition, oldDefinitions) {
		const oldDefinition = oldDefinitions[id];

		const oldObject = layers[id];

		// update layer
		updateProxyObject({
			di: di,
			group: 'layer',
			oldObject: oldObject,
			oldDefinition: oldDefinition,
			newDefinition: newDefinition,
			remover: function removeLayer() {
				const group = getGroupForLayer(oldObject);
				groups[group].groupLayer.getLayers().remove(oldObject);
				delete layers[id];
				delete groups[group].layers[id];
			},
			adder: function addLayer(layer) {
				const group = newDefinition.group || LAYER_GROUP_DEFAULT;
				const layerGroup = getOrCreateLayerGroup(mapController, map, groups, group);
				layers[id] = layer;
				groups[group].layers[id] = layer;
				tagLayer(layer, mapController, id, group);
				layerGroup.getLayers().push(layer);
				proxyPassOpenLayersEventsToMapController(mapController, layer, newDefinition.type, id, 'layer');
			},
			parentObject: mapController,
		});
	}

	mapController.getAndObserveUncontrolled(
		state => state.layers,
		function updateLayers(newDefinitions = {}, oldDefinitions = {}) {
			forEach(oldDefinitions, (_, id) => updateLayer(id, newDefinitions[id], oldDefinitions));
			forEach(newDefinitions, (newDefinition, id) => updateLayer(id, newDefinition, oldDefinitions));
		}
	);

	mapController.registerReducer(function reduceWithLayers(state, action) {
		// layer based animation
		if (action.type === FIT_MAP_VIEW_TO_LAYER_SOURCE_EXTENT) {
			const [matches, {layerId}] = matchesPath(action.path, 'layers/:layerId');
			if (matches && layers[layerId]) {
				const source = layers[layerId].getSource();
				if (source && source.getExtent) {
					const sourceExtent = source.getExtent();
					if (sourceExtent) {
						mapController.fit(sourceExtent, action.options);
					}
				}
			}
		}

		if (action.type === FIT_MAP_VIEW_TO_LAYER_FEATURE) {
			const [matches, {layerId}] = matchesPath(action.path, 'layers/:layerId');
			if (matches && layers[layerId]) {
				const source = layers[layerId].getSource();
				if (source && source.getFeatureById) {
					const feature = source.getFeatureById(action.featureId);
					if (feature) {
						mapController.fitMapViewToFeature(feature, action.options);
					}
				}
			}
		}

		// enforce only one base layer being visible at once
		if (action.type === ACTION_SET && action.value === true) {
			const [matches, {layerId}] = matchesPath(action.path, 'layers/:layerId/options/visible');
			if (matches && state.layers[layerId].metaData.isBaseLayer) {
				state = reduceSetLayerVisibility(state, false, ({metaData}) => metaData && metaData.isBaseLayer);
			}
		}

		if (action.type === SET_STYLE_ENV) {
			forEach(layers, layer => {
				if (layer.changed) {
					layer.changed();
				}
			});

			map.render();
		}

		return state;
	});
}
