import React, {memo, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {search} from '../../store/actions';
import {searchQuerySelector} from '../../store/selectors';
import {translate} from '../../helpers/i18n';

import SearchQueryInput from './query-input';
import SearchResult from './result';

const preventDefault = e => e.preventDefault();

function Search({onSelect}) {
	const dispatch = useDispatch();
	const query = useSelector(searchQuerySelector);

	const onChange = useCallback(
		(searchQuery) => {
			dispatch(search(searchQuery));
		},
		[dispatch]
	);

	return (
		<form className="ms3-search" onSubmit={preventDefault}>
			<div className="ms3-search__query-input">
				<SearchQueryInput query={query} onChange={onChange} name="search" />

				<button className="ms3-search__send-button" type="submit">
					<span className="visuallyhidden">{translate('ui.search.send')}</span>
				</button>
			</div>

			{query && (
				<output
					className="ms3-search__output"
					name="search-output"
					htmlFor="search"
				>
					<SearchResult onSelect={onSelect} />
				</output>
			)}
		</form>
	);
}

export default memo(Search);
