import {FEATURE_SELECTIONS, FEATURE_SOURCES} from '../constants/controllers';
import {FEATURE_SELECTION_HIGHLIGHT, FEATURE_SELECTION_SELECT} from '../feature/selections';

export function features(featureSourceId, visible = false, interactive = false, _metaData = {}, style = 'features', sourceOptions = {}) {
	return {
		type: 'Vector',
		options: {
			visible: visible,
			style: style,
			//updateWhileAnimating: true,
			//updateWhileInteracting: true,
			renderBuffer: 200,
			source: {
				type: 'VectorFeatureSource',
				options: {
					featureSourceId: featureSourceId,
					featureSourcesControllerName: FEATURE_SOURCES,
					featureSelectionsControllerName: FEATURE_SELECTIONS,
					...sourceOptions,
				},
			},
			selections: !interactive ? {} : {
				//'second-touch': FEATURE_SELECTION_SELECT,
				//touch: FEATURE_SELECTION_HIGHLIGHT,
				mousedown: FEATURE_SELECTION_SELECT,
				touch: FEATURE_SELECTION_SELECT,
				mouseover: FEATURE_SELECTION_HIGHLIGHT,
			},
		},
		metaData: _metaData,
	};
}

export function interactiveFeatures(featureSourceId, visible = false, _metaData = {}, style = 'features', sourceOptions = {}) {
	return features(featureSourceId, visible, true, _metaData, style, sourceOptions);
}

export function userGeolocation(featureSourceId, visible, _metaData = {}, style = 'userGeolocation', sourceOptions = {}) {
	const base = features(featureSourceId, visible, false, _metaData, style, sourceOptions);

	return {
		...base,
		options: {
			...base.options,
			updateWhileAnimating: true,
			updateWhileInteracting: true,
			renderBuffer: 400,
		},
	};
}

export function osm(url, visible = false, _metaData = {}) {
	return {
		type: 'Tile',
		metaData: _metaData,
		options: {
			source: {
				type: 'OSM',
				options: {
					url: url,
				},
			},
			visible: visible,
		},
	};
}

export function wms(url, parameters = {}, projection = 'ESPG:3857', visible = false, _metaData = {}, opacity = undefined) {
	return {
		type: 'Tile',
		metaData: _metaData,
		options: {
			source: {
				type: 'TileWMS',
				options: {
					projection: projection,
					url: url,
					params: parameters,
				},
			},
			visible: visible,
			opacity: opacity,
		},
	};
}


// TODO metadata auftrennen in metaData, withAttribution() und withLegend()
export function metaData(title, attribution = null, visibleInLayerSwitcher = false, visibleInExternalLayerSwitcher = false, isBaseLayer = false, group = null, lockedInLayerSwitcher = undefined) {
	return {
		title: title,
		attribution: attribution,
		visibleInLayerSwitcher: visibleInLayerSwitcher,
		visibleInExternalLayerSwitcher: visibleInExternalLayerSwitcher,
		group: group,
		isBaseLayer: isBaseLayer,
		lockedInLayerSwitcher: lockedInLayerSwitcher, // users can't change layer's visbility TODO this has to be implemented
	};
}

export function metaDataWithAttribution(_metaData, attribution) {
	return {
		..._metaData,
		attribution: attribution,
	};
}

export function metaDataWithLegend(_metaData, legend) {
	return {
		..._metaData,
		legend: legend,
	};
}

export function metaDataWithMiniLegend(_metaData, miniLegend) {
	return {
		..._metaData,
		miniLegend,
	};
}
