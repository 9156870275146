import React, {memo, useCallback} from 'react';

import {translate} from '../../helpers/i18n';

// FIXME css
function FeatureListCycling({
	filteredFeatures,
	haveSelection,
	selected,
	highlighted,
	onFeatureSelection,
	onFeatureHighlight,
	onFeatureUnHighlight,
}) {
	let selectedIdx = -2;

	const partA = [];
	const partB = [];

	// let store = partA.unshift.bind(partA);
	// Alternative: Wenn nicht selected ist, dann alles in die rechte Hälfte
	let store = selected ? partA.unshift.bind(partA) : partB.push.bind(partB);

	if (filteredFeatures && filteredFeatures.length) {
		filteredFeatures.forEach((feature, idx) => {
			const isSelected = (feature.id === selected);
			store(<div
				key={feature.id}
				className={'ms3-list__item--selectable' + (isSelected ? ' ms3-list__item--selected' : '') + (feature.id === highlighted ? ' ms3-list__item--highlight' : '') + ' ms3-list__cycling-box--dot'}
				role="button"
				onClick={onFeatureSelection.bind(undefined, feature.id)}
				onMouseEnter={isSelected ? null : onFeatureHighlight.bind(undefined, feature.id)}
				onMouseLeave={onFeatureUnHighlight}
			/>);

			if (isSelected || (!selected && idx > filteredFeatures.length / 2)) {
				selectedIdx = idx;
				store = partB.push.bind(partB);
			}
		});
	}

	const onPrev = useCallback(
		() => {
			if (filteredFeatures && filteredFeatures.length) {
				if (!selected) {
					onFeatureSelection(filteredFeatures[0].id);
				} else if (selectedIdx > 0) {
					onFeatureSelection(filteredFeatures[selectedIdx - 1].id);
				} else {
					onFeatureSelection(filteredFeatures[filteredFeatures.length - 1].id);
				}
			}
		},
		[filteredFeatures, selected, onFeatureSelection, selectedIdx],
	);

	const onNext = useCallback(
		() => {
			if (filteredFeatures && filteredFeatures.length) {
				if (selected && selectedIdx < filteredFeatures.length - 1) {
					onFeatureSelection(filteredFeatures[selectedIdx + 1].id);
				} else {
					onFeatureSelection(filteredFeatures[0].id);
				}
			}
		},
		[filteredFeatures, selected, onFeatureSelection, selectedIdx],
	);

	return (
		<div className="ms3-list__cycling-box">
			<button
				type="button"
				className="ms3-list__item--selectable ms3-list__cycling-box--prev"
				onClick={onPrev}
			>
				<span className="visuallyhidden">{translate('prevEntry')}</span>
			</button>

			<div className="ms3-list__cycling-box--parts">
				<div className="ms3-list__cycling-box--part ms3-list__cycling-box--part-left">
					{partA}
				</div>
				<div className="ms3-list__cycling-box--part ms3-list__cycling-box--part-right">
					{partB}
				</div>
			</div>

			<button
				type="button"
				className="ms3-list__item--selectable ms3-list__cycling-box--next"
				onClick={onNext}
			>
				<span className="visuallyhidden">{translate('nextEntry')}</span>
			</button>
		</div>
	);
}

export default memo(FeatureListCycling);
