import olExtent from 'ol/extent';

export const DEFAULT_OPTIONS = {
	duration: 300, // TODO: MAGIC NUMBER!
};

export default function centerOnFeature(view, feature, options = DEFAULT_OPTIONS) {
	const featureExtent = feature.getGeometry().getExtent();
	const center = olExtent.getCenter(featureExtent);
	view.animate({center: center, ...options});
}
