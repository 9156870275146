import cloneAction from './clone-action';

/**
 * Filters the actions so the reducer only gets actions without a path or
 * a path matching the given key.
 *
 * @param {Function} reducer the base reducer that should get filtered actions only
 * @param {string} key key of the action parameter that contains the path array
 * @param {string} [statePathMetaKey] name of the meta field that contains the path array (action.meta.path), default: 'path'
 * @returns {Function} resulting reducer
 */
function createFilteredReducerForPath(reducer, key, statePathMetaKey = 'path') {
	function aFilteredByPathReducer(state = {}, action, fullState) {
		const path = action.meta && action.meta[statePathMetaKey];
		if (path && path.length) {
			if (path[0] !== key) {
				return state;
			}

			const slicedAction = cloneAction(action);
			slicedAction[statePathMetaKey] = path.slice(1);

			return reducer(state, slicedAction, fullState);
		}

		return reducer(state, action, fullState);
	}

	if (reducer.name) {
		Object.defineProperty(aFilteredByPathReducer, 'name', {value: reducer.name + '__filtered-by-path'});
	}

	return aFilteredByPathReducer;
}

export default createFilteredReducerForPath;
