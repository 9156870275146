/**
 * Gets the value at `path` of `object`. If the resolved value is
 * `undefined`, the `defaultValue` is returned in its place.
 *
 * @static
 * @param {object | Array | null | undefined} object The object (incl. arrays) to query.
 * @param {(string | symbol | number)[]} path The path of the property to get.
 * @param {*} [defaultValue=undefined] The value returned for `undefined` resolved values.
 * @returns {*} Returns the resolved value.
 * @example
 *
 * var object = {a: [{b: {c: 3}}]};
 * getPath(object, ['a', '0', 'b', 'c']);
 * // => 3
 */
export default function getPath(object, path, defaultValue = undefined) {
	// no path can be resolved if object is null or undefined => return defaultValue
	if (object === null || object === undefined) {
		return defaultValue;
	}

	let index = 0;
	const length = path.length;
	while (object !== null && object !== undefined && index < length) {
		object = object[path[index++]];
	}

	if (index && index === length && object !== undefined) {
		return object;
	}

	return defaultValue;
}
