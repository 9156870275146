import React, {useState, useCallback, memo, useEffect} from 'react';
import {hasGeolocationSupport} from '@mapsight/core/lib/helpers';

import {translate} from '../../helpers/i18n';

import StatusIndicator from './status-indicator';

/** @typedef {{title: string, x: number, y: number, z?: number}} MapsightUiPlace */
/** @typedef {{type: "group", title: string, entries: MapsightUiPlacesData}} MapsightUiPlaceGroup */
/** @typedef {Array.<MapsightUiPlace|MapsightUiPlaceGroup>} MapsightUiPlacesData */

/**
 * @param {MapsightUiPlacesData} places
 * @param {Array<string>} [keyPath]
 * @returns {Array<React.ReactElement>}
 */
function renderOptions(places, keyPath = []) {
	return Object.entries(places).map(([key, place]) => {
		const keyArr = [...keyPath, key];
		const keyStr = keyArr.join(',');

		if (place.type === 'group') {
			return (
				<optgroup label={place.title} key={keyStr}>
					{renderOptions(place.entries, keyArr)}
				</optgroup>
			);
		} else {
			return (
				<option value={keyStr} key={keyStr}>{place.title}</option>
			);
		}
	});
}

/* NOTICE: using onChange instead of onBlur as the change occurs just below this input and should be clear */

/* eslint-disable jsx-a11y/no-onchange */
function FeatureSorter({places, sorting, geolocationStatus, onChange, requestGeolocation}) {
	const [supportsGeoloc, setSupportsGeoloc] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	useEffect(
		() => {
			setSupportsGeoloc(hasGeolocationSupport);
		},
		[],
	);

	const toggleOpen = useCallback(
		() => {
			setIsOpen((prev) => !prev);
		},
		[setIsOpen],
	);

	const onSelectChange = useCallback(
		/**
		 * @param {React.ChangeEvent<HTMLSelectElement>} e
		 */
		(e) => {
			const newValue = e.target.value;

			if (newValue === 'geolocation') {
				requestGeolocation();
			}

			onChange(newValue);
		},
		[onChange, requestGeolocation],
	);

	return (
		<div className={`ms3-features-sorting ms3-features-sorting--${isOpen ? 'active' : 'inactive'}`}>
			<button
				type="button"
				className={'ms3-filter-button ' + (isOpen ? ' ms3-filter-button--icon-sort-active' : ' ms3-filter-button--icon-sort')}
				onClick={toggleOpen}
				title={translate('ui.feature-list.sorting.announcements')}
			>
				<i>{isOpen ? translate('close') : translate('open')}</i>
			</button>

			<div>
				{isOpen && (
					<div className="ms3-features-sorting__panel">
						{translate('ui.feature-list.sorting.byDistance')}

						<select
							value={sorting}
							onChange={onSelectChange}
							onBlur={toggleOpen}
						>
							<option value="">
								{translate('ui.feature-list.sorting.choose')}
							</option>

							{supportsGeoloc && (
								<option value="geolocation">
									{translate('ui.feature-list.sorting.own')}
								</option>
							)}

							{renderOptions(places)}
						</select>

						<StatusIndicator status={geolocationStatus} />
					</div>
				)}
			</div>
		</div>
	);
}

export default memo(FeatureSorter);
