import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import {batchActions} from 'redux-batched-actions';

import {createFeatureSelectionSelector, getFilteredFeatures} from '@mapsight/core/lib/feature-selections/selectors';
import {deselectAll, selectExclusively} from '@mapsight/core/lib/feature-selections/actions';

import {FEATURE_SELECTIONS} from '../../config/constants/controllers';
import {FEATURE_SELECTION_HIGHLIGHT, FEATURE_SELECTION_SELECT} from '../../config/feature/selections';

import FeatureListCycling from './feature-list-cycling';
//import getDocumentScroll from '@neonaut/simplejs/dom/get-document-scroll';

export default connect(
	// stateProps selector
	createSelector(
		[
			createFeatureSelectionSelector(FEATURE_SELECTIONS, FEATURE_SELECTION_SELECT),
			createFeatureSelectionSelector(FEATURE_SELECTIONS, FEATURE_SELECTION_HIGHLIGHT),
		],

		(selectSelection, highlightSelection) => {
			const selectSelectionFeatures = getFilteredFeatures(selectSelection);
			const highlightSelectionFeatures = getFilteredFeatures(highlightSelection);

			return {
				haveSelection: selectSelectionFeatures && selectSelectionFeatures.length > 0,
				selected: selectSelectionFeatures && selectSelectionFeatures[0],
				highlighted: highlightSelectionFeatures && highlightSelectionFeatures[0],
			};
		}
	),

	// dispatchProps
	(dispatch) => ({
		onFeatureSelection: (id, b, c) => {
			if (process.env.NN_JS_LOG_LEVEL === 'verbose') {
				console.log('feature-list-cycling index.js onFeatureSelection', id, b, c);
			}
			const actions = [
				deselectAll(FEATURE_SELECTIONS, FEATURE_SELECTION_HIGHLIGHT),
				selectExclusively(FEATURE_SELECTIONS, FEATURE_SELECTION_SELECT, id, b, c),
			];

			// FIXME kopiert aus feature-list-item/index.js aber der code würde hier wegen dem getDocumentScroll() nicht funktionieren
			// const doScroll = !isMobile && !selectedOnly;
			// // FIXME scroll passiert in mobile obwohl doScroll false ist
			// console.log('onFeatureSelection isMobile: '+isMobile+', selectedOnly: '+selectedOnly+', doScroll: '+doScroll);
			// if (doScroll) {
			//     actions.push(setLastListScrollPosition(getDocumentScroll()));
			//     window.scroll({top: 0, left: 0, behavior: 'smooth'});
			// }

			dispatch(batchActions(actions));
		},
		onFeatureUnSelection: () => dispatch(deselectAll(FEATURE_SELECTIONS, FEATURE_SELECTION_SELECT)),
		onFeatureHighlight: (id) => dispatch(selectExclusively(FEATURE_SELECTIONS, FEATURE_SELECTION_HIGHLIGHT, id)),
		onFeatureUnHighlight: () => dispatch(deselectAll(FEATURE_SELECTIONS, FEATURE_SELECTION_HIGHLIGHT)),
	})
)(FeatureListCycling);
