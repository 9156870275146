import VectorLayer from 'ol/layer/vector';

import {di, OPTION_SET, updateProxyObject} from '../../index';
import base from './_base';

function createOrUpdateSource(layer, oldDefinition, newDefinition) {
	updateProxyObject({
		di: di,
		group: 'source',
		oldObject: layer.getSource(),
		oldDefinition: oldDefinition,
		newDefinition: newDefinition,
		parentObject: layer,
		remover: () => layer.setSource(null),
		adder: source => {
			if (source.setLayer) {
				source.setLayer(layer);
			}

			layer.setSource(source);
		},
	});
}

export default {
	type: 'layer',
	name: 'Vector',
	Constructor: VectorLayer,
	optionMap: {
		...base.optionMap,
		source: (layer, _, oldDefinition, newDefinition) => createOrUpdateSource(layer, oldDefinition, newDefinition),
		style: (layer, _, __, style, {parentObject: mapController}) => {
			const styleEnv = typeof style === 'string' ? {style: style} : style;
			const styleFunction = mapController.createStyleFunction(styleEnv);
			layer.setStyle(styleFunction);
		},
		styleFunction: 'setStyle',
		preload: 'setPreload',
		renderBuffer: OPTION_SET,
		renderOrder: OPTION_SET,
		useInterimTilesOnError: 'setUseInterimTilesOnError',
	},
	initialOptionMap: {
		...base.initialOptionMap,
		preload: 'preload',
		styleFunction: 'style',
		renderBuffer: 'renderBuffer',
		renderMode: 'renderMode',
		renderOrder: 'renderOrder',
		declutter: 'declutter',
		updateWhileAnimating: 'updateWhileAnimating',
		updateWhileInteracting: 'updateWhileInteracting',
		useInterimTilesOnError: 'useInterimTilesOnError',
	},
};
