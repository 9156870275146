import React, {memo} from 'react';

import {siteConfig} from '../../config';

function MapsightIcon({id}) {
	if (!id) {
		return null;
	}

	// TODO make this paths configurable
	const iconFileName = `${id}-default.svg`;
	const iconSrc1x = `${siteConfig.imagesUrl}mapsight-icons-svg/${iconFileName}`;

	return (
		<img src={iconSrc1x} alt="" />
	);
}

function FeatureListIcon({selectable, mapsightIconId, as: T, ...attributes}) {
	let className = `ms3-list__icon ms3-list__icon--id-${mapsightIconId || '-'}`;
	if (selectable) {
		className += ' ms3-list__icon--selectable';
	}

	return (
		<T className={className} aria-hidden={true} {...attributes}>
			<MapsightIcon id={mapsightIconId} />
		</T>
	);
}

export default memo(FeatureListIcon);
