import {createSelector} from 'reselect';

import {ERROR_NOT_ACCURATE} from './actions';

export const STATUS_LOADING = 'loading';
export const STATUS_ERROR = 'error';

export const geolocationStatusSelector = createSelector(
	[
		state => state.error,
		state => state.isRequesting,
	],
	(error, isRequesting) => (isRequesting ? STATUS_LOADING : error ? STATUS_ERROR : null)
);

/**
 * @param {string} userGeolocationControllerName controller
 * @returns {Function} selector
 */
export function createUserGeolocationIsEnabledSelector(userGeolocationControllerName) {
	return createSelector(
		(state) => state[userGeolocationControllerName],
		(geoLoc) => {
			if (typeof geoLoc === 'object' && typeof geoLoc.isEnabled === 'boolean') {
				return !!geoLoc.isEnabled;
			} else {
				return false;
			}
		},
	);
}

/**
 * @typedef {{
 *   error: null | import('./actions').UserGeolocationError,
 *   isRequesting: boolean,
 *   isEnabled: boolean,
 *   latitude: null | number,
 *   longitude: null | number,
 *   accuracy: null | number,
 *   lastUpdated: null | number,
 *  }} UserGeolocationResult
 */

/**
 * @param {string} userGeolocationControllerName controller name
 * @param {{minimumAccuracy?: null | number}} [options] options
 * @returns {Function} selector
 */
export function createUserGeolocationSelector(
	userGeolocationControllerName,
	{minimumAccuracy = null} = {},
) {
	return createSelector(
		(state) => state[userGeolocationControllerName],

		/**
		 * @param {UserGeolocationResult} geoLoc state
		 * @returns {UserGeolocationResult} result
		 */
		(geoLoc) => {
			if (minimumAccuracy !== null && !geoLoc.error) {
				if (geoLoc.accuracy >= minimumAccuracy) {
					return {
						...geoLoc,
						error: ERROR_NOT_ACCURATE,
					};
				}
			}

			return geoLoc;
		}
	);
}

/**
 * @param {string} userGeolocationControllerName controller
 * @param {{minimumAccuracy?: null | number}} [options] options
 * @returns {{data: {type: 'FeatureCollection', features: object[]}}} feature source data
 */
export const createUserGeolocationAsFeatureSelector = (userGeolocationControllerName, {minimumAccuracy = null} = {}) =>
	createSelector(
		createUserGeolocationSelector(userGeolocationControllerName, {minimumAccuracy: minimumAccuracy}),
		(userGeolocation) => {
			if (userGeolocation.error) {
				return userGeolocation;
			}

			const id = userGeolocationControllerName + 'Feature';
			const props = {
				id: id,
				...userGeolocation,
			};

			return {
				data: {
					type: 'FeatureCollection',
					features: [
						{
							type: 'Feature',
							geometry: {
								type: 'Point',
								coordinates: [userGeolocation.longitude, userGeolocation.latitude],
							},
							id: id,
							properties: props,
						},
					],
				},
			};
		}
	);

