import {
	createUserGeolocationAsFeatureSelector,
	createUserGeolocationIsEnabledSelector,
	geolocationStatusSelector,
} from '@mapsight/core/lib/user-geolocation/selectors';
import {createStorage} from '@neonaut/lib-redux/es/local-storage';
import {getGeolocation} from '@mapsight/core/lib/user-geolocation/actions';
import {createSelector, createStructuredSelector} from 'reselect';
import {AbortObserving, getAndObserveState} from '@neonaut/lib-redux/es/observe-state';
import {set} from '@mapsight/core/lib/base/actions';

import * as c from '../../config/constants/controllers';

const defaultFeatureSourceId = 'userGeolocation';
const defaultFeatureSourcesControllerName = c.FEATURE_SOURCES;
const defaultUserGeolocationControllerName = c.USER_GEOLOCATION;

/**
 * This plugin will bind the use geolocation feature source to the use geolocation controller
 *
 * @param {Object} [options] options
 * @param {String} [options.featureSourceId="userGeolocation"] name of the feature source
 * @param {String} [options.featureSourcesControllerName] name of the feature sources controller, defaults to mapsight ui default
 * @param {String} [options.userGeolocationControllerName] name of the user geolocation controller, defaults to mapsight ui default
 * @param {boolean} [options.storeInLocalStorage] Syncs enabled state with localStorage if true
 * @return {import("../..").MapsightUiPlugin} plugin
 */
export default function createPlugin(options = {}) {
	const {
		featureSourceId = defaultFeatureSourceId,
		featureSourcesControllerName = defaultFeatureSourcesControllerName,
		userGeolocationControllerName = defaultUserGeolocationControllerName,
		storeInLocalStorage = false,
	} = options;

	const featureSelector = createUserGeolocationAsFeatureSelector(userGeolocationControllerName);

	if (typeof window === 'undefined') {
		console.error('This plugin will only work as intended in the browser!');
	}

	const localStorage = createStorage('neonaut.de/ms3-ui/user-geolocation');

	return {
		afterCreate: function searchPlugin(context) {
			context.controllers[featureSourcesControllerName].bindFeatureSourceToStore(featureSourceId, featureSelector);
		},

		/**
		 * @param {{store: any}} ctx
		 */
		afterRender({
			store,
		}) {
			if (!storeInLocalStorage) {
				return;
			}

			const lSState = localStorage.getLocalStorageState();

			if (
				lSState !== null
				&& typeof lSState === 'object'
				&& userGeolocationControllerName in lSState
				&& lSState[userGeolocationControllerName] !== null
				&& typeof lSState[userGeolocationControllerName] === 'object'
				&& typeof lSState[userGeolocationControllerName].isEnabled === 'boolean'
			) {
				store.dispatch(set(
					[userGeolocationControllerName, 'isEnabled'],
					lSState[userGeolocationControllerName].isEnabled,
				));
			}

			localStorage.synchronizePathsToLocalStorage(store, [
				[userGeolocationControllerName, 'isEnabled'],
			]);

			const geoLocIsEnabledSelector = createUserGeolocationIsEnabledSelector(
				userGeolocationControllerName,
			);

			const geoLocStatusSelector = createSelector(
				(state) => state[userGeolocationControllerName],
				geolocationStatusSelector,
			);

			getAndObserveState(
				store,
				createStructuredSelector({
					isEnabled: geoLocIsEnabledSelector,
					alreadyRequested: createSelector(
						geoLocStatusSelector,
						(status) => status === 'loading' || status === 'error',
					),
				}),
				({isEnabled, alreadyRequested}) => {
					if (alreadyRequested) {
						return AbortObserving;
					}

					if (isEnabled) {
						store.dispatch(getGeolocation());
						return AbortObserving;
					}
				},
			);
		},
	};
}
