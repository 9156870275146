import Collection from 'ol/collection';
import VectorLayer from 'ol/layer/vector';
import VectorSource from 'ol/source/vector';

import base from './vector';

let optionMap = {...base.optionMap};
delete optionMap.source; // overridden by afterCreation event (see below)
delete optionMap.zIndex; // overridden by afterCreation event (see below)

let initialOptionMap = {...base.initialOptionMap};
delete initialOptionMap.zIndex; // overridden by afterCreation event (see below)

export default {
	type: 'layer',
	name: 'VectorOverlay',
	Constructor: VectorLayer,
	events: {
		afterCreation: layer => {
			const source = new VectorSource({
				useSpatialIndex: false,
				features: new Collection([], {
					unique: true,
				}),
			});
			layer.set('updateWhileAnimating', true);
			layer.set('updateWhileInteracting', true);
			layer.setSource(source);
			layer.setZIndex(1000); // TODO
		}
	},
	optionMap: optionMap,
	initialOptionMap: initialOptionMap,
};
