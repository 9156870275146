import OlVectorTileSource from 'ol/source/vectortile';

/**
 * @classdesc
 * Extended openlayers vector source that is connected to a mapsight feature source
 *
 * @extends {ol.source.Vector}
 */
export default class VectorTileSource extends OlVectorTileSource {
	constructor(options) {
		super(options);

		this._timer = null;
		this._doRefresh = null;
		this._lastLoadTime = null;
		this._refreshTimeout = null;
	}

	getTile(...args) {
		if (this._lastLoadTime === null) {
			this._lastLoadTime = Date.now();
		}

		return super.getTile(...args);
	}

	setTimer(timer) {
		this._timer = timer;
		this._updateRefreshing();
	}

	setDoRefresh(doRefresh) {
		this._doRefresh = doRefresh;
		this._updateRefreshing();
	}

	refresh() {
		this._lastLoadTime = Date.now();
		this.clear();
		this.changed();


		if (this._refreshTimeout) {
			clearTimeout(this._refreshTimeout);
		}

		if (typeof window !== 'undefined' && this._doRefresh && this._timer > 0) {
			this._refreshTimeout = setTimeout(() => this.refresh(), this._timer);
		}
	}

	_updateRefreshing() {
		if (typeof window !== 'undefined' &&this._doRefresh && this._timer > 0) {
			if (this._refreshTimeout) {
				clearTimeout(this._refreshTimeout);

				if (this._lastLoadTime + this._timer < Date.now()) {
					this.refresh();
				}
			}

			this._refreshTimeout = setTimeout(() => this.refresh(), this._timer);
		}
	}
}
