import {set, addTo, removeFrom} from '../base/actions';

export function selectExclusively(controllerName, selectionId, featureId) {
	return set(
		[controllerName, selectionId, 'features'],
		Array.isArray(featureId) ? featureId : [featureId]
	);
}

export function select(controllerName, selectionId, featureId) {
	return addTo([controllerName, selectionId, 'features'], featureId);
}

export function deselectAll(controllerName, selectionId) {
	return selectExclusively(controllerName, selectionId, []);
}

export function deselect(controllerName, selectionId, featureId) {
	return removeFrom([controllerName, selectionId, 'features'], featureId);
}
