import Kinetic from 'ol/kinetic';
import DragPan from 'ol/interaction/dragpan';

import base from './pointer';

export default {
	type: 'interaction',
	name: 'DragPan',
	Constructor: DragPan,
	optionMap: {
		...base.optionMap,
	},
	initialOptionMap: {
		...base.initialOptionMap,
		condition: 'condition', // TODO: function
		// a: [decay, minVelocity, delay]
		kinetic: a => a && Array.isArray(a) && {
			kinetic: new Kinetic(a[0], a[1], a[2]),
		},
	},
};
