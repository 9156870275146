import {ERROR} from '../selectors';

export const defaultSelector = state => ({error: null, data: state});
export const defaultTransformer = state => state;

export function load(state, options, id, getState) {
	const selector = state.selector || defaultSelector;
	const sourceState = selector(state.store ? state.store.getState() : getState());
	const transformer = state.transformer || defaultTransformer;

	if (!sourceState || !sourceState.data) {
		return Promise.resolve()
			.then(() => Promise.reject((sourceState && sourceState.error) || ERROR));
	}
	return Promise.resolve(transformer(sourceState.data));
}
