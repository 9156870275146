/**
 * TODO: Document!
 *
 * @param {ol.Map} map
 */
export default function canvasSizeFixer(map) {
	let canvas;

	function updateCanvasSize() {
		if (canvas) {
			const size = map.getSize();
			const width = size[0] + 'px';
			const height = size[1] + 'px';

			if (canvas.style.width !== width || canvas.style.height !== height) {
				canvas.style.width = width;
				canvas.style.height = height;
			}
		}
	}

	function getTargetCanvas() {
		const targetElement = map.getTargetElement();
		if (!targetElement) {
			return null;
		}

		const viewports = targetElement.getElementsByClassName('ol-viewport');
		if (!viewports.length) {
			return null;
		}

		const canvases = viewports[0].getElementsByTagName('canvas');
		if (!canvases.length) {
			return null;
		}

		return canvases[0];
	}

	function updateTargetCanvas() {
		canvas = getTargetCanvas();
	}

	map.on('change:target', updateTargetCanvas);
	updateTargetCanvas();

	map.on('postcompose', updateCanvasSize);
}
