import forEach from 'lodash/forEach';
import {di, updateProxyObject} from '@mapsight/ol-proxy';

import proxyPassOpenLayersEventsToMapController from './proxyPassOpenLayersEventsToMapController';

export default function withInteractions(mapController, map) {
	const interactions = {};

	let oldDefinitions = {};
	mapController.getAndObserveUncontrolled(
		state => state.interactions,
		function onInteractionChange(newDefinitions = {}) {
			forEach(newDefinitions, (newDefinition, id) => updateProxyObject({
				di: di,
				group: 'interaction',
				oldObject: interactions[id],
				oldDefinition: oldDefinitions && oldDefinitions[id],
				newDefinition: newDefinition,
				remover: function removeInteraction() {
					map.removeInteraction(interactions[id]);
					delete interactions[id];
				},
				adder: function addInteraction(object) {
					interactions[id] = object;
					map.addInteraction(object);
					proxyPassOpenLayersEventsToMapController(mapController, object, newDefinition.type, id, 'interaction');
				},
				parentObject: mapController,
			}));

			oldDefinitions = newDefinitions;
		}
	);
}
