function runAsync(fn) {
	return setTimeout(fn, 10);
}

/**
 * Enhances the stores dispatch function so you can dispatch actions flagged as async.
 * This allows "dispatching while dispatching" by queueing actions that are dispatched while dispatching is already
 * in progress and dispatching them after finishing with the current action.
 *
 * The action is guaranteed to be dispatched asynchronously in any case (even when dispatching is possible immediately).
 *
 * @param {object} store redux store to be enhanced (having dispatch)
 * @param {string|symbol} asyncActionFlag flag that async actions expose (action[actionFlag] == true)
 */
export default function enableAsyncDispatch(store, asyncActionFlag = 'isAsync') {
	// This function will override the store.dispatch method, deferring the actual dispatch of actions flagged as
	// async by queueing them and only dispatching them in the next microtask by scheduling them with setTimeout.
	let isAsyncScheduled = false;
	const actionQueue = [];

	const baseDispatch = store.dispatch;

	function isActionAsync(action) {
		return !!(action.meta && action.meta[asyncActionFlag]);
	}

	function runScheduleAsyncDispatch() {
		isAsyncScheduled = false;

		while (actionQueue.length) {
			const action = actionQueue.shift();
			baseDispatch(action);
		}
	}

	function scheduleAsyncDispatch() {
		if (!isAsyncScheduled) {
			isAsyncScheduled = true;
			runAsync(runScheduleAsyncDispatch);
		}
	}

	function dispatchWithAsyncSupport(action) {
		if (isActionAsync(action)) {
			actionQueue.push(action);
			scheduleAsyncDispatch();
			return;
		}

		return baseDispatch(action);
	}

	store.dispatch = dispatchWithAsyncSupport;
}
