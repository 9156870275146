import get from 'lodash/get';

import deepChangeState from './deep-change-state';

/**
 * Creates a new reducer, that replaces an value at the given path using the given reducer,
 * replacing all parent objects, instead of mutating the existing object(s).
 *
 * The given reducer has to work immutable as well to get the full desired effect!
 *
 * @param {Function} reducer reducer to reduce the value at the path
 * @param {string|symbol} [pathKey] path in the object to reduce, default: 'path'
 * @returns {Function} resulting immutable path reducer
 */
export default function createImmutablePathReducer(reducer, pathKey = 'path') {
	function aImmutablePathReducer(state, action, fullState) {
		const path = action[pathKey];
		const oldValue = path && path.length ? get(state, path) : state;
		const newValue = reducer(oldValue, action, fullState);
		if (newValue === oldValue) {
			return state;
		}

		return deepChangeState(state, path, newValue);
	}

	//if (reducer.name) {
	//	Object.defineProperty(aImmutablePathReducer, 'name', {value: reducer.name + '__immutable-path'});
	//}

	return aImmutablePathReducer;
}
