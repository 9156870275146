import _map from 'lodash/map'; // using underscores to prevent confusion with action names

import {batchActions} from 'redux-batched-actions';

import {ACTION_ADD_TO, ACTION_MERGE, ACTION_REMOVE_FROM, ACTION_SET} from './reducer';

export const ASYNC_ACTION_FLAG = 'MAPSIGHT_ASYNC_ACTION';
export const CONTROLLED_ACTION_FLAG = 'MAPSIGHT_CONTROLLED_ACTION';
export const QUIET_ACTION_FLAG = 'MAPSIGHT_QUIET_ACTION';

export const STATE_PATH_KEY = 'path';

const ERROR_MESSAGE_EMPTY_PATH = 'required path is missing in action';

export function controlled(action) {
	action.meta = action.meta || {};
	action.meta[CONTROLLED_ACTION_FLAG] = true;

	if (action.meta.batch === true) {
		action.payload = action.payload.map(batchedAction => controlled(batchedAction));
	}

	return action;
}

export function quiet(action) {
	action.meta = action.meta || {};
	action.meta[QUIET_ACTION_FLAG] = true;

	if (action.meta.batch === true) {
		action.payload = action.payload.map(batchedAction => quiet(batchedAction));
	}

	return action;
}

export function async(action) {
	action.meta = action.meta || {};
	action.meta[ASYNC_ACTION_FLAG] = true;

	return action;
}

export function throwOnEmptyPath(path) {
	if (!Array.isArray(path) || !path.length) {
		throw new Error(ERROR_MESSAGE_EMPTY_PATH);
	}
}

export function withPath(action, path = null) {
	if (path) {
		action.meta = action.meta || {};
		action.meta[STATE_PATH_KEY] = path;

		if (action.meta.batch === true) {
			action.payload = action.payload.map(batchedAction => withPath(batchedAction, path));
		}
	}

	return action;
}

function allKeys(fn) {
	return function (path, options = null) {
		if (!options) {
			options = path;
			path = null;
		}

		path = path || [];

		const actions = _map(options, (option, key) => fn(path.concat([key]), option));

		return batchActions(actions);
	};
}

export function set(path, value) {
	throwOnEmptyPath(path);

	return withPath({
		type: ACTION_SET,
		value: value,
	}, path);
}

export const setAll = allKeys(set);

export function merge(path, value) {
	throwOnEmptyPath(path);

	return withPath({
		type: ACTION_MERGE,
		value: value,
	}, path);
}

export const mergeAll = allKeys(merge);

export function addTo(path, element) {
	throwOnEmptyPath(path);

	return withPath({
		type: ACTION_ADD_TO,
		element: element,
	}, path);
}

export const addToAll = allKeys(addTo);

export function unset(path) {
	throwOnEmptyPath(path);

	return set(path, undefined);
}

export function removeFrom(path, element) {
	throwOnEmptyPath(path);

	return withPath({
		type: ACTION_REMOVE_FROM,
		element: element,
	}, path);
}

export const removeFromAll = allKeys(removeFrom);
