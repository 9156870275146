import addTo from './add-to';
import merge from './merge';
import noop from './noop';
import removeFrom from './remove-from';
import set from './set';

export default {
	addTo: addTo,
	merge: merge,
	noop: noop,
	removeFrom: removeFrom,
	set: set,
};
