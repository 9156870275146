import React from 'react';
import ReactDOM from 'react-dom';

import App from '../components/app';

/**
 * Browser renderer (DOM) with re-hydration
 *
 * @type {MapsightUiRenderer}
 * @inheritDoc {MapsightUiRenderer}
 */
export default function mapsightUiBrowserDomRenderer(container, props, hydrate = false) {
	return ReactDOM[hydrate ? 'hydrate' : 'render'](<App {...props} />, container);
}
